import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { DateCommon } from '@libs/shared/date/date.common';
import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { UserInputValidators } from '@libs/shared/user/user-input-validators';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { RegistrationInputedAction } from '@libs/store/registration/actions/registration.action';

import { TimeToRegisterActions } from '@meupatrocinio/modules/time-to-register/state/actions';

@Directive()
export class FrictionlessStep5ComponentCommon implements OnInit, OnDestroy {
  public headline: string;
  public headlineOk: boolean;
  public headlineError: string;
  public description: string;
  public descriptionOk: boolean;
  public descriptionError: string;
  public happyToTravel = '';
  public happyToTravelOk: boolean;
  public happyToTravelError: string;
  public lifestyleBudget: string;
  public interestDescription: string;
  public interestDescriptionOk: boolean;
  public interestDescriptionError: string;
  public myType: number;
  public birthdate: string;

  @Input() myGender: number;
  @Input() lookingForMen: number;
  @Input() lookingForWomen: number;

  @Output() readonly skipped: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly resetStep: EventEmitter<number> = new EventEmitter<number>();

  protected subscription: Subscription[] = [];
  protected pictureData: string[] = [];
  protected hasPictures: boolean;
  protected skippedPhotoStep = false;

  constructor(
    protected translate: TranslateService,
    protected store: Store<IApplicationState>,
  ) {
    this.subscription.push(
      this.store.select('storeImageOnRegistration').subscribe((imageData: IRegistrationPayload): void => {
        this.pictureData = [imageData.profile_image, ...imageData.image];
        this.hasPictures = !!this.pictureData.filter((picture): boolean => picture !== undefined).length;
      }),
    );
  }

  public ngOnInit(): void {
    this.store
      .select('registration')
      .pipe(take(1))
      .subscribe((value): void => {
        this.myType = value['extended[what]'];
        this.birthdate = value.birthdate;
        this.skippedPhotoStep = value.skippedPhotoStep;

        if (value.general_description) {
          this.description = value.general_description;
          this.checkField('description', true);
        }

        if (value.headline) {
          this.headline = value.headline;
          this.checkField('headline', true);
        }

        if (value['extended[happy_to_travel]']) {
          this.happyToTravel = UserCommon.getTravelStatus(value['extended[happy_to_travel]']);
          this.checkField('happyToTravel', true);
        }

        if (value['extended[description]']) {
          this.interestDescription = value['extended[description]'];
          this.checkField('interestDescription', true);
        }

        this.lifestyleBudget = UserCommon.getLifestyleBudgetStatus(UserCommon.EXTENDED_LIFESTYLE_BUDGET_NEGOTIABLE);
      });
  }

  ngOnDestroy(): void {
    this.subscription.forEach((subscription): void => subscription.unsubscribe());
    this.subscription = [];
  }

  public getMatchSex(): number {
    let ret = 0;

    if (this.lookingForMen) {
      ret += UserCommon.GENDER_MAN;
    }

    if (this.lookingForWomen) {
      ret += UserCommon.GENDER_WOMAN;
    }

    return ret;
  }

  public canContinue(): boolean {
    return (
      UserInputValidators.isValidHeadline(this.headline) &&
      UserInputValidators.isValidDescription(this.description) &&
      !!this.lifestyleBudget &&
      UserInputValidators.isValidDescription(this.interestDescription)
    );
  }

  public commitStep(): void {
    this.store.dispatch(
      new RegistrationInputedAction({
        data: {
          general_description: this.description,
          headline: this.headline,
          'extended[happy_to_travel]': this.happyToTravel && UserCommon.getTravelKey(this.happyToTravel),
          'extended[lifestyle_budget]': this.lifestyleBudget && UserCommon.getLifestyleBudgetKey(this.lifestyleBudget),
          'extended[description]': this.interestDescription,
        },
      }),
    );

    this.store.dispatch(
      TimeToRegisterActions.updateRequest({
        endTime: Date.now(),
      }),
    );
  }

  public submitRegistration(): void {
    this.skipped.emit();
  }

  public happyToTravelOptions(): string[] {
    return UserCommon.getTravelOptions();
  }

  public lifestyleBudgetOptions(): string[] {
    return UserCommon.getLifeStyleOptions();
  }

  public checkField(fieldName: string, isOnChange?: boolean): void {
    if (this[`${fieldName}Ok`] !== false && !isOnChange) {
      return;
    }

    this[`${fieldName}Ok`] = false;

    if (this.isEmpty(this[fieldName])) {
      this[`${fieldName}Error`] = 'modules.main.pages.edit_profile.mandatory_field';
      return;
    }

    if (fieldName === 'headline' && !UserInputValidators.isValidHeadline(this[fieldName])) {
      this[`${fieldName}Error`] = 'modules.main.pages.edit_profile.headline.too_short';
      return;
    }

    if (
      (fieldName === 'description' || fieldName === 'interestDescription') &&
      !UserInputValidators.isValidDescription(this[fieldName])
    ) {
      this[`${fieldName}Error`] = 'modules.main.pages.edit_profile.general_description.too_short';
      return;
    }

    this[`${fieldName}Ok`] = true;
    this[`${fieldName}Error`] = 'modules.frictionless.field_ok';
  }

  public canFinishRegistrationLater(): boolean {
    return FrictionlessCommon.canFinishRegistrationLater(this.myType, DateCommon.yearDiff(new Date(this.birthdate)));
  }

  protected isEmpty(value: string): boolean {
    return !value || !value.trim();
  }
}
