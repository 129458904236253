export function truncateString(stringText: string, maxLength = 10): string {
  if (stringText.length === 0) {
    return '';
  }

  if (stringText.length <= maxLength) {
    return `"${stringText}"`;
  }

  return `"${stringText.substring(0, maxLength)}..."`;
}

export function truncateWithoutParentheses(stringText: string, maxLength = 10) {
  if (stringText.length === 0) {
    return '';
  }

  if (stringText.length <= maxLength) {
    return `${stringText}`;
  }

  return `${stringText.substring(0, maxLength)}...`;
}
