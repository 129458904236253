<div
    class="step"
    id="step01"
>
    <div class="form-field feedback">
        <label for="username">{{ 'common.username' | translate }}</label>
        <input
            id="username"
            #usernameElement
            placeholder="{{ 'modules.frictionless.username.placeholder' | translate }}"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
            name="username"
            [(ngModel)]="username"
            (blur)="checkUsername()"
            (focusout)="removeUndueSpacesAfterFocusOut($event)"
            required
            minlength="4"
            maxlength="32"
        >
        <div
            class="field-loading"
            *ngIf="checkingUsername"
        ></div>
        <small [ngClass]="{ ok: usernameAvailable }">{{ usernameError | translate }}</small>
    </div>
    <div class="form-field feedback">
        <label for="email">{{ 'common.email' | translate }}</label>
        <input
            id="email"
            #emailElement
            type="email"
            [placeholder]="'modules.frictionless.email.placeholder' | translate"
            autocomplete="off"
            data-lpignore="true"
            autocorrect="off"
            spellcheck="false"
            name="email"
            [(ngModel)]="email"
            (focus)="checkExistingEmail()"
            required
        >
        <div
            class="field-loading"
            *ngIf="checkingEmail"
        ></div>
        <small [ngClass]="getEmailSuggestionClass()">
            {{ emailError | translate }}
        </small>
        <small
            *ngIf="!isEmailSuggestionEmpty(suggestionString)"
            class="email-suggestion"
            [innerHTML]="suggestionString"
            (click)="replaceEmailToSuggestion()"
        >
        </small>
    </div>
    <div class="form-field feedback">
        <label for="password">{{ 'common.password' | translate }}</label>
        <input
            id="password"
            type="password"
            [placeholder]="'modules.frictionless.password.placeholder' | translate"
            autocomplete="new-password"
            autocorrect="off"
            spellcheck="false"
            name="password"
            [(ngModel)]="password"
            (input)="checkPassword()"
            (blur)="checkPassword(true)"
            required
            minlength="6"
        >
        <small [ngClass]="{ ok: passwordValid }">{{ passwordError | translate }}</small>
    </div>
    <div class="form-field feedback">
        <label for="birthdate">
            {{ 'modules.frictionless.birthdate.label' | translate }}
        </label>
        <div class="born">
            <select
                id="registration-step1-select-day-birth"
                name="birthdayDay"
                [(ngModel)]="birthdayDay"
                (change)="checkDate()"
                required
            >
                <option
                    disabled
                    [value]="undefined"
                >
                    {{ 'modules.frictionless.birthday.day_placeholder' | translate }}
                </option>
                <option
                    *ngFor="let opt of generateRange(1, 31)"
                    [value]="opt"
                >
                    {{ opt }}
                </option>
            </select>
            <select
                id="registration-step1-select-month-birth"
                name="birthdayMonth"
                [(ngModel)]="birthdayMonth"
                (change)="checkDate()"
                required
            >
                <option
                    disabled
                    [value]="undefined"
                >
                    {{ 'modules.frictionless.birthday.month_placeholder' | translate }}
                </option>
                <option
                    *ngFor="let opt of generateRange(1, 12)"
                    [value]="opt"
                >
                    {{ monthName(opt) | translate }}
                </option>
            </select>
            <select
                id="registration-step1-select-year-birth"
                name="birthdayYear"
                [(ngModel)]="birthdayYear"
                (change)="checkDate()"
                required
            >
                <option
                    disabled
                    [value]="undefined"
                >
                    {{ 'modules.frictionless.birthday.year_placeholder' | translate }}
                </option>
                <option
                    *ngFor="let opt of generateYearRange()"
                    [value]="opt"
                >
                    {{ opt }}
                </option>
            </select>
        </div>
        <small [ngClass]="{ ok: birthdayValid }">{{ birthdayError | translate }}</small>
    </div>
    <div
        class="line-option-checked location-auto"
        *ngIf="locationAuto"
    >
        <div>
            {{ 'modules.frictionless.location' | translate }}
            <span
                class="field-loading"
                *ngIf="locationAuto && locationLatitude === null"
            ></span>
            <span>
                {{ locationLatitude }} {{ locationLongitude }}
            </span>
        </div>
        <button
            class="remove-line"
            (click)="locationFallback()"
        ></button>
    </div>
    <div
        class="location-select"
        *ngIf="!locationAuto"
    >
        <div class="form-field">
            <label for="countrySelect">
                {{ 'modules.main.pages.edit_profile.location.country' | translate }}
            </label>
            <select
                id="countrySelect"
                [(ngModel)]="locationCountry"
                name="locationCountry"
                (change)="setCountryName($event.target.value)"
                required
            >
                <option
                    disabled
                    [value]="undefined"
                >
                    {{ 'modules.frictionless.select_option' | translate }}
                </option>
                <option
                    *ngFor="let option of countries"
                    [value]="option.Country_str_code"
                >
                    {{ option.Country_str_name }}
                </option>
            </select>
        </div>
        <div class="form-field">
            <label for="stateSelect">
                {{ 'modules.main.pages.edit_profile.location.province' | translate }}
            </label>
            <select
                id="stateSelect"
                [(ngModel)]="locationState"
                name="locationState"
                (change)="setStateName($event.target.value)"
                required
            >
                <option
                    disabled
                    [value]="undefined"
                >
                    {{ 'modules.frictionless.select_option' | translate }}
                </option>
                <option
                    *ngFor="let option of regions"
                    [value]="option.Admin1_str_code"
                >
                    {{ option.Admin1_str_name }}
                </option>
            </select>
        </div>
        <div class="form-field">
            <label for="citySelect">
                {{ 'modules.main.pages.edit_profile.location.city' | translate }}
            </label>
            <select
                id="citySelect"
                [(ngModel)]="locationCity"
                name="locationCity"
                required
            >
                <option
                    disabled
                    [value]="undefined"
                >
                    {{ 'modules.frictionless.select_option' | translate }}
                </option>
                <option
                    *ngFor="let option of cities"
                    [value]="option.Feature_int_id"
                >
                    {{ option.Feature_str_name }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-field">
        <label for="referenceSelect">
            {{ 'modules.frictionless.reference' | translate }}
        </label>
        <select
            id="referenceSelect"
            [(ngModel)]="reference"
            name="reference"
        >
            <option
                disabled
                [value]="undefined"
            >
                {{ 'modules.frictionless.select_option' | translate }}
            </option>
            <option
                *ngFor="let option of referenceOptions"
                [value]="option"
            >
                {{ option | translate }}
            </option>
        </select>
    </div>
    <div class="buttons-container">
        <button
            id="registration-step1-button-save-continue"
            class="mp-main-button bt-shadow bt-lg"
            [disabled]="!canContinue()"
            (click)="commitStep()"
        >
            {{ 'modules.frictionless.save_and_continue' | translate }}
        </button>
    </div>
</div>
