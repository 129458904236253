import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { BoostToastStrategy } from '@libs/modules/main/toast/strategy/classes/boost-strategy';
import { FavoriteToastStrategy } from '@libs/modules/main/toast/strategy/classes/favorite-strategy';
import { MeltToastStrategy } from '@libs/modules/main/toast/strategy/classes/melt-strategy';
import { MessageToastStrategy } from '@libs/modules/main/toast/strategy/classes/message-strategy';
import { ViewToastStrategy } from '@libs/modules/main/toast/strategy/classes/view-strategy';
import { IToastStrategy } from '@libs/modules/main/toast/strategy/interfaces/toast-strategy.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { ToastType } from '@libs/shared/toast/interfaces/toast-parameters.interface';

@Directive()
export class ToastContextCommon {
  protected strategy: IToastStrategy = new ViewToastStrategy();
  protected readonly strategies: {
    [toastName: string]: IToastStrategy;
  } = {
    [ToastType.typeFavorite]: new FavoriteToastStrategy(),
    [ToastType.typeView]: new ViewToastStrategy(),
    [ToastType.typeMessage]: new MessageToastStrategy(),
  } as const;
  protected customStrategies: {
    [toastName: string]: IToastStrategy;
  } = {
    [ToastType.typeBoost]: new BoostToastStrategy(),
    [ToastType.typeMelt]: new MeltToastStrategy(),
  } as const;

  public changeStrategy(toastType: ToastType | undefined): void {
    const newStrategy: IToastStrategy | undefined = this.getStrategyAccordingToType(toastType);

    if (newStrategy === undefined || toastType === undefined) {
      return;
    }

    this.setStrategy(newStrategy);
  }

  public getCurrentStrategy(): IToastStrategy {
    return this.strategy;
  }

  public getStrategyAccordingToType(toastType: ToastType | undefined): IToastStrategy {
    return this.strategies[toastType ?? ''];
  }

  public changeToCustomToast(toastType: ToastType | undefined): void {
    const newStrategy: IToastStrategy | undefined = this.customStrategies[toastType ?? ''];

    if (newStrategy === undefined || toastType === undefined) {
      return;
    }

    this.setStrategy(newStrategy);
  }

  public execute(store: Store, profile: IProfile): void {
    this.strategy.execute(store, profile);
  }

  public clear(store: Store): void {
    this.strategy.clear(store);
  }

  public navigate(router: Router, profiles: IProfile[]): void {
    this.strategy.navigate(router, profiles);
  }

  public navigateMultiple(router: Router): void {
    this.strategy.navigateMultiple(router);
  }

  public selectAllProfiles$(store: Store): Observable<IProfile[]> {
    return this.strategy.selectAllProfiles$(store).pipe(share());
  }

  protected setStrategy(strategy: IToastStrategy): void {
    this.strategy = strategy;
  }
}
