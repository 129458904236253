<div
    class="registration-form-field registration-form-field--feedback"
    [formGroup]="form"
>
    <div [formGroupName]="groupName">
        <label for="birthDate">
            {{ 'modules.frictionless.birthdate.label' | translate }}
        </label>
        <div
            class="registration-form-field__birthdate"
            id="birthDate"
        >
            <select
                id="birthDateDay"
                [formControlName]="dayControlName"
            >
                <option
                    disabled
                    [value]="''"
                >
                    {{ 'modules.frictionless.birthday.day_placeholder' | translate }}
                </option>
                <option
                    *ngFor="let day of (dayRange | generateRange: true); trackBy: trackByDate"
                    [value]="day"
                >
                    {{ day }}
                </option>
            </select>
            <select
                id="birthDateMonth"
                [formControlName]="monthControlName"
            >
                <option
                    disabled
                    [value]="''"
                >
                    {{ 'modules.frictionless.birthday.month_placeholder' | translate }}
                </option>
                <option
                    *ngFor="let month of (monthRange | generateRange); trackBy: trackByDate"
                    [value]="month"
                >
                    {{ getMonthName(month) | translate }}
                </option>
            </select>
            <select
                name="birthDateYear"
                [formControlName]="yearControlName"
            >
                <option
                    disabled
                    [value]="''"
                >
                    {{ 'modules.frictionless.birthday.year_placeholder' | translate }}
                </option>
                <option
                    *ngFor="let year of (yearRange | generateRange: false : true); trackBy: trackByDate"
                    [value]="year"
                >
                    {{ year }}
                </option>
            </select>
        </div>
        <mp-validation-group-error
            [form]="form"
            [groupName]="groupName"
            *ngIf="canShowValidationErrorFeedback()"
        ></mp-validation-group-error>
        <ng-container *ngIf="shouldShowSuccessFeedback">
            <mp-validation-success
                [successMessage]="'modules.frictionless.birthday.valid'"
                *ngIf="canShowValidationSuccessFeedback()"
            ></mp-validation-success>
        </ng-container>
    </div>
</div>
