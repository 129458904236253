import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import { IProfilePhotos } from '@libs/shared/profile/photo';
import { Tick } from '@libs/utils/timeout-typings';

import { Config } from '@meupatrocinio/config';
import { PhotoVisualizationService } from '@meupatrocinio/services/photo-visualization/photo-visualization.service';

@Component({
  selector: 'mp-carousel',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './carousel.html',
})
export class CarouselComponent implements OnInit, AfterViewInit, OnChanges {
  public dragging = false;
  public draggingTimeout: Tick;
  protected subscriptions: Subscription[] = [];
  protected profileId = 0;
  protected scrolling = false;
  protected position = 0;
  protected oldPosition = 0;
  protected containerWidth = 0;
  protected initialX = 0;
  protected initialY = 0;
  protected touchStartXPosition = 0;
  protected touchEndXPosition = 0;
  protected initialTime = 0;
  protected index = 0;
  protected readonly LONG_SWIPE_TIME = 300;

  @ContentChild(TemplateRef) template: TemplateRef<unknown>;
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('inner', { static: true }) inner: ElementRef<HTMLDivElement>;
  @Output() readonly selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() readonly selectIsDragging: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() readonly swipeLeft: EventEmitter<void> = new EventEmitter<void>();
  @Input() items: IProfilePhotos[];
  @Input() set selectedIndex(index: number) {
    if (index === null || index === undefined) {
      return;
    }

    this.inner.nativeElement.style.transitionTimingFunction = '';
    this.setIndex(index);
  }

  constructor(
    protected photoVisualizationService: PhotoVisualizationService,
    protected activatedRoute: ActivatedRoute,
  ) {
    //
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
        this.profileId = Number(params.get('profileId'));
      }),
    );
  }

  ngAfterViewInit() {
    this.handleTransitionEnd();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('items' in changes) {
      let length = changes.items.currentValue.length;

      if (length > 1) {
        length += 1;
      }

      this.inner.nativeElement.style.width = `${length * 100}%`;
    }
  }

  ngOnDestroy() {
    this.photoVisualizationService.clearPhotoViewsCounter();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public onSwipeLeft() {
    this.swipeLeft.emit();
  }

  public trackByItem(index: number, item: IProfilePhotos | never): number | string {
    if (item === undefined || !(typeof item === 'object') || !('photo_id' in item)) {
      return index;
    }

    return item.photo_id;
  }

  public shouldCancelDragStart() {
    return (
      Config.enableProfileSwipe &&
      (this.items.length === 1 || (this.position === this.items.length - 1 && this.hasSwipedLeft()))
    );
  }

  public handleIsDraggingEmitter() {
    if (this.position > this.items.length - 1) {
      return;
    }

    this.selectIsDragging.emit(true);
  }

  public dragStart(event: MouseEvent | TouchEvent) {
    if (event instanceof TouchEvent) {
      this.touchStartXPosition = event.changedTouches[0].pageX;
    }

    if (this.shouldCancelDragStart()) {
      return;
    }

    this.handleIsDraggingEmitter();

    this.dragging = true;
    this.scrolling = true;
    this.initialTime = performance.now();

    const widthElement = getComputedStyle(this.container.nativeElement).width;

    this.containerWidth = parseFloat(widthElement);

    if (event instanceof MouseEvent) {
      this.initialX = event.pageX;
      this.initialY = event.pageY;
    } else {
      this.initialX = event.targetTouches[0].pageX;
      this.initialY = event.targetTouches[0].pageY;
    }
  }

  public dragMove(event: MouseEvent | TouchEvent) {
    if (this.items.length === 1 || !this.dragging || !this.scrolling) {
      return;
    }

    const dx: number = event instanceof MouseEvent ? this.calculateMouseDX(event) : this.calculateTouchDX(event);

    if (event.cancelable) {
      event.preventDefault();
    }

    this.position = this.normalizePosition(this.index - dx / this.containerWidth);
    this.inner.nativeElement.style.transform = this.translate3D(this.position);
  }

  public dragEnd(e: MouseEvent | TouchEvent) {
    if (e instanceof TouchEvent) {
      this.touchEndXPosition = e.changedTouches[0].pageX;
    }

    if (this.items.length === 1) {
      return;
    }

    this.dragging = false;
    this.scrolling = false;
    this.inner.nativeElement.style.transitionTimingFunction = 'ease-out';

    const timeDelta = performance.now() - this.initialTime;

    this.handleIsDraggingEmitterOnDragEnd();

    if (this.hasSwipedRightOnLastPhoto()) {
      this.position = 0;
    }

    if (this.isSwipeDurationBelowThreshold(timeDelta)) {
      this.handleIndexByPosition();

      return;
    }

    this.setIndex(Math.round(this.position));
  }

  protected handleTransitionEnd() {
    const transitionEndCallback = () => {
      this.dragging = true;
      this.setIndex(this.normalizePosition(this.position));
      setTimeout((): void => {
        this.dragging = false;
      }, 1);
    };

    this.inner.nativeElement.addEventListener('transitionend', transitionEndCallback);

    this.inner.nativeElement.removeEventListener('transitionend', () => {});
  }

  protected get currentCount() {
    let ret = this.items.length;

    if (ret > 1) {
      ret += 1;
    }

    return ret;
  }

  protected handleIsDraggingEmitterOnDragEnd() {
    if (this.draggingTimeout) {
      clearTimeout(this.draggingTimeout);
    }

    this.draggingTimeout = setTimeout(() => {
      this.selectIsDragging.emit(false);
    }, 25);
  }

  protected calculateMouseDX(event: MouseEvent) {
    return event.pageX - this.initialX;
  }

  protected calculateTouchDX(event: TouchEvent) {
    return event.targetTouches[0].pageX - this.initialX;
  }

  protected hasSwipedRightOnLastPhoto() {
    return (
      this.position > this.numberOfPhotosAvailable() && this.currentIndexLowerThanPrevious() && this.oldPosition !== 0
    );
  }

  protected currentIndexLowerThanPrevious() {
    return Math.floor(this.position) < this.oldPosition && this.items[this.position] !== undefined;
  }

  protected handleIndexByPosition() {
    if (this.isItemBehind() || (!this.index && this.isLastPositionOrAhead())) {
      this.setIndex(Math.floor(this.position));
      this.handlePhotoVisualization();

      return;
    }

    if (!this.isItemAhead()) {
      return;
    }

    this.setIndex(Math.ceil(this.position));
    this.handlePhotoVisualization();
  }

  protected handlePhotoVisualization() {
    const photo = this.items[this.position];

    if (!photo) {
      return;
    }

    this.photoVisualizationService.handleEvent(photo, 'carousel', this.profileId);
  }

  protected isSwipeDurationBelowThreshold(time: number) {
    return time < this.LONG_SWIPE_TIME;
  }

  protected isItemAhead() {
    return this.position > this.index;
  }

  protected isItemBehind() {
    return this.position < this.index;
  }

  protected isLastPositionOrAhead() {
    return this.position >= this.items.length - 1;
  }

  protected numberOfPhotosAvailable() {
    return this.items.filter((photo: IProfilePhotos | undefined) => photo !== undefined).length;
  }

  protected hasSwipedLeft() {
    return this.touchEndXPosition < this.touchStartXPosition;
  }

  protected normalizePosition(position: number) {
    if (position >= this.items.length) {
      position -= this.items.length;
    }

    if (position < 0) {
      position += this.items.length;
    }

    return position;
  }

  protected setIndex(index: number) {
    this.oldPosition = index;
    index = Math.max(0, index);
    this.position = index;
    this.index = index % this.items.length;
    this.inner.nativeElement.style.transform = this.translate3D(index);
    this.selectedIndexChange.emit(index);
  }

  protected translate3D(index: number) {
    return `translate3d(-${(index * 100) / this.currentCount}%, 0, 0)`;
  }
}
