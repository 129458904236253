import { IUnsafeAction } from '@libs/store/application-state';
import {
  INITIAL_NOTIFICATION_SETTINGS,
  INotificationSettings,
  NOTIFICATION_SETTING_CHANGED_ACTION,
} from '@libs/store/notifications/actions/notification-setting-changed.action';

export function notificationSettings(
  state: INotificationSettings = INITIAL_NOTIFICATION_SETTINGS,
  action: IUnsafeAction,
): INotificationSettings {
  switch (action.type) {
    case NOTIFICATION_SETTING_CHANGED_ACTION:
      const settings = JSON.parse(JSON.stringify(state));

      for (const payload of action.payload) {
        if (settings[payload.setting] === undefined) {
          settings[payload.setting] = { push: false, email: false };
        }

        settings[payload.setting][payload.type] = payload.value;
      }

      return settings;
    default:
      return state;
  }
}
