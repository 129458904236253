import { FocusTrap } from '@angular/cdk/a11y';
import { OverlayRef } from '@angular/cdk/overlay';

export class ModalRef {
  constructor(
    protected focusTrap: FocusTrap,
    protected overlayRef: OverlayRef,
    protected prevFocused: HTMLElement,
  ) {
    //
  }

  close(): void {
    this.focusTrap.destroy();
    this.overlayRef.dispose();

    if (this.prevFocused) {
      this.prevFocused.focus();
    }
  }
}
