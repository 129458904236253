import { AfterViewInit, Directive, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { ActionbarServiceCommon } from '@libs/modules/main/services/actionbar/actionbar.service.common';

@Directive()
export abstract class ActionbarComponentCommon implements AfterViewInit, OnDestroy {
  @ViewChild('contents', { static: true })
  contents: TemplateRef<unknown> | undefined;

  constructor(protected actionBarService: ActionbarServiceCommon) {
    //
  }

  public ngAfterViewInit(): void {
    this.forceUpdate();
  }

  public ngOnDestroy(): void {
    this.actionBarService.onUpdate.emit();
  }

  public forceUpdate(): void {
    this.actionBarService.onUpdate.emit(this.contents);
  }
}
