<div
    class="promo"
    *ngIf="!paymentSucess"
>
    <div class="big-title">
        {{ 'modules.main.shared.coupon.blur1' | translate }}
        <button
            (click)="redeemClick()"
            *ngIf="!show"
        >
            {{ 'modules.main.shared.coupon.redeem_now' | translate }}
        </button>
    </div>
    <form class="promo-form">
        <div *ngIf="show">
            <input
                type="text"
                placeholder="{{ 'modules.main.shared.coupon.code' | translate }}"
                [(ngModel)]="couponCode"
                name="couponCode"
            >
            <button
                type="submit"
                (click)="redeem()"
                [ngClass]="{'loading': isLoading()}"
            >
                <div>
                    {{ 'modules.main.shared.coupon.redeem' | translate }}
                </div>
                <div class="spin-loader">
                    <svg
                        height="40px"
                        style="enable-background:new 0 0 50 50;"
                        version="1.1"
                        viewBox="0 0 50 50"
                        width="40px"
                        x="0px"
                        :xml:space="preserve"
                        :xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns="http://www.w3.org/2000/svg"
                        y="0px"
                    >
                        <path
                            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
                            fill="#e00c00"
                            transform="rotate(319.408 25 25)"
                        >
                            <animateTransform
                                attributeName="transform"
                                attributeType="xml"
                                dur="0.6s"
                                from="0 25 25"
                                repeatCount="indefinite"
                                to="360 25 25"
                                type="rotate"
                            ></animateTransform>
                        </path>
                    </svg>
                </div>
            </button>
            <div
                class="fail-massage"
                *ngIf="redeemFail()"
            >
                {{ getErrorMessage(this.errorMessage) }}
            </div>
        </div>
    </form>
</div>
<div
    class="payment-sucess"
    *ngIf="paymentSucess"
>
    <p>
        {{ 'shared.coupon.redeem_success_title' | translate }}
    </p>
    <p>
        {{ 'shared.coupon.redeem_success' | translate:{ period: period, membershipType: membershipType } }}
    </p>
</div>
