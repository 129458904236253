import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { EXPIRED_TOKEN_ACTION } from '@libs/store/expired-token/expired-token.action';

export class ExpiredTokenEffectsCommon {
  expiredToken$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(EXPIRED_TOKEN_ACTION),
        tap((): void => {
          this.auth.logout();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    protected actions$: Actions,
    protected auth: AuthenticationServiceCommon,
  ) {
    //
  }
}
