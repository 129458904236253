import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DeactivateAccountCommon } from '@libs/shared/deactivate-account/deactivate-account.common';
import { IReason } from '@libs/store/deactivate-reason/interface';
import { IDeactivateReasonsState, reasonAdapter } from '@libs/store/deactivate-reason/state';

export const selectDeactivateReasonState = createFeatureSelector<IDeactivateReasonsState>('deactivateReason');

export const selectAll = createSelector(selectDeactivateReasonState, reasonAdapter.getSelectors().selectAll);

export const selectCurrentReason = createSelector(
  selectDeactivateReasonState,
  (state: IDeactivateReasonsState): string => state.currentReasonTextSelected,
);

export const selectShuffleReasons = createSelector(selectAll, (reasons: IReason[]): IReason[] => {
  return DeactivateAccountCommon.getShuffleReasons(reasons);
});

export const selectReasonByValue = createSelector(
  selectAll,
  (reasons: IReason[], props: { value: number }): IReason | undefined => {
    return reasons.find((reasonValue: IReason): boolean => {
      return reasonValue.value === props.value;
    });
  },
);
