import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BlockServiceCommon } from '@libs/modules/main/services/block/block.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';
import { ProfileUnblockReceivedAction } from '@libs/store/profiles/actions/blocked.action';

import { Config } from '@meupatrocinio/config';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable()
export class BlockService extends BlockServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpService,
  ) {
    super(store, authHttp);
  }

  public blockUser(profileId: number): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + this.ENDPOINT_BLOCK + profileId;

    return this.authHttp.post(endpoint, {});
  }

  public unblockUser(profileId: number): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + this.ENDPOINT_BLOCK + profileId;

    return this.authHttp.delete(endpoint).pipe(
      tap({
        next: (): void => {
          this.store.dispatch(new ProfileUnblockReceivedAction({ profileId }));
        },
      }),
    );
  }
}
