import { IProfile } from '@libs/shared/profile/profile';
import {
  ProfilesNearbyReceivedAction,
  PROFILES_NEARBY_RECEIVED_ACTION,
  PROFILES_NEARBY_REFRESHED_ACTION,
  PROFILES_NEARBY_REMOVED_ACTION,
} from '@libs/store/profiles/actions/nearby.action';

export function storeNearbyProfiles(state: number[] = [], action: ProfilesNearbyReceivedAction): number[] {
  switch (action.type) {
    case PROFILES_NEARBY_RECEIVED_ACTION:
      return onProfilesNearbyReceived(state, action.payload.profiles);
    case PROFILES_NEARBY_REMOVED_ACTION:
      return onProfilesNearbyRemoved(state, action.payload.profiles);
    case PROFILES_NEARBY_REFRESHED_ACTION:
      return [];
    default:
      return state;
  }
}

function onProfilesNearbyReceived(state: number[], profiles: IProfile[]): number[] {
  for (const profile of profiles) {
    state = onProfileNearbyReceived(state, profile);
  }

  return state;
}

function onProfileNearbyReceived(state: number[], newProfile: IProfile): number[] {
  const index: number = state.indexOf(newProfile.profile_id);
  if (index !== -1) {
    return state;
  }

  return [...state, newProfile.profile_id];
}

function onProfilesNearbyRemoved(state: number[], newProfiles: IProfile[]): number[] {
  for (const profile of newProfiles) {
    state = onProfileNearbyRemoved(state, profile);
  }

  return state;
}

function onProfileNearbyRemoved(state: number[], newProfile: IProfile): number[] {
  const index: number = state.indexOf(newProfile.profile_id);
  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
