import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { DateCommon } from '@libs/shared/date/date.common';
import { IImageRegistrationPayload } from '@libs/shared/profile/image-registration';
import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { RegistrationInputedAction } from '@libs/store/registration/actions/registration.action';

import { TimeToRegisterActions } from '@meupatrocinio/modules/time-to-register/state/actions';

@Directive()
export abstract class FrictionlessStep2ComponentCommon implements OnDestroy, OnInit {
  public hasPicture: boolean;
  public myType: number;
  public birthdate: string;

  @Output() readonly skipped: EventEmitter<void> = new EventEmitter<void>();

  protected subscriptions: Subscription[] = [];

  constructor(protected store: Store<IApplicationState>) {
    //
  }

  public abstract saveUser(): void;

  public abstract restoreUserData(value: IImageRegistrationPayload): void;

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('registration').subscribe((value: IRegistrationPayload): void => {
        this.myType = value['extended[what]'];
        this.birthdate = value.birthdate;
      }),
    );

    this.subscriptions.push(
      this.store.select('storeImageOnRegistration').subscribe((imageData: IImageRegistrationPayload): void => {
        this.restoreUserData(imageData);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public canContinue(): boolean {
    return this.hasPicture;
  }

  public canCommitStep(): boolean {
    return UserCommon.isDaddyMommyByWhat(this.myType) && !this.hasPicture;
  }

  public commitStep(): void {
    this.saveUser();
    this.dispatchRegistrationAction(false);
  }

  public skipWithoutPhoto(): void {
    this.saveUser();
    this.dispatchRegistrationAction(true);
  }

  public submitRegistration(): void {
    this.store.dispatch(TimeToRegisterActions.updateRequest({ endTime: Date.now() }));
    this.skipped.emit();
  }

  public canFinishRegistrationLater(): boolean {
    return FrictionlessCommon.canFinishRegistrationLater(this.myType, DateCommon.yearDiff(new Date(this.birthdate)));
  }

  protected dispatchRegistrationAction(skippedPhotoStep: boolean): void {
    this.store.dispatch(
      new RegistrationInputedAction({
        data: {
          skippedPhotoStep,
        },
      }),
    );
  }
}
