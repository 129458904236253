import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { UiActions } from '@libs/store/new-ui';

import { Config } from '@meupatrocinio/config';

@Injectable({
  providedIn: 'root',
})
export class UpsellService {
  constructor(
    protected store: Store,
    protected translateService: TranslateService,
  ) {
    //
  }

  public isEligibleToSeeAd(user: UserCommon): boolean {
    return !UserCommon.isElite(user) && UserCommon.isDaddyMommy(user);
  }

  public handleHighFrequencyAd(user: UserCommon): void {
    if (!this.isEligibleToSeeAd(user)) {
      return;
    }

    this.showUpsell();
  }

  public showUpsell(): void {
    this.store.dispatch(
      UiActions.setIsUpsellVisible({
        isOpen: true,
      }),
    );
  }

  public hideUpsell(): void {
    this.store.dispatch(
      UiActions.setIsUpsellVisible({
        isOpen: false,
      }),
    );
  }

  public getUpsellText(user: UserCommon): string {
    if (!MembershipCommon.isFree(user.membership_type_id)) {
      return this.translateService.instant('modules.main.upsell.text.elite', {
        gender: user.sex,
      });
    }

    return this.translateService.instant('modules.main.upsell.text.premium');
  }

  public getUpsellPlanBadge(user: UserCommon): string {
    if (!MembershipCommon.isFree(user.membership_type_id)) {
      return `${Config.imagesFolderBaseUrl}elite-badge-only-gold.svg`;
    }

    return `${Config.imagesFolderBaseUrl}premium-rounded.svg`;
  }
}
