import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MembershipType } from '@libs/shared/membership/membership.common';
import { IMembershipState } from '@libs/store/membership/state';

const selectFeature = createFeatureSelector<IMembershipState>('membership');

export const selectLatestPaidMembership = createSelector(
  selectFeature,
  (state: IMembershipState): MembershipType | null => {
    return state.latestPaidMembership;
  },
);
