import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FrictionlessStep1ComponentCommon } from '@libs/modules/frictionless-registration/step1/step1.component.common';
import { EmailSuggestionService } from '@libs/services/email-suggestion/email-suggestion.service';
import { IApplicationState } from '@libs/store/application-state';

import { LocationService } from '@meupatrocinio/services/location/location.service';
import { UserService } from '@meupatrocinio/services/user.service';

@Component({
  selector: 'mp-frictionless-step1',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './step1.html',
})
export class FrictionlessStep1Component extends FrictionlessStep1ComponentCommon {
  constructor(
    protected userService: UserService,
    protected location: LocationService,
    protected store: Store<IApplicationState>,
    protected translateService: TranslateService,
    protected emailSuggestionService: EmailSuggestionService,
  ) {
    super(userService, location, store);
  }

  public checkEmailSuggestion() {
    const emailSuggestionObject = this.emailSuggestionService.getEmailSuggestion(this.email);
    this.suggestedEmail = emailSuggestionObject.suggestedEmail;
    this.suggestionString = emailSuggestionObject.suggestionString;
  }

  public checkExistingEmail(): void {
    const validity = this.emailElement.nativeElement.validity;
    this.suggestionString = '';
    this.suggestedEmail = '';

    if (validity.typeMismatch) {
      this.emailError = 'modules.registration.pages.registration2.email.invalid';
      return;
    }

    if (!this.email) {
      this.emailError = 'modules.registration.pages.registration2.email.missing';
      return;
    }

    super.checkExistingEmail();

    this.checkEmailSuggestion();
  }

  public isEmailSuggestionEmpty(emailSuggestion: string) {
    return this.emailSuggestionService.isEmailSuggestionEmpty(emailSuggestion);
  }

  public replaceEmailToSuggestion() {
    this.email = this.suggestedEmail;

    this.checkExistingEmail();
  }

  public getEmailSuggestionClass() {
    if (!this.emailAvailable) {
      return '';
    }

    if (!this.isEmailSuggestionEmpty(this.suggestionString)) {
      return 'attention';
    }

    return 'ok';
  }
}
