import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SubscribeButtonComponentCommon } from '@libs/components/subscribe-button/subscribe-button.component.common';

import { PaymentInfoService } from '@meupatrocinio/modules/main/services/payment/payment-info.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Component({
  selector: 'mp-subscribe-button',
  templateUrl: 'subscribe-button.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SubscribeButtonComponent extends SubscribeButtonComponentCommon {
  constructor(
    protected authenticationService: AuthenticationService,
    public paymentInfoService: PaymentInfoService,
  ) {
    super(authenticationService, paymentInfoService);
  }

  public getSubscribeText() {
    if (this.isDaddyMommyFree() || this.isBabyFree()) {
      return 'common.subscribe';
    }

    return 'common.upgrade';
  }
}
