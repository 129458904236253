<small
    *ngIf="isControlWithRequiredError; else customValidationErrors"
    class="error"
>
    {{ requiredMessage | translate }}
</small>
<small
    *ngIf="canShowMinLengthError()"
    class="error"
>
    {{ invalidLength | translate }}
</small>
<ng-template #customValidationErrors>
    <small class="error">{{ controlCustomErrorMessage | translate }}</small>
</ng-template>
