import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { DeactivateAccountCommon } from '@libs/shared/deactivate-account/deactivate-account.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { DeactivateReasonActions, DeactivateReasonSelectors, IReason } from '@libs/store/deactivate-reason';

import { DeactivateAccountService } from '@meupatrocinio/services/deactivate-account/deactivate-account.service';
import { ModalService } from '@meupatrocinio/services/modal.service';

@Component({
  selector: 'mp-deactivate-account-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './deactivate-account-modal.html',
})
export class DeactivateAccountModalComponent implements OnInit, OnDestroy, IModalComponent {
  public data: never;
  public user: UserCommon | undefined;
  public joinDate: number;
  public disableReason = -1;
  public disableDescription: string;
  public subscriptions: Subscription[] = [];
  public reasons$: Observable<IReason[]>;
  public readonly MIN_DESCRIPTION_LENGTH: number = 50;
  public readonly OTHER_REASON: number = 0;

  constructor(
    public store: Store<IApplicationState>,
    protected modalService: ModalService,
    protected modalRef: ModalRef,
    protected deactivateAccountService: DeactivateAccountService,
  ) {
    this.subscriptions.push(
      this.store.select('user').subscribe((user: UserCommon): void => {
        this.user = user;
      }),
    );

    this.addDeactivateReasons();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('stats').subscribe((stats): void => {
        this.joinDate = stats.joinDateTimestamp;
      }),
    );

    this.reasons$ = this.store.pipe(select(DeactivateReasonSelectors.selectShuffleReasons));
  }

  closeModal(): void {
    this.modalRef.close();
  }

  isDaddyMommy(): boolean {
    return UserCommon.isDaddyMommy(this.user);
  }

  validate(): boolean {
    if (this.disableReason < 0) {
      this.modalService.open('modules.main.pages.visibility.disable.validation.reason');
      return false;
    }

    if (this.disableReason >= 0 && (!this.disableDescription || !this.disableDescription.trim().length)) {
      this.modalService.open('modules.main.pages.visibility.disable.validation.description');
      return false;
    }

    if (this.disableDescription.length < this.MIN_DESCRIPTION_LENGTH && this.disableReason === this.OTHER_REASON) {
      this.modalService.open('modules.main.pages.visibility.disable.validation.description_length');
      return false;
    }

    return true;
  }

  disableAccountSubmit(): void {
    if (this.validate()) {
      this.disableAccount();
    }
  }

  disableAccount(): void {
    this.deactivateAccountService.handleDisableAccount(this.disableReason, this.disableDescription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  isJoinDaysHighEnough(): boolean {
    return UserCommon.getUserJoinDays(this.joinDate) > 10;
  }

  hasSelectedDisableReason(): boolean {
    return this.disableReason >= 0;
  }

  addDeactivateReasons(): void {
    this.store.dispatch(
      DeactivateReasonActions.upsertDeactivateReasons({
        reasons: DeactivateAccountCommon.getReasons(),
      }),
    );
  }
}
