import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { PaymentInfoActions, PaymentInfoSelectors, PaymentSelectors } from '@libs/store/payment-info';

import { Config } from '@meupatrocinio/config';

@Component({
  selector: 'mp-payment-nagbar-v2',
  styleUrls: ['payment-nagbar-v2.scss'],
  templateUrl: './payment-nagbar-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentNagbarV2Component implements OnInit {
  public paymentStatus$: Observable<PaymentStatus> = of();
  public provider$: Observable<string> = of();

  constructor(
    protected store: Store,
    protected translate: TranslateService,
  ) {
    //
  }

  ngOnInit(): void {
    this.paymentStatus$ = this.store.select(PaymentInfoSelectors.selectPaymentStatus);
    this.provider$ = this.store.select(PaymentSelectors.selectSelectedProvider);
  }

  public paymentFailed(paymentStatus: PaymentStatus): boolean {
    return paymentStatus === PaymentStatus.PAYMENT_ERROR;
  }

  public close(): void {
    this.store.dispatch(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus: PaymentStatus.PAYMENT_NONE,
      }),
    );
  }

  public get closeSvgPath(): string {
    return `${Config.imagesFolderBaseUrl}close-upgrade-status.svg`;
  }

  public get errorSvgPath(): string {
    return `${Config.imagesFolderBaseUrl}error.svg`;
  }

  public get alternativoInfoForButtonImage(): string {
    return this.translate.instant('common.close');
  }

  public get errorTitle(): string {
    return this.translate.instant('modules.main.pages.home.error_payment');
  }

  public get errorDescription(): string {
    return this.translate.instant('modules.main.pages.home.error_subtext_v2', {
      email: 'suporte@meupatrocinio.com',
    });
  }
}
