import { UserCommon } from '@libs/shared/user/user.common';

const TWA_ORIGIN_HOSTNAME: string = 'beta.meupat.com';

export class OriginUtils {
  public static origin(): number {
    if (!OriginUtils.isTWAOrigin()) {
      return UserCommon.ORIGIN_APP_WEB;
    }

    return UserCommon.ORIGIN_APP_ANDROID;
  }

  public static isTWAOrigin(): boolean {
    return window.location.origin.includes(TWA_ORIGIN_HOSTNAME);
  }
}
