<div class="method-selector">
    <ul class="method-selector__list">
        <li
            class="method-selector__item"
            *ngFor="let paymentMethod of paymentMethods; trackBy: trackByPaymentMethod"
        >
            <button
                class="method-selector__button"
                (click)="changePaymentMethod(paymentMethod.name)"
                [class.method-selector__button--selected]="isSelected(paymentMethod.name)"
            >
                {{ paymentMethod.label | translate }}
            </button>
        </li>
    </ul>
</div>
