import { Injectable } from '@angular/core';

import { AnalyticsEvent } from '@libs/services/analytics/analytics.service.common';
import { NativeEventsNetcore } from '@meupatrocinio/infra/netcore/types/native-events-netcore';

export interface ICustomWindow extends Window {
  dataLayer?: {
    push(content: AnalyticsEvent): void;
  };
  smartech?: (eventName: NativeEventsNetcore, value: string | number, object?: Object) => void;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalObjectServiceCommon {
  get window(): ICustomWindow {
    return window;
  }
}
