import { Directive, Input } from '@angular/core';

import { IUnsubscribeEmailResponse } from '@libs/shared/unsubscribe-email-response/unsubscribe-email-response.interface';

@Directive()
export class EmailUnsubscribeHeaderComponentCommon {
  @Input() mpLogo: string | undefined;
  @Input() emailResponse: IUnsubscribeEmailResponse | undefined;

  protected readonly OBJECT_EMPTY_LENGTH: number = 0;

  canShowEmail(): boolean {
    return this.emailResponse !== undefined && Object.keys(this.emailResponse).length > this.OBJECT_EMPTY_LENGTH;
  }
}
