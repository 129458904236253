import { AnimationEvent } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IModalViewOptions } from '@libs/components/modal-view/interface/modal-view-options';
import { MODAL_VIEW_OPTIONS } from '@libs/components/modal-view/modal-view.tokens';
import { ModalViewComponentCommon } from '@libs/modules/main/shared/modal-view/modal-view.component.common';
import { IApplicationState } from '@libs/store/application-state';
import { ModalViewActions, ModalViewSelectors } from '@libs/store/modal-view';

import { ModalViewAnimation, STATES_STR } from '@meupatrocinio/modules/main/shared/modal-view/modal-view.animation';

@Component({
  selector: 'mp-modal-view',
  templateUrl: './modal-view.html',
  animations: ModalViewAnimation,
})
export class ModalViewComponent extends ModalViewComponentCommon implements OnInit, OnDestroy {
  @ViewChild('modalViewContent', { read: ViewContainerRef, static: true })
  modalViewContent: ViewContainerRef;
  @ViewChild('modalViewContainer', { static: true })
  modalViewContainer: ElementRef;

  public animationState: string = STATES_STR.OPEN;
  public toggleAnimationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();
  public withHeaderModifierClass = 'modal-view__container--with-header';

  protected subscriptions: Subscription[] = [];

  constructor(
    @Inject(MODAL_VIEW_OPTIONS) public modalViewOptions: IModalViewOptions,
    protected store: Store<IApplicationState>,
    protected router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToModalViewIsOpened();
    this.scrollToContainerTopAfterNavigation();
  }

  ngOnDestroy(): void {
    this.toggleAnimationStateChanged.complete();
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  close(): void {
    this.store.dispatch(ModalViewActions.close());
  }

  subscribeToModalViewIsOpened(): void {
    this.subscriptions.push(
      this.store.pipe(select(ModalViewSelectors.selectIsOpened)).subscribe({
        next: (isModalOpened: boolean): void => {
          if (isModalOpened) {
            return;
          }

          this.animationState = STATES_STR.CLOSED;
        },
      }),
    );
  }

  protected scrollToContainerTopAfterNavigation(): void {
    this.subscriptions.push(
      this.router.events.subscribe({
        next: (navigationEvent: Event): void => {
          if (!(navigationEvent instanceof NavigationEnd)) {
            return;
          }

          this.modalViewContainer.nativeElement.scrollTop = 0;
        },
      }),
    );
  }

  animationFinished(event: AnimationEvent): void {
    this.toggleAnimationStateChanged.emit(event);
  }

  get hasHeader(): boolean {
    return this.modalViewOptions.showHeader;
  }

  get hasFooter(): boolean {
    return this.modalViewOptions.showFooter;
  }

  get containerModifierClasses(): string {
    const modifierClasses: string[] = [];

    if (this.hasHeader) {
      modifierClasses.push(this.withHeaderModifierClass);
    }

    return modifierClasses.join(' ');
  }
}
