import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export enum STATES_STR {
  VOID = 'void',
  OPEN = 'open',
  CLOSED = 'closed',
}

export const ModalViewAnimation: AnimationTriggerMetadata[] = [
  trigger('ModalView', [
    state(
      STATES_STR.VOID,
      style({
        top: '100vh',
      }),
    ),
    state(
      STATES_STR.OPEN,
      style({
        top: '0',
      }),
    ),
    state(
      STATES_STR.CLOSED,
      style({
        top: '100vh',
      }),
    ),
    transition('* => *', [animate('400ms ease-in-out')]),
  ]),
];
