import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';

@Directive()
export class ValidationSuccessComponentCommon implements OnInit, OnDestroy {
  @Input() public successMessage = '';
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() controlName = '';
  protected subscriptions: Subscription[] = [];

  constructor(protected changeDetectorReference: ChangeDetectorRef) {
    //
  }

  ngOnInit(): void {
    this.handleValueChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }

  protected handleValueChanges(): void {
    this.subscriptions.push(
      FormHelpers.getControlValueChanges$<string>(this.form, this.controlName).subscribe({
        next: (): void => {
          this.changeDetectorReference.markForCheck();
        },
      }),
    );
  }
}
