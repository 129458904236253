import { Action, createReducer, on } from '@ngrx/store';

import { profileContextReceived } from '@libs/store/profile-context/actions';
import {
  IProfileContextState,
  profileContextEntitiesInitialState,
  profileContextEntityAdapter,
} from '@libs/store/profile-context/state';

const previousUrlReducer = createReducer(
  profileContextEntitiesInitialState,
  on(profileContextReceived, (state, { profileContext }) => {
    return profileContextEntityAdapter.upsertOne({ id: 'current', profileContext: profileContext }, state);
  }),
);

export function reducer(state: IProfileContextState | undefined, action: Action): IProfileContextState {
  return previousUrlReducer(state, action);
}
