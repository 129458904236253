import { Directive, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BoostServiceCommon } from '@libs/modules/boost/services/boost/boost.service.common';
import { IProfile } from '@libs/shared/profile/profile';

@Directive()
export abstract class BoostNagbarComponentCommon implements OnInit {
  public profiles$: Observable<IProfile[]> = of([]);

  constructor(protected boostService: BoostServiceCommon) {
    //
  }

  ngOnInit(): void {
    this.profiles$ = this.boostService.getBoostProfiles$();
  }
}
