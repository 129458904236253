import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { throttle } from 'rxjs/operators';

import { AuthRequestFacadeCommon } from '@libs/modules/main/services/auth-request-facade/auth-request-facade.common';
import { AnalyticsServiceCommon } from '@libs/services/analytics/analytics.service.common';
import { LoginInfo } from '@libs/shared/profile/login-info';
import { IUnsubscribeEmailResponse } from '@libs/shared/unsubscribe-email-response/unsubscribe-email-response.interface';
import { IStats } from '@libs/shared/user/stats';
import { IApplicationState } from '@libs/store/application-state';
import { GtmStatsAction } from '@libs/store/authentication/actions/stats.action';

@Injectable()
export abstract class UserServiceCommon implements OnDestroy {
  protected readonly contentType: string = 'application/json';
  protected readonly contentTypeOctetStream: string = 'application/octet-stream';
  protected readonly THROTTLE_REQUEST_TIME: number = 5000;
  protected readonly FIVE_MINUTES_TIMESTAMP: number = 300000;

  protected gtmSubject: Subject<void>;

  protected defaultHeaders: HttpHeaders;

  constructor(
    protected http: HttpClient,
    protected authRequestFacade: AuthRequestFacadeCommon,
    protected store: Store<IApplicationState>,
    protected translate: TranslateService,
    protected analytics: AnalyticsServiceCommon,
  ) {
    this.defaultHeaders = new HttpHeaders()
      .append('mobile', this.getOrigin())
      .append('X-MP-Request-Origin', this.getOrigin());
  }

  ngOnDestroy(): void {
    this.destroyGtmSubject();
  }

  abstract registerNewUser(userdata: any): Observable<any>;

  abstract getClientInfo(): string;

  abstract getOrigin(): string;

  abstract login(loginInfo: LoginInfo): Observable<HttpResponse<any>>;

  abstract forgotPassword(email: string): Observable<HttpResponse<any>>;

  abstract isEmailUnique(email: string): Observable<HttpResponse<any>>;

  abstract isUsernameUnique(username: string): Observable<HttpResponse<any>>;

  abstract sendVerificationEmailAgain(email: string, token: string): Observable<HttpResponse<any>>;

  abstract reactivate(token: string): Observable<HttpResponse<any>>;

  abstract unsubscribeEmailByCampaign(key: string): Observable<HttpResponse<IUnsubscribeEmailResponse>>;

  protected abstract getStats(callback: (response: IStats) => void): void;

  abstract checkForgottenPasswordHash(hash: string): Observable<{ token: string }>;

  abstract checkEmailKey(hash: string): Observable<{ token: string }>;

  handleErrors = (error: Response): Observable<never> => {
    return throwError(error);
  };

  getEmailMessageByErrorResponse(err: HttpErrorResponse): string {
    let emailError = 'common.email.unknown.error';

    if (err.error && err.error.error !== undefined) {
      emailError = this.getEmailMessage(err.error.error);
    }

    return emailError;
  }

  getEmailMessageByOKResponse(data: HttpResponse<any>): string {
    let emailMessage = '';

    if (data.body && data.body.return !== undefined) {
      emailMessage = this.getEmailMessage(data.body.return);
    }

    return emailMessage;
  }

  public getEmailMessage(message: string): string {
    const messageMap = {
      'email.not.found': 'email.not.valid',
      'ip.not.found': 'email.not.valid',
    };

    return `common.${messageMap[message] || message}`;
  }

  public handleAnalyticsMeData(): void {
    this.gtmSubject = new Subject<void>();
    this.gtmSubject.pipe(throttle((): Observable<number> => timer(this.THROTTLE_REQUEST_TIME))).subscribe({
      next: (): void =>
        this.getStats((data: IStats): void => {
          this.store.dispatch(new GtmStatsAction(data));
        }),
    });
  }

  public getGtmStats(): void {
    if (this.gtmSubject === undefined || this.gtmSubject.closed) {
      return;
    }

    this.gtmSubject.next();
  }

  public destroyGtmSubject(): void {
    if (this.gtmSubject === undefined || this.gtmSubject.closed) {
      return;
    }

    this.gtmSubject.unsubscribe();
  }

  protected extractData(res: any): any {
    if (res.status < 200 || res.status >= 300) {
      throw new Error('Bad response status: ' + res.status);
    }

    return res.body.data;
  }

  protected invalidEmailError = (err: HttpErrorResponse): Observable<never> => {
    let translateCode = 'common.request_error';

    if (
      err.error &&
      err.error.error !== undefined &&
      err.error.error.email !== undefined &&
      err.error.error.email[0] !== undefined &&
      err.error.error.email[0] === 'validation.exists'
    ) {
      translateCode = 'common.email.not_found';
    }

    if (err.error && err.error.email !== undefined && err.error.email === 'status.temporary_deleted') {
      translateCode = 'common.email.status.temporary_deleted';
    }

    return throwError(this.translate.instant(translateCode));
  };

  protected bearerByToken(token: string): string {
    return 'Bearer ' + token;
  }
}
