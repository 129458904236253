export enum ProductPaths {
  EXPRESS_APPROVAL = 'checkout',

  TRIAL_DADDY_PREMIUM_7_DAYS = 'trial-premium-7-days',

  DADDY_PREMIUM_15_DAYS = 'daddy-premium-15-days',
  DADDY_PREMIUM_1_MONTH = 'daddy-premium-1-month',
  DADDY_PREMIUM_3_MONTHS = 'daddy-premium-3-months',
  DADDY_PREMIUM_6_MONTHS = 'daddy-premium-6-months',

  DADDY_PREMIUM_PLUS_1_MONTH = 'daddy-premium-plus-1-month',

  DADDY_ELITE_1_MONTH = 'daddy-elite-1-month',
  DADDY_ELITE_3_MONTHS = 'daddy-elite-3-months',
  DADDY_ELITE_6_MONTHS = 'daddy-elite-6-months',

  BABY_PREMIUM_1_MONTH = 'baby-premium-1-month',
  BABY_PREMIUM_3_MONTHS = 'baby-premium-3-months',
  BABY_PREMIUM_6_MONTHS = 'baby-premium-6-months',
}
