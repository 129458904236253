import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { TrialServiceCommon } from '@libs/modules/main/services/trial/trial.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@meupatrocinio/config';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class TrialService extends TrialServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpService,
    protected auth: AuthenticationService,
    protected router: Router,
  ) {
    super(store, authHttp, router, auth);
  }

  public isElectiveToDeactivateModal(user: UserCommon): boolean {
    return UserCommon.isDaddyMommyFree(user) && Config.gameTrial.enableDeactivateGame;
  }

  public trialOptionsRequest(): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}payment/all-trials`;

    return this.authHttp.get(endpoint);
  }
}
