import { Injectable, Type } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { concatMap, delay, map, tap } from 'rxjs/operators';

import { IModalViewCloseByNavigate } from '@libs/components/modal-view/interface/modal-view-close-by-navigate';
import { IModalViewOptions } from '@libs/components/modal-view/interface/modal-view-options';
import { IApplicationState } from '@libs/store/application-state';
import { ModalViewActions } from '@libs/store/modal-view';

@Injectable({
  providedIn: 'root',
})
export abstract class ModalViewEffectsCommon {
  openModalView$: Observable<void> = createEffect(
    (): Observable<void> =>
      this.actions$.pipe(
        ofType(ModalViewActions.openModalView),
        map(
          ({
            component,
            options,
          }: {
            component: Type<unknown>;
            options: IModalViewOptions;
          }): void => {
            this.openModalView(component, options);
            this.handleModalViewIsOpenend(true);
          },
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  closeModalView$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ModalViewActions.close),
        tap((): void => {
          this.closeModalView();
          this.handleModalViewIsOpenend(false);
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  closeAndDispatchNavigate$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ModalViewActions.closeAndDispatchNavigate),
        concatMap(({ route }: IModalViewCloseByNavigate): Observable<Action> => {
          this.closeModalView();
          this.handleModalViewIsOpenend(false);

          return of(ModalViewActions.navigateAfterClosing({ route })).pipe(delay(450));
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  navigateAfterClosing$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ModalViewActions.navigateAfterClosing),
        tap(({ route }: IModalViewCloseByNavigate): void => {
          this.router.navigate(route);
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected store: Store<IApplicationState>,
    protected router: Router,
  ) {
    //
  }

  abstract openModalView(component: Type<unknown>, options: IModalViewOptions): void;

  abstract closeModalView(): void;

  handleModalViewIsOpenend(isModalOpened: boolean): void {
    this.store.dispatch(
      ModalViewActions.setModalOpened({
        isModalOpened,
      }),
    );
  }
}
