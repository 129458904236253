import { Action } from '@ngrx/store';

export const SHOW_HIDE_MP_SAFE_TIP_NAGBAR_ACTION: string = 'SHOW_HIDE_MP_SAFE_TIP_NAGBAR_ACTION';

export class ShowHideMPSafeTipNagbarAction implements Action {
  readonly type: string = SHOW_HIDE_MP_SAFE_TIP_NAGBAR_ACTION;

  constructor(public payload: boolean) {
    //
  }
}
