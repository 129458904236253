<ul class="tablist-radio-lookalike" role="tablist">
    <mp-payment-method-option
        *ngIf="canShowCardPaymentMethod(user) | async"
        [activeClassConditional]="isCardPayment()"
        [placeholderText]="'modules.main.pages.payment.methods.card'"
        [brands]="getPaymentBrands(PAYMENT_CARD)"
        (selected)="changePaymentType(PAYMENT_CARD)"
    ></mp-payment-method-option>
    <mp-payment-method-option
        *ngIf="canShowBoletoPaymentMethod() | async"
        [activeClassConditional]="isBoletoPayment()"
        [placeholderText]="'modules.main.pages.payment.methods.boleto'"
        [brands]="getPaymentBrands(PAYMENT_BOLETO)"
        (selected)="changePaymentType(PAYMENT_BOLETO)"
    ></mp-payment-method-option>
    <mp-payment-method-option
        *ngIf="shouldShowPixPayment(hasPixTest$ | async)"
        [activeClassConditional]="isPixPayment()"
        [placeholderText]="'modules.main.pages.payment.methods.pix'"
        [brands]="getPaymentBrands(PAYMENT_PIX)"
        (selected)="changePaymentType(PAYMENT_PIX)"
    ></mp-payment-method-option>
    <mp-payment-method-option
        *ngIf="isTrial"
        [activeClassConditional]="false"
        [placeholderText]="'modules.main.pages.payment.methods.boleto'"
        [additionalText]="'modules.main.pages.payment.methods.boleto.unavailable'"
        [brands]="getPaymentBrands(PAYMENT_BOLETO)"
        [disabled]="true"
    ></mp-payment-method-option>
</ul>
