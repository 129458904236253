import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { IThreeDotsMenuItem } from '@libs/shared/interfaces/three-dots-menu-item.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IConversation } from '@libs/store/conversations';
import { ProfileContextActions } from '@libs/store/profile-context';

@Component({
  selector: 'mp-message-header',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './message-header.html',
})
export class MessageHeaderComponent {
  @Input() receiver: IProfile | undefined;
  @Input() currentConversation: IConversation;
  @Input() isRecipientWriting: boolean;
  @Input() threeDotsMenu: IThreeDotsMenuItem[];

  constructor(
    protected router: Router,
    protected store: Store,
  ) {
    //
  }

  public isReceiverOnline(): boolean {
    if (!this.receiver) {
      return false;
    }

    return UserCommon.isOnline(this.receiver);
  }

  public navigateToProfile(): void {
    this.handleUrl();
    this.router.navigate(['main', 'profile', this.receiver.profile_id]);
  }

  public handleUrl() {
    this.store.dispatch(
      ProfileContextActions.profileContextReceived({
        profileContext: {
          originName: 'message-header',
          previousUrl: this.router.url,
          promotionName: this.receiver.promotionName ?? '',
          score: this.receiver.score ?? '',
          recommendationId: this.receiver.recommendationId ?? '',
        },
      }),
    );
  }
}
