<div class="pictures">
    <label
        class="avatar-label"
        *ngFor="let i of indices"
    >
        <div class="avatar">
            <div class="content">
                @if (isCropping(isCropping$ | async, i)) {
                  <div class="d-flex items-center justify-center w-full">
                    <div class="inline-loader"></div>
                  </div>
                } @else if (!pictureData[i] ) {
                  <div>
                      <img
                          class="icon"
                          [src]="getCameraImg()"
                      >
                      <div>
                          {{ 'modules.frictionless.select_picture' | translate }}
                      </div>
                  </div>
                } @else if (pictureData[i]) {
                  <img
                    class="picture"
                    [src]="pictureData[i]"
                  >
                }
                <input
                    #fileInput
                    type="file"
                    [accept]="getAcceptedImageTypes()"
                    (change)="processPicture($event, i)"
                    required
                >
            </div>
        </div>
    </label>
</div>
<div
    class="modal"
    *ngIf="isUploading"
>
    <mp-loading></mp-loading>
</div>
