import { Money } from '@libs/shared/money/money';

export interface IInstalmentToInterest {
  [installment: number]: number;
}

export class Interest {
  protected static readonly PERCENT_0: number = 0;
  protected static readonly PERCENT_1: number = 1;
  protected static readonly PERCENT_2: number = 2;
  protected static readonly PERCENT_3: number = 3;
  protected static readonly PERCENT_4: number = 4;
  protected static readonly PERCENT_5: number = 5;

  public static readonly InstalmentToInterest: IInstalmentToInterest = {
    1: Interest.PERCENT_0,
    2: Interest.PERCENT_1,
    3: Interest.PERCENT_2,
    4: Interest.PERCENT_3,
    5: Interest.PERCENT_4,
    6: Interest.PERCENT_5,
  };

  constructor(
    protected price: Money,
    protected interestPercentage: number,
  ) {
    this.price = price;
    this.interestPercentage = interestPercentage;
  }

  calcInterestAmount(): Money {
    return new Money((this.price.toNumber() * this.interestPercentage) / 100);
  }

  getTotal(): Money {
    return new Money(this.calcInterestAmount().toNumber() + this.price.toNumber());
  }
}
