import { Action } from '@ngrx/store';

import { IModalPhotoData } from '@libs/shared/interfaces/modal-photo-data.interface';

export const OPEN_CROP_MODAL_ACTION: string = 'OPEN_CROP_MODAL_ACTION';

export class OpenCropModalAction implements Action {
  readonly type: string = OPEN_CROP_MODAL_ACTION;

  constructor(public payload: IModalPhotoData) {
    //
  }
}
