import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';

import { destroyAnalytics } from '@libs/store/analytics/actions';
import { ModalViewActions } from '@libs/store/modal-view';

import { Config } from '@meupatrocinio/config';
import { DatadogActions } from '@meupatrocinio/infra/datadog/actions';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { ListTTLService } from '@meupatrocinio/services/list-ttl.service';
import { TrialService } from '@meupatrocinio/services/trial/trial.service';
import { UserService } from '@meupatrocinio/services/user.service';

interface INavigationEvent {
  id?: number;
  url?: string;
  start?: number;
  end?: number;
}

@Component({
  selector: 'mp-app',
  changeDetection: ChangeDetectionStrategy.Default,
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  protected navigationEvents: INavigationEvent[] = [];
  protected subscriptions: Subscription[] = [];

  constructor(
    protected auth: AuthenticationService,
    public translate: TranslateService,
    protected router: Router,
    protected downloadManager: DownloadManagerService,
    protected listsTTL: ListTTLService,
    protected zone: NgZone,
    protected userService: UserService,
    protected store: Store,
    protected trialService: TrialService,
    private readonly renderer2: Renderer2,
  ) {
    this.subscriptions.push(
      router.events.subscribe((event): void => {
        if (Config.showLogs.routing && event instanceof NavigationStart) {
          this.debugNavigationStart(event);
        }

        if (Config.showLogs.routing && event instanceof NavigationEnd) {
          this.debugNavigationEnd(event);
        }

        if (Config.development && event instanceof NavigationError) {
          console.error(event);
        }
      }),
    );

    this.adjustVH();
    this.monitorVH();
    this.monitorLogout();
    this.checkReduxName();
  }

  ngOnInit(): void {
    if (Config.showLogs.device) {
      const date = new Date();
      const timeString =
        date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();

      console.log('[' + timeString + '] APP --> AppComponent.ngOnInit()');
    }

    this.setModalViewStateAsClosed();
    this.store.dispatch(DatadogActions.initialize());

    this.injectDesignTokensStylesheet();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  protected setModalViewStateAsClosed(): void {
    this.store.dispatch(
      ModalViewActions.setModalOpened({
        isModalOpened: false,
      }),
    );
  }

  protected checkReduxName(): void {
    const reduxName = localStorage.getItem('reduxName');

    if (reduxName === Config.reduxName) {
      return;
    }

    if (!reduxName) {
      localStorage.clear();
      localStorage.setItem('reduxName', Config.reduxName);
      return;
    }

    localStorage.removeItem(reduxName);
    localStorage.setItem('reduxName', Config.reduxName);
  }

  protected adjustVH = (): void => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  protected monitorVH(): void {
    this.subscriptions.push(fromEvent(window, 'resize').subscribe(this.adjustVH));
  }

  protected monitorLogout(): void {
    this.subscriptions.push(
      this.auth.onLogout$.subscribe((): void => {
        this.downloadManager.clear();
        this.listsTTL.destroy();
        this.store.dispatch(destroyAnalytics());
      }),
    );
  }

  protected debugNavigationStart(event: NavigationStart): void {
    const date = new Date();
    this.navigationEvents[event.id] = {
      id: event.id,
      url: event.url,
      start: Math.floor(date.getTime()),
    };

    const timeString =
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();
    console.log(`[${timeString}] ROUTE START --> ${event.url}`);
  }

  protected debugNavigationEnd(event: NavigationEnd): void {
    if (this.navigationEvents[event.id] === undefined) {
      return;
    }

    const date = new Date();
    this.navigationEvents[event.id].end = Math.floor(date.getTime());
    const timeString =
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();
    const loadingTime = this.navigationEvents[event.id].end - this.navigationEvents[event.id].start;

    console.log(`[${timeString}] ROUTE END --> ${event.url} took ${loadingTime}ms`);
  }

  protected injectDesignTokensStylesheet() {
    const themeLink = this.renderer2.createElement('link');
    this.renderer2.setAttribute(themeLink, 'rel', 'stylesheet');
    //TO-DO: change dynamically based on the brand.
    this.renderer2.setAttribute(themeLink, 'href', './assets/tokens/meupatrocinio/tokens.css');
    this.renderer2.appendChild(document.head, themeLink);
  }
}
