import { TranslateService } from '@ngx-translate/core';

import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { MembershipType } from '@libs/shared/membership/membership.common';

import { CPFValidationHandler } from '@meupatrocinio/modules/payment-v2/validators/cpf-validation-handler';
import { ModalService } from '@meupatrocinio/services/modal.service';

export class CPFValidator {
  public cpfValidationHandler: CPFValidationHandler;

  constructor(
    private readonly paymentHelpersService: PaymentHelpersService,
    private readonly modalService: ModalService,
    private readonly translateService: TranslateService,
    private readonly type: MembershipType,
    private readonly period: number,
  ) {
    this.cpfValidationHandler = new CPFValidationHandler(
      this.paymentHelpersService,
      this.modalService,
      this.translateService,
      this.type,
      this.period,
    );
  }

  public isFormValid(cpf: string): boolean {
    return (
      this.cpfValidationHandler.isExistingCPF(cpf) &&
      this.cpfValidationHandler.isValidInput(cpf) &&
      this.cpfValidationHandler.isValidField(cpf) &&
      this.cpfValidationHandler.isNotTrialPayment()
    );
  }
}
