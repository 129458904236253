import { Directive, Input, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ConversationServiceCommon } from '@libs/modules/main/services/conversation/conversation.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { ConversationSelectors } from '@libs/store/conversations';

@Directive()
export class UserMenuItemsComponenCommon implements OnDestroy {
  readonly PAGE_ID_HOME: number = 0;
  readonly PAGE_ID_CONVERSATION: number = 1;
  readonly PAGE_ID_SEARCH: number = 2;
  readonly PAGE_ID_INTERESTS: number = 3;

  protected pageId = -1;
  protected subscriptions: Subscription[] = [];

  @Input() isSelectedForSearchV2 = false;

  constructor(
    protected router: Router,
    protected store: Store<IApplicationState>,
    protected auth: AuthenticationServiceCommon,
    protected conversationService: ConversationServiceCommon,
  ) {
    this.subscriptions.push(
      this.router.events.subscribe((event: Event): void => {
        if (event instanceof NavigationEnd || event instanceof NavigationStart) {
          this.onRouteChanged(event.url);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  onRouteChanged(path: string): void {
    this.pageId = this.pageIdByPath(path);
  }

  getUnreadConversationsCount$(): Observable<number> {
    return this.store.pipe(select(ConversationSelectors.selectUnreadConversations));
  }

  showUnreadConversations$(): Observable<boolean> {
    return this.store.pipe(select(ConversationSelectors.hasUnreadConversations));
  }

  pageIdByPath(path: string): number {
    if (path.indexOf('/main/home') !== -1) {
      return this.PAGE_ID_HOME;
    }

    if (path.indexOf('/main/conversation') !== -1) {
      return this.PAGE_ID_CONVERSATION;
    }

    if (path.indexOf('/main/search') !== -1) {
      return this.PAGE_ID_SEARCH;
    }

    if (path.indexOf('/main/favorites') !== -1) {
      return this.PAGE_ID_INTERESTS;
    }

    return -1;
  }

  isInActivedPage(page: number): boolean {
    return this.pageId === page;
  }

  isConnected(): boolean {
    return this.auth.isAuthenticated();
  }
}
