import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subject, interval } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PaymentInfoSelectors } from '@libs/store/payment-info';

@Injectable({
  providedIn: 'root',
})
export class PixStateFacadeService implements OnDestroy {
  public clearSubscriptions$ = new Subject<void>();
  public expirationTimeClearSubject$ = new Subject<void>();
  private readonly fifteenMinutesInMilliseconds: number = 15 * 60 * 1000;
  private readonly defaultExpirationTime: string = '15:00';

  private readonly isPayingSubject = new BehaviorSubject<boolean>(false);
  public isPaying$ = this.isPayingSubject.asObservable();

  private readonly qrCodeSubject = new BehaviorSubject<string>('');
  public qrCode$ = this.qrCodeSubject.asObservable();

  private readonly copyCodeSubject = new BehaviorSubject<string>('');
  public copyCode$ = this.copyCodeSubject.asObservable();

  private readonly shouldHideQrCodeSubject = new BehaviorSubject<boolean>(false);
  public shouldHideQrCode$ = this.shouldHideQrCodeSubject.asObservable();

  private readonly hasPixTestSubject = new BehaviorSubject<boolean>(false);
  public hasPixTest$ = this.hasPixTestSubject.asObservable();

  public readonly qrCodeGenerationTimestampSubject = new BehaviorSubject<number>(0);
  public qrCodeGenerationTimestamp$ = this.qrCodeGenerationTimestampSubject.asObservable();

  private expirationTimeSubject = new BehaviorSubject<string>(this.defaultExpirationTime);
  public expirationTime$ = this.expirationTimeSubject.asObservable();

  constructor(private readonly store: Store) {
    this.selectIsPaying();
    this.selectQrCode();
    this.selectCopyCode();
    this.selectShouldHideQrCode();
    this.selectHasPixTest();
    this.selectQrCodeGenerationTimestamp();
  }

  public ngOnDestroy() {
    this.clearSubscriptions$.next();
  }

  public initializeExpirationTime() {
    this.expirationTimeClearSubject$ = new Subject<void>();
    this.initializeCountdownTime();
  }

  public initializeCountdownTime() {
    this.expirationTimeSubject = new BehaviorSubject<string>(this.defaultExpirationTime);
    this.expirationTime$ = this.expirationTimeSubject.asObservable();
  }

  public finishExpirationTime() {
    this.expirationTimeClearSubject$.next();
    this.expirationTimeClearSubject$.complete();
  }

  public selectIsPaying() {
    this.store.pipe(select(PaymentInfoSelectors.selectIsPaying), takeUntil(this.clearSubscriptions$)).subscribe({
      next: (isPaying) => this.isPayingSubject.next(isPaying),
      complete: () => this.isPayingSubject.complete(),
    });
  }

  public selectQrCode() {
    this.store.pipe(select(PaymentInfoSelectors.selectQrCode), takeUntil(this.clearSubscriptions$)).subscribe({
      next: (qrCode) => this.qrCodeSubject.next(qrCode),
      complete: () => this.qrCodeSubject.complete(),
    });
  }

  public selectCopyCode() {
    this.store.pipe(select(PaymentInfoSelectors.selectCopyCode), takeUntil(this.clearSubscriptions$)).subscribe({
      next: (copyCode) => this.copyCodeSubject.next(copyCode),
      complete: () => this.copyCodeSubject.complete(),
    });
  }

  public selectShouldHideQrCode() {
    this.store
      .pipe(select(PaymentInfoSelectors.selectShouldHideQrCode), takeUntil(this.clearSubscriptions$))
      .subscribe({
        next: (shouldHideQrCode) => this.shouldHideQrCodeSubject.next(shouldHideQrCode),
        complete: () => this.shouldHideQrCodeSubject.complete(),
      });
  }

  public selectHasPixTest() {
    this.store.pipe(select(PaymentInfoSelectors.selectHasPixTest), takeUntil(this.clearSubscriptions$)).subscribe({
      next: (hasPixTest) => this.hasPixTestSubject.next(hasPixTest),
      complete: () => this.hasPixTestSubject.complete(),
    });
  }

  public selectQrCodeGenerationTimestamp() {
    this.store
      .pipe(select(PaymentInfoSelectors.selectQrCodeGenerationTimestamp), takeUntil(this.clearSubscriptions$))
      .subscribe({
        next: (qrCodeGenerationTimestamp) => this.qrCodeGenerationTimestampSubject.next(qrCodeGenerationTimestamp),
        complete: () => this.qrCodeGenerationTimestampSubject.complete(),
      });
  }

  public selectExpirationTime(qrCodeGenerationTimestamp: number) {
    this.initializeExpirationTime();

    this.expirationTimeSubject.next(this.defaultExpirationTime);

    interval(1000)
      .pipe(
        map(() => {
          const end = qrCodeGenerationTimestamp + this.fifteenMinutesInMilliseconds;
          const differenceInMilliseconds = end - Date.now();

          if (differenceInMilliseconds < 0) {
            this.finishExpirationTime();

            return;
          }

          const minutes = Math.floor(differenceInMilliseconds / 60000)
            .toString()
            .padStart(2, '0');
          const seconds = Math.floor((differenceInMilliseconds % 60000) / 1000)
            .toString()
            .padStart(2, '0');

          this.expirationTimeSubject.next(`${minutes}:${seconds}`);
        }),
        takeUntil(this.clearSubscriptions$),
        takeUntil(this.expirationTimeClearSubject$),
      )
      .subscribe({
        complete: () => this.expirationTimeSubject.complete(),
      });
  }
}
