import { Injectable, Type } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { BoostSelectors } from '@libs/store/boost';
import { BoostedProfilesSelectors } from '@libs/store/boosted-profiles';
import { PaymentInfoSelectors } from '@libs/store/payment-info';
import { IUiState } from '@libs/store/ui/ui-state';
import { ScreenSizeHelpers } from '@libs/utils/screen-size-helpers/screen-size-helpers';

import { BoostEligibilityHelpers } from '@meupatrocinio/modules/boost/helpers/boost-eligibility-helpers';
import { FeedbackNagbarComponent } from '@meupatrocinio/modules/main/shared/feedback-nagbar/feedback-nagbar.component';
import { MobileNagbarComponent } from '@meupatrocinio/modules/main/shared/mobile-nagbar/mobile-nagbar.component';
import { NagBarList } from '@meupatrocinio/modules/main/shared/nagbar/nagbar-list';
import { PaymentNagbarComponent } from '@meupatrocinio/modules/main/shared/payment-nagbar/payment-nagbar.component';
import { PhotoVerifyNagbarComponent } from '@meupatrocinio/modules/main/shared/photo-verify-nagbar/photo-verify-nagbar.component';
import { ReferralFriendshipDayNagbarComponent } from '@meupatrocinio/modules/main/shared/referral-friendship-day-nagbar/referral-friendship-day-nagbar';
import { INagbarItem } from '@meupatrocinio/modules/nagbar/nagbar-list-item';
import { NagbarCommon } from '@meupatrocinio/modules/nagbar/nagbar.common';
import { BoostNagbarComponent } from '@meupatrocinio/modules/shared/boost-shared/components/boost-nagbar/boost-nagbar.component';
import { BoostRunningNagbarComponent } from '@meupatrocinio/modules/shared/boost-shared/components/boost-running-nagbar/boost-running-nagbar.component';
import { BoostProgressService } from '@meupatrocinio/modules/shared/boost-shared/services/boost-progress/boost-progress.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ReferralService } from '@meupatrocinio/services/referral.service';

@Injectable()
export class NagbarService {
  public nagbars: NagBarList<INagbarItem> = new NagBarList<INagbarItem>();

  protected paymentStatus: PaymentStatus = PaymentStatus.PAYMENT_NONE;
  protected user: UserCommon | undefined;

  public isPhotoVerifyNagbarVisible = false;
  public feedBackNagbarVisible = false;
  public userJoinDaysTimestamp = 0;
  public hasBoostEnded = false;
  public hasBoostRunningOrCredits = false;
  public boostedProfiles: number[] | string[] = [];
  public canShowBoostNagbar = false;

  constructor(
    protected authenticationService: AuthenticationService,
    protected store: Store<IApplicationState>,
    protected referralService: ReferralService,
    protected boostProgressService: BoostProgressService,
    protected router: Router,
  ) {
    this.startObservablesSubscription();
    this.addNagbars();
  }

  protected startObservablesSubscription(): void {
    const observables: Observable<unknown>[] = [
      this.fetchUserData(),
      this.fetchHasBoostEnded(),
      this.fetchPaymentStatus(),
      this.fetchBackNagbarAndPhotoVerifyVisibleStatus(),
      this.fetchJoinTimestamp(),
      this.fetchHasBoostEnded(),
      this.fetchCanShowBoostModalResults(),
      this.fetchBoostedProfiles(),
    ];

    observables.forEach((observable: Observable<unknown>): void => {
      observable.subscribe();
    });
  }

  protected fetchUserData(): Observable<UserCommon> {
    return this.store.pipe(
      select('user'),
      tap((userData: UserCommon): void => {
        this.user = userData;
      }),
    );
  }

  protected fetchHasBoostRunningOrBoostCredits(): Observable<boolean> {
    return this.store.pipe(
      select(BoostSelectors.selectHasBoostRunningOrBoostCredits),
      tap((hasBoostRunningOrCredits: boolean): void => {
        this.hasBoostRunningOrCredits = hasBoostRunningOrCredits;
      }),
    );
  }

  protected fetchPaymentStatus(): Observable<PaymentStatus> {
    return this.store.pipe(
      select(PaymentInfoSelectors.selectPaymentStatus),
      tap((paymentStatus: PaymentStatus): void => {
        this.paymentStatus = paymentStatus;
      }),
    );
  }

  protected fetchBackNagbarAndPhotoVerifyVisibleStatus(): Observable<IUiState> {
    return this.store.pipe(
      select('uiState'),
      tap((uiState): void => {
        this.feedBackNagbarVisible = uiState.feedbackHidden;
        this.isPhotoVerifyNagbarVisible = !uiState.photoVerifyHidden;
      }),
    );
  }

  protected fetchJoinTimestamp(): Observable<IStats> {
    return this.store.pipe(
      select('stats'),
      tap((stats: IStats): void => {
        this.userJoinDaysTimestamp = stats.joinDateTimestamp;
      }),
    );
  }

  protected fetchHasBoostEnded(): Observable<boolean> {
    return this.store.pipe(
      select(BoostSelectors.selectHasBoostEnded, {
        boostProgressService: this.boostProgressService,
      }),
      tap((hasBoostEnded: boolean): void => {
        this.hasBoostEnded = hasBoostEnded;
      }),
    );
  }

  protected fetchCanShowBoostModalResults(): Observable<boolean> {
    return this.store.pipe(
      select(BoostSelectors.selectCanShowBoostModalResults),
      tap((canShowNagbar: boolean): void => {
        this.canShowBoostNagbar = canShowNagbar;
      }),
    );
  }

  protected fetchBoostedProfiles(): Observable<number[] | string[]> {
    return this.store.pipe(
      select(BoostedProfilesSelectors.selectIds),
      tap((boostedProfilesId: number[] | string[]): void => {
        this.boostedProfiles = boostedProfilesId;
      }),
    );
  }

  public addNagbars(): void {
    if (!this.user) {
      return;
    }

    this.nagbars.add({
      component: PaymentNagbarComponent,
      condition: (): boolean => NagbarCommon.isPaymentNagbarValid(this.user, this.paymentStatus),
    });

    this.nagbars.add({
      component: BoostRunningNagbarComponent,
      condition: (): boolean => !UserCommon.isDaddyMommyFree(this.user) && !this.hasBoostEnded,
    });

    this.nagbars.add({
      component: MobileNagbarComponent,
      condition: (): boolean =>
        NagbarCommon.isMobileNagbarValid(this.user) && (!this.isPrimaryList() || ScreenSizeHelpers.isMobile()),
    });

    this.nagbars.add({
      component: BoostNagbarComponent,
      condition: (): boolean => this.canShowNagbarAtPrimaryLists() || this.canShowNagbarAtSecondaryLists(),
    });

    this.nagbars.add({
      component: ReferralFriendshipDayNagbarComponent,
      condition: (): boolean =>
        NagbarCommon.isReferralFriendshipNagbarValid(
          this.user,
          (): boolean => this.referralService.canShowFriendshipDayReferralAd(),
          this.paymentStatus,
        ),
    });

    this.nagbars.add({
      component: PhotoVerifyNagbarComponent,
      condition: (): boolean =>
        NagbarCommon.isPhotoVerifyNagbarValid(this.isPhotoVerifyNagbarVisible, this.user, (): boolean =>
          this.referralService.canShowFriendshipDayReferralAd(),
        ),
    });

    this.nagbars.add({
      component: FeedbackNagbarComponent,
      condition: (): boolean =>
        NagbarCommon.isFeedbackNagbarValid(this.user, this.userJoinDaysTimestamp, this.feedBackNagbarVisible) &&
        (!this.isPrimaryList() || ScreenSizeHelpers.isMobile()),
    });
  }

  public getAValidNagbarComponent(): Type<any> {
    for (let i = 0; i < this.nagbars.size(); i++) {
      if (this.nagbars.get(i).condition()) {
        return this.nagbars.get(i).component;
      }
    }

    return null;
  }

  public isPrimaryList(): boolean {
    return this.router.url.includes('main/home') || this.router.url.includes('main/recommended');
  }

  protected isSecondaryList(): boolean {
    return this.router.url.includes('main/favorites');
  }

  protected canShowNagbarAtPrimaryLists(): boolean {
    return (
      (this.isPrimaryList() && BoostEligibilityHelpers.isBabyEligibleToBoost(this.user)) ||
      this.isDaddyElegibleToBoostNagbarAtHome()
    );
  }

  protected isDaddyElegibleToBoostNagbarAtHome(): boolean {
    return (
      BoostEligibilityHelpers.isDaddyEligibleToBoost(this.user) &&
      (this.canShowBoostNagbar || this.boostedProfiles.length !== 0 || ScreenSizeHelpers.isMobile())
    );
  }

  protected canShowNagbarAtSecondaryLists(): boolean {
    return (
      this.isSecondaryList() &&
      BoostEligibilityHelpers.isBoostEnabled() &&
      BoostEligibilityHelpers.isUserEligibleToBoost(this.user) &&
      BoostEligibilityHelpers.canUseBoost(this.hasBoostRunningOrCredits)
    );
  }

  protected hasBoostedProfiles(): boolean {
    return this.boostedProfiles.length !== 0;
  }
}
