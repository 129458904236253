import { Component, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';

import { IModalDataDeleteConversation } from '@meupatrocinio/modules/main/pages/conversation-item/interface/modal-delete-conversation.interface';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ConversationService } from '@meupatrocinio/services/conversation.service';

@Component({
  selector: 'mp-modal-delete-conversation',
  templateUrl: './modal-delete-conversation.component.html',
})
export class ModalDeleteConversation implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  constructor(
    protected modalRef: ModalRef,
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected conversationService: ConversationService,
    @Inject(MODAL_DATA) public data: IModalDataDeleteConversation,
  ) {
    //
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public closeModal() {
    this.modalRef.close();
  }

  protected get user() {
    return this.authenticationService.get();
  }

  public deleteConversation() {
    if (!this.user || !this.user.profile_id || !this.data || !this.data.conversation) {
      return;
    }

    this.modalRef.close();
    this.subscriptions.push(this.emitConversationDeletion().subscribe());
  }

  protected emitConversationDeletion() {
    return this.conversationService
      .maybeRedirectBeforeDeletion(this.user.profile_id, this.data.conversation, this.router)
      .pipe(
        tap({
          next: () => this.data.emitter.emit(this.data.conversation),
        }),
      );
  }
}
