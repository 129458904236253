import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const InitialNavigationActions = createActionGroup({
  source: 'Initial Navigation',
  events: {
    'Redirect By Status': emptyProps(),
    'Verify Email': props<{ hash: string }>(),
    'Change Password': props<{ hash: string }>(),
    'Navigate To': props<{ path: string[] }>(),
  },
});
