import { Injectable } from '@angular/core';

import { ICreditCardBrandDetector } from '@meupatrocinio/modules/payment-v2/credit-card-brand-detector/interfaces/credit-card-brand-detector-service.interface';

import { MoipValidator } from 'moip-sdk-js';

@Injectable()
export class CreditCardBrandDetectorService implements ICreditCardBrandDetector {
  public getCardBrand(cardNumber: string): string {
    const cardBrandMoipName = this.getCardBrandFromLib(cardNumber);

    return this.getAllCashName(cardBrandMoipName);
  }

  protected getCardBrandFromLib(cardNumber: string): string {
    const brand = MoipValidator.cardType(cardNumber);

    if (brand === null) {
      return '';
    }

    return brand['brand'];
  }

  protected getAllCashName(cardBrand: string): string {
    const allCashNames = {
      MASTERCARD: 'MASTER',
      VISA: 'VISA',
      AMEX: 'AMEX',
      DINERS: 'DINERS',
      HIPERCARD: 'HIPERCARD',
      ELO: 'ELO',
    };

    if (allCashNames[cardBrand] === undefined) {
      return '';
    }

    return allCashNames[cardBrand];
  }
}
