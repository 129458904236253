import { Action } from '@ngrx/store';

export const LIST_VIEW_MODE_ACTION: string = 'LIST_VIEW_MODE_ACTION';

export class ListViewModeAction implements Action {
  readonly type: string = LIST_VIEW_MODE_ACTION;

  constructor(public payload: boolean) {
    //
  }
}
