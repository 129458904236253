import { createAction, props } from '@ngrx/store';

import { IReferralStatus } from '@libs/shared/referral/referral-status';

export const LOAD_REFERRAL_STATUS_ACTION: string = '[Referral] - Load Referral Status Action';

export const UPSERT_ONE_REFERRAL_STATUS_ACTION: string = '[Referral] - Upsert One Referral Status Action';

export const loadReferralStatus = createAction(LOAD_REFERRAL_STATUS_ACTION);

export const upsertOneReferralStatus = createAction(
  UPSERT_ONE_REFERRAL_STATUS_ACTION,
  props<{ referralStatus: IReferralStatus }>(),
);
