import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { PixStateFacadeService } from '@libs/modules/payment-v2/services/pix-state-facade.service';
import { MaterialIcons } from '@libs/shared/material-icons/material-icons.common';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { PaymentInfoActions } from '@libs/store/payment-info';

import { UuidGenerator } from '@libs/utils/uuid-generator/uuid-generator';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions';
import { CPFValidator } from '@meupatrocinio/modules/payment-v2/validators/cpf-validator';
import { ModalService } from '@meupatrocinio/services/modal.service';

@Component({
  selector: 'mp-pix-payment-form',
  templateUrl: 'pix-payment-form.html',
  styleUrls: ['pix-payment-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PixPaymentFormComponent implements OnInit, OnDestroy {
  @Input() type: MembershipType = 0;
  @Input() period = 0;
  @Input() public productDescription = '';
  @Input() productUuid = '';
  @Input() variantUuid = '';

  public cpfValidator: CPFValidator;
  public isLoading$: Observable<boolean> = of(false);
  public shouldHideQrCode$: Observable<boolean> = of(false);
  public qrCode$: Observable<string> = of('');
  public copyCode$: Observable<string> = of('');
  public cpf = '';

  constructor(
    private readonly store: Store,
    private readonly paymentHelpersService: PaymentHelpersService,
    private readonly modalService: ModalService,
    private readonly translateService: TranslateService,
    private readonly clipboard: Clipboard,
    private readonly pixStateFacadeService: PixStateFacadeService,
  ) {
    this.cpfValidator = new CPFValidator(
      this.paymentHelpersService,
      this.modalService,
      this.translateService,
      this.type,
      this.period,
    );
  }

  public ngOnInit() {
    this.isLoading$ = this.pixStateFacadeService.isPaying$;
    this.qrCode$ = this.pixStateFacadeService.qrCode$;
    this.copyCode$ = this.pixStateFacadeService.copyCode$;
    this.shouldHideQrCode$ = this.pixStateFacadeService.shouldHideQrCode$;
  }

  public ngOnDestroy() {
    this.resetPixData();
  }

  public get icon() {
    return MaterialIcons.ContentCopy;
  }

  public canShowPaymentContent(qrCode: string, copyCode: string) {
    return qrCode !== '' && copyCode !== '';
  }

  public onSubmit() {
    if (!this.isFormValid()) {
      return;
    }

    this.makePixPayment();
  }

  public isFormValid() {
    return this.cpfValidator.isFormValid(this.cpf);
  }

  public makePixPayment() {
    const idempotencyKey = UuidGenerator.generate();

    this.store.dispatch(
      ProviderSwitchActions.handlePixPayment({
        paymentData: {
          taxId: this.cpf,
          product: {
            product_uuid: this.productUuid,
            variant_uuid: this.variantUuid,
          },
          idempotencyKey,
        },
      }),
    );
  }

  public copyToClipboard(url: string) {
    this.clipboard.copy(url);
  }

  public resetPixData() {
    this.store.dispatch(PaymentInfoActions.resetPixData());
  }
}
