import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ILists } from '@libs/modules/main/services/download-manager.service.common';
import { CookieType } from '@libs/shared/interfaces/cookie-fake-data.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { ProfileSwipeActions, ProfileSwipeSelectors } from '@libs/store/profile-swipe';
import { CookieUtils } from '@libs/utils/cookie-utils';

import { Config } from '@meupatrocinio/config';
import { IListNameMapper } from '@meupatrocinio/modules/main/services/profile-swipe/interfaces/list-name-mapper.interface';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class ProfileSwipeService {
  public readonly PROFILE_SWIPE_COOKIE_KEY_NAME: string = 'hasDoneSwipe';
  public currentProfileIndex = -1;
  public profileIds: number[] = [];
  public isSwipingProfiles = false;
  public lastRouteBeforeEnteringProfile = '';
  protected currentProfileId = 0;
  protected subscriptions: Subscription[] = [];
  protected readonly URL_PATH_INDEX_ON_MAIN_LISTS: number = 3;
  protected readonly URL_PATH_INDEX_ON_ALTERNATIVE_LISTS: number = 2;
  protected readonly ALMOST_END_OF_LIST_INDEX: number = 3;
  protected listNameMapper: IListNameMapper = {
    '/nearby': 'nearby',
    '/featured': 'featured',
    '/new': 'new',
    '/viewed-me': 'viewedMe',
    '/fav-my': 'favMy',
    '/fav-me': 'favMe',
    '/melt': 'melt',
    '/search': 'search',
  } as const;
  protected primaryLists: readonly string[] = ['/nearby', '/featured', '/new'] as const;
  protected secondaryLists: readonly string[] = ['/viewed-me', '/fav-my', '/fav-me', '/melt'] as const;
  protected hasProfileSwipeTest = false;

  constructor(
    protected router: Router,
    protected downloadManagerService: DownloadManagerService,
    protected store: Store,
    protected cookieService: CookieService,
  ) {
    this.subscribeToIsSwipingProfiles();
    this.subscribeToHasProfileSwipeTest();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  public initialize(profileId: number): void {
    this.setCurrentProfileId(profileId);

    if (this.isSwipingProfiles || !Config.enableProfileSwipe) {
      return;
    }

    this.store.dispatch(ProfileSwipeActions.initialize());
  }

  public setCookie(): void {
    if (this.getCookie()) {
      return;
    }

    const expiryDate: Date = CookieUtils.getCookieExpiryDate(999);

    this.cookieService.set(this.PROFILE_SWIPE_COOKIE_KEY_NAME, true.toString(), {
      expires: expiryDate,
      path: '/',
      secure: false,
      sameSite: CookieType.TYPE_LAX,
    });
  }

  public getCookie(): boolean {
    return this.cookieService.check(this.PROFILE_SWIPE_COOKIE_KEY_NAME);
  }

  public setCurrentProfileId(profileId: number): void {
    this.currentProfileId = profileId;
  }

  public getCurrentProfileId(): number {
    return this.currentProfileId;
  }

  public persistProfiles(profileIds: number[]): void {
    this.profileIds = profileIds;
  }

  public handleProfilesListUpdate(profiles: IProfile[]): void {
    const profileIds: number[] = profiles.map((profile: IProfile): number => {
      return profile.profile_id;
    });

    const updatedProfileIds: number[] = Array.from(
      new Set([...this.profileIds.slice(this.currentProfileIndex, this.profileIds.length), ...profileIds]),
    );

    this.handleLoadAllProfiles(updatedProfileIds);
    this.persistProfiles(updatedProfileIds);
    this.handleInitializeCurrentIndex();
  }

  public subscribeToIsSwipingProfiles(): void {
    this.subscriptions.push(
      this.store.pipe(select(ProfileSwipeSelectors.selectIsSwipingProfiles)).subscribe({
        next: (isSwipingProfiles: boolean): void => {
          this.isSwipingProfiles = isSwipingProfiles;
        },
      }),
    );
  }

  public subscribeToHasProfileSwipeTest(): void {
    this.subscriptions.push(
      this.store.pipe(select(ProfileSwipeSelectors.selectHasProfileSwipeTest)).subscribe({
        next: (hasProfileSwipeTest: boolean): void => {
          this.hasProfileSwipeTest = hasProfileSwipeTest;
        },
      }),
    );
  }

  public handlePreviousUrl(): void {
    if (!this.lastRouteBeforeEnteringProfile) {
      this.lastRouteBeforeEnteringProfile = '/main/home/nearby';
    }

    const possibleQueryStringIndex: number = this.lastRouteBeforeEnteringProfile?.indexOf('?');
    const cleanedUpUrl: string =
      possibleQueryStringIndex !== -1
        ? this.lastRouteBeforeEnteringProfile.slice(0, possibleQueryStringIndex)
        : this.lastRouteBeforeEnteringProfile;
    const urlParts: string[] = cleanedUpUrl.split('/').map((part: string): string => part.trim());

    const formattedUrlResult: string =
      urlParts.length - 1 === this.URL_PATH_INDEX_ON_MAIN_LISTS
        ? `/${urlParts[this.URL_PATH_INDEX_ON_MAIN_LISTS]}`
        : `/${urlParts[this.URL_PATH_INDEX_ON_ALTERNATIVE_LISTS]}`;

    this.updateListUrlPath(formattedUrlResult);
  }

  public updateListUrlPath(listUrlPath: string): void {
    this.store.dispatch(
      ProfileSwipeActions.setLatestSwipeListName({
        latestSwipeListName: listUrlPath,
      }),
    );
  }

  public navigateToProfile(profileId: number, callback: () => void): void {
    this.router
      .navigate(['/main/profile', profileId], {
        queryParams: { profileSwipe: true },
      })
      .then((): void => {
        callback();
      });
  }

  public handleInitializeCurrentIndex(): void {
    const currentProfileIndex: number = this.getProfileIndex(this.currentProfileId);

    if (currentProfileIndex === -1) {
      this.currentProfileIndex = -1;
      return;
    }

    this.currentProfileIndex = currentProfileIndex;
  }

  public handleNextProfile(profileId: number, callback: () => void): void {
    const currentProfileIndex: number = this.getProfileIndex(profileId);

    if (currentProfileIndex >= this.profileIds.length) {
      return;
    }

    this.store.dispatch(ProfileSwipeActions.handleNextPageOrReset());
    this.setHasDoneProfileSwipe(true);
    this.setCookie();

    this.currentProfileIndex = currentProfileIndex + 1;

    const nextProfileId: number = this.profileIds[this.currentProfileIndex];

    this.navigateToProfile(nextProfileId, callback);
  }

  public isPrimaryList(listPathUrl: string): boolean {
    return this.primaryLists.some((primaryList: string): boolean => {
      return primaryList === listPathUrl;
    });
  }

  public isSecondaryList(listPathUrl: string): boolean {
    return this.secondaryLists.some((secondaryList: string): boolean => {
      return secondaryList === listPathUrl;
    });
  }

  public mapStateLatestSwipeListNameToUrlPath(latestSwipeListName: string): string[] {
    const cleanedUpUrl = latestSwipeListName.replace('/', '');

    if (this.isPrimaryList(latestSwipeListName)) {
      return ['main', 'home', cleanedUpUrl];
    }

    if (this.isSecondaryList(latestSwipeListName)) {
      return ['main', 'favorites', cleanedUpUrl];
    }

    return ['main', cleanedUpUrl];
  }

  public navigateToLatestSwipeListName(route: string[], callback: () => void): void {
    this.router.navigate(route, { replaceUrl: true }).then(() => {
      callback();
    });
  }

  public getListName(listUrlPath: string): keyof ILists {
    return this.listNameMapper[listUrlPath] ?? this.listNameMapper['/nearby'];
  }

  public setIsSwipingProfiles(isSwipingProfiles: boolean): void {
    this.store.dispatch(
      ProfileSwipeActions.setIsSwipingProfiles({
        isSwipingProfiles,
      }),
    );
  }

  public setHasDoneProfileSwipe(hasDoneProfileSwipe: boolean): void {
    this.store.dispatch(
      ProfileSwipeActions.setHasDoneProfileSwipe({
        hasDoneProfileSwipe,
      }),
    );
  }

  public setLastRouteBeforeEnteringProfile(lastRouteBeforeEnteringProfile: string): void {
    this.lastRouteBeforeEnteringProfile = lastRouteBeforeEnteringProfile;
  }

  public getProfileIndex(profileId: number): number {
    return this.profileIds.findIndex((id: number): boolean => id === profileId);
  }

  public isAtLastProfile(): boolean {
    return this.currentProfileIndex === this.profileIds.length - 1;
  }

  public isProfileSwipeTest(): boolean {
    return this.hasProfileSwipeTest;
  }

  public canDowloadList(listName: keyof ILists): boolean {
    return (
      this.currentProfileIndex >= this.profileIds.length - this.ALMOST_END_OF_LIST_INDEX &&
      !this.downloadManagerService.isDownloading(listName)
    );
  }

  public handleLoadAllProfiles(profileIds: number[]): void {
    this.store.dispatch(
      ProfileSwipeActions.loadAllProfiles({
        profileIds,
      }),
    );
  }

  public handleIsSwipingOnNearby(isSwipingOnNearby: boolean): void {
    this.store.dispatch(
      ProfileSwipeActions.setIsSwipingOnNearby({
        isSwipingOnNearby,
      }),
    );
  }

  public restoreNavigationCallback = (): void => {
    this.store.dispatch(ProfileSwipeActions.restoreScrollPosition());
  };

  public shouldLoadProfileFromBulk(profile: IProfile, profileIds: number[]): boolean {
    return profileIds.includes(profile.profile_id) && (!profile.public_album || !profile.public_album.length);
  }
}
