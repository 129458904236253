import { createAction } from '@ngrx/store';

enum Actions {
  CONNECT = '[Notifications] - Connect',
  DISCONNECT = '[Notifications] - Disconnect',
  INITIALIZE_NOTIFICATION_LISTENERS = '[Notifications] - Initialize Notification Listeners',
  NOTIFICATION_INITIALIZED = '[Notification] - Listeners Initialized',
  DOWNLOAD_MESSAGES_RECENT = '[Notification] - Download Messages Recent',
  MESSAGES_RECENT_DOWNLOADED = '[Notification] - Messages Recent Downloaded',
}

export const connect = createAction(Actions.CONNECT);

export const disconnect = createAction(Actions.DISCONNECT);

export const initializeNotificationListeners = createAction(Actions.INITIALIZE_NOTIFICATION_LISTENERS);

export const notificationListenersInitialized = createAction(Actions.NOTIFICATION_INITIALIZED);

export const downloadMessagesRecent = createAction(Actions.DOWNLOAD_MESSAGES_RECENT);

export const messagesRecentDownloaded = createAction(Actions.MESSAGES_RECENT_DOWNLOADED);
