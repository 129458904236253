import { createAction, props } from '@ngrx/store';

export interface IProfileContextReceivedActionPayload {
  originName: string;
  previousUrl: string;
  promotionName: string;
  score: string;
  recommendationId: string;
}

enum Actions {
  PROFILE_CONTEXT_RECEIVED_ACTION = '[ProfileContext] - Profile Context Received',
}

export const profileContextReceived = createAction(
  Actions.PROFILE_CONTEXT_RECEIVED_ACTION,
  props<{ profileContext: IProfileContextReceivedActionPayload }>(),
);
