import { Directive } from '@angular/core';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';

@Directive()
export abstract class DailyMessageLimitModalComponentCommon {
  public isConversation: boolean;

  constructor(protected auth: AuthenticationServiceCommon) {
    //
  }

  public abstract closeModal(): void;
}
