import { createAction, props } from '@ngrx/store';

import { UserCommon } from '@libs/shared/user/user.common';

enum Actions {
  SET_HAS_VERIFIED_PHOTO = '[Photo Verify] - Set Has Verify Photo',
  SET_IS_UPLOADING_IMAGE = '[Photo Verify] - Set Is Uploading Image',
  SET_PHOTO_IN_REVIEW = '[Photo Verify] - Set Is Photo In Review',
  USER_PROFILE = '[Photo Verify] - User Profile',
}

export const setHasVerifiedPhoto = createAction(Actions.SET_HAS_VERIFIED_PHOTO, props<{ hasPhotoVerified: boolean }>());

export const setIsUploadingImage = createAction(Actions.SET_IS_UPLOADING_IMAGE, props<{ isUploadingImage: boolean }>());

export const setPhotoInReview = createAction(Actions.SET_PHOTO_IN_REVIEW, props<{ photoInReview: boolean }>());

export const checkIfUserHasVerificationPhoto = createAction(Actions.USER_PROFILE, props<{ user: UserCommon }>());
