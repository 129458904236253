import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { ITrialModalData } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';
import { TrialModalComponentCommon } from '@libs/shared/trial/trial-modal.component.common';
import { IApplicationState } from '@libs/store/application-state';

import FlipDown from 'flipdown-mp/dist/flipdown';

interface IModalTrial {
  trial: Trial;
  modalData: ITrialModalData;
}

@Component({
  selector: 'mp-trial-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './trial-modal.html',
})
export class TrialModalComponent extends TrialModalComponentCommon implements IModalComponent, AfterViewInit {
  public readonly TYPE_PREMIUM: MembershipType = MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM;

  public data: never;
  protected flipDown: HTMLElement;
  protected isFlipCounterWorking: boolean;

  constructor(
    protected modalRef: ModalRef,
    protected translate: TranslateService,
    protected store: Store<IApplicationState>,
    @Inject(MODAL_DATA) public dataTrial: IModalTrial,
  ) {
    super(store, translate);
  }

  ngAfterViewInit(): void {
    this.flipDown = document.getElementById('flipdown');
    this.startCountdown();
  }

  trial(): Trial {
    return this.dataTrial.trial;
  }

  modalData(): ITrialModalData {
    return this.dataTrial.modalData || {};
  }

  public startCountdown(): void {
    if (!this.canShowCountdown()) {
      return;
    }

    new FlipDown(this.trial().getEndDateTimestampInSeconds(), {
      theme: 'mp',
    })
      .start()
      .ifEnded((): void => {
        this.close();
      });
    this.isFlipCounterWorking = true;
  }

  public close(): void {
    this.isFlipCounterWorking = false;
    this.modalRef.close();
  }

  public get activeTrialRoutePath(): number {
    if (this.dataTrial.trial.getTrialPlanDaysActive() !== Trial.TRIAL_15_DAYS) {
      return Trial.TRIAL_TIME;
    }

    return Trial.TRIAL_15_DAYS;
  }
}
