import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IConversationWriting } from '@libs/store/conversations-writing/interface';

export interface IConversationWritingState extends EntityState<IConversationWriting> {}

function selectWriter(writer: IConversationWriting): number {
  return writer.conversation_peer_id;
}

export const ConversationWritingAdapter: EntityAdapter<IConversationWriting> =
  createEntityAdapter<IConversationWriting>({
    selectId: selectWriter,
  });

export const ConversationWritingInitialState: IConversationWritingState = ConversationWritingAdapter.getInitialState();
