<div class="payment-additional-information">
    <div
        *ngIf="!isExpressApprovalCheckout"
        class="after-coupon"
    >
        <p class="small">
            <strong>
                {{ 'modules.main.pages.payment.blurb8title' | translate }}
            </strong>
            <br />
            {{ 'modules.main.pages.payment.blurb8' | translate }}
        </p>
    </div>
    <div
        *ngIf="!isExpressApprovalCheckout"
        class="after-coupon"
    >
        <p class="small">
            {{ 'modules.main.pages.payment.blurb1' | translate }}
        </p>
    </div>
    <div *ngIf="!isExpressApprovalCheckout">
        <p class="small">
            {{ 'modules.main.pages.payment.blurb2' | translate }}
        </p>
    </div>
    <div class="linebottom"></div>
    <div>
        <p class="small">
            {{ getBoletoExplanation() | translate }}
        </p>
    </div>
    <div [ngClass]="{'hidden': !isEliteCheckout}">
        <p class="small">
            {{ 'modules.main.pages.payment.blurb5' | translate }}
        </p>
    </div>
    <div [ngClass]="{ 'hidden': !isEliteCheckout }">
        <p class="small">
            {{ 'modules.main.pages.payment.blurb6' | translate }}
        </p>
    </div>
    <div [ngClass]="{ 'hidden': !isEliteCheckout }">
        <p class="small">
            {{ 'modules.main.pages.payment.blurb7' | translate }}
        </p>
    </div>
    <div class="linebottom"></div>
    <p class="small">
        {{ getCnpj() }}
    </p>
</div>
