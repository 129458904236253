import { IBuilder } from '@libs/integration/mp-cropperjs/lib/interfaces/builder.interface';
import { ICropperResult } from '@libs/integration/mp-cropperjs/lib/interfaces/cropper-result.interface';
import { IImageCropperSetting } from '@libs/integration/mp-cropperjs/lib/interfaces/image-cropper-settings.interface';

import Cropper from 'cropperjs';

export class CropperBuilder implements IBuilder<ICropperResult> {
  public imageElement: HTMLImageElement;
  public cropperOptions: Cropper.Options;
  public imageResolutionSettings: IImageCropperSetting;
  public cropbox: Cropper.CropBoxData;
  public cropper: Cropper;
  public readonly ANONYMOUS_CROSS_ORIGIN: string = 'anonymous';

  constructor(
    imageElement: HTMLImageElement,
    cropperOptions: Cropper.Options,
    imageResolutionSettings: IImageCropperSetting,
    cropper: Cropper,
    cropbox: Cropper.CropBoxData,
  ) {
    this.reset();

    this.imageElement = imageElement;
    this.cropperOptions = cropperOptions;
    this.imageResolutionSettings = imageResolutionSettings;
    this.cropper = cropper;
    this.cropbox = cropbox;
  }

  public addCrossOrigin(): void {
    if (!this.cropperOptions || !this.cropperOptions.checkCrossOrigin) {
      return;
    }

    this.imageElement.crossOrigin = this.ANONYMOUS_CROSS_ORIGIN;
  }

  public listenToReadyEvent(callbackListener: () => void): void {
    this.imageElement.addEventListener('ready', (): void => {
      callbackListener();

      if (!this.cropbox || !this.cropper) {
        return;
      }

      this.cropper.setCropBoxData(this.cropbox);
    });
  }

  public removerListenerToReadyEvent(): void {
    this.imageElement.removeEventListener('ready', (): void => {
      //
    });
  }

  public setupCropperOptions(): void {
    let aspectRatio: number = NaN;

    if (this.imageResolutionSettings) {
      const { width, height } = this.imageResolutionSettings;
      aspectRatio = width / height;
    }

    this.cropperOptions = Object.assign(
      {
        aspectRatio,
        movable: false,
        scalable: false,
        zoomable: false,
        viewMode: 1,
        checkCrossOrigin: true,
      },
      this.cropperOptions,
    );
  }

  public createCropperInstance(): void {
    this.cropper = new Cropper(this.imageElement, this.cropperOptions);
  }

  public getResult(): ICropperResult {
    return {
      imageElement: this.imageElement,
      cropperOptions: this.cropperOptions,
      imageResolutionSettings: this.imageResolutionSettings,
      cropper: this.cropper,
    };
  }

  public resetCropper(): void {
    if (!this.cropper) {
      return;
    }

    this.cropper.destroy();
    this.cropper = undefined;
  }

  public reset(): void {
    this.imageElement = undefined;
    this.imageResolutionSettings = {} as IImageCropperSetting;
    this.cropbox = {} as Cropper.CropBoxData;
    this.cropperOptions = {};
  }
}
