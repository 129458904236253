import { Action, createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as MessageToastActions from '@libs/store/message-toast/actions';
import { messageToastAdapter, messageToastInitialState, IMessageToastState } from '@libs/store/message-toast/state';

const messageToastReducer = createReducer(
  messageToastInitialState,
  on(
    MessageToastActions.upsertProfile,
    (state: IMessageToastState, { profile }: { profile: IProfile }): IMessageToastState => {
      return messageToastAdapter.upsertOne(profile, state);
    },
  ),
  on(MessageToastActions.removeAllProfiles, (state: IMessageToastState): IMessageToastState => {
    return messageToastAdapter.removeAll(state);
  }),
);

export function reducer(state: IMessageToastState, action: Action): IMessageToastState {
  return messageToastReducer(state, action);
}
