import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import {
  UPGRADE_ACCOUNT_VERSIONS,
  UpgradeAccountVersion,
} from '@libs/store/upgrade-account/types/upgrade-account-version.type';

import { Config } from '@meupatrocinio/config';
import { ABTestsConfig } from '@meupatrocinio/modules/ab-tests/configurations/ab-tests.config';
import { AuthRequestFacade } from '@meupatrocinio/modules/main/services/auth-request-facade/auth-request-facade';
import { NEW_PLANS } from '@meupatrocinio/modules/upgrade-account/constants';

@Injectable({
  providedIn: 'root',
})
export class PaymentOptionsLoaderService {
  protected readonly isNewPackageEnabledOutsideABTest: boolean = false as const;

  constructor(
    protected authRequestFacade: AuthRequestFacade,
    protected store: Store<IApplicationState>,
  ) {
    //
  }

  public getSpecificVersionPaymentOptions$(version: UpgradeAccountVersion): Observable<IPaymentOption[]> {
    return this.loadPaymentOptions$(version).pipe(
      map((response: IAuthResponse): IPaymentOption[] => {
        return this.formatPaymentOptions(response);
      }),
    );
  }

  public getUserEligibilityToNewPackage$(): Observable<boolean> {
    if (!this.isNewPackageEnabledOutsideABTest) {
      return of(false);
    }

    return this.store.pipe(
      select('user'),
      withLatestFrom(this.store.pipe(select('stats'))),
      map(([user, stats]: [UserCommon, IStats]): boolean => {
        return ABTestsConfig.isEligibleToSpecialOffer(user, stats);
      }),
      take(1),
    );
  }

  public fetchNewPlans() {
    return of(
      this.formatPaymentOptions({
        data: NEW_PLANS,
      } as IAuthResponse),
    );
  }

  protected loadPaymentOptions$(version: UpgradeAccountVersion): Observable<IAuthResponse> {
    const endpoint: string = this.getEndpointAccordingToVersion(version);

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  protected getEndpointAccordingToVersion(version: UpgradeAccountVersion): string {
    if (version === UPGRADE_ACCOUNT_VERSIONS.premiumWithPrize) {
      return `${Config.serverIp}payment/v2/a/plans`;
    }

    return `${Config.serverIp}payment/v2/b/plans`;
  }

  protected formatPaymentOptions(response: IAuthResponse): IPaymentOption[] {
    let paymentOptions: IPaymentOption[] = [];

    for (const membershipKey in response.data) {
      paymentOptions = paymentOptions.concat(response.data[membershipKey]);
    }

    return paymentOptions;
  }

  public fetchPlanByUuid$(uuid: string): Observable<IAuthResponse> {
    return this.authRequestFacade.get({
      endpoint: `${Config.serverIp}payment/v2/plans/${uuid}`,
    });
  }

  public isEligibleToAPlusCheckout(user: UserCommon): boolean {
    return UserCommon.isDaddyMommyFree(user) && UserCommon.isMale(user);
  }
}
