import { IProfile } from '@libs/shared/profile/profile';
import {
  ProfileViewedMeReceivedAction,
  PROFILE_VIEWED_ME_PREPEND_ACTION,
  PROFILE_VIEWED_ME_RECEIVED_ACTION,
  PROFILE_VIEWED_ME_REFRESHED_ACTION,
  PROFILE_VIEWED_ME_REMOVED_ACTION,
} from '@libs/store/profiles/actions/viewed.action';

export function storeViewedMeProfiles(state: number[] = [], action: ProfileViewedMeReceivedAction): number[] {
  switch (action.type) {
    case PROFILE_VIEWED_ME_PREPEND_ACTION:
      return onProfilesViewedReceived(state, action.payload.profiles, true);
    case PROFILE_VIEWED_ME_RECEIVED_ACTION:
      return onProfilesViewedReceived(state, action.payload.profiles);
    case PROFILE_VIEWED_ME_REMOVED_ACTION:
      return onProfileViewedRemoved(state, action.payload.profiles);
    case PROFILE_VIEWED_ME_REFRESHED_ACTION:
      return [];
    default:
      return state;
  }
}

function onProfilesViewedReceived(state: number[], profiles: IProfile[], reverse?: boolean): number[] {
  for (const profile of profiles) {
    state = onProfileViewedReceived(state, profile, reverse);
  }

  return state;
}

function onProfileViewedReceived(state: number[], newProfile: IProfile, reverse?: boolean): number[] {
  if (newProfile.profile_id === undefined) {
    return state;
  }

  const index = state.indexOf(newProfile.profile_id);

  if (index !== -1 && reverse) {
    return [newProfile.profile_id, ...state.slice(0, index), ...state.slice(index + 1)];
  }

  if (index !== -1) {
    return state;
  }

  if (reverse) {
    return [newProfile.profile_id, ...state];
  }

  return [...state, newProfile.profile_id];
}

function onProfileViewedRemoved(state: number[], profiles: IProfile[]): number[] {
  for (const profile of profiles) {
    state = onProfilesViewedMeRemoved(state, profile);
  }

  return state;
}

function onProfilesViewedMeRemoved(state: number[], newProfile: IProfile): number[] {
  if (newProfile.profile_id === undefined) {
    return state;
  }

  const index: number = state.indexOf(newProfile.profile_id);

  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
