import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';
import { IAvailableTrial, ITrialModalOnce } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { TrialActions, TrialSelectors } from '@libs/store/trial';

@Injectable({
  providedIn: 'root',
})
export abstract class TrialServiceCommon implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  protected globalTrial: Trial = new Trial({});
  protected gamificationTrial: Trial = new Trial({});
  protected linkTrial: Trial = new Trial({});

  readonly MILLISECONDS_IN_A_MINUTE: number = 60000;
  readonly ONE_SECOND_IN_MILLISECONDS: number = 1000;

  protected readonly TRIAL_PERIODS: number[] = [7, 15];

  public readonly DEFAULT_TRIAL_PERIOD: number = 7;

  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpServiceCommon,
    protected router: Router,
    protected auth: AuthenticationServiceCommon,
  ) {
    this.subscriptions.push(
      this.store
        .pipe(select(TrialSelectors.selectGlobalTrial))
        .subscribe((globalTrial: IAvailableTrial): void => this.handleGlobalTrial(globalTrial)),
      this.store
        .pipe(select(TrialSelectors.selectGameTrial))
        .subscribe((gameTrial: IAvailableTrial): void => this.handleGameTrial(gameTrial)),
      this.store
        .pipe(select(TrialSelectors.selectLinkTrial))
        .subscribe((linkTrial: IAvailableTrial): void => this.handleLinkTrial(linkTrial)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }

  protected get user() {
    return this.auth.get();
  }

  public handleGlobalTrial(trial: IAvailableTrial): void {
    this.globalTrial = new Trial(trial);
  }

  public handleGameTrial(trial: IAvailableTrial | undefined): void {
    if (trial === undefined || Object.keys(trial).length === 0) {
      trial = {};
    }

    this.gamificationTrial = new Trial(trial);
  }

  public handleLinkTrial(trial: IAvailableTrial | undefined): void {
    if (trial === undefined || Object.keys(trial).length === 0) {
      trial = {};
    }

    this.linkTrial = new Trial(trial);
  }

  public getAvailableTrial(): Trial {
    if (!this.getGameTrial().isEmpty()) {
      return this.getGameTrial();
    }

    if (!this.getGlobalTrial().isEmpty()) {
      return this.getGlobalTrial();
    }

    return this.getLinkTrial();
  }

  public getGameTrial(): Trial {
    return this.gamificationTrial;
  }

  public getGameTrialFreeMessageRule = (): boolean => {
    return this.getGameTrial().isAtPlans(this.router.url);
  };

  public isGameTrialFreeMessageSatisfied = (): boolean => {
    return MembershipCommon.isFree(this.user.membership_type_id) && !this.getGameTrial().isEmpty();
  };

  public abstract isElectiveToDeactivateModal(user: UserCommon): boolean;

  public getGlobalTrial(): Trial {
    return this.globalTrial;
  }

  public getLinkTrial(): Trial {
    return this.linkTrial;
  }

  public hasSomeTrial(): boolean {
    return !this.getGlobalTrial().isEmpty() || !this.getGameTrial().isEmpty() || !this.getLinkTrial().isEmpty();
  }

  public isTrialProduct(planUuid: string) {
    return (
      planUuid === ProductVariantIdentifier.TRIAL_DADDY_PREMIUM_7_DAYS ||
      planUuid === ProductVariantIdentifier.DADDY_PREMIUM_15_DAYS
    );
  }

  setGameTrialAccordingToGlobal(): void {
    let period: number = this.DEFAULT_TRIAL_PERIOD;

    if (!this.getGlobalTrial().isEmpty()) {
      period = this.getGlobalTrial().getTrialPlanDaysActive();
    }

    this.store.dispatch(
      TrialActions.setGameTrial({
        period,
      }),
    );
  }

  startGamefiedTrial({
    firstTimer,
    satisfied,
    retryStrategy: { retryWhen, millisecondsRetry },
    trial,
    modalData,
  }: ITrialModalOnce): void {
    this.store.dispatch(
      TrialActions.initializeTrialOnceModal({
        trialProps: {
          firstTimer,
          satisfied,
          retryStrategy: {
            retryWhen,
            millisecondsRetry,
          },
          trial,
          modalData,
        },
      }),
    );
  }

  canShowGlobalTrial = (): boolean => {
    return (
      this.getGameTrial().isEmpty() &&
      !this.getGlobalTrial().isEmpty() &&
      this.getGlobalTrial().isTrialEnabled(this.router) &&
      UserCommon.isDaddyMommyFree(this.auth.get())
    );
  };

  initializeGlobalTrial(): void {
    this.store.dispatch(TrialActions.stopTrialTimer());
    this.store.dispatch(
      TrialActions.initializeTrialTimerModal({
        trialProps: {
          firstTimer: this.MILLISECONDS_IN_A_MINUTE,
          secondTimer: this.MILLISECONDS_IN_A_MINUTE * 15,
          satisfied: this.canShowGlobalTrial,
          trial: this.getGlobalTrial(),
          retryStrategy: {
            retryWhen: this.globalTrialRetry,
            millisecondsRetry: null,
          },
        },
      }),
    );
  }

  globalTrialRetry = (): boolean => {
    return false;
  };

  public abstract trialOptionsRequest(): Observable<IAuthResponse>;

  hasUserPaidForTrial(amountPaid: number): boolean {
    return (
      amountPaid === Trial.PRICE_3_DAYS.toNumber() ||
      amountPaid === Trial.PRICE_7_DAYS.toNumber() ||
      amountPaid === Trial.PRICE_15_DAYS.toNumber() ||
      amountPaid === Trial.PRICE_15_DAYS_NEW.toNumber()
    );
  }
}
