<div class="membership-badge">
    <div
        class="user-badge membership-badge__elite-badge"
        [ngClass]="{ 'user-badge--white-border': (isWhiteThemeEnabled$ | async) }"
        *ngIf="isElite(user$ | async)"
    >
        <img src="/assets/img/elite-crown-only-white.svg" />
    </div>
    <div
        class="user-badge membership-badge__premium-badge"
        [ngClass]="{ 'user-badge--white-border': (isWhiteThemeEnabled$ | async) }"
        *ngIf="isPremium(user$ | async)"
    >
        <img src="/assets/img/sbp-diamond-icon.svg" />
    </div>
</div>
