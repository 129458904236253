import { Injectable } from '@angular/core';

import { UserCommon } from '@libs/shared/user/user.common';

@Injectable({
  providedIn: 'root',
})
export class CustomerIOEligibilityService {
  public isProfileEligibleForCustomerIO(user: UserCommon) {
    return (
      this.isProfileStatusActive(user) &&
      UserCommon.isUserCategory1(user) &&
      (this.isSugarBabyFemaleFree(user) || this.isSugarDaddy(user))
    );
  }

  protected isSugarDaddy(user: UserCommon) {
    return UserCommon.isMale(user) && UserCommon.isDaddyMommy(user);
  }

  protected isSugarBabyFemaleFree(user: UserCommon) {
    return UserCommon.isFemale(user) && UserCommon.isBabyFree(user);
  }

  protected isProfileStatusActive(user: UserCommon) {
    return user.status === UserCommon.STATUS_ACTIVE;
  }
}
