import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { BoostNagbarComponentCommon } from '@libs/modules/boost/components/boost-nagbar/boost-nagbar.component.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';

import { BoostService } from '@meupatrocinio/modules/shared/boost-shared/services/boost/boost.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

@Component({
  selector: 'mp-boost-nagbar',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './boost-nagbar.html',
})
export class BoostNagbarComponent extends BoostNagbarComponentCommon {
  protected boostRoute: string[] = ['main', 'boost'];

  constructor(
    protected boostService: BoostService,
    protected auth: AuthenticationService,
    protected router: Router,
  ) {
    super(boostService);
  }

  protected get user(): UserCommon {
    return this.auth.get();
  }

  public getProfilePicture(profile: IProfile): string {
    return ImageHelper.getProfilePicture(profile, this.auth.get(), UserCommon.PICTURE_SMALL);
  }

  public navigateToBoostRoute(): void {
    this.router.navigate(this.boostRoute);
  }

  public getNagbarSubtitle(): string {
    if (!UserCommon.isBaby(this.user)) {
      return 'modules.main.shared.boost_nagbar.subtitle.sd';
    }

    return 'modules.main.shared.boost_nagbar.subtitle.sb';
  }
}
