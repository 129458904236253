import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import { IAvailableTrial } from '@libs/shared/trial/interface';
import { ITrialState } from '@libs/store/trial/interfaces/trial-state.interface';

export const InitialTrialState: IAvailableTrial = {};

function selectByPeriod(trial: IAvailableTrial): number {
  return trial.period;
}

export const TrialAdapter: EntityAdapter<IAvailableTrial> = createEntityAdapter<IAvailableTrial>({
  selectId: selectByPeriod,
});

export const TrialInitialState: ITrialState = TrialAdapter.getInitialState({
  gameTrialIndex: null,
  linkTrialIndex: null,
  globalTrial: {},
});
