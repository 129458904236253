import { Action } from '@ngrx/store';

export const IMAGE_TAPPED_ACTION: string = 'IMAGE_TAPPED_ACTION';

export interface IImageTappedActionPayload {
  image: number;
}

export class ImageTappedAction implements Action {
  readonly type: string = IMAGE_TAPPED_ACTION;

  constructor(public payload: IImageTappedActionPayload) {
    //
  }
}
