import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

import { IValidations } from '@libs/services/payment/validators';

import { MoipValidator } from 'moip-sdk-js';

const validation: ValidationErrors[] = [Validators.required, Validators.pattern(/^\d{2}\/\d{2}$/), validateDate];

function messages(errors: ValidationErrors): string {
  if (errors === null || errors.pattern || errors.invalidDate) {
    return 'modules.main.pages.payment.cc_expiry.error.invalid';
  }

  if (errors.required) {
    return 'modules.main.pages.payment.cc_expiry.error.missing';
  }

  return 'modules.main.pages.payment.cc_expiry.error.title';
}

function validateDate(control: AbstractControl): ValidationErrors | null {
  const date = control.value;

  const invalidDateError = {
    invalidDate: true,
  };

  if (!/^\d{2}\/\d{2}$/.test(date)) {
    return invalidDateError;
  }

  const dates = date.split('/');
  if (MoipValidator.isExpiryDateValid(dates[0], dates[1])) {
    return null;
  }

  return invalidDateError;
}

export const validations: IValidations = {
  validation,
  messages,
};
