import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { ToastCommon } from '@libs/effects/toast/common/toast.common';
import { IToastStrategy } from '@libs/modules/main/toast/strategy/interfaces/toast-strategy.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { ToastType } from '@libs/shared/toast/interfaces/toast-parameters.interface';
import { MeltToastActions, MeltToastSelectors } from '@libs/store/melt-toast';

export class MeltToastStrategy implements IToastStrategy {
  readonly singleToastRoute: readonly unknown[] = [] as const;
  readonly multipleToastsRoute: readonly unknown[] = ['main', 'favorites', 'melt'] as const;

  public execute(store: Store, profile: IProfile): void {
    store.dispatch(
      MeltToastActions.upsertProfile({
        profile,
      }),
    );
  }

  public clear(store: Store): void {
    store.dispatch(MeltToastActions.removeAllProfiles());
  }

  public navigate(): void {
    //
  }

  public navigateMultiple(router: Router): void {
    ToastCommon.navigate(router, [...this.multipleToastsRoute], {
      'toast-type': `${ToastType.typeMelt}-multiple`,
    });
  }

  public selectAllProfiles$(store: Store): Observable<IProfile[]> {
    return store.pipe(select(MeltToastSelectors.selectAll), share());
  }
}
