import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ILoginTokenData } from '@libs/shared/interfaces/login-token-data.interface';
import { IStats } from '@libs/shared/user/stats';
import { IWaitingListState } from '@libs/store/waiting-list/interfaces/waiting-list-state';

const waitingListFeature = createFeatureSelector<IWaitingListState>('waitingList');
const statsFeature = createFeatureSelector<IStats>('stats');
const tokenFeature = createFeatureSelector<ILoginTokenData>('token');

export const selectIsRequestingNewConfirmationEmail = createSelector(
  waitingListFeature,
  (state: IWaitingListState): boolean => {
    return state.isRequestingNewConfirmationEmail;
  },
);

export const selectConfirmationEmailRequestTimestamp = createSelector(
  waitingListFeature,
  (state: IWaitingListState): number => {
    return state.confirmationEmailRequestTimestamp;
  },
);

export const selectIsUserApproved = createSelector(waitingListFeature, (state: IWaitingListState): boolean => {
  return state.isUserApproved;
});

export const selectIsEmailVerified = createSelector(waitingListFeature, (state: IWaitingListState): boolean => {
  return state.isEmailVerified;
});

export const selectIsSendingRequest = createSelector(waitingListFeature, (state: IWaitingListState): boolean => {
  return state.isSendingRequest;
});

export const selectUserEmail = createSelector(statsFeature, (state: IStats): string => {
  return state.email;
});

export const selectToken = createSelector(tokenFeature, (state: ILoginTokenData): string => {
  return state.token;
});
