import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { IValidations } from '@libs/services/payment/validators';

import * as CPF from 'gerador-validador-cpf';

const validation: ValidationErrors | ValidatorFn[] = [Validators.required, Validators.pattern(/^\d{11}$/), validateCPF];

function messages(errors: ValidationErrors): string {
  if (errors === null || errors.invalidCPF) {
    return 'modules.main.pages.payment.cpf.error.invalid';
  }

  if (errors.required) {
    return 'modules.main.pages.payment.cpf.error.missing';
  }

  return 'modules.main.pages.payment.cpf.error.invalid';
}

export function validateCPF(control: AbstractControl): ValidationErrors | null {
  const cpf: string = control.value;
  const parent: AbstractControl = control.parent;

  if (parent === undefined) {
    return null;
  }

  if (CPF.validate(cpf)) {
    return null;
  }

  return {
    invalidCPF: true,
  };
}

export const validations: IValidations = {
  validation,
  messages,
};
