<div
    [formGroup]="form"
    class="registration-form-field registration-form-field--feedback"
>
    <label>{{ 'common.email' | translate }}</label>
    <input
        #emailInputElement
        type="email"
        autocomplete="off"
        autocorrect="off"
        spellcheck="false"
        [formControlName]="controlName"
        [placeholder]="'modules.frictionless.email.placeholder' | translate"
        autocomplete="new-password"
    >
    <div
        *ngIf="canShowLoadingIndicator"
        class="validation-loader"
    ></div>
    <mp-validation-control-error
        *ngIf="canShowValidationError"
        [requiredMessage]="'modules.registration.pages.registration2.email.missing' | translate"
        [form]="form"
        [controlName]="controlName"
    ></mp-validation-control-error>
    <small
        *ngIf="!isEmailSuggestionEmpty()"
        class="email-suggestion"
        [innerHTML]="getEmailSuggestionText()"
        (click)="replaceEmailToSuggestion()"
    ></small>
</div>

