import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { DateCommon } from '@libs/shared/date/date.common';
import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { RegistrationInputedAction } from '@libs/store/registration/actions/registration.action';

import { TimeToRegisterActions } from '@meupatrocinio/modules/time-to-register/state/actions';

@Directive()
export class FrictionlessStep4ComponentCommon implements OnInit {
  public smokeFrequency: string;
  public drinkFrequency: string;
  public relationship: string;
  public children: string;
  public education: string;
  public occupation: string;
  public income: string;
  public netWorth: string;
  public myType: number;
  public birthdate: string;

  @Output() readonly skipped: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected translate: TranslateService,
    protected store: Store<IApplicationState>,
  ) {
    //
  }

  public ngOnInit(): void {
    this.store
      .select('registration')
      .pipe(take(1))
      .subscribe((value): void => {
        this.myType = value['extended[what]'];
        this.birthdate = value.birthdate;

        this.smokeFrequency = UserCommon.getSmokeStatus(value['extended[smoke]']);
        this.drinkFrequency = UserCommon.getDrinkStatus(value['extended[drink]']);
        this.relationship = UserCommon.getRelationshipStatus(value['extended[relationship]']);
        this.children = UserCommon.getChildrenStatus(value['extended[have_children]']);
        this.education = UserCommon.getEducationStatus(value['extended[education]']);
        this.occupation = UserCommon.getOccupationStatus(value['extended[occupation]']);

        if (this.isDaddyMommy()) {
          this.income = UserCommon.getIncomeStatus(value['extended[income]']);
          this.netWorth = UserCommon.getNetWorthStatus(value['extended[net_worth]']);
        }
      });
  }

  public canContinue(): boolean {
    return (
      !!this.smokeFrequency &&
      !!this.drinkFrequency &&
      !!this.education &&
      !!this.children &&
      !!this.relationship &&
      (!this.isDaddyMommy() || (!!this.income && !!this.netWorth))
    );
  }

  public commitStep(): void {
    let data: IRegistrationPayload = {
      'extended[smoke]': this.smokeFrequency && UserCommon.getSmokeKey(this.smokeFrequency),
      'extended[drink]': this.drinkFrequency && UserCommon.getDrinkKey(this.drinkFrequency),
      'extended[relationship]': this.relationship && UserCommon.getRelationshipKey(this.relationship),
      'extended[have_children]': this.children && UserCommon.getChildrenKey(this.children),
      'extended[education]': this.education && UserCommon.getEducationKey(this.education),
      'extended[occupation]': this.occupation && UserCommon.getOccupationKey(this.occupation),
    };

    if (this.isDaddyMommy()) {
      data = {
        ...data,
        'extended[income]': this.income && UserCommon.getIncomeKey(this.income),
        'extended[net_worth]': this.netWorth && UserCommon.getNetWorthKey(this.netWorth),
      };
    }

    this.store.dispatch(new RegistrationInputedAction({ data }));
  }

  public submitRegistration(): void {
    this.store.dispatch(TimeToRegisterActions.updateRequest({ endTime: Date.now() }));
    this.skipped.emit();
  }

  public occupationOptions(): string[] {
    return UserCommon.getOccupationStatusList();
  }

  public smokeFrequencyOptions(): string[] {
    return UserCommon.getSmokeOptions();
  }

  public drinkFrequencyOptions(): string[] {
    return UserCommon.getDrinkOptions();
  }

  public relationshipOptions(): string[] {
    return UserCommon.getRelationshipOptions();
  }

  public childrenOptions(): string[] {
    return UserCommon.getChildrenOptions();
  }

  public educationOptions(): string[] {
    return UserCommon.getEducationOptions();
  }

  public incomeOptions(): string[] {
    return UserCommon.getIncomeOptions();
  }

  public netWorthOptions(): string[] {
    return UserCommon.getNetWorthOptions();
  }

  public isDaddyMommy(): boolean {
    return UserCommon.isDaddyMommyByWhat(this.myType);
  }

  public canFinishRegistrationLater(): boolean {
    return FrictionlessCommon.canFinishRegistrationLater(this.myType, DateCommon.yearDiff(new Date(this.birthdate)));
  }
}
