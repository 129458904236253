import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ShowHideFeedbackNagbarAction } from '@libs/store/ui/actions/feedback-nagbar.action';
import { SupportParamsAction } from '@libs/store/ui/actions/support-params.action';

import { Config } from '@meupatrocinio/config';
import { NagbarCommon } from '@meupatrocinio/modules/nagbar/nagbar.common';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Component({
  selector: 'mp-feedback-nagbar',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './feedback-nagbar.html',
})
export class FeedbackNagbarComponent implements OnDestroy {
  static componentName: string = NagbarCommon.FEEDBACK_NAGBAR_COMPONENT_NAME;
  public nagbarHidden = true;
  public joinDate: number;
  public currentVersion: string;
  protected subscriptions: Subscription[] = [];

  constructor(
    protected store: Store<IApplicationState>,
    protected auth: AuthenticationService,
  ) {
    this.subscriptions.push(
      this.store.select('uiState').subscribe((uiState): void => {
        this.nagbarHidden = uiState.feedbackHidden;
      }),
    );

    this.subscriptions.push(
      this.store.select('stats').subscribe((stats): void => {
        this.joinDate = stats.joinDateTimestamp;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public hide(): void {
    this.store.dispatch(new ShowHideFeedbackNagbarAction(true));
  }

  public setSupportOrigin(): void {
    this.store.dispatch(new SupportParamsAction({ custom_tags: ['feedback'] }));
  }

  public isMinimumVersion(): boolean {
    const minVersion = Config.feedbackNagbarPatchMinVersion;
    const currentVersion = Config.version.replace('v', '').split('.');

    return minVersion >= +currentVersion[2];
  }

  public isAbleToShowNagbar(): boolean {
    return (
      this.auth.get() && UserCommon.getUserJoinDays(this.joinDate) > 10 && !this.nagbarHidden && this.isMinimumVersion()
    );
  }
}
