import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IExplanationSugar } from '@libs/shared/interfaces/explanation-sugar.interface';
import { ISugarEllipse } from '@libs/shared/interfaces/sugar-ellipse.interface';
import { UserCommon } from '@libs/shared/user/user.common';

interface IModalData {
  myGender?: number;
  typeExplanation?: number;
}

@Component({
  selector: 'mp-explanation-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './explanation-modal.html',
})
export class ExplanationModalComponent implements OnInit, IModalComponent<IModalData> {
  public sugarExplanation: IExplanationSugar;
  public imagesUrl: ISugarEllipse;

  constructor(
    protected modalRef: ModalRef,
    @Inject(MODAL_DATA) public data: IModalData,
  ) {
    //
  }

  ngOnInit(): void {
    this.getTextBasedOnType();
    this.getImageUrl();
  }

  public getImageUrl(): void {
    this.imagesUrl = UserCommon.getEllipseUrl(this.data.myGender, this.data.typeExplanation);
  }

  public getTypeTitle(): string {
    return UserCommon.getTypeName(this.data.myGender, this.data.typeExplanation);
  }

  public getTextBasedOnType(): void {
    this.sugarExplanation = UserCommon.getWhatIsSugarExp(this.data.myGender, this.data.typeExplanation);
  }

  public closeModal(): void {
    this.modalRef.close();
  }
}
