import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IConversationWriting } from '@libs/store/conversations-writing';

export const setConversationWriting = createAction(
  '[ConversationWriting] - Set Conversation Writing Action',
  props<IConversationWriting>(),
);

export const unsetConversationWriting = createAction(
  '[ConversationWriting] - Unset Conversation Writing Action',
  props<IConversationWriting>(),
);

export const setImWriting = createAction('[Conversation / API] - Set Im Writing Action');

export const setImWritingSucess = createAction('[Conversation / API] - Set Im Writing Action Success');

export const setImWritingError = createAction(
  '[Conversation / API] - Set Im Writing Action Error',
  props<{ error: HttpErrorResponse }>(),
);
