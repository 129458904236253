<div class="logo-container">
    <a *ngIf="hasLoggedIn()" [routerLink]="['/main', 'home']">
        <img *ngIf="isWhiteThemeEnabled$ | async" src="/assets/img/meu-patrocinio-logo-v02.svg" />
        <img *ngIf="!(isWhiteThemeEnabled$ | async)" src="/assets/img/meu-patrocinio-logo.svg" />
    </a>
    <img
        *ngIf="!hasLoggedIn() && (isWhiteThemeEnabled$ | async)"
        src="/assets/img/meu-patrocinio-logo-v02.svg"
    />
    <img
        *ngIf="!hasLoggedIn() && !(isWhiteThemeEnabled$ | async)"
        src="/assets/img/meu-patrocinio-logo.svg"
    />
</div>
