import { Action } from '@ngrx/store';

import { IRegistrationPayload } from '@libs/shared/profile/registration';

export const REGISTRATION_INPUTED_ACTION: string = 'REGISTRATION_INPUTED_ACTION';
export const REGISTRATION_CLEAR_ACTION: string = 'REGISTRATION_CLEAR_ACTION';

export interface IRegistrationInputedActionPayload {
  data: IRegistrationPayload;
}

export class RegistrationInputedAction implements Action {
  readonly type: string = REGISTRATION_INPUTED_ACTION;

  constructor(public payload: IRegistrationInputedActionPayload) {
    //
  }
}

export class RegistrationClearAction implements Action {
  readonly type: string = REGISTRATION_CLEAR_ACTION;
}
