import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { ICallbackData } from '@libs/shared/interfaces/callback-data.interface';
import { IDataPicture } from '@libs/shared/interfaces/data-photo.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { range } from '@libs/utils/array-functions';

@Directive()
export abstract class UploadImageBoxComponentCommon {
  public isUploading = false;

  protected photoType = 'public';
  protected imageIndex = 0;

  @Output() readonly pictureBoxData: EventEmitter<IDataPicture> = new EventEmitter<IDataPicture>();

  @Input() myType?: number;
  @Input() pictureData: string[] = [];
  @Input() isRegistration = false;

  constructor(protected store: Store<IApplicationState>) {
    //
  }

  public get indices(): number[] {
    if (this.myType !== undefined && UserCommon.isDaddyMommyByWhat(this.myType)) {
      return range(1);
    }

    return range(3);
  }

  abstract getImageUploadedCallback(event: ICallbackData): void;
}
