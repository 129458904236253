<button
    id="home-button-profile-header"
    type="button"
    aria-haspopup="true"
    aria-expanded="true"
    (click)="pictureAction()"
>
    <mp-img-srcset
        url="{{ getProfilePicture() }}"
        imgClass="avatar"
        place="profile-header"
    ></mp-img-srcset>
</button>
