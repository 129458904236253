<div class="dial-box notifications-modal">
    <div class="notifications-info">
        <button
            class="close-button"
            (click)="closeModal()"
        >
            <i
                class="material-icons"
                aria-hidden="true"
            >
                close
            </i>
        </button>
        <h1 class="header-text">
            {{ 'modules.main.shared.blocked_modal.title' | translate }}
        </h1>
        <p class="notifications-description">
            {{ 'modules.main.shared.blocked_modal.title_description' | translate }}
        </p>
        <div class="curve">
            <img [src]="getBrowserImage()">
        </div>
        <a
            class="mp-main-button outline-red bt-sm"
            type="button"
            [href]="getBrowserSupportLink()"
            target="_blank"
        >
            {{ 'modules.main.shared.blocked_modal.btn' | translate }}
        </a>
    </div>
</div>
