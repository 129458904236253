import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { ToastServiceCommon } from '@libs/modules/main/toast/toast.service.common';
import { IProfile } from '@libs/shared/profile/profile';
import { IToastComponent } from '@libs/shared/toast/interfaces/toast-component.interface';
import { ToastType } from '@libs/shared/toast/interfaces/toast-parameters.interface';
import { UserCommon } from '@libs/shared/user/user.common';

import { CustomToastService } from '@meupatrocinio/modules/main/services/custom-toast/custom-toast.service';
import { ToastContext } from '@meupatrocinio/modules/main/toast/context/toast.context';
import { MPToastComponent } from '@meupatrocinio/modules/shared/mp-toast/mp-toast.component';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Memoize } from 'typescript-memoize';

@Injectable()
export class ToastService extends ToastServiceCommon implements OnDestroy {
  public toast: ActiveToast<MPToastComponent> | undefined = undefined;

  constructor(
    protected store: Store,
    protected toastrService: ToastrService,
    protected router: Router,
    protected translate: TranslateService,
    protected location: Location,
    protected authenticationService: AuthenticationService,
    protected profileService: ProfileService,
    protected customToastService: CustomToastService,
    protected toastContext: ToastContext,
  ) {
    super(profileService, translate, router, authenticationService, store, toastContext);
  }

  public showToast(toastType: ToastType, profiles: IProfile[]): void {
    this.toast = this.toastrService.show();

    this.toast.toastRef.componentInstance.message = this.getMessage(profiles);
    this.toast.toastRef.componentInstance.title = this.getTitle(profiles);
    this.toast.toastRef.componentInstance.icon = toastType;
    this.toast.toastRef.componentInstance.images = this.getImages(profiles);
    this.toast.toastRef.onDuplicate(true, false);

    this.listenToToastTap(profiles);
    this.listenerHidden();
  }

  public handleCustomToastInjection(component: IToastComponent): void {
    this.toast = this.toastrService.show();

    this.customToastService.injectNewCustomToast(this.toast, component);
    this.listenerHidden();
  }

  public canToast(): boolean {
    const path: string = this.location.path();

    return !/^\/main\/(conversation|upgrade-account|payment)/.test(path);
  }

  public hasCustomToastRunning(): boolean {
    return this.customToastService.isCustomToast(this.toast);
  }

  protected resetToast(): void {
    this.toast = undefined;
  }

  protected listenToToastTap(profiles: IProfile[]): void {
    if (this.toast === undefined) {
      return;
    }

    this.subscriptions.push(
      this.toast.onTap.pipe(take(1)).subscribe({
        next: (): void => {
          this.navigate(profiles);
          this.resetToast();
          this.resetProfilesByToastType();
        },
      }),
    );
  }

  @Memoize()
  protected getImages(profiles: IProfile[]): string[] {
    return profiles
      .slice(-2)
      .map((profile: IProfile): string =>
        ImageHelper.getProfilePicture(profile, this.authenticationService.get(), UserCommon.PICTURE_EXTRA_LARGE),
      );
  }

  protected listenerHidden(): void {
    if (this.toast === undefined) {
      return;
    }

    this.subscriptions.push(
      this.toast.onHidden.pipe(take(1)).subscribe({
        next: (): void => {
          this.resetToast();
        },
      }),
    );
  }
}
