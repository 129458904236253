import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ImageServiceCommon } from '@libs/modules/main/services/image/image-service.common';
import { IModalPhotoData } from '@libs/shared/interfaces/modal-photo-data.interface';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@meupatrocinio/config';
import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { OriginUtils } from '@meupatrocinio/utils/origin';

@Injectable({
  providedIn: 'root',
})
export class ImageService extends ImageServiceCommon {
  constructor(
    protected advancedModalService: AdvancedModalService,
    protected modalService: ModalService,
    protected profileService: ProfileService,
    protected http: HttpClient,
    protected store: Store<IApplicationState>,
  ) {
    super(profileService, store);
  }

  cropPhoto(data: IModalPhotoData): void {
    if (!this.validateImageFile(data)) {
      return;
    }

    this.openCropModal(data);
  }

  getOrigin(): string {
    return OriginUtils.origin().toString();
  }

  protected validateImageFile(data: IModalPhotoData): boolean {
    const imageFile = data.file;

    if (imageFile === undefined) {
      return false;
    }

    if (!/^image\/(?:gif|jpe?g|png)$/.test(imageFile.type)) {
      this.modalService.open('modules.main.shared.picture_crop.invalid_file');
      return false;
    }

    if (!this.isImageFileSizeValid(imageFile.size)) {
      this.modalService.open('modules.frictionless.picture_size');
      return false;
    }

    return true;
  }

  protected prepareDataToUpload(): void {
    const formData = new FormData();

    if (this.uploadData.modalData && this.uploadData.modalData.extraParams) {
      for (const key of Object.keys(this.uploadData.modalData.extraParams)) {
        formData.append(key, this.uploadData.modalData.extraParams[key]);
      }
    }

    if (this.uploadData.modalData.idempotentKey) {
      formData.append('idempotentKey', this.uploadData.modalData.idempotentKey);
    }

    formData.append('image', this.uploadData.image);
    formData.append('perfil', '0');
    formData.append('publishing_status', this.uploadData.modalData.type);

    this.uploadData.formData = formData;

    this.processImageRequest();
  }

  protected processImageRequest(): void {
    if (this.uploadData.modalData === undefined || !this.uploadData.modalData.callback) {
      return;
    }

    const request = this.mountDataRequest();

    this.uploadData.modalData.callback({
      image: this.uploadData.image,
      request: this.http.request(request).pipe(
        tap((event: HttpEvent<any>): void => {
          if (event.type !== HttpEventType.Sent) {
            return;
          }
          this.uploadData.closeCallback();
          this.token = '';
        }),
        catchError((error: HttpErrorResponse): Observable<never> => {
          this.uploadData.closeCallback();
          this.modalService.open('modules.main.shared.picture_crop.upload_error');
          this.token = '';
          this.uploadData = {};
          return throwError(error);
        }),
      ),
    });
  }

  protected mountDataRequest(): HttpRequest<FormData> {
    const headers = new HttpHeaders({
      Authorization: this.getAuthenticationKey(),
      mobile: this.getOrigin(),
      'X-MP-Request-Origin': this.getOrigin(),
    });

    return new HttpRequest(this.METHOD_HTTP_POST, Config.serverIp + 'profile/photo', this.uploadData.formData, {
      headers,
      reportProgress: true,
    });
  }
}
