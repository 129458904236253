import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const SlidableMenuAnimations: {
  readonly slideToggle: AnimationTriggerMetadata;
} = {
  slideToggle: trigger('slideToggle', [
    state(
      'void',
      style({
        marginRight: '-290px',
      }),
    ),
    state(
      'open',
      style({
        marginRight: 0,
      }),
    ),
    state(
      'closed',
      style({
        marginRight: '-290px',
      }),
    ),
    transition('* => *', animate('250ms ease-in-out')),
  ]),
};
