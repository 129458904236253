<div class="dial-box explanation-modal">
    <button
        class="close-button"
        (click)="closeModal()"
    >
        <i
            class="material-icons"
            aria-hidden="true"
        >
            close
        </i>
    </button>
    <h2 class="title">
        <mp-advanced-translate key="modules.frictionless.what_is_sugar2">
            <span
                class="large"
                mp-translate-param="accountType"
            >
                {{ getTypeTitle() | translate }}
            </span>
        </mp-advanced-translate>
    </h2>
    <div class="rounded-container">
        <img
            class="rouded-pics"
            src="{{ imagesUrl.firstUrl }}"
            [alt]="getTypeTitle() | translate"
        >
        <img
            class="rouded-pics"
            src="{{ imagesUrl.secondUrl }}"
            [alt]="getTypeTitle() | translate"
        >
        <img
            class="rouded-pics"
            src="{{ imagesUrl.thirdUrl }}"
            [alt]="getTypeTitle() | translate"
        >
    </div>
    <div class="explanation-box">
        <p class="explanation-text">
            {{ sugarExplanation.firstParagraph | translate }}
        </p>
        <p class="explanation-text">
            {{ sugarExplanation.secondParagraph | translate }}
        </p>
        <p class="explanation-text">
            {{ sugarExplanation.thirdParagraph | translate }}
        </p>
    </div>
    <a
        class="mp-main-button bt-md outline-red"
        href="javascript:void(0);"
        (click)="closeModal()"
    >
        {{ 'modules.frictionless.what_is_sugar_btn' | translate }}
    </a>
</div>
