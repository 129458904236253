import { HttpResponse } from '@angular/common/http';
import { Directive } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginInfo } from '@libs/shared/profile/login-info';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';

@Directive()
export abstract class LoginComponentCommon {
  readonly LOGIN_STATUS_SUSPENDED: string = 'status.suspended';
  readonly LOGIN_STATUS_PERM_DELETED: string = 'status.temporary_deleted';
  readonly LOGIN_STATUS_DELETED: string = 'status.deleted';
  readonly LOGIN_ONLY_CHANGE_PASSWORD: string = 'only.with.reseted.password';

  public loginInfo: LoginInfo;
  public CLOSE = '\uf00d';
  public CHECKED = '\uf00c';
  public emailValidateIcon: string;

  constructor(protected userService: UserServiceCommon) {
    this.loginInfo = new LoginInfo();
  }

  login(): Observable<HttpResponse<any>> {
    return this.userService.login(this.loginInfo);
  }

  getEmailValidatedIcon(): boolean {
    if (!this.loginInfo.username) {
      return false;
    }

    const validEmail = this.loginInfo.username.indexOf('@') !== -1 && UserCommon.isValidEmail(this.loginInfo.username);

    return validEmail || this.loginInfo.isValidUserName();
  }

  getValidateIcon(action: boolean): string {
    if (!action) {
      return this.CLOSE;
    }

    return this.CHECKED;
  }

  onEmailChange(): void {
    this.emailValidateIcon = this.getValidateIcon(this.getEmailValidatedIcon());
  }
}
