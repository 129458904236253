import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { MeltServiceCommon } from '@libs/modules/main/services/melt/melt.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { MeltProfilesActions } from '@libs/store/melt-profiles';

import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { SocketService } from '@meupatrocinio/services/socket/socket.service';

@Injectable()
export class MeltService extends MeltServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpService,
    protected downloadManager: DownloadManagerService,
    protected socketService: SocketService,
  ) {
    super(store, authHttp, downloadManager);
  }

  watchMelt(): void {
    this.subscriptions.push(
      this.socketService.listenUserEvent('.melt').subscribe({
        next: (socketMessage: string): void => {
          const userMeltId: number = JSON.parse(socketMessage).data.meltId;

          this.store.dispatch(
            MeltProfilesActions.upsertMeltProfile({
              profile: { profile_id: userMeltId },
            }),
          );

          this.addMeltPushNotification(userMeltId);
          this.handleMeltToast(userMeltId);
        },
      }),
    );
  }
}
