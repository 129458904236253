<mp-actionbar>
    <mp-actionbar-back-title [title]="productDescription"></mp-actionbar-back-title>
</mp-actionbar>
<main>
    <div
        class="modal"
        *ngIf="isLoading | async"
    >
        <mp-loading></mp-loading>
    </div>
    <div>
        <form
            class="page-form payment-form"
            (submit)="makePayment()"
        >
            <div class="form-group">
                <label for="cpf">
                    {{ 'modules.main.pages.payment.cpf' | translate }}
                </label>
                <input
                    class="form-control"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    id="cpf"
                    [(ngModel)]="cpf"
                    [ngModelOptions]="{standalone: true}"
                >
            </div>
            <div
                *ngIf="(boletoUrlToCopy | async) as boletoUrl"
                class="form-group"
            >
                <p class="small">
                    <strong>
                        <mp-advanced-translate key="modules.main.pages.payment-boleto.download_boleto">
                            <a
                                [href]="boletoUrl"
                                mp-translate-param="link"
                                target="_blank"
                                download="download"
                            >
                                {{ 'modules.main.pages.payment-boleto.download_boleto.link' | translate }}
                            </a>
                        </mp-advanced-translate>
                    </strong>
                </p>
            </div>
            <div class="form-group">
                <button
                    *ngIf="!(boletoUrlToCopy | async)"
                    class="btn"
                    type="submit"
                    [disabled]="isProcessingPayment | async"
                >
                    {{ 'modules.main.pages.payment.generate_boleto' | translate }}
                </button>
                <button
                    *ngIf="boletoUrlToCopy | async as boletoUrl"
                    class="btn boleto-url"
                    type="button"
                    (click)="copyToClipboard(boletoUrl)"
                >
                    {{ 'modules.main.pages.payment.generate_boleto.copy_to_clipboard' | translate }}
                    <span
                        class="material-icons"
                        aria-hidden="true"
                    >
                        &#xE14D;
                    </span>
                </button>
            </div>
        </form>
    </div>
</main>
<mp-loading *ngIf="isProcessingPayment | async"></mp-loading>
