import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IReferralStatus } from '@libs/shared/referral/referral-status';
import { IApplicationState } from '@libs/store/application-state';
import { range } from '@libs/utils/array-functions';

export abstract class ReferralServiceCommon {
  constructor(
    protected authHttp: AuthHttpServiceCommon,
    protected store: Store<IApplicationState>,
  ) {
    //
  }

  public abstract retrieveReferAll(profileId: number): Observable<IAuthResponse>;

  public abstract retrieveReferralStatus(profileId: number): Observable<IAuthResponse>;

  public abstract getCode(referralStatus: IReferralStatus): string;

  public generateRange(referralStatus: IReferralStatus): number[] {
    let nRange = 1;
    if (referralStatus.current_limit_to_reward > 0) {
      nRange = referralStatus.current_limit_to_reward + 1;
    }

    return range(1, nRange, 1);
  }

  public getStatus(referralStatus: IReferralStatus): number {
    if (referralStatus.current_limit_to_reward === 0) {
      return 0;
    }

    return referralStatus.total_paid % referralStatus.current_limit_to_reward;
  }
}
