import { Action } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const PROFILES_BLOCKED_RECEIVED_ACTION: string = 'PROFILES_BLOCKED_RECEIVED_ACTION';
export const PROFILE_UNBLOCK_RECEIVED_ACTION: string = 'PROFILE_UNBLOCK_RECEIVED_ACTION';

export interface IProfilesBlockedReceivedActionPayload {
  profiles: IProfile[];
  skipDownloadProfiles?: boolean;
}

export class ProfilesBlockedReceivedAction implements Action {
  readonly type: string = PROFILES_BLOCKED_RECEIVED_ACTION;

  constructor(public payload: IProfilesBlockedReceivedActionPayload) {
    //
  }
}

export interface IProfileUnblockReceivedActionPayload {
  profileId: number;
  skipDownloadProfile?: boolean;
}

export class ProfileUnblockReceivedAction implements Action {
  readonly type: string = PROFILE_UNBLOCK_RECEIVED_ACTION;

  constructor(public payload: IProfileUnblockReceivedActionPayload) {
    //
  }
}
