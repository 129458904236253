import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IMeltProfilesState } from '@libs/store/melt-profiles';
import { meltProfilesAdapter } from '@libs/store/melt-profiles/state';

const selectFeature = createFeatureSelector<IMeltProfilesState>('meltProfiles');

export const selectAll = createSelector(selectFeature, meltProfilesAdapter.getSelectors().selectAll);

export const selectMeltProfiles = createSelector(selectFeature, (state: IMeltProfilesState): string[] | number[] => {
  return state.ids;
});

export const selectMeltTTL = createSelector(selectFeature, (state: IMeltProfilesState): number => {
  return state.lastUpdateTimestamp;
});
