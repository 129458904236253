<div
    class="subscribed-status"
    [class.extended]="!isTinyBadges()"
>
    <mp-subscribe-button
        id="menu-bar-button-to-sign"
        (click)="closeSlidableMenu()"
        [theme]="getButtonSizeModifierClass()"
    ></mp-subscribe-button>
    <mp-membership-badge (click)="openSlidableMenu()" *ngIf="isTinyBadges(); else badgesWithText"></mp-membership-badge>
    <ng-template #badgesWithText>
        <mp-membership-badge-extended></mp-membership-badge-extended>
    </ng-template>
</div>
