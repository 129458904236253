import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MessageCommon } from '@libs/shared/message/message.common';
import { IMessage } from '@libs/store/messages/interface';
import { IMessageState, MessageAdapter } from '@libs/store/messages/state';
import { IPendingMessage, PendingMessageSelectors } from '@libs/store/pending-messages';

export const selectState = createFeatureSelector<IMessageState>('storeMessageEntities');

export const selectAll = createSelector(selectState, MessageAdapter.getSelectors().selectAll);

export const alreadySentFreeMessage = createSelector(
  selectState,
  (state: IMessageState): boolean => state.sentFreeMessage,
);

export const selectMessagesTotal = createSelector(selectState, MessageAdapter.getSelectors().selectTotal);

export const selectLastMessageIdByProfileAndReceiver = createSelector(
  selectAll,
  (messages: IMessage[], props: { profileId: number; receiverId: number }): number => {
    const messagesSelected: number[] = messages
      .filter((message: IMessage): boolean => {
        return MessageCommon.isMessageByProfileIdOrReceiverId(props.profileId, props.receiverId, message);
      })
      .map((message: IMessage): number => message.message_id);

    return messagesSelected[messagesSelected.length - 1];
  },
);

export const selectPendingAndMessagesByProfileOrReceiver = createSelector(
  selectAll,
  PendingMessageSelectors.selectAll,
  (messages: IMessage[], pending: IPendingMessage[], props: { profileId: number; receiverId: number }): IMessage[] =>
    MessageCommon.concatMessagesAndPendingMessages(messages, pending).filter(
      (message: IMessage | IPendingMessage): boolean => {
        return MessageCommon.isMessageByProfileIdOrReceiverId(props.profileId, props.receiverId, message);
      },
    ),
);

export const selectMessagesIdsNotFound = createSelector(
  selectAll,
  (messages: IMessage[], props: { messagesIds: number[] }): number[] => {
    return props.messagesIds.filter(
      (messageId: number): boolean => MessageCommon.findMessageIndexByMessageId(messages, messageId) === -1,
    );
  },
);

export const selectMessagesByMessagesIds = createSelector(
  selectAll,
  (messages: IMessage[], props: { messagesIds: number[] }): IMessage[] => {
    return messages.filter(
      (message: IMessage): boolean => MessageCommon.findMessageIndexByMessagesIds(message, props.messagesIds) !== -1,
    );
  },
);

export const selectLastMessageIdReceived = createSelector(
  selectAll,
  (messages: IMessage[], props: { profileId: number }): number => {
    return MessageCommon.getLastReceivedMessageId(messages, props.profileId);
  },
);
