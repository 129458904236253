import { Action } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const SEARCH_PROFILES_RECEIVED_ACTION: string = 'SEARCH_PROFILES_RECEIVED_ACTION';
export const SEARCH_PROFILES_REFRESHED_ACTION: string = 'SEARCH_PROFILES_REFRESHED_ACTION';
export const SEARCH_PROFILES_REMOVED_ACTION: string = 'SEARCH_PROFILES_REMOVED_ACTION';

export interface ISearchProfilesReceivedActionPayload {
  profiles: IProfile[];
}

export class SearchProfilesReceivedAction implements Action {
  readonly type: string = SEARCH_PROFILES_RECEIVED_ACTION;

  constructor(public payload: ISearchProfilesReceivedActionPayload) {
    //
  }
}

export class SearchProfilesRefreshedAction implements Action {
  readonly type: string = SEARCH_PROFILES_REFRESHED_ACTION;
}

export class SearchProfilesRemovedAction implements Action {
  readonly type: string = SEARCH_PROFILES_REMOVED_ACTION;

  constructor(public payload: ISearchProfilesReceivedActionPayload) {
    //
  }
}
