import { Action, createReducer, on } from '@ngrx/store';

import * as BoostActions from '@libs/store/boost/actions';
import { IBoostRunningInfo } from '@libs/store/boost/boost-running-info';
import { IBoostState } from '@libs/store/boost/interfaces/boost-state.interface';
import { boostInitialState } from '@libs/store/boost/state';

const boostReducer = createReducer(
  boostInitialState,
  on(
    BoostActions.setCanShowBoostList,
    (state: IBoostState, { canShowBoostList }: { canShowBoostList: boolean }): IBoostState => {
      return {
        ...state,
        canShowBoostList,
      };
    },
  ),
  on(
    BoostActions.setIsActivatingBoost,
    (state: IBoostState, { isActivatingBoost }: { isActivatingBoost: boolean }): IBoostState => {
      return {
        ...state,
        isActivatingBoost,
      };
    },
  ),
  on(
    BoostActions.setIsActivationButtonDisabled,
    (state: IBoostState, { isDisabled }: { isDisabled: boolean }): IBoostState => {
      return {
        ...state,
        isActivationButtonDisabled: isDisabled,
      };
    },
  ),
  on(
    BoostActions.setBoostRunningInfo,
    (state: IBoostState, boostRunning: { boostRunning: IBoostRunningInfo }): IBoostState => {
      return {
        ...state,
        boostRunningInfo: {
          startTimestamp: boostRunning.boostRunning.startTimestamp,
          endTimestamp: boostRunning.boostRunning.endTimestamp,
        },
      };
    },
  ),
  on(BoostActions.setBalance, (state: IBoostState, { balance }: { balance: number }): IBoostState => {
    return {
      ...state,
      balance,
    };
  }),
  on(
    BoostActions.setCanShowBoostModalResults,
    (state: IBoostState, { canShowBoostModalResults }: { canShowBoostModalResults: boolean }): IBoostState => {
      return {
        ...state,
        canShowBoostModalResults,
      };
    },
  ),
  on(
    BoostActions.setMultiplierValue,
    (state: IBoostState, { multiplierValue }: { multiplierValue: number }): IBoostState => {
      return {
        ...state,
        multiplierValue,
      };
    },
  ),
  on(
    BoostActions.setIsProcessingBoostPrize,
    (state: IBoostState, { isProcessing }: { isProcessing: boolean }): IBoostState => {
      return {
        ...state,
        isProcessingBoostPrize: isProcessing,
      };
    },
  ),
);

export function reducer(state: IBoostState, action: Action): IBoostState {
  return boostReducer(state, action);
}
