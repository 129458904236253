import { Directive, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Registration } from '@libs/interfaces/registration/registration';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';

@Directive()
export class ValidationGroupErrorComponentCommon {
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() public groupName = '';

  public get groupCustomErrorMessage(): string {
    return FormHelpers.getFormControlSpecificError(this.form, this.groupName, Registration.VALIDATION_ERROR_KEY_NAME);
  }
}
