<ng-container *ngIf="isPaymentDone(paymentStatus$ | async)">
    <ng-container *ngIf="!isPendingElite(isPendingElite$() | async)">
        <div
            class="upgrade-status"
            *ngIf="isNonElitePaymentOk(paymentStatus$ | async)"
        >
            <div class="box bg-white">
                <button
                    type="button"
                    (click)="closeBox()"
                >
                    <img
                        src="/assets/img/close-upgrade-status.svg"
                        alt="{{ 'common.close' | translate }}"
                    >
                </button>
                <img
                    class="icon-badge"
                    src="/assets/img/premium-rounded.svg"
                    alt="{{ 'modules.main.pages.home.premium' | translate }}"
                >
                <div class="title">
                    {{ 'modules.main.pages.home.premium_subscription' | translate }}
                </div>
                <p *ngIf="!isBabyPremium()">
                    {{ 'modules.main.pages.home.subscription_subtext' | translate }}
                </p>
                <p *ngIf="isBabyPremium()">
                    {{ 'modules.main.pages.home.sbp_subscription_subtext' | translate }}
                </p>
            </div>
        </div>
    </ng-container>
    <div
        class="upgrade-status"
        *ngIf="isPendingElite$() | async"
    >
        <div class="box bg-white">
            <img
                class="icon-badge"
                src="/assets/img/line-crown.svg"
                alt
            >
            <div class="title">
                {{ 'modules.main.pages.home.elite_subscription_pending' | translate }}
            </div>
            <p>
                <mp-advanced-translate key="modules.main.pages.home.elite_subscription_pending_subtext">
                    <b mp-translate-param="rg">
                        {{ 'modules.main.pages.home.elite_subscription_pending_subtext.rg' | translate }}
                    </b>
                    <b mp-translate-param="cpf">
                        {{ 'modules.main.pages.payment.cpf' | translate }}
                    </b>
                    <b mp-translate-param="cnh">
                        {{ 'modules.main.pages.home.elite_subscription_pending_subtext.cnh' | translate }}
                    </b>
                </mp-advanced-translate>
            </p>
            <a
                class="btn btn-cta"
                href="mailto:membroelite@meupatrocinio.com"
            >
                {{ 'modules.main.pages.home.elite_subscription_pending_send' | translate }}
            </a>
        </div>
    </div>
</ng-container>
