<div
    class="step"
    id="step04"
>
    <mp-fancy-dropdown
        id="registration-step4-select-you-smoke"
        #smokeFrequencyElement
        label="modules.main.pages.edit_profile.smoke"
        [(value)]="smokeFrequency"
        [options]="smokeFrequencyOptions()"
        (valueChange)="activateDropdownIfEmpty(drinkFrequencyElement)"
        [active]="!smokeFrequency"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step4-select-you-drink"
        #drinkFrequencyElement
        label="modules.main.pages.edit_profile.drink"
        [(value)]="drinkFrequency"
        [options]="drinkFrequencyOptions()"
        (valueChange)="activateDropdownIfEmpty(relationshipElement)"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step4-select-marital-status"
        #relationshipElement
        label="modules.main.pages.profile.details_page.relationship"
        [(value)]="relationship"
        [options]="relationshipOptions()"
        (valueChange)="activateDropdownIfEmpty(childrenElement)"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step4-select-have-children"
        #childrenElement
        label="modules.main.pages.edit_profile.have_children"
        [(value)]="children"
        [options]="childrenOptions()"
        (valueChange)="activateDropdownIfEmpty(educationElement)"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step4-select-education"
        #educationElement
        label="modules.frictionless.education"
        [(value)]="education"
        [options]="educationOptions()"
        (valueChange)="activateDropdownIfEmpty(occupationElement)"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step4-select-profession"
        #occupationElement
        label="modules.frictionless.occupation"
        [(value)]="occupation"
        [options]="occupationOptions()"
        (valueChange)="activateDropdownIfEmpty(incomeElement)"
    ></mp-fancy-dropdown>
    <div [hidden]="!isDaddyMommy()">
        <mp-fancy-dropdown
            id="registration-step4-select-monthly-income"
            #incomeElement
            label="modules.main.pages.profile.details_page.income"
            [(value)]="income"
            [options]="incomeOptions()"
            (valueChange)="activateDropdownIfEmpty(netWorthElement)"
        ></mp-fancy-dropdown>
        <mp-fancy-dropdown
            id="registration-step4-select-personal-assets"
            #netWorthElement
            label="modules.main.pages.profile.details_page.net_worth"
            [(value)]="netWorth"
            [options]="netWorthOptions()"
        ></mp-fancy-dropdown>
    </div>
    <div class="buttons-container">
        <button
            id="registration-step4-button-save-continue"
            class="mp-main-button bt-shadow bt-lg"
            [disabled]="!canContinue()"
            (click)="commitStep()"
        >
            {{ 'modules.frictionless.save_and_continue' | translate }}
        </button>
        <button
            id="registration-step4-button-finish-later"
            class="text-link"
            *ngIf="canFinishRegistrationLater()"
            (click)="submitRegistration()"
        >
            {{ 'modules.frictionless.finish_later' | translate }}
        </button>
    </div>
</div>
