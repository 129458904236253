import { Action, createReducer, on } from '@ngrx/store';

import {
  setEmailConfirmation,
  setHasLoadedPaymentProvider,
  setHasRedirectedOnSession,
  setIsRefreshButtonVisible,
  setIsUpsellVisible,
  setListFirstUpdateTimestamp,
  setResendEmailTimestamp,
  setSearchLocationOption,
  setWebSocketStatus,
} from '@libs/store/new-ui/actions';
import { INewUiState } from '@libs/store/new-ui/interfaces/new-ui-state.interface';
import { newUiInitialState } from '@libs/store/new-ui/state';
import { setIsSavingNewPassword } from '@libs/store/password/actions';

const newUiReducer = createReducer(
  newUiInitialState,
  on(
    setSearchLocationOption,
    (state: INewUiState, { searchLocationOption }: { searchLocationOption: number }): INewUiState => {
      return {
        ...state,
        searchLocationOption,
      };
    },
  ),
  on(
    setIsSavingNewPassword,
    (state: INewUiState, { isSavingNewPassword }: { isSavingNewPassword: boolean }): INewUiState => {
      return {
        ...state,
        isSavingNewPassword,
      };
    },
  ),
  on(setHasRedirectedOnSession, (state: INewUiState): INewUiState => {
    return {
      ...state,
      hasRedirectedOnSession: true,
    };
  }),
  on(
    setIsRefreshButtonVisible,
    (state: INewUiState, { isRefreshButtonVisible }: { isRefreshButtonVisible: boolean }): INewUiState => {
      return {
        ...state,
        isRefreshButtonVisible,
      };
    },
  ),
  on(
    setListFirstUpdateTimestamp,
    (state: INewUiState, { listFirstUpdateTimestamp }: { listFirstUpdateTimestamp: number }): INewUiState => {
      return {
        ...state,
        listFirstUpdateTimestamp,
      };
    },
  ),
  on(setIsUpsellVisible, (state: INewUiState, { isOpen }: { isOpen: boolean }): INewUiState => {
    return {
      ...state,
      isUpsellVisible: isOpen,
    };
  }),
  on(setWebSocketStatus, (state: INewUiState, { webSocketStatus }): INewUiState => {
    return {
      ...state,
      webSocketStatus,
    };
  }),
  on(setResendEmailTimestamp, (state: INewUiState, { resendEmailTimestamp }) => {
    return {
      ...state,
      resendEmailTimestamp,
    };
  }),
  on(setEmailConfirmation, (state: INewUiState, { email }) => {
    return {
      ...state,
      emailConfirmation: email,
    };
  }),
  on(setHasLoadedPaymentProvider, (state, { hasLoadedPaymentProvider }) => {
    return {
      ...state,
      hasLoadedPaymentProvider,
    };
  }),
);

export function reducer(state: INewUiState, action: Action): INewUiState {
  return newUiReducer(state, action);
}
