import { Injectable } from '@angular/core';

import { GlobalObjectServiceCommon } from '@libs/services/global-object/global-object.service.common';

@Injectable({
  providedIn: 'root',
})
export class GlobalObjectService extends GlobalObjectServiceCommon {
  constructor() {
    super();
  }
}
