import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { ProgressierService } from '@meupatrocinio/infra/progressier/progressier.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class ProgressierEffects {
  initializeUser$ = createEffect(
    () =>
      this.authenticationService.onLogin$.pipe(
        tap({
          next: () => {
            this.progressierService.identifyUser();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected authenticationService: AuthenticationService,
    protected progressierService: ProgressierService,
  ) {
    //
  }
}
