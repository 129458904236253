import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BusinessCommon } from '@meupatrocinio/utils/business-common';

@Component({
  selector: 'mp-payment-additional-information',
  templateUrl: 'payment-additional-information.html',
  styleUrls: ['payment-additional-information.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentAdditionalInformationComponent {
  @Input() isExpressApprovalCheckout = false;
  @Input() isEliteCheckout = false;

  public getCnpj() {
    return BusinessCommon.CNPJ;
  }

  public getBoletoExplanation() {
    if (this.isExpressApprovalCheckout) {
      return 'modules.main.pages.payment.blurb4.express_approval';
    }

    return 'modules.main.pages.payment.blurb4';
  }
}
