import { createAction, props } from '@ngrx/store';

import { ICreateBoletoPayment } from '@libs/modules/payment-v2/interfaces/create-boleto-payment.interface';
import { ICreatePixPayment } from '@libs/modules/payment-v2/interfaces/create-pix-payment.interface';
import { IHandleCreditCardPayment } from '@libs/modules/payment-v2/interfaces/handle-credit-card-payment.interface';
import { IPrepareCreditCardPayment } from '@libs/modules/payment-v2/interfaces/prepare-credit-card-payment.interface';

enum Actions {
  PREPARE_CREDIT_CARD_PAYMENT = '[Provider Switch] - Prepare Credit Card',
  HANDLE_CREDIT_CARD_PAYMENT = '[Provider Switch] - Handle Credit Card Payment By Provider',
  HANDLE_BOLETO_PAYMENT = '[Provider Switch] - Handle Boleto Payment By Provider',
  HANDLE_PIX_PAYMENT = '[Provider Switch] - Handle Pix Payment By Provider',
}

export const prepareCreditCardPayment = createAction(
  Actions.PREPARE_CREDIT_CARD_PAYMENT,
  props<IPrepareCreditCardPayment>(),
);

export const handleCreditCardPayment = createAction(
  Actions.HANDLE_CREDIT_CARD_PAYMENT,
  props<IHandleCreditCardPayment>(),
);

export const handleBoletoPayment = createAction(Actions.HANDLE_BOLETO_PAYMENT, props<ICreateBoletoPayment>());

export const handlePixPayment = createAction(Actions.HANDLE_PIX_PAYMENT, props<ICreatePixPayment>());
