import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class AuthGuard implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  protected url: string;
  protected isVerified: boolean;
  protected isSugarBaby: boolean;
  protected isPaying: boolean;
  protected expressApprovalPaid: boolean;
  protected user: UserCommon | undefined;
  protected hasUserMadeAnyPayments: boolean;
  protected numberOfMessagesReceived: number;

  constructor(
    protected authenticationService: AuthenticationService,
    protected router: Router,
    protected store: Store<IApplicationState>,
    protected modal: AdvancedModalService,
  ) {
    this.subscriptions.push(
      this.store
        .select('user')
        .pipe(filter((user) => UserCommon.isConnected(user)))
        .subscribe((user): void => {
          this.user = user;
          this.isSugarBaby = UserCommon.isBaby(user);
          this.isPaying = !MembershipCommon.isFree(user.membership_type_id);
          this.expressApprovalPaid = UserCommon.hasPaidExpressApproval(user);
          this.isVerified = UserCommon.isPhotoVerified(user);
        }),
    );

    this.subscriptions.push(
      this.store.select('stats').subscribe((stats): void => {
        this.hasUserMadeAnyPayments = stats.paymentsMade > 0;
        this.numberOfMessagesReceived = stats.numberOfMessagesReceived;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | UrlTree {
    this.url = routerState.url;

    if (!this.checkLogin()) {
      this.authenticationService.onFailedAttemptToAccessURL(this.url);

      return this.router.createUrlTree(['initial', 'login'], this.getUrlTreeParameters(activatedRoute));
    }

    if (this.checkLoggedOutPages() || this.isWrongfullyAtPhotoVerifyLocation() || this.isWrongfullyAtPlansLocation()) {
      return this.navigateToHome(activatedRoute);
    }

    if (this.checkExpressApprovalPaymentLocation() && this.expressApprovalPaid) {
      return this.navigateToExpressApproval(activatedRoute);
    }

    return true;
  }

  protected checkLogin(): boolean {
    if (!this.authenticationService.isAuthenticated()) {
      return this.handleUnauthenticatedAccess();
    }

    if (this.authenticationService.isAtWaitingList()) {
      return this.handleWaitingListUser();
    }

    if (this.checkExpressApprovalLocation()) {
      this.router.navigate(['main'], { replaceUrl: true });
    }

    return true;
  }

  protected navigateToExpressApproval(activatedRoute: ActivatedRouteSnapshot): UrlTree {
    return this.router.createUrlTree(['register', 'waiting-list'], this.getUrlTreeParameters(activatedRoute));
  }

  protected navigateToHome(activatedRoute: ActivatedRouteSnapshot): UrlTree {
    return this.router.createUrlTree(['main', 'home'], this.getUrlTreeParameters(activatedRoute));
  }

  protected handleUnauthenticatedAccess(): boolean {
    if (this.checkInitialLocation() || this.checkSignupLocation()) {
      return true;
    }

    this.authenticationService.logout();

    return false;
  }

  protected handleWaitingListUser(): boolean {
    if (this.checkExpressApprovalLocation()) {
      return true;
    }

    this.router.navigate(['register', 'waiting-list'], {
      replaceUrl: true,
    });

    return false;
  }

  protected checkLoggedOutPages(): boolean {
    return this.authenticationService.isAuthenticated() && (this.checkInitialLocation() || this.checkSignupLocation());
  }

  protected isWrongfullyAtPhotoVerifyLocation(): boolean {
    return this.checkPhotoVerifyLocation() && !this.checkVerified();
  }

  protected isWrongfullyAtPlansLocation(): boolean {
    return this.checkPlansLocation() && !this.isPaying;
  }

  protected getUrlTreeParameters(activatedRoute: ActivatedRouteSnapshot) {
    return {
      queryParams: activatedRoute.root.queryParams,
      fragment: activatedRoute.root.fragment,
    };
  }

  protected checkVerified(): boolean {
    return this.isSugarBaby && !this.isVerified;
  }

  protected checkExpressApprovalLocation(): boolean {
    return this.url.indexOf('register/waiting-list') !== -1;
  }

  protected checkInitialLocation(): boolean {
    return this.url.indexOf('initial') !== -1;
  }

  protected checkSignupLocation(): boolean {
    return this.url.indexOf('cadastro') !== -1;
  }

  protected checkExpressApprovalPaymentLocation(): boolean {
    return this.url.indexOf('express-approval/checkout') !== -1;
  }

  protected checkPhotoVerifyLocation(): boolean {
    return this.url.indexOf('photo-verify') !== -1;
  }

  protected checkPlansLocation(): boolean {
    return this.url.indexOf('settings/plans') !== -1;
  }
}
