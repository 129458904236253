import { Component, Input } from '@angular/core';

@Component({
  selector: 'mp-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.scss'],
})
export class TipComponent {
  @Input() borderColor = '';
  @Input() icon = '';

  constructor() {
    //
  }

  public getBorderColor(): string {
    if (!this.borderColor) {
      return '';
    }

    return 'tip' + this.borderColor;
  }
}
