import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const MeltModalAnimations: AnimationTriggerMetadata[] = [
  trigger('MeltModal', [
    transition('void => *', [
      style({
        top: '-100%',
      }),
      animate('300ms linear'),
    ]),
  ]),
];
