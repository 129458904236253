import { Action, createReducer, on } from '@ngrx/store';

import {
  savePaymentOptions,
  setActiveVersion,
  setDefaultSelectedPlans,
  setIsResolvingVisiblePlans,
  setSelectedPlan,
} from '@libs/store/upgrade-account/actions';
import { ISavePaymentOptionsAction } from '@libs/store/upgrade-account/interfaces/save-payment-options-action.interface';
import { IUpgradeAccountActionPayload } from '@libs/store/upgrade-account/interfaces/upgrade-account-action-payload.interface';
import { IUpgradeAccountState } from '@libs/store/upgrade-account/interfaces/upgrade-account-state.interface';
import { upgradeAccountInitialState } from '@libs/store/upgrade-account/state';
import { SetDefaultSelectedPlansAction } from '@libs/store/upgrade-account/types/set-default-selected-plans-action.type';

const upgradeAccountReducer = createReducer(
  upgradeAccountInitialState,
  on(
    setIsResolvingVisiblePlans,
    (
      state: IUpgradeAccountState,
      { isResolvingVisiblePlans }: { isResolvingVisiblePlans: boolean },
    ): IUpgradeAccountState => {
      return {
        ...state,
        isResolvingVisiblePlans,
      };
    },
  ),
  on(
    setActiveVersion,
    (state: IUpgradeAccountState, { version }: IUpgradeAccountActionPayload): IUpgradeAccountState => {
      return {
        ...state,
        activeVersion: version,
      };
    },
  ),
  on(
    savePaymentOptions,
    (
      state: IUpgradeAccountState,
      { version, paymentOptions, dataTTL }: ISavePaymentOptionsAction,
    ): IUpgradeAccountState => {
      return {
        ...state,
        paymentOptions: {
          ...state.paymentOptions,
          [version]: [...paymentOptions],
        },
        dataTTL,
      };
    },
  ),
  on(
    setSelectedPlan,
    (
      state: IUpgradeAccountState,
      {
        planUuid,
        membershipTypeId,
      }: {
        planUuid: string;
        membershipTypeId: number;
      },
    ): IUpgradeAccountState => {
      return {
        ...state,
        selectedPlans: {
          ...state.selectedPlans,
          [membershipTypeId]: planUuid,
        },
      };
    },
  ),
  on(
    setDefaultSelectedPlans,
    (state: IUpgradeAccountState, { selectedPlans }: SetDefaultSelectedPlansAction): IUpgradeAccountState => {
      return {
        ...state,
        selectedPlans: {
          ...selectedPlans,
        },
      };
    },
  ),
);

export function reducer(state: IUpgradeAccountState, action: Action): IUpgradeAccountState {
  return upgradeAccountReducer(state, action);
}
