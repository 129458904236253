import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { IPlatformService } from '@libs/shared/interfaces/platform-service.interface';

@Injectable({
  providedIn: 'root',
})
export class PlatformService implements IPlatformService {
  readonly isMobile$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected platform: Platform,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    //
  }

  public findAndUpdateIsMobile() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params['origin']) {
        this.setIsMobile(params['origin'] === 'native-app');
      }
    });
  }

  private setIsMobile(isMobile: boolean) {
    this.isMobile$.next(isMobile);
  }

  public get isMobile() {
    return this.isMobile$.value;
  }

  public isMobileDevice(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }
}
