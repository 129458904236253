import { Injectable, Injector } from '@angular/core';

import { IToastOptions } from '@libs/modules/shared/toast-common/toast-options.interface';
import { TOAST_OPTIONS } from '@libs/modules/shared/toast-common/toast.tokens';
import { IToastComponent } from '@libs/shared/toast/interfaces/toast-component.interface';

import { MPToastComponent } from '@meupatrocinio/modules/shared/mp-toast/mp-toast.component';
import { InjectComponentService } from '@meupatrocinio/services/inject-component.service';

import { ActiveToast } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CustomToastService {
  public toastOptions: IToastOptions = {
    toast: undefined,
  };

  constructor(
    protected injector: Injector,
    protected injectComponentService: InjectComponentService,
  ) {
    //
  }

  public injectNewCustomToast(toast: ActiveToast<MPToastComponent>, component: IToastComponent): void {
    this.toastOptions = {
      toast: toast,
    };

    if (toast.toastRef.componentInstance.mpToastContent === undefined) {
      return;
    }

    this.injectComponentService.inject(
      component,
      toast.toastRef.componentInstance.mpToastContent,
      this.createToastInjector(),
    );

    toast.toastRef.componentInstance.hasCustomContent = true;
  }

  public isCustomToast(toast: ActiveToast<MPToastComponent> | undefined): boolean {
    if (toast === undefined) {
      return false;
    }

    return toast.toastRef.componentInstance.hasCustomContent;
  }

  protected createToastInjector(): Injector {
    return Injector.create({
      providers: [
        {
          provide: TOAST_OPTIONS,
          useValue: this.toastOptions,
        },
      ],
    });
  }
}
