import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IBoostPackage } from '@libs/modules/boost/interface/boost-package';
import { IBoostPackagesState, boostPackagesAdapter } from '@libs/store/boost-packages/state';

const selectFeature = createFeatureSelector<IBoostPackagesState>('boostPackages');

export const selectAll = createSelector(selectFeature, boostPackagesAdapter.getSelectors().selectAll);

export const selectIsPaymentProcessing = createSelector(
  selectFeature,
  (state: IBoostPackagesState): boolean => state.isPaymentProcessing,
);

export const selectProductVariantByVariantUuid = (variantUuid: string) =>
  createSelector(selectAll, (state: IBoostPackage[]): IBoostPackage | undefined => {
    return state.find((boostPackage: IBoostPackage): boolean => {
      return boostPackage.uuid === variantUuid;
    });
  });

export const selectRefreshDataTimestamp = createSelector(selectFeature, (state: IBoostPackagesState): number => {
  return state.refreshData;
});
