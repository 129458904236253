<div class="social-media-list">
    <div
    class="social-media-list__icon-block"
        *ngFor="let icon of socialMediaIconsList; trackBy: trackBySocialMediaIcon"
    >
        <a [href]="icon.socialMediaLink" target="_blank">
            <img
                class="social-media-list__image"
                [src]="icon.imagePath"
                [alt]="icon.imageAlt | translate"
            >
        </a>
    </div>
</div>

