import { IProfile } from '@libs/shared/profile/profile';
import {
  ProfilesFeaturedReceivedAction,
  PROFILES_FEATURED_RECEIVED_ACTION,
  PROFILES_FEATURED_REFRESHED_ACTION,
  PROFILES_FEATURED_REMOVED_ACTION,
} from '@libs/store/profiles/actions/featured.action';

export function storeFeaturedProfiles(state: number[] = [], action: ProfilesFeaturedReceivedAction): number[] {
  switch (action.type) {
    case PROFILES_FEATURED_RECEIVED_ACTION:
      return onProfilesFeaturedReceived(state, action.payload.profiles);
    case PROFILES_FEATURED_REMOVED_ACTION:
      return onProfilesFeaturedRemoved(state, action.payload.profiles);
    case PROFILES_FEATURED_REFRESHED_ACTION:
      return [];
    default:
      return state;
  }
}

function onProfilesFeaturedReceived(state: number[], profiles: IProfile[]): number[] {
  for (const profile of profiles) {
    state = onProfileFeaturedReceived(state, profile);
  }

  return state;
}

function onProfileFeaturedReceived(state: number[], newProfile: IProfile): number[] {
  const index: number = state.indexOf(newProfile.profile_id);
  if (index !== -1) {
    return state;
  }

  return [...state, newProfile.profile_id];
}

function onProfilesFeaturedRemoved(state: number[], newProfiles: IProfile[]): number[] {
  for (const profile of newProfiles) {
    state = onProfileFeaturedRemoved(state, profile);
  }

  return state;
}

function onProfileFeaturedRemoved(state: number[], newProfile: IProfile): number[] {
  const index: number = state.indexOf(newProfile.profile_id);
  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
