<div
    class="testimonials"
    [ngClass]="{ 'testimonials__single-package': mode === 'single-package' }"
>
    <h4 class="testimonials__title">
        {{ getTitleTranslation() }}
    </h4>
    <ul class="testimonials__list">
        <li
            class="testimonial"
            *ngFor="let testimonial of testimonials$ | async; trackBy: trackByUser"
            [ngClass]="getFullWidthClass()"
        >
            <div class="testimonial__quotation">
                <img [src]="quotationMarks" alt="Quotation marks">
            </div>
            <div class="testimonial__comment">{{ testimonial.quote }}</div>
            <div class="testimonial__user notranslate">{{ testimonial.user }}</div>
        </li>
    </ul>
</div>
