import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ILastPayment } from '@libs/modules/main/services/payment/payment.common';
import { IUserAnalytics } from '@libs/services/analytics/interfaces/user-analytics.interface';
import { MembershipName, UserTypeName } from '@libs/services/analytics/interfaces/user-types';
import { GlobalObjectServiceCommon, ICustomWindow } from '@libs/services/global-object/global-object.service.common';
import { IBoostGtmData } from '@libs/shared/boost/interfaces/boost-gtm-data.interface';
import { MembershipCommon, MembershipType } from '@libs/shared/membership/membership.common';
import { IMobileResponse, IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { IPaymentInfo } from '@libs/store/payment-info';

import { CookieService } from 'ngx-cookie-service';

type AnalyticsParameterKeyValue =
  | string
  | number
  | IAnalyticsParameter
  | IAnalyticsParameter[]
  | IMobileResponse[]
  | boolean;
export interface IAnalyticsParameter {
  [key: string]: AnalyticsParameterKeyValue;
}

export interface IAnalyticsEvent {
  event: string;
}

export type AnalyticsEvent = Partial<IAnalyticsEvent> & IAnalyticsParameter;

@Injectable({
  providedIn: 'root',
})
export abstract class AnalyticsServiceCommon implements OnDestroy {
  protected subscriptions: Subscription[] = [];

  protected window: ICustomWindow;

  constructor(
    protected store: Store<IApplicationState>,
    protected router: Router,
    protected cookieService: CookieService,
    protected globalObjectReference: GlobalObjectServiceCommon,
  ) {
    this.window = globalObjectReference.window;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());

    this.subscriptions = [];
  }

  public abstract push(content: AnalyticsEvent): void;

  public abstract onLoaded(): void;

  public abstract get userLanguage(): string;

  public getPurchaseDate(lastPayment: ILastPayment): number | null {
    if (lastPayment.order_stamp !== undefined) {
      return lastPayment.order_stamp;
    }

    return null;
  }

  public getCurrentPlan(lastPayment: ILastPayment): number | null {
    if (lastPayment.period !== undefined) {
      return lastPayment.period;
    }

    return null;
  }

  public getInitialUserData(): IUserAnalytics {
    return {
      account_type: null,
      gender: null,
      match_sex: null,
      email: null,
      email_verified: null,
      username: null,
      profile_id: null,
      user_id: null,
      age: null,
      profile_country: null,
      profile_region: null,
      profile_city: null,
      profile_language: null,
      number_of_payments: null,
      number_of_days_purchased: null,
      number_of_photos: null,
      number_of_messages_sent: null,
      number_of_messages_received: null,
      number_of_logins: null,
      number_of_profiles_viewed: null,
      account_status: null,
      join_date: null,
      premium_status: null,
      vcid: null,
      utm: null,
      utm_source: null,
      android_review: null,
      is_complete: null,
      have_pending_changes: null,
      membership_expiration_timestamp: null,
      next_recurring_payment_timestamp: null,
      purchase_date: null,
      current_plan: null,
      photo_verified: null,
      pcat: null,
    };
  }

  protected getUserData(user: UserCommon, stats: IStats): IUserAnalytics {
    if (!UserCommon.isConnected(user) || Object.keys(stats).length === 0 || !user.sex) {
      return this.getInitialUserData();
    }

    const sex: number = user.sex;
    const what: number = user.profile_extended.what;

    return {
      account_type: this.getUserTypeName(sex, what),
      gender: sex,
      match_sex: user.match_sex,
      email: stats.email,
      email_verified: user.email_verified,
      username: user.username,
      profile_id: user.profile_id, // deprecated
      user_id: `${user.profile_id}`,
      age: user.age,
      profile_country: user.countryName,
      profile_region: user.stateName,
      profile_city: user.cityName,
      profile_language: this.userLanguage,
      number_of_payments: stats.paymentsMade,
      number_of_days_purchased: stats.purchasedDays,
      number_of_photos: user.public_album.length + user.private_album.length,
      number_of_messages_sent: stats.numberOfMessagesSent,
      number_of_messages_received: stats.numberOfMessagesReceived,
      number_of_logins: stats.numberOfLogins,
      number_of_profiles_viewed: stats.numberOfProfilesViewed,
      account_status: user.status,
      join_date: stats.joinDateTimestamp,
      premium_status: this.getMembershipName(user.membership_type_id, stats.paymentsMade),
      vcid: stats.voluumCookie,
      utm: stats.utmCookie,
      utm_source: stats.utmSourceCookie,
      android_review: stats.android_review,
      is_complete: user.is_complete,
      have_pending_changes: user.have_pending_changes,
      membership_expiration_timestamp: user.membership_expiration_timestamp,
      next_recurring_payment_timestamp: user.next_recurring_payment_timestamp,
      purchase_date: this.getPurchaseDate(stats.last_payment),
      current_plan: this.getCurrentPlan(stats.last_payment),
      photo_verified: UserCommon.getPhotoVerificationStatus(user),
      pcat: this.getProfileCategoryNumber(user.profiletype),
    };
  }

  public abstract updateUser(user: UserCommon, stats: IStats): void;

  public getProfileCategoryNumber(category: string): number {
    return {
      [UserCommon.USER_CATEGORY_1]: 1,
      [UserCommon.USER_CATEGORY_2]: 2,
      [UserCommon.USER_CATEGORY_3]: 3,
      [UserCommon.USER_CATEGORY_4]: 4,
    }[category];
  }

  public routeLoaded(_: NavigationEnd): void {
    const EVENT_NAME: string = 'virtualPageview';

    this.push({
      event: EVENT_NAME,
    });
  }

  public registrationSubmitted({
    isBaby,
    isMale,
    isRegV5 = false,
  }: { isBaby: boolean; isMale: boolean; isRegV5?: boolean }) {
    const EVENT_NAME: string = 'sign_up';

    if (!((isBaby && !isMale) || (!isBaby && isMale))) {
      return;
    }

    this.push({
      event: EVENT_NAME,
      is_reg_v5: isRegV5,
    });
  }

  public registrationFailed({ error, isRegV5 = false }: { error: unknown; isRegV5?: boolean }) {
    const EVENT_NAME: string = 'sign_up_error';

    this.push({
      event: EVENT_NAME,
      details: JSON.stringify(error),
      is_reg_V5: isRegV5,
    });
  }

  public abstract onPayment(paymentInfo: IPaymentInfo, membershipId: MembershipType, boostProductUuid?: string): void;

  public onPaymentError(): void {
    const EVENT_NAME: string = 'purchase_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onFavorite(profileId: number): void {
    const EVENT_NAME: string = 'favorite';

    this.push({
      event: EVENT_NAME,
      profile_id: profileId,
    });
  }

  public onFavoriteError(): void {
    const EVENT_NAME: string = 'favorite_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onUnfavorite(profileId: number): void {
    const EVENT_NAME: string = 'unfavorite';

    this.push({
      event: EVENT_NAME,
      profile_id: profileId,
    });
  }

  public onUnfavoriteError(): void {
    const EVENT_NAME: string = 'unfavorite_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onBoostDataUpdateTriggered(boostData: IBoostGtmData): void {
    this.push({
      boost_credits: boostData.balance,
      boost_last_activation: boostData.lastActivation,
      boost_last_purchase_date: boostData.lastPurchaseDate,
      boost_last_purchase_package: boostData.lastPurchasePackage,
    });
  }

  public onMessageSent(profileId: number): void {
    const EVENT_NAME: string = 'message';

    this.push({
      event: EVENT_NAME,
      profile_id: profileId,
    });
  }

  public onMessageSentError(): void {
    const EVENT_NAME: string = 'message_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onPrivatePhotoAccessGranted(profileId: number): void {
    const EVENT_NAME: string = 'private_photo_grant';

    this.push({
      event: EVENT_NAME,
      profile_id: profileId,
    });
  }

  public onPrivatePhotoAccessGrantError(): void {
    const EVENT_NAME: string = 'private_photo_grant_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onPrivatePhotoRevoked(profileIds: number[]): void {
    const EVENT_NAME: string = 'private_photo_revoked';

    this.push({
      event: EVENT_NAME,
      profile_ids: profileIds.join(', '),
    });
  }

  public onPrivatePhotoRevokeError(): void {
    const EVENT_NAME: string = 'private_photo_revoke_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onPrivatePhotoAccessRequested(profileId: number): void {
    const EVENT_NAME: string = 'private_photo_requested';

    this.push({
      event: EVENT_NAME,
      profile_id: profileId,
    });
  }

  public onPrivatePhotoAccessRequestError(): void {
    const EVENT_NAME: string = 'private_photo_request_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onLogin(): void {
    const EVENT_NAME: string = 'login';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onLoginError(): void {
    const EVENT_NAME: string = 'login_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onProfileViewed(profileId: number): void {
    const EVENT_NAME: string = 'profile_viewed';

    this.push({
      event: EVENT_NAME,
      profile_id: profileId,
    });
  }

  public onProfileViewedError(): void {
    const EVENT_NAME: string = 'profile_viewed_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  protected getUserTypeName(gender: number, type: number): UserTypeName {
    if (gender === UserCommon.GENDER_MAN && type === UserCommon.WHAT_SUGGAR_DADDY_MOMMY) {
      return UserTypeName.SugarDaddy;
    }

    if (gender === UserCommon.GENDER_WOMAN && type === UserCommon.WHAT_SUGGAR_DADDY_MOMMY) {
      return UserTypeName.SugarMommy;
    }

    if (gender === UserCommon.GENDER_MAN && type === UserCommon.WHAT_SUGGAR_BABY) {
      return UserTypeName.SugarBabyMale;
    }

    if (gender === UserCommon.GENDER_WOMAN && type === UserCommon.WHAT_SUGGAR_BABY) {
      return UserTypeName.SugarBabyFemale;
    }

    throw new Error('Invalid gender / type: ' + gender + ', ' + type);
  }

  protected getMembershipName(membershipTypeId: MembershipType, numberOfPayments: number): MembershipName {
    if (MembershipCommon.isEliteType(membershipTypeId)) {
      return MembershipName.Elite;
    }

    if (MembershipCommon.isDaddyMommyPremiumType(membershipTypeId)) {
      return MembershipName.Premium;
    }

    if (numberOfPayments > 0 && MembershipCommon.isDaddyMommyFreeType(membershipTypeId)) {
      return MembershipName.Expired;
    }

    if (MembershipCommon.isDaddyMommyFreeType(membershipTypeId)) {
      return MembershipName.Free;
    }

    if (MembershipCommon.isBabyPremium(membershipTypeId)) {
      return MembershipName.BabyPremium;
    }

    if (numberOfPayments > 0 && MembershipCommon.isBaby(membershipTypeId)) {
      return MembershipName.ExpiredBabyPremium;
    }

    if (MembershipCommon.isBaby(membershipTypeId)) {
      return MembershipName.BabyFree;
    }

    throw new Error('Invalid membership_type_id / number_of_payments: ' + membershipTypeId + ', ' + numberOfPayments);
  }

  public onReactivate(): void {
    const EVENT_NAME: string = 'reactivate';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onReactivateError(): void {
    const EVENT_NAME: string = 'reactivate_error';

    this.push({
      event: EVENT_NAME,
    });
  }

  public onDeactivation(deactivate_reason: string): void {
    const EVENT_NAME: string = 'deactivate';

    this.push({
      event: EVENT_NAME,
      deactivate_reason,
    });
  }

  public hasPayments(stats: IStats): boolean {
    return stats.paymentsMade > 0;
  }

  public onFailedPayment(price: number): void {
    const EVENT_NAME: string = 'failed_payment';

    this.push({
      transaction_total: price,
      event: EVENT_NAME,
    });
  }

  public onFinishLater(): void {
    const EVENT_NAME: string = 'used_finish_later';

    this.push({
      event: EVENT_NAME,
    });
  }
}
