import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { DownloadManagerServiceCommon } from '@libs/modules/main/services/download-manager.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { ISocketService } from '@libs/shared/interfaces/socket.interface';
import { MessageCommon } from '@libs/shared/message/message.common';
import { ConversationActions } from '@libs/store/conversations';
import { ExpressApprovalActions } from '@libs/store/express-approval';
import { MainActions } from '@libs/store/main';
import { MeltToastActions } from '@libs/store/melt-toast';
import { MessageSelectors } from '@libs/store/messages';
import { NotificationActions } from '@libs/store/notification';
import { ProfileActions } from '@libs/store/profile';

@Injectable({
  providedIn: 'root',
})
export abstract class NotificationEffectsCommon {
  connect$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(NotificationActions.connect),
        tap({
          next: (): void => {
            this.socketService.connect();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  disconnect$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(NotificationActions.disconnect),
        tap((): void => this.socketService.disconnect()),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  initialize$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MainActions.userInitialized),
        map((): Action => {
          return this.handleConnection();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  initializeOnExpressApproval$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ExpressApprovalActions.initializeExpressApproval),
        map((): Action => {
          return this.handleConnection();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  initializeNotificationListeners$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(NotificationActions.initializeNotificationListeners),
        tap({
          next: (): void => {
            this.store.dispatch(ConversationActions.watchConversation());
            this.store.dispatch(ConversationActions.watchReadConversation());
            this.store.dispatch(ProfileActions.watchBookmark());
            this.store.dispatch(ProfileActions.watchViewProfile());
            this.store.dispatch(MeltToastActions.watchMelt());
          },
        }),
        map((): Action => NotificationActions.notificationListenersInitialized()),
      ),
    { dispatch: false, useEffectsErrorHandler: false },
  );

  onLogout$: Observable<Action> = createEffect(
    (): Observable<Action> => {
      return this.authenticationService.onLogout$.pipe(map((): Action => NotificationActions.disconnect()));
    },
    { dispatch: true, useEffectsErrorHandler: true },
  );

  onConnectionError$: Observable<Action> = createEffect(
    (): Observable<Action> => {
      return this.socketService.listen('connect_error').pipe(
        map((): Action => {
          return NotificationActions.downloadMessagesRecent();
        }),
      );
    },
    { dispatch: true, useEffectsErrorHandler: true },
  );

  downloadMessagesRecent$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(NotificationActions.downloadMessagesRecent),
        withLatestFrom(
          this.store.pipe(
            select(MessageSelectors.selectLastMessageIdReceived, {
              profileId: this.authenticationService.get().profile_id,
            }),
          ),
        ),
        filter(([_, lastMessageId]: [Action, number]): boolean =>
          MessageCommon.canDownloadMessagesRecent(lastMessageId, this.downloadManagerService),
        ),
        concatMap(([_, lastMessageId]: [Action, number]): Observable<Action> => {
          return this.downloadManagerService.resetAndUpdate('messagesRecent', lastMessageId).pipe(
            map((): Action => {
              return NotificationActions.messagesRecentDownloaded();
            }),
            catchError((): Observable<Action> => EMPTY),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  constructor(
    protected actions$: Actions,
    protected store: Store,
    protected socketService: ISocketService,
    protected authenticationService: AuthenticationServiceCommon,
    protected downloadManagerService: DownloadManagerServiceCommon,
  ) {
    //
  }

  private handleConnection() {
    this.store.dispatch(NotificationActions.initializeNotificationListeners());

    return NotificationActions.connect();
  }
}
