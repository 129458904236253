import { IImageRegistrationPayload } from '@libs/shared/profile/image-registration';
import { IUnsafeAction } from '@libs/store/application-state';
import {
  IMAGE_CLEAR_ALL_ACTION,
  IMAGE_RECEIVED_ACTION,
} from '@libs/store/registration/actions/frictionless-image.action';

const initialState: IImageRegistrationPayload = {};

export function storeImageOnRegistration(
  state: IImageRegistrationPayload = initialState,
  action: IUnsafeAction,
): IImageRegistrationPayload {
  switch (action.type) {
    case IMAGE_RECEIVED_ACTION:
      return Object.assign({}, state, action.payload.data);
    case IMAGE_CLEAR_ALL_ACTION:
      return {};
    default:
      return state;
  }
}
