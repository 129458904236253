import { Injectable } from '@angular/core';

import { MembershipType } from '@libs/shared/membership/membership.common';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';

import {
  NEW_PRODUCT_ROUTES_MAP,
  PRODUCT_ROUTES_MAP,
} from '@meupatrocinio/modules/payment-v2/product-route-info/product-routes-map';

@Injectable({
  providedIn: 'root',
})
export class ProductRouteInfoService {
  public getPathByVariantUuid(planUuid: string) {
    const productRouteMap = this.isNewProduct(planUuid) ? NEW_PRODUCT_ROUTES_MAP : PRODUCT_ROUTES_MAP;
    const productRouteInfo = productRouteMap.find((product): boolean => product.variantUuid === planUuid);

    if (!productRouteInfo) {
      return '';
    }

    return productRouteInfo.path;
  }

  private isNewProduct(planUuid: string) {
    return (
      planUuid === ProductVariantIdentifier.MEMBERSHIP_DADDY_PREMIUM_THREE_MONTHS_AB_TEST ||
      planUuid === ProductVariantIdentifier.MEMBERSHIP_DADDY_PREMIUM_SIX_MONTHS_AB_TEST
    );
  }

  public getVariantUuidByPath(productPath: string) {
    const productRouteInfo =
      PRODUCT_ROUTES_MAP.find((product): boolean => product.path === productPath) ||
      NEW_PRODUCT_ROUTES_MAP.find((product): boolean => product.path === productPath);

    if (!productRouteInfo) {
      return '';
    }

    return productRouteInfo.variantUuid;
  }

  public getNewProductVariantUuidByPath(productPath: string) {
    const productRouteInfo = NEW_PRODUCT_ROUTES_MAP.find((product): boolean => product.path === productPath);

    if (!productRouteInfo) {
      return '';
    }

    return productRouteInfo.variantUuid;
  }

  public doesPathExists(productPath: string) {
    return PRODUCT_ROUTES_MAP.some((product): boolean => product.path === productPath);
  }

  public getPathByVariantPeriodAndMembershipType(period: number, membershipType: MembershipType) {
    const productRouteInfo = PRODUCT_ROUTES_MAP.find(
      (product): boolean => product.periodCode === period && product.membershipType === membershipType,
    );

    if (!productRouteInfo) {
      return null;
    }

    return productRouteInfo.path;
  }

  public getProductByPlanPath(productPath: string) {
    const productRouteInfo = PRODUCT_ROUTES_MAP.find((product): boolean => product.path === productPath);

    if (!productRouteInfo) {
      return null;
    }

    return productRouteInfo;
  }

  public getNewProductByPlanPath(productPath: string) {
    const productRouteInfo = NEW_PRODUCT_ROUTES_MAP.find((product): boolean => product.path === productPath);

    if (!productRouteInfo) {
      return null;
    }

    return productRouteInfo;
  }
}
