export const MINIMUM_REGISTRATION = 'minimumRegistration';
export const EMAIL_CONFIRMATION = 'emailConfirmation';
export const HEARD_FROM = 'heardFrom';
export const SUGAR_PROFILE = 'sugarProfile';
export const LOCATION = 'location';
export const BODY_TYPE = 'bodyType';
export const ETHNICITY = 'ethnicity';
export const HAIR_COLOR = 'hairColor';
export const EYE_COLOR = 'eyeColor';
export const HEIGHT = 'height';
export const SMOKING_HABIT = 'smokingHabit';
export const DRINKING_HABIT = 'drinkingHabit';
export const MARITAL_STATUS = 'maritalStatus';
export const NUMBER_OF_CHILDREN = 'numberOfChildren';
export const EDUCATION_LEVEL = 'educationLevel';
export const OCCUPATION = 'occupation';
export const ABOUT_YOU = 'aboutYou';
export const HEADLINE = 'headline';
export const LOOKING_FOR = 'lookingFor';
export const PROFILE_PICTURES = 'profilePictures';
export const USERNAME = 'username';
export const WAITING_LIST = 'waitingList';
export const EXPRESS_APPROVAL = 'expressApproval';
export const RECOVERY = 'recovery';
