export { ListNameConversation } from '@libs/store/conversations/enum';

export { IConversation } from '@libs/store/conversations/interface';

export {
  ConversationAdapter,
  ConversationInitialState,
  IConversationState,
} from '@libs/store/conversations/state';

import * as ConversationActions from '@libs/store/conversations/actions';
import * as ConversationReducer from '@libs/store/conversations/reducer';
import * as ConversationSelectors from '@libs/store/conversations/selectors';

export { ConversationActions, ConversationReducer, ConversationSelectors };
