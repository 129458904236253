import { ComponentFactoryResolver, Injectable } from '@angular/core';

import { InjectComponentServiceCommon } from '@libs/modules/main/services/inject-component/inject-component.service.common';

@Injectable({
  providedIn: 'root',
})
export class InjectComponentService extends InjectComponentServiceCommon {
  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
}
