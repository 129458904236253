import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IApplicationState } from '@libs/store/application-state';
import { tokenExpiredAction } from '@libs/store/expired-token/expired-token.action';

@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {
  protected readonly HTTP_UNAUTHORIZED_ERROR: number = 401;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse): Observable<never> => {
        this.handleExpiredToken(error);
        return throwError(error);
      }),
    );
  }

  handleExpiredToken(errorResponse: HttpErrorResponse): void {
    if (errorResponse && errorResponse.status && errorResponse.status === this.HTTP_UNAUTHORIZED_ERROR) {
      this.store.dispatch(tokenExpiredAction());
    }
  }

  constructor(protected store: Store<IApplicationState>) {
    //
  }
}
