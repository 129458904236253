import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ListScrollPositionService {
  protected scrollPosition = '';
  public canRestoreScrollPosition = false;

  public setScrollPosition(value: string) {
    this.scrollPosition = value;
  }

  public getScrollPosition() {
    return this.scrollPosition;
  }

  public setIsReadyToRestoreScrollPosition(isReadyToRestoreScrollPosition: boolean) {
    this.canRestoreScrollPosition = isReadyToRestoreScrollPosition;
  }

  public isReadyToRestoreScrollPosition() {
    return this.canRestoreScrollPosition;
  }
}
