import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { BoostLastViewersSelectors } from '@libs/store/boost-last-viewers';
import { MeltProfilesSelectors } from '@libs/store/melt-profiles';

import { IBadgeService } from '@meupatrocinio/services/badge/interfaces/badge-service.interface';

import { Memoize } from 'typescript-memoize';

@Injectable({
  providedIn: 'root',
})
export class BadgeService implements IBadgeService, OnDestroy {
  public meltProfileIds: number[] = [];
  public boostProfiles: IProfile[] = [];
  private readonly clearSubscriptions$ = new Subject<void>();

  constructor(private readonly store: Store) {
    this.subscribeToMeltProfiles();
    this.subscribeToBoostProfiles();
  }

  public ngOnDestroy() {
    this.clearSubscriptions$.next();
    this.clearSubscriptions$.complete();
  }

  @Memoize()
  public isBoost(profile: IProfile) {
    return this.boostProfiles.find((boostProfile) => boostProfile.profile_id === profile.profile_id) !== undefined;
  }

  public isMelt(profile: IProfile) {
    return this.meltProfileIds.includes(profile.profile_id);
  }

  public isElite(profile: IProfile) {
    return UserCommon.isElite(profile);
  }

  public isVerified(profile: IProfile) {
    return UserCommon.isPhotoVerified(profile);
  }

  public isPremium(profile: IProfile) {
    return UserCommon.isPremium(profile);
  }

  public isNew(profile: IProfile) {
    return UserCommon.isNew(profile);
  }

  private subscribeToMeltProfiles() {
    this.store
      .pipe(select(MeltProfilesSelectors.selectMeltProfiles), takeUntil(this.clearSubscriptions$))
      .subscribe((meltProfileIds: number[]) => {
        this.meltProfileIds = meltProfileIds;
      });
  }

  private subscribeToBoostProfiles() {
    this.store
      .pipe(select(BoostLastViewersSelectors.selectAll), takeUntil(this.clearSubscriptions$))
      .subscribe((boostProfiles: IProfile[]) => {
        this.boostProfiles = boostProfiles;
      });
  }
}
