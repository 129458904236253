import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthRequestFacadeCommon } from '@libs/modules/main/services/auth-request-facade/auth-request-facade.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { BoostedProfilesActions } from '@libs/store/boosted-profiles';
import { MeltTTLUpdatedAction, removeMeltProfile, upsertMeltProfiles } from '@libs/store/melt-profiles/actions';
import { ProfilesActions } from '@libs/store/profiles-v2';
import { ProfilesBlockedReceivedAction } from '@libs/store/profiles/actions/blocked.action';
import {
  FavMeTTLUpdatedAction,
  ProfilesFavMeReceivedAction,
  ProfilesFavMeRemovedAction,
} from '@libs/store/profiles/actions/fav-me.action';
import {
  FavMyTTLUpdatedAction,
  ProfilesFavMyReceivedAction,
  ProfilesFavMyRemovedAction,
} from '@libs/store/profiles/actions/fav-my.action';
import {
  FeaturedTTLUpdatedAction,
  ProfileFeaturedRemovedAction,
  ProfilesFeaturedReceivedAction,
} from '@libs/store/profiles/actions/featured.action';
import {
  NearByTTLUpdatedAction,
  ProfileNearbyRemovedAction,
  ProfilesNearbyReceivedAction,
} from '@libs/store/profiles/actions/nearby.action';
import {
  NewTTLUpdatedAction,
  ProfileNewRemovedAction,
  ProfilesNewReceivedAction,
} from '@libs/store/profiles/actions/new.action';
import {
  ProfilesIdPrivatePhotoIGaveAccessReceivedAction,
  ProfilesIdPrivatePhotoIRemoveAccessReceivedAction,
} from '@libs/store/profiles/actions/private-photo-gave.action';
import { ProfilesPrivatePhotoIHaveAccessRemoveReceivedAction } from '@libs/store/profiles/actions/private-photo-have.action';
import { SearchProfilesReceivedAction, SearchProfilesRemovedAction } from '@libs/store/profiles/actions/search.action';
import {
  ProfilesViwedMeRemovedAction,
  ProfileViewedMeReceivedAction,
  ViewedTTLUpdatedAction,
} from '@libs/store/profiles/actions/viewed.action';
import { SearchV2Actions } from '@libs/store/recommended-list';

@Injectable({
  providedIn: 'root',
})
export abstract class ListServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpServiceCommon,
    protected authRequestFacade: AuthRequestFacadeCommon,
  ) {
    //
  }

  public abstract downloadHomeFeatured(page: number): Observable<IAuthResponse>;

  public abstract downloadHomeNearby(page: number): Observable<IAuthResponse>;

  public abstract downloadHomeNewUsers(page: number): Observable<IAuthResponse>;

  public abstract downloadBlockedUsers(page: number): Observable<IAuthResponse>;

  public abstract downloadFavMeProfiles(page: number): Observable<IAuthResponse>;

  public abstract downloadMyFavProfiles(page: number): Observable<IAuthResponse>;

  public abstract downloadFavMelt(page: number): Observable<IAuthResponse>;

  public abstract downloadViewedMeProfiles(page: number): Observable<IAuthResponse>;

  public abstract downloadRecommendedProfiles(page: number): Observable<IAuthResponse>;

  public updateHomeFeatured(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    this.store.dispatch(new ProfilesFeaturedReceivedAction({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    this.store.dispatch(new FeaturedTTLUpdatedAction({ ttl: Date.now() }));
  }

  public updateHomeNearby(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    this.store.dispatch(new ProfilesNearbyReceivedAction({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    this.store.dispatch(new NearByTTLUpdatedAction({ ttl: Date.now() }));
  }

  public updateHomeNew(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    this.store.dispatch(new ProfilesNewReceivedAction({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    this.store.dispatch(new NewTTLUpdatedAction({ ttl: Date.now() }));
  }

  public updateBlockedUsers(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    profiles.forEach((profile): void => {
      profile.status = UserCommon.STATUS_UNAVAILABLE;
    });

    this.store.dispatch(new ProfilesBlockedReceivedAction({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
  }

  public updateFavMeProfiles(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    this.store.dispatch(new ProfilesFavMeReceivedAction({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    this.store.dispatch(new FavMeTTLUpdatedAction({ ttl: Date.now() }));
  }

  public updateMyFavProfiles(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    profiles.forEach((value): void => {
      value.bookmarked_count = 1;
    });

    this.store.dispatch(new ProfilesFavMyReceivedAction({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    this.store.dispatch(new FavMyTTLUpdatedAction({ ttl: Date.now() }));
  }

  public updateFavMelt(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    this.store.dispatch(upsertMeltProfiles({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    this.store.dispatch(MeltTTLUpdatedAction({ lastUpdateTimestamp: Date.now() }));
  }

  public updateViewedMeProfiles(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    this.store.dispatch(new ProfileViewedMeReceivedAction({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    this.store.dispatch(new ViewedTTLUpdatedAction({ ttl: Date.now() }));
  }

  public updateRecommendedProfiles(profiles: IProfile[]) {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    this.store.dispatch(SearchV2Actions.storeRecommendedProfileIdList({ profiles }));
  }

  public updateMyPrivatePhotosAccess(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    const profileIds: number[] = profiles.map((profile: IProfile): number => profile.profile_id);
    this.store.dispatch(
      new ProfilesIdPrivatePhotoIGaveAccessReceivedAction({
        profileIds,
      }),
    );
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
  }

  public updateSearch(profiles: IProfile[]): void {
    if (!profiles.length) {
      return;
    }

    if (!profiles.every((profile): boolean => !!profile)) {
      throw new Error('Invalid response from the API. Null profiles were sent.');
    }

    this.store.dispatch(new SearchProfilesReceivedAction({ profiles }));
    this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
  }

  public removeProfileFromLists(profile: IProfile): void {
    this.store.dispatch(ProfilesActions.removeProfile({ profileId: profile.profile_id }));
    this.store.dispatch(new ProfileFeaturedRemovedAction({ profiles: [profile] }));
    this.store.dispatch(new ProfilesFavMyRemovedAction({ profiles: [profile] }));
    this.store.dispatch(new ProfileNearbyRemovedAction({ profiles: [profile] }));
    this.store.dispatch(removeMeltProfile({ profile }));
    this.store.dispatch(new ProfileNewRemovedAction({ profiles: [profile] }));
    this.store.dispatch(new ProfilesFavMeRemovedAction({ profiles: [profile] }));
    this.store.dispatch(new ProfilesViwedMeRemovedAction({ profiles: [profile] }));
    this.store.dispatch(
      new ProfilesIdPrivatePhotoIRemoveAccessReceivedAction({
        profileIds: [profile.profile_id],
      }),
    );
    this.store.dispatch(
      new ProfilesPrivatePhotoIHaveAccessRemoveReceivedAction({
        profileIds: [profile.profile_id],
      }),
    );
    this.store.dispatch(new SearchProfilesRemovedAction({ profiles: [profile] }));
    this.store.dispatch(
      BoostedProfilesActions.removeBoostedProfile({
        profileId: profile.profile_id,
      }),
    );
  }
}
