import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { TranslateParamDirective } from '@libs/really-shared/advanced-translate/translate-param.directive';

@Component({
  selector: 'mp-advanced-translate',
  changeDetection: ChangeDetectionStrategy.Default,
  template: '<ng-content select="[mp-translate-param]">',
})
export class AdvancedTranslateComponent implements AfterViewInit, OnDestroy {
  @Input() key = '';
  @Input() params: unknown = {};
  @ContentChildren(TranslateParamDirective)
  htmlParams: QueryList<TranslateParamDirective> = new QueryList();
  @ContentChildren(TranslateParamDirective, { read: ElementRef })
  htmlParamsElems: QueryList<ElementRef> = new QueryList();

  protected subscriptions: Subscription[] = [];
  protected htmlParamsMap: { [param: string]: Text } = {};

  constructor(
    protected translate: TranslateService,
    protected elementRef: ElementRef,
  ) {
    //
  }

  ngAfterViewInit() {
    const placeholders: unknown = { ...(this.params as {}) };
    const htmlParamsElems = this.htmlParamsElems.toArray();
    const findInterpolationCharacters = new RegExp(/\{\{\{|\}\}\}/g);

    this.htmlParamsMap = {};
    this.htmlParams.forEach((param, index) => {
      placeholders[param.translateParam] = `{{{${param.translateParam}}}}`;
      this.htmlParamsMap[param.translateParam] = htmlParamsElems[index].nativeElement;
    });
    this.subscriptions.push(
      this.translate.get(this.key, placeholders).subscribe((result: string) => {
        const fragment = document.createDocumentFragment();

        result.split(findInterpolationCharacters).forEach((part, index) => {
          let node: Text = document.createTextNode(part);

          if (index % 2) {
            node = this.htmlParamsMap[part];
          }

          fragment.append(node);
        });

        this.elementRef.nativeElement.append(fragment);
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
