import { Action } from '@ngrx/store';

export const PROFILE_ALBUM_ACCESS_DELETE_ADD: string = 'PROFILE_ALBUM_ACCESS_DELETE_ADD';

export interface IProfileIdsAlbumAccessDeleteAddActionPayload {
  profileAlbumAccessToDelete: number[];
}

export class AlbumAccessDeleteAddAction implements Action {
  readonly type: string = PROFILE_ALBUM_ACCESS_DELETE_ADD;

  constructor(public payload: IProfileIdsAlbumAccessDeleteAddActionPayload) {
    //
  }
}
