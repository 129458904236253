import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { InitialNavigationActions } from '@meupatrocinio/effects/initial-navigation/actions';

@Component({
  selector: 'mp-initial-change-password',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './change-password.html',
})
export class InitialChangePasswordComponent implements OnInit, OnDestroy {
  protected subscriptions: Subscription[] = [];

  constructor(
    protected route: ActivatedRoute,
    protected store: Store,
  ) {
    //
  }

  ngOnInit(): void {
    this.subscriptions.push(this.route.params.subscribe((params): void => this.verifyHash(params.hash)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public verifyHash(hash: string) {
    this.store.dispatch(InitialNavigationActions.changePassword({ hash }));
  }
}
