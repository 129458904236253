import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { MembershipCommon, MembershipType } from '@libs/shared/membership/membership.common';
import { Trial } from '@libs/shared/trial/trial';
import { IPaymentInfo } from '@libs/store/payment-info';

export enum PaymentStatus {
  PAYMENT_NONE,
  PAYMENT_AWAITING,
  PAYMENT_OK,
  PAYMENT_PROCESSING,
  PAYMENT_ERROR,
}

export interface ILastPayment {
  fin_sale_id: number;
  amount: number;
  period: number;
  membership_type_id: MembershipType;
  order_stamp: number;
}

export abstract class PaymentCommon {
  public static readonly PAYMENT_METHOD_CREDIT_CARD: string = 'CREDIT_CARD';
  public static readonly PAYMENT_METHOD_BOLETO: string = 'BOLETO';
  public static readonly PAYMENT_BILL_DESCRIPTION_MP = 'MEUPAT';
  public static readonly PROVIDER_ALLCASH: string = 'allcash';
  public static readonly PROVIDER_PAGSEGURO: string = 'PagSeguro';
  public static readonly CURRENCY_BRAZILIAN_REAL: string = 'BRL';
  public static readonly DEFAULT_CPF: string = '06503410743';
  public static readonly EXPRESS_APPROVAL_ID = -1;

  public static isTrialPayment(membershipType: MembershipType, paymentTime: number): boolean {
    return MembershipCommon.isDaddyMommyPremiumType(membershipType) && Trial.getEnabledTrial(paymentTime) !== null;
  }

  public static findMostRecentPayment(payments: IPaymentInfo[]): IPaymentInfo {
    return payments.reduce((previousPayment: IPaymentInfo, currentPayment: IPaymentInfo): IPaymentInfo => {
      if (
        previousPayment !== undefined &&
        currentPayment !== undefined &&
        previousPayment.fin_sale_id > currentPayment.fin_sale_id
      ) {
        return previousPayment;
      }

      return currentPayment;
    });
  }

  public static hasCreditCardPaymentType(paymentTypes: string[]): boolean {
    return paymentTypes.includes(PaymentMethods.PAYMENT_CREDIT_CARD);
  }

  public static hasBoletoPaymentType(paymentTypes: string[]): boolean {
    return paymentTypes.includes(PaymentMethods.PAYMENT_BOLETO);
  }

  public static hasPaymentTypesAvailable(paymentTypes: string[]): boolean {
    return Object.keys(paymentTypes).length > 0;
  }

  public static isPaymentForAPlusPackage(paymentInfo: IPaymentInfo): boolean {
    const A_PLUS_PACKAGE_PRICE: number = 300;

    return paymentInfo.subtotal_amount !== undefined && paymentInfo.subtotal_amount === A_PLUS_PACKAGE_PRICE;
  }

  public static getLatestPayment(paymentCollection: IPaymentInfo[]): IPaymentInfo | null {
    if (paymentCollection.length === 0) {
      return null;
    }

    return paymentCollection.reduce((previousInfo: IPaymentInfo, currentInfo: IPaymentInfo): IPaymentInfo => {
      if (previousInfo.fin_sale_id > currentInfo.fin_sale_id) {
        return previousInfo;
      }

      return currentInfo;
    });
  }
}
