import { Inject, Injectable, InjectionToken } from '@angular/core';

import { forEach } from '@libs/utils/array-functions';

export interface IFaviconsConfig {
  icons: IIconsConfig;
}

export interface IIconsConfig {
  [name: string]: IIconConfig;
}

export interface IIconConfig {
  type: string;
  href: string;
  isDefault?: boolean;
}

export const BROWSER_FAVICONS_CONFIG = new InjectionToken<IFaviconsConfig>('Favicons Configuration');

export abstract class FaviconsService {
  abstract activate(name: string): void;

  abstract reset(): void;
}

@Injectable()
export class BrowserFaviconsService implements FaviconsService {
  private readonly elementId: string;
  private readonly icons: IIconsConfig;

  constructor(@Inject(BROWSER_FAVICONS_CONFIG) config: IFaviconsConfig) {
    this.elementId = 'favicons-service-injected-node';
    this.icons = Object.assign({}, config.icons);
    this.removeExternalLinkElements();
    this.reset();
  }

  public activate(name: string): void {
    if (!this.icons[name]) {
      throw new Error(`Favicon for [${name}] not found.`);
    }

    this.setNode(this.icons[name].type, this.icons[name].href);
  }

  public reset(): void {
    for (const name of Object.keys(this.icons)) {
      const icon = this.icons[name];

      if (icon.isDefault) {
        this.setNode(icon.type, icon.href);
        return;
      }
    }

    this.removeNode();
  }

  private addNode(type: string, href: string): void {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('id', this.elementId);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('href', href);
    document.head.appendChild(linkElement);
  }

  private removeExternalLinkElements(): void {
    forEach(document.querySelectorAll("link[rel~='icon']"), (linkElement): void => {
      linkElement.parentNode.removeChild(linkElement);
    });
  }

  private removeNode(): void {
    const linkElement = document.head.querySelector('#' + this.elementId);

    if (linkElement) {
      document.head.removeChild(linkElement);
    }
  }

  private setNode(type: string, href: string) {
    const linkElement = document.getElementById('favicons-service-injected-node') as HTMLLinkElement;

    if (linkElement) {
      linkElement.href = href;
      linkElement.type = type;

      return;
    }

    this.addNode(type, href);
  }
}
