import { createAction, props } from '@ngrx/store';

import { ILists } from '@libs/modules/main/services/download-manager.service.common';

enum Actions {
  INITIALIZE = '[Profile Swipe] - Initialize',
  HANDLE_PROFILE_SWIPE_LOAD_CYCLE = '[Profile Swipe] - Handle Profile Swipe Load Cycle',
  GET_PROFILE_LIST = '[Profile Swipe] - Get Profile List',
  HANDLE_SWIPE_LIST_NAME = '[Profile Swipe] - Handle Swipe List Name',
  HANDLE_NEXT_PAGE_OR_RESET = '[Profile Swipe] - Handle Next Page Or Reset',
  HANDLE_NEARBY_LIST = '[Profile Swipe] - Handle Nearby List',
  DOWNLOAD_NEXT_PAGE_OF_LIST = '[Profile Swipe] - Download Next Page Of List',
  RESET_AND_DONWLOAD_LIST = '[Profile Swipe] - Reset And Download List',
  SET_IS_SWIPING_PROFILES = '[Profile Swipe] - Set Is Swiping Profiles',
  SET_LATEST_SWIPE_LIST_NAME = '[Profile Swipe] - Set Latest Swipe List Name',
  SET_PREVIOUS_SWIPE_LIST_NAME = '[Profile Swipe] - Set Previous Swipe List Name',
  SET_HAS_RELOADED_DURING_SWIPE = '[Profile Swipe] - Set Has Reloaded During Swipe',
  SET_PROFILE_SWIPE_TEST = '[Profile Swipe] - Set Profile Swipe Test',
  SET_IS_SWIPING_ON_NEARBY = '[Profile Swipe] - Set Is Swiping On Nearby',
  HANDLE_GO_BACK_NAVIGATION = '[Profile Swipe] - Handle Go Back Navigation',
  SET_LIST_SCROLL_POSITION = '[Profile Swipe] - Set List Scroll Position',
  HANDLE_SCROLL_POSITION = '[Profile Swipe] - Handle Scroll Position',
  RESTORE_SCROLL_POSITION = '[Profile Swipe] - Restore Scroll Position',
  LOAD_ALL_PROFILES = '[Profile Swipe] - Load All Profiles',
  FETCH_PROFILES = '[Profile Swipe] - Fetch Profiles',
  PROFILES_FOR_SWIPE_FETCHED = '[Profile Swipe] - Profiles For Swipe Fetched',
}

export const initialize = createAction(Actions.INITIALIZE);

export const handleSwipeListName = createAction(Actions.HANDLE_SWIPE_LIST_NAME);

export const handleHasProfileSwipeLoadCycle = createAction(Actions.HANDLE_PROFILE_SWIPE_LOAD_CYCLE);

export const getProfilesFromList = createAction(Actions.GET_PROFILE_LIST, props<{ listName: string }>());

export const handleNextPageOrReset = createAction(Actions.HANDLE_NEXT_PAGE_OR_RESET);

export const handleNearbyList = createAction(Actions.HANDLE_NEARBY_LIST);

export const downloadNextPageOfList = createAction(
  Actions.DOWNLOAD_NEXT_PAGE_OF_LIST,
  props<{ listName: keyof ILists }>(),
);

export const resetAndDownloadList = createAction(Actions.RESET_AND_DONWLOAD_LIST, props<{ listName: keyof ILists }>());

export const setIsSwipingProfiles = createAction(
  Actions.SET_IS_SWIPING_PROFILES,
  props<{ isSwipingProfiles: boolean }>(),
);

export const setLatestSwipeListName = createAction(
  Actions.SET_LATEST_SWIPE_LIST_NAME,
  props<{ latestSwipeListName: string }>(),
);

export const setIsSwipingOnNearby = createAction(
  Actions.SET_IS_SWIPING_ON_NEARBY,
  props<{ isSwipingOnNearby: boolean }>(),
);

export const setHasDoneProfileSwipe = createAction(
  Actions.SET_HAS_RELOADED_DURING_SWIPE,
  props<{ hasDoneProfileSwipe: boolean }>(),
);

export const setProfileSwipeTest = createAction(
  Actions.SET_PROFILE_SWIPE_TEST,
  props<{ hasProfileSwipeTest: boolean }>(),
);

export const handleGoBackNavigation = createAction(Actions.HANDLE_GO_BACK_NAVIGATION);

export const handleListScrollPosition = createAction(Actions.HANDLE_SCROLL_POSITION);

export const restoreScrollPosition = createAction(Actions.RESTORE_SCROLL_POSITION);

export const setListScrollPosition = createAction(
  Actions.SET_LIST_SCROLL_POSITION,
  props<{ listScrollPosition: number }>(),
);

export const loadAllProfiles = createAction(Actions.LOAD_ALL_PROFILES, props<{ profileIds: number[] }>());

export const fetchProfiles = createAction(Actions.FETCH_PROFILES, props<{ profileIds: number[] }>());

export const profilesForSwipedFetched = createAction(Actions.PROFILES_FOR_SWIPE_FETCHED);
