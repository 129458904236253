import { Action } from '@ngrx/store';

import { ICountry } from '@libs/services/location/country/country';

export const COUNTRIES_RECEIVED_ACTION: string = 'COUNTRIES_RECEIVED_ACTION';

export interface ICountriesReceivedActionPayload {
  countries: ICountry[];
}

export class CountriesReceivedAction implements Action {
  readonly type: string = COUNTRIES_RECEIVED_ACTION;

  constructor(public payload: ICountriesReceivedActionPayload) {
    //
  }
}
