import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MembershipType } from '@libs/shared/membership/membership.common';
import { Trial } from '@libs/shared/trial/trial';

import { PrizeService } from '@meupatrocinio/modules/main/services/prize/prize.service';

@Component({
  selector: 'mp-payment-header',
  templateUrl: './payment-header.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentHeaderComponent {
  @Input() plan = '';
  @Input() isTrial = false;
  @Input() isExpressApproval = false;
  @Input() period = 0;
  @Input() installments = 0;
  @Input() price = 0;
  @Input() trialPrice = 0;
  @Input() prizeUuid = '';

  constructor(
    protected translateService: TranslateService,
    protected prizeService: PrizeService,
  ) {
    //
  }

  public getMembershipNameWithDuration() {
    return this.translateService.instant('modules.main.pages.payment.plan_description', {
      plan: this.plan,
      duration: this.getPlanDuration(),
    });
  }

  public getPlanDuration() {
    if (this.isTrial) {
      return this.translateService.instant('modules.main.pages.payment.plan_duration.trial', {
        trialDays: this.trialDuration,
      });
    }

    if (this.isExpressApproval) {
      return '';
    }

    return this.translateService.instant('modules.main.pages.payment.duration', { duration: this.period });
  }

  protected get trialDuration() {
    if (Trial.getEnabledTrial(this.period) === null) {
      return 0;
    }

    return Trial.getEnabledTrial(this.period).duration;
  }

  public getPlanPrice(price: number) {
    if (this.isTrial) {
      return this.translateService.instant('modules.main.pages.payment.price.trial', { price });
    }

    return this.translateService.instant('modules.main.pages.payment.price', {
      price: this.getPlanConditionsPrice(price),
      period: this.getPeriod(price),
    });
  }

  public getPlanConditionsPrice(price: number) {
    if (this.installments === 0) {
      this.installments = price;
    }

    if (this.isTrial) {
      return this.trialPrice;
    }

    return this.installments;
  }

  protected getPeriod(price: number) {
    let period: number = this.period;

    if (this.installments === price) {
      period = 1;
    }

    if (this.isExpressApproval) {
      period = MembershipType.MEMBERSHIP_TYPE_EXPRESS_APPROVAL;
    }

    return period;
  }

  public getPlanConditions(price: number) {
    if (this.isExpressApproval) {
      return '';
    }

    if (this.isTrial && !this.isFifteenDaysTrial()) {
      return this.translateService.instant('modules.main.pages.payment.trial_renewal');
    }

    return this.translateService.instant('modules.main.pages.payment.renewal', {
      plan: this.plan,
      period: this.getPlanDuration(),
      price: this.getPlanConditionsPrice(price),
    });
  }

  public get planPrizeTitle() {
    const prize = this.prizeService.getPrize(this.prizeUuid);

    if (!prize) {
      return '';
    }

    return prize.title;
  }

  protected isFifteenDaysTrial() {
    return this.period === Trial.TRIAL_15_DAYS;
  }
}
