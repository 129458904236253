import { Component } from '@angular/core';

import { BoostProgressComponentCommon } from '@libs/modules/boost/components/boost-progress/boost-progress.component.common';

import { BoostProgressService } from '@meupatrocinio/modules/shared/boost-shared/services/boost-progress/boost-progress.service';

@Component({
  selector: 'mp-boost-progress',
  templateUrl: './boost-progress.html',
})
export class BoostProgressComponent extends BoostProgressComponentCommon {
  constructor(protected boostProgressService: BoostProgressService) {
    super(boostProgressService);
  }
}
