import { createAction, props } from '@ngrx/store';

enum Actions {
  FORGOT_PASSWORD = '[Login] - Forgot Password',
  FORGOT_PASSWORD_EMAIL_SENT = '[Login] - Email Sent',
  FORGOT_PASSWORD_EMAIL_NOT_FOUND = '[Login] - Email Not Found',
  FORGOT_PASSWORD_SENDING_REQUEST = '[Login] - Sending Request',
  FORGOT_PASSWORD_RESET_STATE = '[Login] - Reset State',
}

export const forgotPassword = createAction(Actions.FORGOT_PASSWORD, props<{ email: string }>());

export const forgotPasswordSendingRequest = createAction(
  Actions.FORGOT_PASSWORD_SENDING_REQUEST,
  props<{ sendingRequest: boolean }>(),
);

export const forgotPasswordEmailSent = createAction(Actions.FORGOT_PASSWORD_EMAIL_SENT);

export const forgotPasswordEmailNotFound = createAction(
  Actions.FORGOT_PASSWORD_EMAIL_NOT_FOUND,
  props<{ emailNotFound: boolean }>(),
);

export const resetState = createAction(Actions.FORGOT_PASSWORD_RESET_STATE);
