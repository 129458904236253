import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';

export interface IFavoriteToastState extends EntityState<IProfile> {
  //
}

function selectProfileId(profile: IProfile): number | undefined {
  return profile.profile_id;
}

export const favoriteToastAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>({
  selectId: selectProfileId,
  sortComparer: false,
});

export const favoriteToastInitialState: IFavoriteToastState = favoriteToastAdapter.getInitialState({});
