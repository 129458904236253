import { Action, createReducer, on } from '@ngrx/store';

import * as ReferralStatusActions from '@libs/store/referral-status/actions';
import {
  IReferralStatusState,
  referralStatusAdapter,
  referralStatusInitialState,
} from '@libs/store/referral-status/state';

const referralStatusReducer = createReducer(
  referralStatusInitialState,
  on(ReferralStatusActions.upsertOneReferralStatus, (state, { referralStatus }): IReferralStatusState => {
    return referralStatusAdapter.upsertOne(referralStatus, state);
  }),
);

export function reducer(state: IReferralStatusState, action: Action): IReferralStatusState {
  return referralStatusReducer(state, action);
}
