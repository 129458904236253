import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { IPhotoVisualizationType } from '@meupatrocinio/effects/photo-visualization/interface/photo-visualization.interface';

export const PhotoVisualizationActions = createActionGroup({
  source: 'Photo Visualization',
  events: {
    Successfully: emptyProps(),
    'Handle Photo Visualization': props<{
      photoId: number;
      visualizationType: IPhotoVisualizationType;
      profileId: number;
    }>(),
  },
});
