import { Action } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const PROFILES_FAV_MY_RECEIVED_ACTION: string = 'PROFILES_FAV_MY_RECEIVED_ACTION';
export const PROFILES_FAV_MY_REMOVED_ACTION: string = 'PROFILES_FAV_MY_REMOVED_ACTION';
export const PROFILES_FAV_MY_REFRESHED_ACTION: string = 'PROFILES_FAV_MY_REFRESHED_ACTION';
export const FAV_MY_TTL_UPDATED_ACTION: string = 'FAV_MY_TTL_UPDATED_ACTION';
export const PROFILES_FAV_MY_BIND_ACTION: string = 'PROFILES_FAV_MY_BIND_ACTION';

export interface IProfilesFavMyActionPayload {
  profiles: IProfile[];
}

export class ProfilesFavMyReceivedAction implements Action {
  readonly type: string = PROFILES_FAV_MY_RECEIVED_ACTION;

  constructor(public payload: IProfilesFavMyActionPayload) {
    //
  }
}

export class ProfilesFavMyRemovedAction implements Action {
  readonly type: string = PROFILES_FAV_MY_REMOVED_ACTION;

  constructor(public payload: IProfilesFavMyActionPayload) {
    //
  }
}

export class ProfilesFavMyRefreshedAction implements Action {
  readonly type: string = PROFILES_FAV_MY_REFRESHED_ACTION;
}

export interface IFavMyTTLActionPayload {
  ttl: number;
}

export class FavMyTTLUpdatedAction implements Action {
  readonly type: string = FAV_MY_TTL_UPDATED_ACTION;

  constructor(public payload: IFavMyTTLActionPayload) {
    //
  }
}
