import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { IBoostPaymentOption } from '@meupatrocinio/modules/boost/components/boost-payment-modal/interfaces/boost-payment-option.interface';

@Component({
  selector: 'mp-payment-modal-method-selector',
  templateUrl: './payment-modal-method-selector.html',
  styleUrls: ['./payment-modal-method-selector.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentModalMethodSelectorComponent {
  @Input() public paymentMethods: IBoostPaymentOption[] = [];
  @Input() public currentPaymentMethod = '';
  @Output() public paymentMethodChanged = new EventEmitter<string>();

  public changePaymentMethod(paymentMethod: string) {
    this.currentPaymentMethod = paymentMethod;
    this.paymentMethodChanged.emit(paymentMethod);
  }

  public isSelected(paymentMethod: string) {
    return this.currentPaymentMethod === paymentMethod;
  }

  public trackByPaymentMethod(_: number, paymentMethod: IBoostPaymentOption) {
    return paymentMethod.name;
  }
}
