import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { BoostProgressServiceCommon } from '@libs/modules/boost/services/boost-progress/boost-progress.service.common';
import { BoostServiceCommon } from '@libs/modules/boost/services/boost/boost.service.common';
import { IProfile } from '@libs/shared/profile/profile';
import { IApplicationState } from '@libs/store/application-state';
import { BoostSelectors } from '@libs/store/boost';

@Directive()
export class BoostRunningNagbarComponentCommon {
  profiles$: Observable<IProfile[]> = of([]);
  hasBoostEnded$: Observable<boolean> = of(false);
  protected readonly BOOST_COUNT_PROFILES: number = 5;

  constructor(
    protected boostService: BoostServiceCommon,
    protected boostProgressService: BoostProgressServiceCommon,
    protected store: Store<IApplicationState>,
    protected router: Router,
  ) {
    this.profiles$ = this.boostService.getBoostProfiles$(this.BOOST_COUNT_PROFILES);
    this.hasBoostEnded$ = this.store.pipe(
      select(BoostSelectors.selectHasBoostEnded, {
        boostProgressService: this.boostProgressService,
      }),
    );
  }

  public navigateToBoost(): void {
    this.router.navigate(['main', 'boost']);
  }

  public hasBoostEnded(hasEnded: boolean): boolean {
    return hasEnded;
  }
}
