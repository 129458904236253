import { Action } from '@ngrx/store';

import { UserCommon } from '@libs/shared/user/user.common';

export const USER_RECEIVED_ACTION: string = 'USER_RECEIVED_ACTION';

export interface IUserReceivedActionPayload {
  user: UserCommon;
}

export class UserReceivedAction implements Action {
  readonly type: string = USER_RECEIVED_ACTION;

  constructor(public payload: IUserReceivedActionPayload) {
    //
  }
}
