<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <mp-alert></mp-alert>
            <form
                id="forgotPassword"
                ngNativeValidate="ngNativeValidate"
                (submit)="forgotPasswordSubmit()"
            >
                <div class="form-group">
                    <label for="forgotPasswordEmail">
                        {{ 'modules.initial.pages.login.forgot_password.prompt' | translate }}
                    </label>
                    <input
                        class="form-control"
                        id="forgotPasswordEmail"
                        required
                        type="email"
                        [(ngModel)]="forgotPasswordEmail"
                        [ngModelOptions]="{standalone: true}"
                    >
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-default"
                type="button"
                data-dismiss="modal"
                (click)="forgotPasswordClose()"
            >
                {{ 'common.close' | translate }}
            </button>
            <button
                class="btn btn-danger"
                [disabled]="isLoading"
                type="submit"
                form="forgotPassword"
            >
                {{ 'common.submit' | translate }}
            </button>
        </div>
    </div>
</div>
<div
    class="modal modal-loading"
    *ngIf="isLoading"
>
    <mp-loading></mp-loading>
</div>
