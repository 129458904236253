import { IProfile } from '@libs/shared/profile/profile';
import { IUnsafeAction } from '@libs/store/application-state';
import {
  PROFILES_BLOCKED_RECEIVED_ACTION,
  PROFILE_UNBLOCK_RECEIVED_ACTION,
} from '@libs/store/profiles/actions/blocked.action';

export function storeProfileBlockedUsers(state: number[] = [], action: IUnsafeAction): number[] {
  switch (action.type) {
    case PROFILES_BLOCKED_RECEIVED_ACTION:
      return onProfilesBlockedReceived(state, action.payload.profiles);
    case PROFILE_UNBLOCK_RECEIVED_ACTION:
      return onProfileUnblockedReceived(state, action.payload.profileId);
    default:
      return state;
  }
}

function onProfilesBlockedReceived(state: number[], profiles: IProfile[]): number[] {
  for (const profile of profiles) {
    state = onProfileBlockedReceived(state, profile.profile_id);
  }

  return state;
}

function onProfileBlockedReceived(state: number[], newProfileId: number): number[] {
  const index: number = state.indexOf(newProfileId);
  if (index !== -1) {
    return state;
  }

  return [...state, newProfileId];
}

function onProfileUnblockedReceived(state: number[], newProfileId: number): number[] {
  const index: number = state.indexOf(newProfileId);
  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
