import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';

import { Config } from '@meupatrocinio/config';
import { DatadogActions } from '@meupatrocinio/infra/datadog/actions';
import { DatadogService } from '@meupatrocinio/infra/datadog/datadog.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';

@Injectable()
export class DatadogEffects {
  initialize$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(DatadogActions.initialize),
        filter(() => Config.isDatadogEnabled),
        filter(() => !this.isRunningLocally()),
        tap({
          next: () => {
            this.datadogService.initialize();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  setUser$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(DatadogActions.setUser),
        filter(() => this.authenticationService.isAuthenticated()),
        tap({
          next: () => {
            const user = this.authenticationService.get();

            this.datadogService.setUser(user);
          },
        }),
      ),
    { dispatch: false },
  );

  constructor(
    protected actions: Actions,
    protected datadogService: DatadogService,
    protected globalObjectService: GlobalObjectService,
    protected authenticationService: AuthenticationService,
  ) {
    //
  }

  protected get hostname(): string {
    return this.globalObjectService.window.location.hostname;
  }

  protected isRunningLocally(): boolean {
    return this.hostname === 'localhost' || this.hostname === '127.0.0.1';
  }
}
