import { Type } from '@angular/core';
import { createAction, props } from '@ngrx/store';

import { IModalViewOptions } from '@libs/components/modal-view/interface/modal-view-options';

export const OPEN_MODAL_VIEW: string = '[Modal View] - Open Modal View';
export const CLOSE_MODAL_VIEW: string = '[Modal View] - Close Modal View';
export const SET_MODAL_OPENED: string = '[Modal View] - Set Modal Opened';
export const CLOSE_AND_DISPATCH_NAVIGATE: string = '[Modal View] - Close Modal View and Dispatch Navigate';
export const NAVIGATE_AFTER_CLOSING: string = '[Modal View] - Navigate after Closing';

export const openModalView = createAction(
  OPEN_MODAL_VIEW,
  props<{ component: Type<unknown>; options: IModalViewOptions }>(),
);

export const close = createAction(CLOSE_MODAL_VIEW);

export const setModalOpened = createAction(SET_MODAL_OPENED, props<{ isModalOpened: boolean }>());

export const closeAndDispatchNavigate = createAction(CLOSE_AND_DISPATCH_NAVIGATE, props<{ route: string[] }>());

export const navigateAfterClosing = createAction(NAVIGATE_AFTER_CLOSING, props<{ route: string[] }>());
