import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { BadgesComponentCommon } from '@libs/components/badges/badges.component.common';
import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { IApplicationState } from '@libs/store/application-state';

import { BoostLastViewersService } from '@meupatrocinio/modules/shared/boost-shared/services/boost-last-viewers/boost-last-viewers.service';

@Component({
  selector: 'mp-badges-extended',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './badges-extended.html',
})
export class BadgesExtendedComponent extends BadgesComponentCommon {
  public getAttributes: IProfileAttributes[] = [
    {
      isVerified: (): boolean => this.isMelt(),
      className: 'changed-attribute',
      extendedClassName: 'melt-badge',
      extendedImagePath: '/assets/img/melt-badge-full.svg',
      imageAlt: 'common.list.melt',
      isMelt: true,
    },
    {
      isVerified: (): boolean => this.isElite(),
      extendedClassName: 'elite-badge gold',
      extendedImagePath: '/assets/img/elite-crown-only-white.svg',
      imageAlt: 'modules.main.pages.profile.member_elite',
    },
    {
      isVerified: (): boolean => this.isEligibleToPremiumBadge(),
      extendedClassName: 'sbp-badge',
      extendedImagePath: '/assets/img/sbp-diamond-icon.svg',
      imageAlt: 'modules.main.pages.profile.member_sbp',
    },
    {
      isVerified: (): boolean => this.isVerified(),
      extendedClassName: 'badge-verified',
      extendedImagePath: '/assets/img/verified-green.svg',
      imageAlt: 'modules.main.pages.profile.member_verified',
    },
    {
      isVerified: (): boolean => this.isNew(),
      interpolateParams: { gender: this.getGender() },
      extendedClassName: 'badge-new',
      extendedImagePath: '/assets/img/new-blue.svg',
      imageAlt: 'modules.main.pages.profile.member_new',
    },
    {
      isVerified: (): boolean => this.sawMeInLastBoost(),
      extendedImagePath: '/assets/img/purple-lightning.svg',
      extendedClassName: 'boost-extended-badge',
      imageAlt: 'modules.main.pages.profile.saw_me_in_last_boost',
    },
  ];

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected store: Store<IApplicationState>,
    public translate: TranslateService,
    protected boostLastViewersService: BoostLastViewersService,
  ) {
    super(changeDetector, store, translate, boostLastViewersService);
  }
}
