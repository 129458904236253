import { ILists } from '@libs/modules/main/services/download-manager.service.common';
import {
  DownloadManagerUpdatedAction,
  DOWNLOAD_MANAGER_UPDATED_ACTION,
} from '@libs/store/download-manager/actions/download-manager.action';

export function downloadManager(state: ILists, action: DownloadManagerUpdatedAction): ILists {
  switch (action.type) {
    case DOWNLOAD_MANAGER_UPDATED_ACTION:
      return { ...action.payload };

    default:
      return state;
  }
}
