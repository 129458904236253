import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, takeWhile, tap } from 'rxjs/operators';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@meupatrocinio/config';
import { OriginUtils } from '@meupatrocinio/utils/origin';

@Injectable()
export class AuthHttpService extends AuthHttpServiceCommon {
  constructor(
    protected http: HttpClient,
    protected store: Store<IApplicationState>,
  ) {
    super(http, store);
  }

  getOrigin(): string {
    return OriginUtils.origin().toString();
  }

  protected action(
    verb: string,
    endpoint: string,
    data?: any,
    urlEncoded?: boolean,
    customHeaders?: { [key: string]: string },
  ): Observable<IAuthResponse> {
    if (!this.token) {
      throw new HttpErrorResponse({
        error: 'Trying to make a request without a Token.',
        statusText: `Verb: ${verb}`,
        url: endpoint,
      });
    }

    const currentToken = this.token;
    let headers = new HttpHeaders({
      Authorization: `Bearer ${currentToken}`,
      mobile: this.getOrigin(),
      'X-MP-Request-Origin': this.getOrigin(),
    });

    if (customHeaders) {
      for (const [key, value] of Object.entries(customHeaders)) {
        headers = headers.set(key, value);
      }
    }

    if (urlEncoded) {
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    }

    const params: any = [endpoint];

    if (verb === this.INPUT_TYPE_POST || verb === this.INPUT_TYPE_PUT || verb === this.INPUT_TYPE_PATCH) {
      params.push(data);
    }

    if (Config.showLogs.downloads) {
      const time = new Date();
      const timeString =
        time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds() + '.' + time.getMilliseconds();

      console.log('[' + timeString + '] HTTP --> Starting a ' + verb + ' request for ' + endpoint + '.');
    }

    return this.http[verb](...params, {
      headers,
      observe: 'response',
    }).pipe(
      takeWhile((): boolean => this.hasToken()),
      tap((args): void => this.debugRequest(args)),
      map((args): IAuthResponse => this.extractData(args, currentToken)),
      catchError((args): Observable<never | HttpErrorResponse | IAuthResponse> => this.handleError(args)),
    );
  }

  protected debugRequest(res: any): any {
    if (Config.showLogs.downloads) {
      const date = new Date();
      const timeString =
        date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();
      const totalLength = (JSON.stringify(res).length / 1024).toFixed(2);
      const headers = res.headers;
      const received = headers['X-Android-Received-Millis'];
      const sent = headers['X-Android-Sent-Millis'];
      let duration = 0;

      if (received && sent) {
        duration = received - sent;
      }

      let description = res.url;

      if (headers['X-Cache'] && headers['X-Cache'] === 'cache') {
        description += '. From cache';
      }

      console.log(
        '[' + timeString + '] HTTP --> ' + description + '. ' + totalLength + ' Kb after ' + duration + ' ms.',
      );
    }

    return res;
  }
}
