export {
  searchStateAdapter,
  searchStateInitialState,
} from '@libs/store/search/state';

export { ISearchState } from '@libs/store/search/interface/search-state.interface';

import * as SearchActions from '@libs/store/search/actions';
import * as SearchStateReducer from '@libs/store/search/reducer';
import * as SearchSelectors from '@libs/store/search/selectors';

export { SearchActions, SearchStateReducer, SearchSelectors };
