export class UserInputValidators {
  public static isValidDescription(description: string) {
    return UserInputValidators.countCharacters(description) >= 6;
  }

  public static isValidHeadline(headline: string) {
    const characterCount = UserInputValidators.countCharacters(headline);

    return characterCount >= 4 && characterCount <= 128;
  }

  public static countCharacters(textInput: string) {
    if (!textInput) {
      return 0;
    }

    const emojis = UserInputValidators.getEmojiCharacters(textInput);

    return textInput.trim().length - emojis.join('').length + emojis.length;
  }

  public static getEmojiCharacters(textInput: string) {
    const EMOJI_PATTERN =
      /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F900}-\u{1F9FF}|\u{1F680}-\u{1F6FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{1F1E6}-\u{1F1FF}]/gu;

    return textInput.match(EMOJI_PATTERN) || [];
  }
}
