import { Injectable, Renderer2 } from '@angular/core';

import { Config } from '@meupatrocinio/config';
import { Netcore } from '@meupatrocinio/infra/netcore/netcore';
import { CustomEventsNetcore } from '@meupatrocinio/infra/netcore/types/custom-events-netcore.type';
import { FilterCustomEventTypeByName } from '@meupatrocinio/infra/netcore/types/filter-custom-event-type-by-name';
import { NativeEventsNetcore } from '@meupatrocinio/infra/netcore/types/native-events-netcore';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetcoreService {
  public isInitialsScriptAlreadyInjected = new BehaviorSubject(false);

  constructor(
    protected globalObjectService: GlobalObjectService,
    protected authenticationService: AuthenticationService,
  ) {
    //
  }

  public isNetCoreAlreadyInjected() {
    return this.getCDNScriptELement() !== null;
  }

  public dispatchCustomEvent<Name extends CustomEventsNetcore['eventName']>(
    eventName: Name,
    params: Omit<FilterCustomEventTypeByName<Name>, 'eventName'>,
  ) {
    if (!this.isInitialsScriptAlreadyInjected.getValue()) {
      return;
    }

    this.dispatchSmartech('dispatch', eventName, params);
  }

  public injectScript(renderer: Renderer2) {
    renderer.appendChild(this.globalObjectService.window.document.body, this.newCDNScriptElement(renderer));
  }

  protected initialize() {
    if (!('smartech' in this.globalObjectService.window)) {
      throw new Error('smartech of netcore function is undefined');
    }

    this.dispatchSmartech('create', Config.netcore.panelIdentifier);
    this.dispatchSmartech('register', Config.netcore.websiteIdentifier);
    this.dispatchSmartech('identify', this.user.profile_id.toString());
    this.dispatchSmartech('contact', 9, {
      'pk^PROFILE_ID': this.user.profile_id.toString(),
      EMAIL_MARKETING: 'true',
    });
    this.isInitialsScriptAlreadyInjected.next(true);
  }

  public dispatchInitialsScript() {
    const cdnScript = this.getCDNScriptELement();

    cdnScript.onload = () => {
      this.initialize();
    };
  }

  public removeCDNScript(renderer: Renderer2) {
    if (!this.isNetCoreAlreadyInjected()) {
      return;
    }

    renderer.removeChild(this.globalObjectService.window.document, this.getCDNScriptELement());
  }

  protected get user() {
    return this.authenticationService.get();
  }

  private newCDNScriptElement(renderer: Renderer2) {
    const scriptElement: HTMLScriptElement = renderer.createElement('script');
    scriptElement.src = Config.netcore.cdnLink;
    scriptElement.id = 'netcore-tracker';
    scriptElement.type = 'text/javascript';

    return scriptElement;
  }

  public getCDNScriptELement(): HTMLScriptElement {
    return this.globalObjectService.window.document.querySelector('#netcore-tracker');
  }

  private dispatchSmartech(eventName: NativeEventsNetcore, value: string | number, object?: unknown) {
    Netcore.smartech(this.globalObjectService.window, eventName, value, object);
  }
}
