<div class="modal-dialog modal-report">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="center">
                {{ title() }}
            </h3>
        </div>
        <div class="modal-body">
            <p>{{ fieldFillingInstruction() }}</p>
            <p>
                {{ noticeAboutProfileToBeReported()  }}
            </p>
            <form
                [formGroup]="reportModalForm"
                class="modal-report__form form-group col-xs-12"
            >
                <textarea
                    class="form-control"
                    rows="6"
                    [id]="reason"
                    [formControlName]="reason"
                    [placeholder]="placeholderTextarea()"
                ></textarea>
                <div
                    class="modal-report__form__alert alert alert-warning"
                    role="alert"
                    *ngIf="showInvalidFormMessage"
                >
                    {{ invalidFormMessage() }}
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <mp-modal-action-button
                [text]="closeModalButtonText()"
                (click)="close()"
                [modifierClass]="'btn-default'"
            ></mp-modal-action-button>
            <mp-modal-action-button
                [text]="submitModalButtonText()"
                (click)="submit()"
                [modifierClass]="'btn-danger'"
            ></mp-modal-action-button>
        </div>
    </div>
</div>
