<button
    class="toast-close-button"
    *ngIf="options.closeButton"
    (click)="remove()"
    [attr.aria-label]="'common.close' | translate"
>
    <span aria-hidden="true">&times;</span>
</button>
<div class="mp-toast__content">
    <ng-template #mpToastContent></ng-template>

    <div class="flex" *ngIf="!hasCustomContent">
        <mp-img-srcset
            *ngFor="let image of images; trackBy: trackByFn"
            [url]="image"
            imgClass="toast-avatar"
            place="toast"
        ></mp-img-srcset>
        <div>
            <div
                *ngIf="title"
                [class]="options.titleClass"
                [attr.aria-label]="cleanTitle"
            >
                <span class="notranslate">{{ username }}</span>
                {{ restOfTitle }}
            </div>
            <div class="flex">
                <i
                    class="toast-icon material-icons"
                    *ngIf="showIcon('message')"
                    aria-hidden="true"
                >
                    &#xE0BF;
                </i>
                <i
                    class="toast-icon material-icons"
                    *ngIf="showIcon('view')"
                    aria-hidden="true"
                >
                    &#xE417;
                </i>
                <i
                    class="toast-icon material-icons"
                    *ngIf="showIcon('favorite')"
                    aria-hidden="true"
                >
                    &#xE87D;
                </i>
                <div
                    *ngIf="message && options.enableHtml"
                    role="alertdialog"
                    aria-live="polite"
                    [class]="options.messageClass"
                    [innerHTML]="message"
                ></div>
                <div
                    *ngIf="message && !options.enableHtml"
                    role="alertdialog"
                    aria-live="polite"
                    [class]="options.messageClass"
                    [attr.aria-label]="message"
                >
                    {{ message }}
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width | percentage"></div>
</div>
