import { UserCommon } from '@libs/shared/user/user.common';

/**
 * same as UserLoginModel
 */
export class LoginInfo {
  constructor(
    public username?: string,
    public password?: string,
  ) {}

  isValidUserName(): boolean {
    return UserCommon.isValidUserName(this.username, true);
  }

  isValidPassword(): boolean {
    return UserCommon.isValidPassword(this.password);
  }
}
