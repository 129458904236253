import {
  PROFILES_ID_PRIVATE_PHOTO_HAVE_ACCESS_RECEIVED_ACTION,
  PROFILES_ID_PRIVATE_PHOTO_I_HAVE_ACCESS_REMOVED_RECEIVED_ACTION,
} from '@libs/store/profiles/actions/private-photo-have.action';

export function storeProfilesPrivatePhotoIHaveAccess(state: number[] = [], action: any): number[] {
  switch (action.type) {
    case PROFILES_ID_PRIVATE_PHOTO_HAVE_ACCESS_RECEIVED_ACTION:
      return onProfileIdsPrivatePhotoIHaveAccessReceived(state, action.payload.profileIds);
    case PROFILES_ID_PRIVATE_PHOTO_I_HAVE_ACCESS_REMOVED_RECEIVED_ACTION:
      return onProfilesIdPrivatePhotoIHaveAccessRemovedReceived(state, action.payload.profileIds);
    default:
      return state;
  }
}

function onProfileIdsPrivatePhotoIHaveAccessReceived(state: number[], profileIds: number[]): number[] {
  for (const profileId of profileIds) {
    state = onProfileIdPrivatePhotoIHaveAccessReceived(state, profileId);
  }

  return state;
}

function onProfileIdPrivatePhotoIHaveAccessReceived(state: number[], profileId: number): number[] {
  const index: number = state.indexOf(profileId);
  if (index !== -1) {
    return state;
  }

  return [...state, profileId];
}

function onProfilesIdPrivatePhotoIHaveAccessRemovedReceived(state: number[], profileIds: number[]): number[] {
  for (const profileId of profileIds) {
    state = onProfileIdPrivatePhotoIHaveAccessRemovedReceived(state, profileId);
  }

  return state;
}

function onProfileIdPrivatePhotoIHaveAccessRemovedReceived(state: number[], newProfileId: number): number[] {
  const index: number = state.indexOf(newProfileId);
  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
