import { Directive, Input } from '@angular/core';

import { SubscribeButtonTheme } from '@libs/components/subscribe-button/subscribe-button-theme.type';
import { PaymentInfoServiceCommon } from '@libs/modules/main/services/payment/payment-info.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { UserCommon } from '@libs/shared/user/user.common';

@Directive()
export class SubscribeButtonComponentCommon {
  @Input() theme: SubscribeButtonTheme = 'medium';
  @Input() hideOnMediumDown = false;

  public readonly BUTTON_BASE_CLASSES = 'subscribe-button subscribe-button--';

  constructor(
    protected authenticationService: AuthenticationServiceCommon,
    public paymentInfoService: PaymentInfoServiceCommon,
  ) {
    //
  }

  protected get user() {
    return this.authenticationService.get();
  }

  protected isBabyFree() {
    return UserCommon.isBabyFree(this.user);
  }

  protected isDaddyMommyFree() {
    return UserCommon.isDaddyMommyFree(this.user);
  }

  public isEligibleToSubscribe() {
    return UserCommon.isEligibleToSubscribe(this.user);
  }

  public getSubscribeButtonText() {
    if (this.isBabyFree()) {
      return 'common.subscribe-baby-premium';
    }

    if (this.isDaddyMommyFree()) {
      return 'common.subscribe';
    }

    return 'common.upgrade';
  }

  public getButtonClasses() {
    return `${this.BUTTON_BASE_CLASSES}${this.theme}`;
  }
}
