import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionbarUserImageComponentCommon } from '@libs/modules/shared/actionbar/actionbar-user-image/actionbar-user-image.component.common';
import { UserCommon } from '@libs/shared/user/user.common';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { SlidableMenuService } from '@meupatrocinio/services/slidable-menu/slidable-menu.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

@Component({
  selector: 'mp-actionbar-user-image',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar-user-image.html',
})
export class ActionbarUserImageComponent extends ActionbarUserImageComponentCommon {
  constructor(
    protected slidableMenu: SlidableMenuService,
    protected auth: AuthenticationService,
  ) {
    super(auth);
  }

  getProfilePicture(): string {
    return ImageHelper.getProfilePicture(this.auth.get(), null, UserCommon.PICTURE_EXTRA_LARGE);
  }

  pictureAction(): void {
    this.slidableMenu.openSlidableMenu();
  }
}
