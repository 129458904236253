import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IPaymentAttemptData } from '@libs/modules/payment-v2/interfaces/payment-data-attempt.interface';
import { PaymentHttpService } from '@libs/modules/payment-v2/services/payment-http.service';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

import { Config } from '@meupatrocinio/config';
import { ITokenizeCreditCardResponse } from '@meupatrocinio/modules/payment-v2/providers/allcash-v2/interfaces/tokenize-credit-card-response.interface';
import { AllCashEncryptionService } from '@meupatrocinio/modules/payment-v2/providers/allcash-v2/services/allcash-encryption.service';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable({
  providedIn: 'root',
})
export class AllCashV2Service extends PaymentHttpService {
  private readonly PROVIDER_IDENTIFIER = 'c66fa130-394b-43d9-a928-f2fc7385af02';

  constructor(
    protected authHttpService: AuthHttpService,
    protected allcashEncryptionService: AllCashEncryptionService,
  ) {
    super(authHttpService);
  }

  public tokenizeCreditCard(encryptedCard: string): Observable<IAuthResponse<ITokenizeCreditCardResponse>> {
    const endpoint: string = `${this.getServerIp()}v2/payment/tokenization-credit-card`;
    const data = {
      cardToken: encryptedCard,
      providerExternalIdentifier: this.getProviderIdentifier(),
    };

    return this.authHttpService.post(endpoint, data);
  }

  protected getProviderIdentifier() {
    return this.PROVIDER_IDENTIFIER;
  }

  protected getServerIp(): string {
    return Config.serverIp;
  }

  public encryptCard(paymentData: IPaymentAttemptData) {
    return this.allcashEncryptionService.encrypt(paymentData);
  }
}
