import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export function getNestableControl(controlName: string, formGroup: UntypedFormGroup): AbstractControl {
  const dotIndex = controlName.indexOf('.');
  const hasNest = dotIndex !== -1;
  if (!hasNest) {
    return formGroup.controls[controlName];
  }

  const parentControlName = controlName.substring(0, dotIndex);
  const parentControl: UntypedFormGroup = <UntypedFormGroup>formGroup.controls[parentControlName];
  const child = controlName.substring(dotIndex + 1);

  return getNestableControl(child, parentControl);
}
