<div class="container">
    <div class="row">
        <div class="col-sm-8 col-sm-offset-2 col-lg-6 col-lg-offset-3 no-padding">
            <div
                class="reactivate-modal-box"
                [@modal]="getState()"
                (@modal.start)="animationStarted()"
                (@modal.done)="animationFinished()"
            >
                <div
                    class="animated-content"
                    [ngClass]="{ 'active': isFinishedAndReady() }"
                >
                    <p class="title">
                        {{ 'modules.modal.reactivated.title' | translate }}
                    </p>
                    <p class="description">
                        {{ 'modules.modal.reactivated.desc' | translate }}
                    </p>
                    <div class="actions">
                        <a
                            class="mp-main-button bt-md outline-red"
                            (click)="closeModal()"
                        >
                            {{ 'modules.modal.reactivated.ok' | translate }}
                        </a>
                    </div>
                </div>
                <div
                    class="animated-content"
                    [ngClass]="{ 'active': isProcessingAndReady() }"
                >
                    <p class="description">
                        <strong>
                            {{ 'modules.modal.reactivating.desc' | translate }}
                        </strong>
                    </p>
                    <div class="loader-container">
                        <mp-loading></mp-loading>
                    </div>
                </div>
                <div [ngClass]="{ 'active': isQuestioningAndReady() }">
                    <p class="title">
                        {{ 'modules.modal.reactivate.title' | translate }}
                    </p>
                    <p class="description">
                        {{ 'modules.modal.reactivate.desc' | translate }}
                    </p>
                    <div class="actions">
                        <div>
                            <a
                                class="mp-main-button bt-md bt-shadow"
                                (click)="reactivate()"
                            >
                                {{ 'modules.modal.reactivate.cta' | translate }}
                            </a>
                        </div>
                        <div>
                            <a
                                class="cancel"
                                (click)="closeModal()"
                            >
                                {{ 'modules.modal.reactivate.cancel' | translate }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
