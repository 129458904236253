export class DateCommon {
  static readonly MONTH_DIFFERENCE: number = 1;

  static yearDiff(date: Date): number {
    const ageDiffMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDiffMs);
    return ageDate.getUTCFullYear() - 1970;
  }

  static isValidDate(date: string): boolean {
    if (date === undefined || date === '') {
      return false;
    }

    const newDate = new Date(date);

    const [year, month, day] = date.split('-');

    let validDate = !isNaN(newDate.getTime());

    if (validDate) {
      validDate =
        year === newDate.getUTCFullYear().toString() &&
        month === DateCommon.maybePadZero(newDate.getUTCMonth() + DateCommon.MONTH_DIFFERENCE) &&
        day === DateCommon.maybePadZero(newDate.getUTCDate());
    }

    return validDate;
  }

  static maybePadZero(dateNumber: number): string {
    if (dateNumber < 10) {
      return `0${dateNumber}`;
    }

    return dateNumber.toString();
  }
}
