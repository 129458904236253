import { Injectable } from '@angular/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { ModalServiceCommon } from '@libs/services/modal/modal.service.common';

import { ModalComponent } from '@meupatrocinio/modules/main/modals/modal/modal.component';
import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';

@Injectable()
export class ModalService extends ModalServiceCommon {
  constructor(protected modal: AdvancedModalService) {
    super();
  }

  open(message: string, closeCallback?: () => void): ModalRef {
    return this.modal.open(ModalComponent, {
      data: { message, type: 'dialog', closeCallback },
    });
  }

  confirm(
    message: string,
    okCallback?: () => void,
    okText = 'common.yes',
    closeText = 'common.no',
    cancelCallback?: () => void,
  ): ModalRef {
    return this.modal.open(ModalComponent, {
      data: {
        message,
        type: 'confirm',
        okCallback,
        okText,
        closeText,
        cancelCallback,
      },
    });
  }
}
