import * as StepNames from '@meupatrocinio/modules/reg-v5/constants/step-names';

interface IRegV5Step {
  routeName: string;
  stepNumber: number;
  recoverable?: boolean;
  isExclusiveForBaby?: boolean;
}

export const REG_V5_MAIN_ROUTE_NAME = 'register';
export const REG_V5_WAITING_LIST_ROUTE_NAME = 'waiting-list';
export const REG_V5_EXPRESS_APPROVAL_ROUTE_NAME = 'express-approval';
export const REG_V5_RECOVER_ROUTE_NAME = 'recovering';
export const REG_V5_CHECK_PROGRESS_ROUTE_NAME = 'checking';

export const REG_V5_STEPS: Record<string, IRegV5Step> = Object.freeze({
  [StepNames.MINIMUM_REGISTRATION]: {
    routeName: 'start',
    stepNumber: 0,
  },
  [StepNames.EMAIL_CONFIRMATION]: {
    routeName: 'email-confirmation',
    stepNumber: 1,
  },
  [StepNames.HEARD_FROM]: {
    routeName: 'heard-from',
    stepNumber: 2,
    recoverable: true,
  },
  [StepNames.SUGAR_PROFILE]: {
    routeName: 'sugar-profile',
    stepNumber: 3,
    recoverable: true,
  },
  [StepNames.LOCATION]: {
    routeName: 'location',
    stepNumber: 4,
    recoverable: true,
  },
  [StepNames.BODY_TYPE]: {
    routeName: 'body-type',
    stepNumber: 5,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.ETHNICITY]: {
    routeName: 'ethnicity',
    stepNumber: 6,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.HAIR_COLOR]: {
    routeName: 'hair-color',
    stepNumber: 7,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.EYE_COLOR]: {
    routeName: 'eye-color',
    stepNumber: 8,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.HEIGHT]: {
    routeName: 'height',
    stepNumber: 9,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.SMOKING_HABIT]: {
    routeName: 'smoking-habit',
    stepNumber: 10,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.DRINKING_HABIT]: {
    routeName: 'drinking-habit',
    stepNumber: 11,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.MARITAL_STATUS]: {
    routeName: 'marital-status',
    stepNumber: 12,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.NUMBER_OF_CHILDREN]: {
    routeName: 'number-of-children',
    stepNumber: 13,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.EDUCATION_LEVEL]: {
    routeName: 'education-level',
    stepNumber: 14,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.OCCUPATION]: {
    routeName: 'profession',
    stepNumber: 15,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.ABOUT_YOU]: {
    routeName: 'about-you',
    stepNumber: 16,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.HEADLINE]: {
    routeName: 'headline',
    stepNumber: 17,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.LOOKING_FOR]: {
    routeName: 'looking-for',
    stepNumber: 18,
    recoverable: true,
    isExclusiveForBaby: true,
  },
  [StepNames.PROFILE_PICTURES]: {
    routeName: 'profile-pictures',
    stepNumber: 19,
    isExclusiveForBaby: true,
  },
  [StepNames.USERNAME]: {
    routeName: 'username',
    stepNumber: 20,
  },
  [StepNames.RECOVERY]: {
    routeName: 'recovering',
    stepNumber: 0,
  },
});
