import { Action } from '@ngrx/store';

export const PREVIOUS_PROFILE_ID_ACTION: string = 'PREVIOUS_PROFILE_ID_ACTION';

export interface IPreviousProfileIdActionPayload {
  previousProfileId: number;
}

export class PreviousProfileIdAction implements Action {
  readonly type: string = PREVIOUS_PROFILE_ID_ACTION;

  constructor(public payload: IPreviousProfileIdActionPayload) {
    //
  }
}
