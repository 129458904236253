export enum MaterialIcons {
  Favorite = '&#xE87D',
  FavoriteBorder = '&#xE87E',
  Lock = '&#xE897',
  LockOpen = '&#xE898',
  Block = '&#xE14B',
  Clear = '&#xE14C',
  Flag = '&#xE153',
  Delete = '&#xE872',
  Bolt = '&#xE3E7',
  EyeOpen = '&#xE8F4',
  EyeClosed = '&#xE8F5',
  ArrowBack = '&#xE5C4',
  ArrowForward = '&#xE5C8',
  Upload = '&#XE2C6',
  Check = '&#XF091',
  Close = '&#XE5CD',
  MotionPhotos = '&#XE9C1',
  Done = '&#XE876',
  ContentCopy = '&#xE14D',
}

export enum MaterialIconsRounded {
  Flash_on = '&#xe3e7',
}
