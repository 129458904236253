import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { concatMap, delay, filter, map, repeatWhen, take, takeUntil, tap } from 'rxjs/operators';

import { MembershipType } from '@libs/shared/membership/membership.common';
import { IPhoto } from '@libs/shared/profile/photo';
import { PhotoCommon } from '@libs/shared/profile/photo.common';
import { IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { IPaymentInfo, PaymentInfoSelectors } from '@libs/store/payment-info';

import { CustomerIOActions } from '@meupatrocinio/infra/customer-io/actions';
import { CustomerIOAccountTypeAbbreviations } from '@meupatrocinio/infra/customer-io/enums/customer-io-account-type-abbreviations.enum';
import { CustomerIOPremiumStatus } from '@meupatrocinio/infra/customer-io/enums/customer-io-premium-status.enum';
import { ICustomerIODataFromState } from '@meupatrocinio/infra/customer-io/interfaces/customer-io-data-from-redux.interface';
import { ICustomerIOInitializeAction } from '@meupatrocinio/infra/customer-io/interfaces/customer-io-initialize-action.interface';
import { ICustomerIOUserData } from '@meupatrocinio/infra/customer-io/interfaces/customer-io-user-data.interface';
import { CustomerIOService } from '@meupatrocinio/infra/customer-io/services/customer-io.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';

@Injectable()
export class CustomerIOEffects {
  protected hasIdentified = false;

  handleDataInitialization$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(CustomerIOActions.handleDataInitialization),
        concatMap(() =>
          this.getNeededDataFromState().pipe(
            concatMap((customerIODataFromState: ICustomerIODataFromState): Observable<Action> => {
              if (!this.isCoreDataAvailable(customerIODataFromState)) {
                return of(CustomerIOActions.handleDataInitialization()).pipe(delay(1000));
              }

              return of(
                CustomerIOActions.initialize({
                  customerIOData: this.getCustomerIODataObject(customerIODataFromState),
                }),
              );
            }),
          ),
        ),
        takeUntil(this.authenticationService.onLogout$),
        repeatWhen((): Subject<void> => this.authenticationService.onLogin$),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleDataInitializationAfterRelogin$: Observable<Action> = createEffect(
    () => {
      const REASONABLE_DELAY_BEFORE_IDENTIFYING = 2000;

      return this.actions$.pipe(
        ofType(CustomerIOActions.handleDataInitializationAfterRelogin),
        delay(REASONABLE_DELAY_BEFORE_IDENTIFYING),
        map(() => CustomerIOActions.handleDataInitialization()),
      );
    },
    { dispatch: true, useEffectsErrorHandler: false },
  );

  identify$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(CustomerIOActions.initialize),
        filter((): boolean => this.customerIOService.canLoadCustomerIO()),
        tap({
          next: (payload: ICustomerIOInitializeAction): void => {
            this.customerIOService.identify(payload.customerIOData);
            this.hasIdentified = true;
          },
        }),
        takeUntil(this.authenticationService.onLogout$),
        repeatWhen((): Subject<void> => this.authenticationService.onLogin$),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  handlePageView$: Observable<ICustomerIODataFromState> = createEffect(
    (): Observable<ICustomerIODataFromState> =>
      this.router.events.pipe(
        filter((routerEvent): boolean => {
          return routerEvent instanceof NavigationEnd && this.hasIdentified;
        }),
        concatMap(() =>
          this.getNeededDataFromState().pipe(
            tap({
              next: (customerIODataFromState: ICustomerIODataFromState) => {
                this.customerIOService.sendPageView(
                  this.fullUrl,
                  this.getCustomerIODataObject(customerIODataFromState),
                );
              },
            }),
          ),
        ),
        takeUntil(this.authenticationService.onLogout$),
        repeatWhen((): Subject<void> => this.authenticationService.onLogin$),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  resetHasIdentified$: Observable<void> = createEffect(
    (): Observable<void> =>
      this.authenticationService.onLogout$.pipe(
        tap({
          next: (): void => {
            this.hasIdentified = false;
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected router: Router,
    protected store: Store<IApplicationState>,
    protected authenticationService: AuthenticationService,
    protected customerIOService: CustomerIOService,
    protected globalObjectService: GlobalObjectService,
  ) {
    //
  }

  protected getNeededDataFromState() {
    return combineLatest([
      this.store.pipe(select('user')),
      this.store.pipe(select('stats')),
      this.store.pipe(select(PaymentInfoSelectors.selectLastMembershipPayment)),
    ]).pipe(
      map(([user, stats, lastMembershipPayment]) => {
        return {
          user,
          stats,
          lastMembershipPayment,
        };
      }),
      take(1),
    );
  }

  protected get fullUrl(): string {
    return this.globalObjectService.window.location.href;
  }

  protected isCoreDataAvailable({ user, stats }: ICustomerIODataFromState): boolean {
    if (!user.profile_id || !stats.email) {
      return false;
    }

    return true;
  }

  protected getCustomerIODataObject({ user, stats, lastMembershipPayment }: ICustomerIODataFromState) {
    const data: ICustomerIOUserData = {
      account_status: user.status ?? null,
      account_type: this.getAccountTypeString(user),
      approved_public_photos: this.getNumberOfApprovedPublicPhotos(user),
      email_verified: user.email_verified ?? null,
      email: stats.email ?? null,
      id: user.profile_id ?? null,
      profile_id: user.profile_id ?? null,
      cio_id: user.profile_id ?? null,
      join_date: stats.joinDateTimestamp ?? null,
      match_sex: user.match_sex ?? null,
      messages_received_count: stats.numberOfMessagesReceived ?? null,
      messages_sent_count: stats.numberOfMessagesSent ?? null,
      number_of_login: stats.numberOfLogins ?? null,
      number_of_private_photos: user.private_album_count ?? null,
      payments_count: stats.paymentsMade ?? null,
      pcat: this.getCategoryNumber(user.profiletype) ?? null,
      photo_verified: UserCommon.getPhotoVerificationStatus(user),
      photos_count: user.numberOfPhotos ?? null,
      premium_expiration_date: user.membership_expiration_timestamp ?? null,
      premium_status: this.getPremiumStatusString(user, stats),
      profile_age: user.age ?? null,
      profile_city: user.cityName ?? null,
      profile_country: user.countryName ?? null,
      profile_gender: user.sex ?? null,
      profile_has_pending_changes: user.have_pending_changes ?? null,
      profile_is_complete: user.is_complete ?? null,
      profile_region: user.wideStateName ?? null,
      profiles_viewed_count: stats.numberOfProfilesViewed ?? null,
      registered_campaign: stats.utmCookie ?? null,
      registered_source: stats.utmSourceCookie ?? null,
      user_language: 'pt-br' ?? null,
      username: user.username ?? null,
      last_expired_membership_timestamp: user.last_expired_membership_timestamp ?? null,
    };

    this.addLastPaymentTypeIfAvailable(data, lastMembershipPayment);

    return data;
  }

  protected addLastPaymentTypeIfAvailable(data: ICustomerIOUserData, lastMembershipPayment: IPaymentInfo): void {
    if (!lastMembershipPayment.payment_type) {
      return;
    }

    data.last_payment_type = lastMembershipPayment.payment_type;
  }

  protected getPremiumStatusString(user: UserCommon, stats: IStats): string {
    if (!user.membership_type_id || stats.paymentsMade === undefined) {
      return '';
    }

    const membershipTypeId: number = user.membership_type_id;
    const paymentsMade: number = stats.paymentsMade;

    if (membershipTypeId === MembershipType.MEMBERSHIP_TYPE_BABY_FREE) {
      return CustomerIOPremiumStatus.BABY_FREE;
    }

    if (membershipTypeId === MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM) {
      return CustomerIOPremiumStatus.BABY_PREMIUM;
    }

    if (membershipTypeId === MembershipType.MEMBERSHIP_TYPE_DADDY_FREE && paymentsMade > 0) {
      return CustomerIOPremiumStatus.DADDY_EXPIRED;
    }

    if (
      membershipTypeId === MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM ||
      membershipTypeId === MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE
    ) {
      return CustomerIOPremiumStatus.DADDY_PREMIUM;
    }

    return CustomerIOPremiumStatus.DADDY_FREE;
  }

  protected getAccountTypeString(user: UserCommon): string {
    if (!user.membership_type_id) {
      return '';
    }

    if (this.isSugarDaddyByMembership(user.membership_type_id)) {
      return CustomerIOAccountTypeAbbreviations.SUGAR_DADDY;
    }

    return CustomerIOAccountTypeAbbreviations.SUGAR_BABY_FREE;
  }

  protected isSugarDaddyByMembership(membershipTypeId: number): boolean {
    return [
      MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
      MembershipType.MEMBERSHIP_TYPE_DADDY_FREE,
      MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE,
    ].includes(membershipTypeId);
  }

  protected getCategoryNumber(category: string): number {
    const categoryNumber: number = parseInt(category.replace(/[^-.0-9]/g, ''));

    if (isNaN(categoryNumber)) {
      return 0;
    }

    return categoryNumber;
  }

  protected getNumberOfApprovedPublicPhotos(user: UserCommon): number {
    if (!user.public_album || user.public_album.length === 0) {
      return 0;
    }

    const approvedPublicPhotos: IPhoto[] = user.public_album.filter(
      (photo: IPhoto): boolean => photo.status === PhotoCommon.STATUS_ACTIVE,
    );

    return approvedPublicPhotos.length;
  }
}
