import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { TrialRule } from '@libs/shared/trial/rules/base/trial.rule';
import { TrialRuleAlwaysFalse } from '@libs/shared/trial/rules/trial.rule.always.false';
import { TrialRuleConversation } from '@libs/shared/trial/rules/trial.rule.conversation';

export abstract class TrialRuleServiceCommon {
  public readonly CONVERSATION_TYPE: string = 'conversation';

  constructor(protected auth: AuthenticationServiceCommon) {
    //
  }

  trialRuleFactory(type: string): TrialRule {
    if (type === this.CONVERSATION_TYPE) {
      return new TrialRuleConversation(this.auth.get());
    }

    return new TrialRuleAlwaysFalse();
  }
}
