import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IBoostPackage } from '@libs/modules/boost/interface/boost-package';

export interface IBoostPackagesState extends EntityState<IBoostPackage> {
  productUuid: string | null;
  isPaymentProcessing: boolean;
  refreshData: number;
}

function selectPackageId(boostPackage: IBoostPackage): number {
  return boostPackage.quantity;
}

export const boostPackagesAdapter: EntityAdapter<IBoostPackage> = createEntityAdapter<IBoostPackage>({
  selectId: selectPackageId,
});

export const boostPackagesInitialState: IBoostPackagesState = boostPackagesAdapter.getInitialState({
  productUuid: null,
  isPaymentProcessing: false,
  refreshData: 0,
});
