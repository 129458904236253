<div
    class="form-field hidden-list"
    [ngClass]="{ active: active, chosen: !!value }"
    (click)="setActive(!active)"
    tabindex="0"
>
    <label>
        {{ label | translate }}
        <br>
        <span *ngIf="!!value">{{ _value | translate }}</span>
    </label>
    <ul
        class="open-options"
        *ngIf="!notSoFancy"
    >
        <li *ngFor="let option of options">
            <button (click)="setValue(option, $event)">{{ option | translate }}</button>
        </li>
    </ul>
    <select
        *ngIf="notSoFancy"
        [(ngModel)]="value"
        (change)="setValue(value, $event)"
        (click)="$event.stopPropagation()"
    >
        <option
            disabled
            [value]="undefined"
        >
            {{ 'modules.frictionless.select_option' | translate }}
        </option>
        <option
            *ngFor="let option of options"
            [value]="option"
        >
            {{ option | translate }}
        </option>
    </select>
</div>
