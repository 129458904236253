import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from '@meupatrocinio/config';
import { IStorageData } from '@meupatrocinio/modules/time-to-register/interface/storage-data-interface';
import { IUpdateRequestData } from '@meupatrocinio/modules/time-to-register/interface/update-request-data-interface';

import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class TimeToRegisterService {
  private readonly storageData: IStorageData;

  constructor(protected http: HttpClient) {
    const localStorageKey = JSON.parse(localStorage.getItem('TimeToRegister') || '{}');

    this.storageData = {
      uuid: localStorageKey.uuid || '',
      createRequestSuccessful: localStorageKey.createRequestSuccessful || false,
      updatedEndTime: localStorageKey.updatedEndTime || false,
      updatedProfileId: localStorageKey.updatedProfileId || false,
    };
  }

  private readonly timeToRegisterEndpoint = Config.serverIp + 'v5/registration/time-to-register';

  public readonly initializeFirstTimeRegistration = () => {
    if (!this.storageData.uuid) {
      const uuid = uuidv4();
      this.updateLocalStorage({ uuid: uuid });
      return true;
    }

    return false;
  };

  public isRequestAlreadyCreated() {
    return this.storageData.createRequestSuccessful;
  }

  public readonly shouldUpdate = () => {
    return this.storageData.createRequestSuccessful;
  };

  public readonly shouldUpdateProfileIdFrictionless = () => {
    return this.storageData.updatedEndTime;
  };

  public isFinished() {
    return (
      this.storageData.createRequestSuccessful && this.storageData.updatedProfileId && this.storageData.updatedEndTime
    );
  }

  public createRequest = () => {
    this.initializeFirstTimeRegistration();

    const requestData = {
      startTime: Date.now(),
      uuid: this.storageData.uuid,
    };

    return this.http.post<HttpResponse<any>>(this.timeToRegisterEndpoint, requestData);
  };

  public updateRequest = ({ profileId, endTime }: IUpdateRequestData = {}) => {
    const requestData = {
      uuid: this.storageData.uuid,
      profileId: profileId,
      endTime: endTime,
    };

    return this.http.put<HttpResponse<any>>(this.timeToRegisterEndpoint, requestData);
  };

  public updateLocalStorage(newValues: Partial<IStorageData>) {
    Object.assign(this.storageData, newValues);
    localStorage.setItem('TimeToRegister', JSON.stringify(this.storageData));
  }
}
