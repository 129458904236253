import { Action } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const PROFILES_FEATURED_RECEIVED_ACTION: string = 'PROFILES_FEATURED_RECEIVED_ACTION';
export const PROFILES_FEATURED_REFRESHED_ACTION: string = 'PROFILES_FEATURED_REFRESHED_ACTION';
export const PROFILES_FEATURED_REMOVED_ACTION: string = 'PROFILES_FEATURED_REMOVED_ACTION';

export const FEATURED_TTL_UPDATED_ACTION: string = 'FEATURED_TTL_UPDATED_ACTION';

export interface IProfilesFeaturedReceivedActionPayload {
  profiles: IProfile[];
}

export class ProfilesFeaturedReceivedAction implements Action {
  readonly type: string = PROFILES_FEATURED_RECEIVED_ACTION;

  constructor(public payload: IProfilesFeaturedReceivedActionPayload) {
    //
  }
}

export class ProfilesFeaturedRefreshedAction implements Action {
  readonly type: string = PROFILES_FEATURED_REFRESHED_ACTION;
}

export interface IFeaturedTTLActionPayload {
  ttl: number;
}

export class FeaturedTTLUpdatedAction implements Action {
  readonly type: string = FEATURED_TTL_UPDATED_ACTION;

  constructor(public payload: IFeaturedTTLActionPayload) {
    //
  }
}

export class ProfileFeaturedRemovedAction implements Action {
  readonly type: string = PROFILES_FEATURED_REMOVED_ACTION;

  constructor(public payload: IProfilesFeaturedReceivedActionPayload) {
    //
  }
}
