import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FrictionlessStep4ComponentCommon } from '@libs/modules/frictionless-registration/step4/step4.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { FancyDropdownComponent } from '@meupatrocinio/modules/frictionless-registration/shared/fancy-dropdown.component';

@Component({
  selector: 'mp-frictionless-step4',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './step4.html',
})
export class FrictionlessStep4Component extends FrictionlessStep4ComponentCommon {
  constructor(
    protected translate: TranslateService,
    protected store: Store<IApplicationState>,
  ) {
    super(translate, store);
  }

  public activateDropdownIfEmpty(dropdown: FancyDropdownComponent): void {
    if (!dropdown || dropdown.value) {
      return;
    }

    dropdown.active = true;
  }
}
