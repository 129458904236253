import { NewTTLUpdatedAction, NEW_TTL_UPDATED_ACTION } from '@libs/store/profiles/actions/new.action';

export function storeNewTTL(state: number = Date.now(), action: NewTTLUpdatedAction): number {
  switch (action.type) {
    case NEW_TTL_UPDATED_ACTION:
      return updateTTL(state, action.payload.ttl);
    default:
      return state;
  }
}

function updateTTL(_: number, newTTL: number): number {
  return newTTL;
}
