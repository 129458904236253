import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MpCropperjsComponent } from '@libs/integration/mp-cropperjs/lib/components/mp-cropperjs/mp-cropperjs.component';

@NgModule({
  declarations: [MpCropperjsComponent],
  imports: [CommonModule],
  exports: [MpCropperjsComponent],
})
export class MpCropperjsModule {}
