import { IImageParams } from '@libs/shared/interfaces/image-params.interface';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { EncodedPhotoUrl, IPhoto } from '@libs/shared/profile/photo';
import { IBaseProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@meupatrocinio/config';

export class ImageHelper {
  static getEncodedImage(photoUrl: EncodedPhotoUrl, { width }: IImageParams = {}): string {
    width = width || 800;

    return `${Config.imagesServer}${photoUrl}&w=${width}&h=${width}`;
  }

  static getImage(profileId: number, photo: IPhoto, { width }: IImageParams = {}): string {
    width = width || 800;

    return `${Config.imagesServer}${profileId}/${photo.photo_id}` + `/${photo.index}/width=${width},height=${width}`;
  }

  static getProfilePicture(profile: IBaseProfile, user: UserCommon, params: IImageParams = {}): string {
    if (!profile || Object.keys(profile).length === 0 || (profile.status && profile.status !== 'active')) {
      return `${Config.imagesFolderBaseUrl}block-suspended-avatar.svg`;
    }

    const photo = profile.photo;

    if (!photo || photo.photo_id === undefined || photo.index === undefined) {
      let membershipTypeToCompare: MembershipType = profile.membership_type_id;

      if (!membershipTypeToCompare && user && user.membership_type_id) {
        membershipTypeToCompare = UserCommon.getTheOppositeMembershipType(user.membership_type_id);
      }

      return UserCommon.getBasicImage(profile.sex, membershipTypeToCompare);
    }

    return ImageHelper.getImage(profile.profile_id, photo, params);
  }
}
