import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { UserMenuItemsComponenCommon } from '@libs/modules/main/shared/user-menu-items/user-menu-items.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { PlatformService } from '@meupatrocinio/modules/main/services/platform/platform.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ConversationService } from '@meupatrocinio/services/conversation.service';

@Component({
  selector: 'mp-user-menu-items',
  templateUrl: 'user-menu-items.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserMenuItemsComponent extends UserMenuItemsComponenCommon implements OnDestroy {
  constructor(
    protected store: Store<IApplicationState>,
    protected auth: AuthenticationService,
    protected conversationService: ConversationService,
    protected router: Router,
    protected location: Location,
    public platformService: PlatformService,
  ) {
    super(router, store, auth, conversationService);
    this.onRouteChanged(this.location.path());
  }
}
