import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

enum Actions {
  UPSERT_PROFILE = '[Melt Toast] - Upsert Profile in toast array',
  REMOVE_ALL_PROFILES = '[Melt Toast] - Remove all profiles of the toast array',
  HANDLE_TAP_ON_MELT_TOAST = '[Melt Toast] - Handle Tap On Melt Toast',
  HANDLE_PROFILES_DELETION = '[Melt Toast] - Handle Profiles Deletion',
  NAVIGATE_TO_MELT_LIST = '[Melt Toats] - Navigate to Melt List',
  WATCH_MELT = '[Melt / Notification] - Watch Melt',
}

export const upsertProfile = createAction(Actions.UPSERT_PROFILE, props<{ profile: IProfile }>());

export const handleTapOnMeltToast = createAction(Actions.HANDLE_TAP_ON_MELT_TOAST);

export const handleProfilesDeletion = createAction(Actions.HANDLE_PROFILES_DELETION);

export const navigateToMeltList = createAction(Actions.NAVIGATE_TO_MELT_LIST);

export const removeAllProfiles = createAction(Actions.REMOVE_ALL_PROFILES);

export const watchMelt = createAction(Actions.WATCH_MELT);
