import { IProfile } from '@libs/shared/profile/profile';
import {
  SearchProfilesReceivedAction,
  SEARCH_PROFILES_RECEIVED_ACTION,
  SEARCH_PROFILES_REFRESHED_ACTION,
  SEARCH_PROFILES_REMOVED_ACTION,
} from '@libs/store/profiles/actions/search.action';

export function storeSearchProfiles(state: number[] = [], action: SearchProfilesReceivedAction): number[] {
  switch (action.type) {
    case SEARCH_PROFILES_RECEIVED_ACTION:
      return onSearchProfilesReceived(state, action.payload.profiles);
    case SEARCH_PROFILES_REMOVED_ACTION:
      return onSearchProfilesRemovedReceived(state, action.payload.profiles);
    case SEARCH_PROFILES_REFRESHED_ACTION:
      return [];
    default:
      return state;
  }
}

function onSearchProfilesReceived(state: number[], profiles: IProfile[]): number[] {
  for (const profile of profiles) {
    state = onSearchProfileReceived(state, profile);
  }

  return state;
}

function onSearchProfileReceived(state: number[], newProfile: IProfile): number[] {
  const index: number = state.indexOf(newProfile.profile_id);
  if (index !== -1) {
    return state;
  }

  return [...state, newProfile.profile_id];
}

function onSearchProfilesRemovedReceived(state: number[], newProfiles: IProfile[]): number[] {
  for (const profile of newProfiles) {
    state = onSearchProfileRemovedReceived(state, profile);
  }

  return state;
}

function onSearchProfileRemovedReceived(state: number[], newProfile: IProfile): number[] {
  const index: number = state.indexOf(newProfile.profile_id);
  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
