import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { ICropImageData } from '@libs/shared/interfaces/crop-image-data.interface';
import { IImageServiceModel } from '@libs/shared/interfaces/image-service-model.interface';
import { IModalPhotoData } from '@libs/shared/interfaces/modal-photo-data.interface';
import { PhotoCommon } from '@libs/shared/profile/photo.common';
import { IApplicationState } from '@libs/store/application-state';
import { OpenCropModalAction } from '@libs/store/crop-modal/actions/open-crop-modal.action';

@Injectable()
export abstract class ImageServiceCommon implements IImageServiceModel {
  public token = '';
  public uri: any;
  public uploadData: ICropImageData;
  protected readonly METHOD_HTTP_POST: string = 'POST';

  constructor(
    protected profileService: ProfileServiceCommon,
    protected store: Store<IApplicationState>,
  ) {
    //
  }

  getAuthenticationKey(): string {
    return `Bearer ${this.token}`;
  }

  uploadImage(uploadData: ICropImageData): void {
    this.uploadData = uploadData;

    const callback = (_uri: string, _photoType, token): void => {
      this.token = token;
      this.uri = _uri;
      this.prepareDataToUpload();
    };

    this.profileService.uploadPhoto(this.uploadData.modalData.filepath, this.uploadData.modalData.type, callback);
  }

  isImageFileSizeValid(imageSize: number): boolean {
    return imageSize >= PhotoCommon.MIN_FILE_SIZE && imageSize <= PhotoCommon.MAX_FILE_SIZE;
  }

  openCropModal(data: IModalPhotoData): void {
    this.store.dispatch(new OpenCropModalAction(data));
  }

  protected abstract validateImageFile(data: IModalPhotoData): boolean;

  protected abstract prepareDataToUpload(): void;

  protected abstract mountDataRequest(): any;

  protected abstract processImageRequest(): void;
}
