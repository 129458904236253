import { MembershipType } from '@libs/shared/membership/membership.common';
import { ProductPaths } from '@libs/shared/product/product-paths.enum';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';

import { IProductRouteInfo } from '@meupatrocinio/modules/payment-v2/product-route-info/interfaces/product-route-info.interface';

export const PRODUCT_ROUTES_MAP: IProductRouteInfo[] = [
  {
    variantUuid: ProductVariantIdentifier.EXPRESS_APPROVAL,
    path: ProductPaths.EXPRESS_APPROVAL,
    periodCode: 0,
    membershipType: MembershipType.MEMBERSHIP_TYPE_EXPRESS_APPROVAL,
  },
  {
    variantUuid: ProductVariantIdentifier.TRIAL_DADDY_PREMIUM_7_DAYS,
    path: ProductPaths.TRIAL_DADDY_PREMIUM_7_DAYS,
    periodCode: 0,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_PREMIUM_15_DAYS,
    path: ProductPaths.DADDY_PREMIUM_15_DAYS,
    periodCode: 15,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_PREMIUM_1_MONTH,
    path: ProductPaths.DADDY_PREMIUM_1_MONTH,
    periodCode: 1,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_PREMIUM_3_MONTHS,
    path: ProductPaths.DADDY_PREMIUM_3_MONTHS,
    periodCode: 3,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_PREMIUM_6_MONTHS,
    path: ProductPaths.DADDY_PREMIUM_6_MONTHS,
    periodCode: 6,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_PREMIUM_PLUS_1_MONTH,
    path: ProductPaths.DADDY_PREMIUM_PLUS_1_MONTH,
    periodCode: 1,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_ELITE_1_MONTH,
    path: ProductPaths.DADDY_ELITE_1_MONTH,
    periodCode: 1,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_ELITE_3_MONTHS,
    path: ProductPaths.DADDY_ELITE_3_MONTHS,
    periodCode: 3,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_ELITE_6_MONTHS,
    path: ProductPaths.DADDY_ELITE_6_MONTHS,
    periodCode: 6,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE,
  },
  {
    variantUuid: ProductVariantIdentifier.BABY_PREMIUM_1_MONTH,
    path: ProductPaths.BABY_PREMIUM_1_MONTH,
    periodCode: 1,
    membershipType: MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.BABY_PREMIUM_3_MONTHS,
    path: ProductPaths.BABY_PREMIUM_3_MONTHS,
    periodCode: 3,
    membershipType: MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.BABY_PREMIUM_6_MONTHS,
    path: ProductPaths.BABY_PREMIUM_6_MONTHS,
    periodCode: 6,
    membershipType: MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM,
  },
];

export const NEW_PRODUCT_ROUTES_MAP: IProductRouteInfo[] = [
  {
    variantUuid: ProductVariantIdentifier.EXPRESS_APPROVAL,
    path: ProductPaths.EXPRESS_APPROVAL,
    periodCode: 0,
    membershipType: MembershipType.MEMBERSHIP_TYPE_EXPRESS_APPROVAL,
  },
  {
    variantUuid: ProductVariantIdentifier.TRIAL_DADDY_PREMIUM_7_DAYS,
    path: ProductPaths.TRIAL_DADDY_PREMIUM_7_DAYS,
    periodCode: 0,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_PREMIUM_15_DAYS,
    path: ProductPaths.DADDY_PREMIUM_15_DAYS,
    periodCode: 15,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_PREMIUM_1_MONTH,
    path: ProductPaths.DADDY_PREMIUM_1_MONTH,
    periodCode: 1,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.MEMBERSHIP_DADDY_PREMIUM_THREE_MONTHS_AB_TEST,
    path: ProductPaths.DADDY_PREMIUM_3_MONTHS,
    periodCode: 3,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.MEMBERSHIP_DADDY_PREMIUM_SIX_MONTHS_AB_TEST,
    path: ProductPaths.DADDY_PREMIUM_6_MONTHS,
    periodCode: 6,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_PREMIUM_PLUS_1_MONTH,
    path: ProductPaths.DADDY_PREMIUM_PLUS_1_MONTH,
    periodCode: 1,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_ELITE_1_MONTH,
    path: ProductPaths.DADDY_ELITE_1_MONTH,
    periodCode: 1,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_ELITE_3_MONTHS,
    path: ProductPaths.DADDY_ELITE_3_MONTHS,
    periodCode: 3,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE,
  },
  {
    variantUuid: ProductVariantIdentifier.DADDY_ELITE_6_MONTHS,
    path: ProductPaths.DADDY_ELITE_6_MONTHS,
    periodCode: 6,
    membershipType: MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE,
  },
  {
    variantUuid: ProductVariantIdentifier.BABY_PREMIUM_1_MONTH,
    path: ProductPaths.BABY_PREMIUM_1_MONTH,
    periodCode: 1,
    membershipType: MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.BABY_PREMIUM_3_MONTHS,
    path: ProductPaths.BABY_PREMIUM_3_MONTHS,
    periodCode: 3,
    membershipType: MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM,
  },
  {
    variantUuid: ProductVariantIdentifier.BABY_PREMIUM_6_MONTHS,
    path: ProductPaths.BABY_PREMIUM_6_MONTHS,
    periodCode: 6,
    membershipType: MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM,
  },
];
