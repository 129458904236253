import { Action } from '@ngrx/store';

import { IBlogPost } from '@libs/shared/interfaces/blog-post.interface';

export const BLOG_POSTS_RECEIVED_ACTION: string = 'BLOG_POSTS_RECEIVED_ACTION';

export interface IBlogPostsReceivedActionPayload {
  posts: IBlogPost[];
}

export class BlogPostsReceivedAction implements Action {
  readonly type: string = BLOG_POSTS_RECEIVED_ACTION;

  constructor(public payload: IBlogPostsReceivedActionPayload) {
    //
  }
}
