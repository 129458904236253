<div
    class="mobile-nagbar"
    *ngIf="canShowFrictionlessNagbar()"
>
    <ng-container *ngIf="hasFilledAllFields()">
        <div class="head incomplete">
            <div class="frictionless-small-loader"></div>
            <div class="text">
                {{ 'modules.frictionless.nagbar.pending.title' | translate }}
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!hasFilledAllFields()">
        <a
            class="link-area"
            [routerLink]="['/main', 'edit-profile']"
        >
            <div class="head incomplete">
                <div class="progress-circle">
                    <svg
                        class="circular-chart"
                        viewBox="0 0 36 36"
                    >
                        <path
                            class="circle-bg"
                            stroke-dasharray="100, 100"
                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        ></path>
                        <path
                            class="circle"
                            [style.strokeDasharray]="getCompletenessSVGPercentage()"
                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        ></path>
                    </svg>
                    <div class="percentage">
                        {{ getCompletenessFormattedPercentage() }}%
                    </div>
                </div>
                <div class="text">
                    {{ 'modules.main.shared.mobile_nagbar.text' | translate }}
                </div>
                <div class="chevron">
                    <img src="/assets/img/chevron-right.svg">
                </div>
            </div>
        </a>
    </ng-container>
    <div
        class="text warning"
        *ngIf="hasNoMembershipExpiration()"
    >
        <mp-advanced-translate key="modules.main.shared.mobile_nagbar.text_explanation">
            <a
                class="link-nagbar"
                mp-translate-param="premiumLink"
                [routerLink]="['/main', 'upgrade-account']"
            >
                {{ 'modules.main.pages.my_profile.basic.member_premium' | translate }}
            </a>
            <a
                class="link-nagbar"
                mp-translate-param="eliteLink"
                [routerLink]="['/main', 'upgrade-account']"
            >
                {{ 'modules.main.pages.profile.member_elite' | translate }}
            </a>
        </mp-advanced-translate>
    </div>
</div>
<div
    id="home-email-confirmation"
    class="mobile-nagbar email-confirmation"
    *ngIf="isVerified()"
>
    <div class="head confirm-email">
        <h1>
            {{ 'modules.main.shared.mobile_nagbar.confirm_email_header' | translate }}
        </h1>
        <div
            class="text email-sent"
            *ngIf="!isClicked"
        >
            <mp-advanced-translate key="modules.main.shared.mobile_nagbar.confirm_email_message1">
                <b mp-translate-param="email">{{ emailObfuscated }}</b>
            </mp-advanced-translate>
        </div>
        <div
            class="text email-resent"
            *ngIf="isClicked && !loading"
        >
            <mp-advanced-translate key="modules.main.shared.mobile_nagbar.confirm_email_message2">
                <b mp-translate-param="email">{{ emailObfuscated }}</b>
            </mp-advanced-translate>
        </div>
    </div>
    <div class="btn-control">
        <div
            class="text b"
            *ngIf="isClicked"
        >
            {{ 'modules.main.shared.mobile_nagbar.waiting_message' | translate:{ count:countDown } }}
        </div>
        <button
            class="send-confirmation"
            (click)="confirmationEmail()"
            *ngIf="!isClicked && !loading"
        >
            {{ 'modules.main.shared.mobile_nagbar.confirm_email_send' | translate }}
        </button>
    </div>
    <div
        class="footer confirm-email"
        *ngIf="isDaddyMommyUser()"
    >
        <div class="text alert-visibility small">
            <mp-advanced-translate key="modules.main.shared.mobile_nagbar.confirm_email_message3"></mp-advanced-translate>
        </div>
    </div>
    <div
        class="loader"
        *ngIf="loading"
    ></div>
</div>
