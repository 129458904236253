import { IProfile } from '@libs/shared/profile/profile';
import { IToastComponent } from '@libs/shared/toast/interfaces/toast-component.interface';

export enum ToastType {
  typeFavorite = 'favorite',
  typeView = 'view',
  typeMessage = 'message',
  typeMelt = 'melt',
  typeBoost = 'boost',
}

export interface IToastParameters {
  profile: IProfile;
  toastType: ToastType;
  component: IToastComponent;
}
