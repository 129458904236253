import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export enum Actions {
  LOAD_BOOSTED_PROFILES = '[Boosted Profiles] - Load Boosted Profiles',
  SET_BOOSTED_PROFILES = '[Boosted Profiles] - Set Boosted Profiles',
  REMOVE_ONE_BOOSTED_PROFILE = '[Boosted Profiles] - Remove One Boosted Profile',
  REMOVE_ALL_BOOSTED_PROFILES = '[Boosted Profiles] - Remove All Boosted Profiles',
}

export const loadBoostedProfiles = createAction(Actions.LOAD_BOOSTED_PROFILES);

export const setBoostedProfiles = createAction(Actions.SET_BOOSTED_PROFILES, props<{ boostedProfiles: IProfile[] }>());

export const removeBoostedProfile = createAction(Actions.REMOVE_ONE_BOOSTED_PROFILE, props<{ profileId: number }>());

export const removeAllBoostedProfiles = createAction(Actions.REMOVE_ALL_BOOSTED_PROFILES);
