<button
    class="user-avatar"
    type="button"
    aria-haspopup="true"
    aria-expanded="true"
    (click)="goToProfile()"
>
    <mp-img-srcset
        url="{{ getUserPicture() }}"
        imgClass="user-avatar__img"
        place="user-menu"
    ></mp-img-srcset>
    <div class="user-avatar__user-texts">
        <div
            id="menu-bar-username"
            class="user-avatar--username"
        >
            {{ getUserName() }}
        </div>
        <div
            id="menu-bar-see-my-profile"
            class="user-avatar--see-my-profile"
        >
            {{ 'modules.main.shared.user-avatar.see-my-profile' | translate }}
        </div>
    </div>
</button>
