import { GenderFemale, GenderMale } from '@libs/interfaces/registration/types/genders.type';
import { Bisexual } from '@libs/interfaces/registration/types/sexual-preferences.type';
import { SugarBaby, SugarDaddyOrMommy } from '@libs/interfaces/registration/types/sugar-roles.type';

export class Registration {
  public static readonly TERMS_OF_SERVICE_STEP: number = 0;
  public static readonly PERSONAL_INFO_STEP: number = 1;
  public static readonly IMAGE_STEP: number = 2;
  public static readonly APPEARANCE_STEP: number = 3;
  public static readonly LIFE_INFO_STEP: number = 4;
  public static readonly LAST_DETAILS_STEP: number = 5;
  public static readonly CONFIRM_EMAIL_STEP: number = 6;
  public static readonly REFRESH_RECOVERY_STEP: number = 7;

  public static readonly MODAL_OUTLET_NAME: string = 'form';

  public static readonly BASE_ROUTE_NAME: string = 'registration';
  public static readonly TERMS_OF_SERVICE_STEP_ROUTE_NAME: string = 'terms-of-service';
  public static readonly PERSONAL_INFO_STEP_ROUTE_NAME: string = 'personal-info';
  public static readonly IMAGE_STEP_ROUTE_NAME: string = 'profile-photo';
  public static readonly APPEARANCE_STEP_ROUTE_NAME: string = 'appearance';
  public static readonly LIFE_INFO_STEP_ROUTE_NAME: string = 'life-info';
  public static readonly LAST_DETAILS_STEP_ROUTE_NAME: string = 'last-details';
  public static readonly CONFIRM_EMAIL_STEP_ROUTE_NAME: string = 'confirm-email';
  public static readonly REFRESH_RECOVERY_STEP_ROUTE_NAME: string = 'check-email';

  public static readonly TERMS_OF_SERVICE_STEP_TITLE: string = 'modules.main.pages.terms_conditions.title';
  public static readonly PERSONAL_INFO_STEP_TITLE: string = 'modules.registration.step.personal_info';
  public static readonly IMAGE_STEP_TITLE: string = 'modules.registration.step.photo';
  public static readonly APPEARANCE_STEP_TITLE: string = 'modules.registration.step.appearance';
  public static readonly LIFE_INFO_STEP_TITLE: string = 'modules.registration.step.life_info';
  public static readonly LAST_DETAILS_STEP_TITLE: string = 'modules.registration.step.last_details';
  public static readonly CONFIRM_EMAIL_STEP_TITLE: string = 'modules.registration.confirm_your_email.title';
  public static readonly REFRESH_RECOVERY_STEP_TITLE: string = 'modules.registration.check_your_email.title';

  public static readonly SELECT_DISABLED_OPTION_TEXT: string = 'modules.frictionless.select_option';
  public static readonly SAVE_AND_CONTINUE_BUTTON_TEXT: string = 'modules.frictionless.save_and_continue';
  public static readonly SUBMIT_REGISTRATION_BUTTON_TEXT: string = 'modules.frictionless.finish_registration';

  public static readonly MINIMUM_AGE: number = 18;
  public static readonly DIFFERENCE_TO_100_YEARS: number = 100 - Registration.MINIMUM_AGE;

  public static readonly DADDY_AND_MOMMY_EXPRESS_APPROVAL_AGE_THRESHOLD: number = 23;
  public static readonly SUGAR_ROLE_DADDY_OR_MOMMY: SugarDaddyOrMommy = 1;
  public static readonly SUGAR_ROLE_BABY: SugarBaby = 2;
  public static readonly SUGAR_DADDY_TEXT: string = 'shared.user.account_types.sugar_daddy';
  public static readonly SUGAR_MOMMY_TEXT: string = 'shared.user.account_types.sugar_mommy';
  public static readonly SUGAR_BABY_TEXT: string = 'shared.user.account_types.sugar_baby';

  public static readonly JUST_DADDY_TEXT: string = 'common.sugar_role.daddy';
  public static readonly JUST_MOMMY_TEXT: string = 'common.sugar_role.mommy';
  public static readonly JUST_BABY_TEXT: string = 'common.sugar_role.baby';

  public static readonly GENDER_FEMALE: GenderFemale = 8;
  public static readonly GENDER_FEMALE_TEXT: string = 'shared.user.genders.female';
  public static readonly GENDER_MALE: GenderMale = 1;
  public static readonly GENDER_MALE_TEXT: string = 'shared.user.genders.male';

  public static readonly BISEXUAL: Bisexual = 9;
  public static readonly USER_LOOKING_FOR_WOMEN_TEXT: string = 'shared.user.match_sex.woman';
  public static readonly USER_LOOKING_FOR_MEN_TEXT: string = 'shared.user.match_sex.man';
  public static readonly USER_LOOKING_FOR_MEN_AND_WOMEN_TEXT: string = 'shared.user.match_sex.man_and_woman';

  public static readonly MODAL_OPENED_BODY_MODIFIER_CLASS: string = 'modal-opened';
  public static readonly VALIDATION_FEEDBACK_MODIFIER_CLASS: string = 'registration-form-field--feedback';
  public static readonly NO_VERTICAL_PADDING_BODY_MODIFIER_CLASS: string = 'no-padding--y-axis';

  public static readonly VALIDATION_ERROR_KEY_NAME: string = 'errorMessage';
  public static readonly USERNAME_UNDUE_SPACES_PATTERN: RegExp = /^\s+|\s+$/g;
  public static readonly DEFAULT_COUNTRY: string = 'BR';

  public static readonly SUBMITTING_FORM_TEXT_STAGE_1: string = 'modules.frictionless.saving_data';
  public static readonly SUBMITTING_FORM_TEXT_STAGE_2: string = 'modules.registration.almost_there';
  public static readonly SUBMITTING_FORM_TEXT_STAGE_3: string = 'modules.registration.finishing';
}
