import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MpSwipeDirective } from '@libs/integration/mp-swipe/directives/mp-swipe.directive';

@NgModule({
  declarations: [MpSwipeDirective],
  imports: [CommonModule],
  exports: [MpSwipeDirective],
})
export class MpSwipeModule {}
