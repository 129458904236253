import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import { ExpiredTokenEffectsCommon } from '@libs/effects/expired-token/expired-token.effects.common';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class ExpiredTokenEffects extends ExpiredTokenEffectsCommon {
  constructor(
    protected actions$: Actions,
    protected auth: AuthenticationService,
  ) {
    super(actions$, auth);
  }
}
