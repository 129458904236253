<mp-actionbar>
    <mp-actionbar-back-title [title]="productDescription"></mp-actionbar-back-title>
</mp-actionbar>
<div class="payment-margin">
    <form
        [formGroup]="paymentGroup"
        (ngSubmit)="makePayment()"
    >
        <div class="container-fluid no-padding">
            <div class="row">
                <div class="col-xs-12">
                    <div class="form-field feedback">
                        <label for="cardNumber">
                            {{ 'modules.main.pages.payment.cc_number' | translate }}
                        </label>
                        <input
                            id="cardNumber"
                            type="text"
                            (change)="handleCardNumber()"
                            formControlName="cardNumber"
                            [placeholder]="'modules.main.pages.payment.cc_number.placeholder' | translate"
                            autocorrect="off"
                            spellcheck="false"
                            name="cardNumber"
                        >
                        <small *ngIf="!validCardNumber">{{ getErrorMessage('cardNumber') }}</small>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-field feedback">
                        <label for="date">
                            {{ 'modules.main.pages.payment.cc_expiry.label' | translate }}
                        </label>
                        <input
                            id="date"
                            type="text"
                            (change)="handleDate()"
                            formControlName="date"
                            [placeholder]="'modules.main.pages.payment.cc_expiry.placeholder' | translate"
                            autocorrect="off"
                            spellcheck="false"
                            name="date"
                            required
                        >
                        <small *ngIf="!validDate">{{ getErrorMessage('date') }}</small>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-field feedback">
                        <label for="cvv" class="feedback-label">
                            {{ 'modules.main.pages.payment.cc_cvv' | translate }}
                        </label>
                        <mp-tooltip
                            class="payment-tooltip"
                            [tooltipText]="'modules.main.pages.payment.cc_cvv.tooltip' | translate"
                            [tooltipIcon]="'&#xe88e;'"
                            [tooltipPosition]="'top'"
                        >
                        </mp-tooltip>
                        <input
                            id="cvv"
                            type="text"
                            (change)="checkCVV()"
                            pattern="[0-9]*"
                            formControlName="cvv"
                            [placeholder]="'modules.main.pages.payment.cc_cvv.placeholder' | translate"
                            autocorrect="off"
                            spellcheck="false"
                            name="cvv"
                            required
                        >
                        <small *ngIf="!validCVV">{{ getErrorMessage('cvv') }}</small>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="form-field feedback">
                        <label for="holder">
                            {{ 'modules.main.pages.payment.cc_holder' | translate }}
                        </label>
                        <input
                            id="holder"
                            type="text"
                            (change)="checkHolder()"
                            formControlName="holder"
                            [placeholder]="'modules.main.pages.payment.cc_holder.placeholder' | translate"
                            autocorrect="off"
                            spellcheck="false"
                            name="holder"
                            required
                        >
                        <small *ngIf="!validHolder">{{ getErrorMessage('holder') }}</small>
                    </div>
                </div>
                <ng-container>
                    <div class="col-xs-12">
                        <div class="form-field">
                            <div class="custom-radio-check mini">
                                <input
                                    id="internationalCard"
                                    type="checkbox"
                                    (change)="checkIsIssuedAbroad()"
                                    name="location"
                                    formControlName="isIssuedAbroad"
                                >
                                <label for="internationalCard">
                                    {{ 'modules.main.pages.payment.cc_international' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-xs-12"
                        *ngIf="!isIssuedAbroad()"
                    >
                        <div class="form-field feedback">
                            <label for="cpf">
                                {{ 'modules.main.pages.payment.cpf' | translate }}
                            </label>
                            <input
                                id="cpf"
                                type="text"
                                (change)="handleCPF()"
                                formControlName="cpf"
                                [placeholder]="'___.___.___-__'"
                                autocorrect="off"
                                spellcheck="false"
                                name="holder"
                                required
                            >
                            <small *ngIf="!validCPF">{{ getErrorMessage('cpf') }}</small>
                        </div>
                    </div>
                </ng-container>
                <div class="col-xs-12">
                    <div class="form-field feedback">
                        <label for="installments">
                            {{ 'modules.main.pages.payment.installmentLabel' | translate }}
                        </label>
                        <select
                            formControlName="installments"
                            (change)="checkInstallments()"
                            name="installments"
                            required
                        >
                            <option
                                *ngFor="let value of getInstallmentsOptions(); index as index"
                                value="{{ index }}"
                                [disabled]="handleDisabledInstallments(index)"
                            >
                                {{ value }}
                            </option>
                        </select>
                        <small *ngIf="!validInstallment">{{ getErrorMessage('installments') }}</small>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="form-field feedback">
                        <button
                            class="mp-main-button bt-lg bt-shadow full-width"
                            type="submit"
                            [disabled]="!canSubmit() || (isPaying$ | async)"
                        >
                            {{ 'modules.main.pages.payment.pay' | translate }}
                        </button>
                        <mp-loading *ngIf="isPaying$ | async"></mp-loading>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
