import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { PaymentModalFormServiceCommon } from '@libs/services/payment-modal-form/payment-modal-form.service.common';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentModalFormService extends PaymentModalFormServiceCommon {
  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected authenticationService: AuthenticationService,
  ) {
    super(formBuilder, authenticationService);
  }
}
