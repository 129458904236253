import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { IUnsafeAction } from '@libs/store/application-state';
import {
  REGISTRATION_CLEAR_ACTION,
  REGISTRATION_INPUTED_ACTION,
} from '@libs/store/registration/actions/registration.action';

export function registration(state: IRegistrationPayload = {}, action: IUnsafeAction): IRegistrationPayload {
  switch (action.type) {
    case REGISTRATION_INPUTED_ACTION:
      return Object.assign({}, state, action.payload.data);
    case REGISTRATION_CLEAR_ACTION:
      return {};
    default:
      return state;
  }
}
