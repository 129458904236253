import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, first, map, shareReplay, take, withLatestFrom } from 'rxjs/operators';

import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@meupatrocinio/config';
import { ABTestsConfig } from '@meupatrocinio/modules/ab-tests/configurations/ab-tests.config';
import { ABTestsService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-service/ab-tests.service';

@Injectable({
  providedIn: 'root',
})
export class ABTestsLoaderService {
  private readonly userHasProfileSwipeTestTreatment$ = of(false);
  private pixPaymentTestTreatment$ = of(false);
  private searchV2TestTreatment$ = of(false);
  private subscribeButtonVisibilityTestTreatment$ = of(false);
  private newPlansExpiredUsersTreatment$ = of(false);
  private newPlansFreeUsersTreatment$ = of(false);
  private whiteThemeTreatment$ = of(null);

  constructor(
    private readonly abTestsService: ABTestsService,
    private readonly store: Store<IApplicationState>,
  ) {
    //
  }

  public loadActiveTests() {
    if (!ABTestsConfig.areABTestsEnabled()) {
      return;
    }

    this.loadSearchV2Test();
    this.loadSubscribeButtonVisibilityTest();
    this.loadPixPaymentMembershipTest();
    this.loadNewPlansExpiredUsersTest();
    this.loadNewPlansFreeUsersTest();
    this.loadWhiteThemeTest();
  }

  private getUser$() {
    return this.store.pipe(select('user'));
  }

  private loadPixPaymentMembershipTest() {
    this.pixPaymentTestTreatment$ = this.getUser$().pipe(
      concatMap((user: UserCommon) => {
        return this.abTestsService.getTestTreatment$(user, {
          testName: ABTestsConfig.PIX_PAYMENT_MEMBERSHIP_TEST_NAME,
          attributes: {
            is_target: true,
          },
        });
      }),
      map(this.convertTreatmentToBoolean),
      first(),
      shareReplay(),
    );
  }

  private loadSearchV2Test() {
    if (!Config.isSearchV2Enabled) {
      return;
    }

    this.searchV2TestTreatment$ = this.getUser$().pipe(
      concatMap((user) => {
        return this.abTestsService.getTestTreatment$(user, {
          testName: ABTestsConfig.SEARCH_V2_TEST_NAME,
          attributes: {
            is_target: true,
          },
        });
      }),
      map(this.convertTreatmentToBoolean),
      first(),
      shareReplay(),
    );
  }

  private loadNewPlansExpiredUsersTest() {
    this.newPlansExpiredUsersTreatment$ = this.getUser$().pipe(
      withLatestFrom(this.store.pipe(select('stats'))),
      concatMap(([user, stats]) => {
        return this.abTestsService.getTestTreatment$(user, {
          testName: ABTestsConfig.NEW_PLANS_EXPIRED_USERS,
          attributes: {
            is_target: ABTestsConfig.isTargetOfNewPlansExpired(user, stats),
          },
        });
      }),
      map(this.convertTreatmentToBoolean),
      take(1),
      shareReplay(),
    );
  }

  private loadNewPlansFreeUsersTest() {
    this.newPlansFreeUsersTreatment$ = this.getUser$().pipe(
      withLatestFrom(this.store.pipe(select('stats'))),
      concatMap(([user, stats]) => {
        return this.abTestsService.getTestTreatment$(user, {
          testName: ABTestsConfig.NEW_PLANS_FREE_USERS,
          attributes: {
            is_target: ABTestsConfig.isTargetOfNewPlansFree(user, stats),
          },
        });
      }),
      map(this.convertTreatmentToBoolean),
      take(1),
      shareReplay(),
    );
  }

  private loadSubscribeButtonVisibilityTest() {
    this.subscribeButtonVisibilityTestTreatment$ = this.getUser$().pipe(
      concatMap((user) =>
        this.abTestsService.getTestTreatment$(user, {
          testName: ABTestsConfig.SUBSCRIBE_BUTTON_VISIBILITY,
          attributes: {
            is_target: UserCommon.isBaby(user),
          },
        }),
      ),
      map(this.convertTreatmentToBoolean),
      take(1),
      shareReplay(),
    );
  }

  protected loadWhiteThemeTest() {
    this.whiteThemeTreatment$ = this.getUser$().pipe(
      concatMap((user) =>
        this.abTestsService.getTestTreatment$(user, {
          testName: ABTestsConfig.WHITE_THEME,
          attributes: {
            is_target: true,
          },
        }),
      ),
      map(this.convertTreatmentToBoolean),
      take(1),
      shareReplay(),
    );
  }

  public getNewPlansExpiredUsersTreatment$() {
    return this.newPlansExpiredUsersTreatment$;
  }

  public getNewPlansFreeUsersTreatment$() {
    return this.newPlansFreeUsersTreatment$;
  }

  public getWhiteThemeTreatment$() {
    return this.whiteThemeTreatment$;
  }

  public getUserHasProfileSwipeTreatment$() {
    return this.userHasProfileSwipeTestTreatment$;
  }

  public getSearchV2Treatment$() {
    return this.searchV2TestTreatment$;
  }

  public getPixPaymentTestTreatment$() {
    return this.pixPaymentTestTreatment$;
  }

  public getSubscribeButtonVisibilityTestTreatment$() {
    return this.subscribeButtonVisibilityTestTreatment$;
  }

  private convertTreatmentToBoolean(treatment: string) {
    return treatment.toLowerCase() === 'on';
  }
}
