import { Medias } from '@libs/utils/medias';

export type ISrcsetDimension = number[];
export interface ISrcsetConfigItem {
  place: string;
  dimensions: ISrcsetDimension[];
  medias?: string[];
}

export interface ISrcsetConfig {
  LISTS: ISrcsetConfigItem;
  CAROUSEL_MOBILE: ISrcsetConfigItem;
  PROFILE_AVATAR: ISrcsetConfigItem;
  PROFILE_THUMBNAILS: ISrcsetConfigItem;
  PROFILE_HEADER: ISrcsetConfigItem;
  USER_MENU: ISrcsetConfigItem;
  CONVERSATION_AVATAR: ISrcsetConfigItem;
  TOAST: ISrcsetConfigItem;
  SETTINGS_PHOTO: ISrcsetConfigItem;
}

export const srcsetConfig: Readonly<ISrcsetConfig> = {
  LISTS: {
    place: 'lists',
    dimensions: [
      [180, 360, 480],
      [200, 400],
      [185, 370],
    ],
    medias: [Medias.MAX_WIDTH_991, Medias.BETWEEN_WIDTH_992_1199, Medias.MIN_WIDTH_1200],
  },
  CAROUSEL_MOBILE: {
    place: 'carousel-mobile',
    dimensions: [[375, 750, 900]],
    medias: [Medias.MAX_WIDTH_991],
  },
  PROFILE_AVATAR: {
    place: 'profile-avatar',
    dimensions: [
      [285, 570],
      [450, 900],
    ],
    medias: [Medias.BETWEEN_WIDTH_992_1199, Medias.MIN_WIDTH_1200],
  },
  PROFILE_THUMBNAILS: {
    place: 'profile-thumbnails',
    dimensions: [
      [110, 220, 330],
      [92, 184],
      [140, 280],
    ],
    medias: [Medias.MAX_WIDTH_991, Medias.BETWEEN_WIDTH_992_1199, Medias.MIN_WIDTH_1200],
  },
  PROFILE_HEADER: {
    place: 'profile-header',
    dimensions: [[40, 80, 120]],
  },
  USER_MENU: {
    place: 'user-menu',
    dimensions: [[40, 80, 120]],
  },
  CONVERSATION_AVATAR: {
    place: 'conversation-avatar',
    dimensions: [
      [50, 100, 150],
      [40, 80],
    ],
    medias: [Medias.MAX_WIDTH_991, Medias.MIN_WIDTH_992],
  },
  TOAST: {
    place: 'toast',
    dimensions: [[44, 88, 132]],
  },
  SETTINGS_PHOTO: {
    place: 'settings-photo',
    dimensions: [[50, 100, 150]],
  },
};
