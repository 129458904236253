import { Component, Input } from '@angular/core';

@Component({
  selector: 'mp-modal-action-button',
  templateUrl: './modal-action-button.html',
  styleUrls: ['./modal-action-button.scss'],
})
export class ModalActionButtonComponent {
  @Input() text = '';
  @Input() modifierClass = '';
  @Input() action: () => void;

  constructor() {
    //
  }
}
