import { UserCommon } from '@libs/shared/user/user.common';

export class BoostCommon {
  public static readonly BOOST_PRODUCT_UUID = 'bb7eea68-d1a7-42d7-9cd5-6805f4680165' as const;

  static readonly WIDTH_SM: number = 768;
  static readonly WIDTH_XL: number = 1500;
  static readonly WIDTH_MD: number = 992;
  static readonly WIDTH_LG: number = 1200;
  static readonly BOOST_SIZE: number = 310;
  static readonly HEADER_LIST_SIZE: number = 44;
  static readonly HEADER_LIST_MARGIN_SIZE: number = 15;
  static readonly BOOST_WIDTH_MOBILE: number = 375;

  static isWideScreen(): boolean {
    return window.innerWidth >= this.WIDTH_XL;
  }

  static isMobile(): boolean {
    return window.innerWidth < this.WIDTH_SM;
  }

  static isSmallWidth(): boolean {
    return window.innerWidth <= this.BOOST_WIDTH_MOBILE;
  }

  static isMediumScreen(): boolean {
    return window.innerWidth >= BoostCommon.WIDTH_MD;
  }

  static isLargeScreen(): boolean {
    return window.innerWidth >= BoostCommon.WIDTH_LG;
  }

  static canViewBoostedUsers(user: UserCommon | undefined): boolean {
    return (
      user !== undefined &&
      (this.isDaddyElegibleToViewBoostedUsers(user) || this.isBabyElegibleToViewBoostedUsers(user))
    );
  }

  static isDaddyElegibleToViewBoostedUsers(user: UserCommon): boolean {
    return this.isDaddyElegible(user) && UserCommon.isDaddyMommy(user);
  }

  static isBabyElegibleToViewBoostedUsers(user: UserCommon): boolean {
    return this.isBabyElegible(user);
  }

  static isDaddyElegible(user: UserCommon): boolean {
    return UserCommon.isMale(user) && (UserCommon.isMaleStraight(user) || UserCommon.isBisexual(user));
  }

  static isBabyElegible(user: UserCommon): boolean {
    return (
      UserCommon.isFemale(user) &&
      (UserCommon.isFemaleStraight(user) || UserCommon.isBisexual(user)) &&
      UserCommon.isBaby(user)
    );
  }

  static canShowBoostInRoute(path: string | undefined): boolean {
    if (path === undefined) {
      return false;
    }

    return path.indexOf('/main/home') !== -1;
  }

  static isAvailable(user: UserCommon): boolean {
    return BoostCommon.canViewBoostedUsers(user);
  }

  static handleBoostPackageQuantity(quantity: number): number {
    return quantity / 100;
  }

  static handleBoostPackagePrice(price: number): number {
    return price / 100;
  }
}
