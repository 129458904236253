import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { IApplicationState } from '@libs/store/application-state';
import { BlogPostsReceivedAction } from '@libs/store/blog/actions/blog-posts.action';

import { Config } from '@meupatrocinio/config';
import { AuthRequestFacade } from '@meupatrocinio/modules/main/services/auth-request-facade/auth-request-facade';

@Injectable()
export class BlogPostsService {
  readonly ENDPOINT_BLOG: string = 'blog';

  constructor(
    private readonly store: Store<IApplicationState>,
    protected authRequestFacade: AuthRequestFacade,
  ) {
    //
  }

  public get(): void {
    const endpoint: string = Config.serverIp + this.ENDPOINT_BLOG;
    this.authRequestFacade
      .get({
        endpoint,
      })
      .subscribe((response): void => {
        this.store.dispatch(
          new BlogPostsReceivedAction({
            posts: response.data,
          }),
        );
      });
  }
}
