import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

import { IValidations } from '@libs/services/payment/validators';

import { MoipValidator } from 'moip-sdk-js';

const validation: ValidationErrors[] = [Validators.required, Validators.pattern(/^\d+$/), validateCard];

function messages(errors: ValidationErrors): string {
  if (errors === null || errors.invalidCard) {
    return 'modules.main.pages.payment.cc_number.error.invalid';
  }

  if (errors.required) {
    return 'modules.main.pages.payment.cc_number.error.missing';
  }

  return 'modules.main.pages.payment.cc_number.error.invalid';
}

function validateCard(control: AbstractControl): ValidationErrors | null {
  const cardNumber = control.value;

  if (MoipValidator.isValidNumber(cardNumber)) {
    return null;
  }

  return {
    invalidCard: true,
  };
}

export const validations: IValidations = {
  validation,
  messages,
};
