import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { IPaymentInfo } from '@libs/store/payment-info';
import { selectLastMembershipPayment } from '@libs/store/payment-info/selectors';
import { IPaymentOptionState, paymentOptionsAdapter } from '@libs/store/payment/reducers';
import { arrayUnique } from '@libs/utils/array-functions';

export const selectPaymentOptionState = createFeatureSelector<IPaymentOptionState>('paymentOptionsEntity');

export const selectAllPaymentOption = createSelector(
  selectPaymentOptionState,
  paymentOptionsAdapter.getSelectors().selectAll,
);

export const selectMemberships = createSelector(selectAllPaymentOption, (options: IPaymentOption[]): number[] =>
  arrayUnique(options.map((option: IPaymentOption): number => option.membership_type_id)),
);

export const selectOptionsByMembership = createSelector(
  selectAllPaymentOption,
  (options: IPaymentOption[], membershipTypeId: MembershipType): IPaymentOption[] => {
    return options.filter((option: IPaymentOption): boolean => option.membership_type_id === membershipTypeId);
  },
);

export const selectOptionsByMembershipAndPeriod = (membershipTypeId: MembershipType, period: number) =>
  createSelector(selectAllPaymentOption, (options: IPaymentOption[]): IPaymentOption => {
    return options.find((paymentOption) => {
      return paymentOption.membership_type_id === membershipTypeId && paymentOption.period === period;
    });
  });

export const selectPeriodByAmountPaid = createSelector(
  selectAllPaymentOption,
  selectLastMembershipPayment,
  (allPaymentOptions: IPaymentOption[], lastPayment: IPaymentInfo | undefined): number => {
    if (!lastPayment.subtotal_amount) {
      return 0;
    }

    const lastPaymentOption: IPaymentOption = allPaymentOptions.find((paymentOption: IPaymentOption): boolean => {
      return paymentOption.price === lastPayment.subtotal_amount;
    });

    if (lastPaymentOption === undefined) {
      return 0;
    }

    return lastPaymentOption.period;
  },
);

export const selectSelectedPlan = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState, membershipTypeId: MembershipType): IPaymentOption =>
    state.entities[membershipTypeId + '_' + state.selectedPeriod],
);

export const selectPlanByMembership = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState, membershipTypeId: MembershipType): IPaymentOption | null => {
    if (
      Object.keys(state.entities).length === 0 ||
      !state.selectedMembershipsPlans ||
      Object.keys(state.selectedMembershipsPlans).length === 0
    ) {
      return null;
    }

    return state.entities[state.selectedMembershipsPlans[membershipTypeId]];
  },
);

export const selectAllAvailablePeriods = createSelector(selectAllPaymentOption, (options: IPaymentOption[]): number[] =>
  options.map((option: IPaymentOption): number => option.period),
);

export const selectAllAvailableTypeIds = createSelector(
  selectAllPaymentOption,
  (options: IPaymentOption[]): MembershipType[] => {
    return options.map((option: IPaymentOption): MembershipType => option.membership_type_id);
  },
);

export const selectPlanByUuid = (planUuid: string) =>
  createSelector(selectPaymentOptionState, (state: IPaymentOptionState): IPaymentOption => {
    return state.entities[planUuid];
  });

export const selectPlanPrizeByUuid = (planUuid: string) =>
  createSelector(selectPlanByUuid(planUuid), (plan: IPaymentOption) => (plan ? plan.prize_uuid || '' : undefined));

export const selectPreferredPlans = createSelector(
  selectAllPaymentOption,
  (options: IPaymentOption[]): IPaymentOption[] => {
    return options.filter((option: IPaymentOption): boolean => option.preferred_plan);
  },
);

export const selectSelectedProvider = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): string => state.selectedProvider,
);

export const hasLoadedPaymentOptions = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): boolean => state.hasLoaded,
);

export const selectHasCreditCardPaymentType = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): boolean => PaymentCommon.hasCreditCardPaymentType(state.payment_types),
);

export const selectHasBoletoPaymentType = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): boolean => PaymentCommon.hasBoletoPaymentType(state.payment_types),
);

export const selectHasPaymentTypesAvailable = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): boolean => PaymentCommon.hasPaymentTypesAvailable(state.payment_types),
);

export const hasPaymentOptions = createSelector(
  selectAllPaymentOption,
  (options: IPaymentOption[]): boolean => options !== undefined && options.length !== 0,
);

export const selectCurrentPayment = createSelector(selectPaymentOptionState, (state) => {
  return state.currentPayment;
});
