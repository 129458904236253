import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfile } from '@libs/shared/profile/profile';

@Pipe({
  name: 'boostToastSubtitle',
  pure: true,
})
export class BoostToastSubtitlePipe implements PipeTransform {
  constructor(protected translateService: TranslateService) {
    //
  }

  transform(profiles: IProfile[] | undefined): string {
    if (profiles === undefined) {
      return '';
    }

    return this.translateService.instant('modules.main.shared.boost_toast.subtitle', {
      numProfiles: profiles.length,
    });
  }
}
