import { Injectable, OnDestroy } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { interval, Observable, Subscription, throwError } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { ListName } from '@libs/modules/main/services/download-manager.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { MeltProfilesActions, MeltProfilesSelectors } from '@libs/store/melt-profiles';
import { ProfilesFavMeRefreshedAction } from '@libs/store/profiles/actions/fav-me.action';
import { ProfilesFavMyRefreshedAction } from '@libs/store/profiles/actions/fav-my.action';
import { ProfilesFeaturedRefreshedAction } from '@libs/store/profiles/actions/featured.action';
import { ProfilesNearbyRefreshedAction } from '@libs/store/profiles/actions/nearby.action';
import { ProfilesNewRefreshedAction } from '@libs/store/profiles/actions/new.action';
import { ProfilesViewedRefreshedAction } from '@libs/store/profiles/actions/viewed.action';

import { Config } from '@meupatrocinio/config';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';

@Injectable()
export class ListTTLService implements OnDestroy {
  protected featured$: Subscription;
  protected nearBy$: Subscription;
  protected melt$: Subscription;
  protected new$: Subscription;
  protected favMe$: Subscription;
  protected favMy$: Subscription;
  protected viewed$: Subscription;
  protected subscriptions: Subscription[] = [];

  protected isSetUp: boolean;

  protected readonly intervalCheck: number = 120000;

  constructor(
    protected store: Store<IApplicationState>,
    protected downloadManager: DownloadManagerService,
  ) {
    //
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public setUp(): void {
    if (this.isSetUp) {
      return;
    }

    this.isSetUp = true;

    this.observeFeatured();
    this.observeNearBy();
    this.observeMelt();
    this.observeNew();
    this.observeFavMe();
    this.observeFavMy();
    this.observeViewed();
  }

  public destroy(): void {
    if (!this.isSetUp) {
      return;
    }

    this.isSetUp = false;

    this.featured$.unsubscribe();
    this.nearBy$.unsubscribe();
    this.melt$.unsubscribe();
    this.new$.unsubscribe();
    this.favMe$.unsubscribe();
    this.favMy$.unsubscribe();
    this.viewed$.unsubscribe();
  }

  protected observeFeatured(): void {
    const featuredTTL: Observable<any> = this.store.select('storeFeaturedTTL');
    const source: Observable<any> = interval(this.intervalCheck);

    this.featured$ = source.pipe(withLatestFrom(featuredTTL)).subscribe((data: any[]): void => {
      this.ttlDiff(data[1], new ProfilesFeaturedRefreshedAction(), 'featured');
    });
  }

  protected observeNearBy(): void {
    const nearByTTL: Observable<any> = this.store.select('storeNearByTTL');
    const source: Observable<any> = interval(this.intervalCheck);

    this.nearBy$ = source.pipe(withLatestFrom(nearByTTL)).subscribe((data: any[]): void => {
      this.ttlDiff(data[1], new ProfilesNearbyRefreshedAction(), 'nearby');
    });
  }

  protected observeMelt(): void {
    const meltTTL: Observable<number> = this.store.select(MeltProfilesSelectors.selectMeltTTL);
    const source: Observable<number> = interval(this.intervalCheck);

    this.melt$ = source.pipe(withLatestFrom(meltTTL)).subscribe((data: number[]): void => {
      this.ttlDiff(data[1], MeltProfilesActions.removeAllMeltProfiles(), 'melt');
    });
  }

  protected observeNew(): void {
    const newTTL: Observable<any> = this.store.select('storeNewTTL');
    const source: Observable<any> = interval(this.intervalCheck);

    this.new$ = source.pipe(withLatestFrom(newTTL)).subscribe((data: any[]): void => {
      this.ttlDiff(data[1], new ProfilesNewRefreshedAction(), 'new');
    });
  }

  protected observeFavMe(): void {
    const favMeTTL: Observable<any> = this.store.select('storeFavMeTTL');
    const source: Observable<any> = interval(this.intervalCheck);

    this.favMe$ = source.pipe(withLatestFrom(favMeTTL)).subscribe((data: any[]): void => {
      this.ttlDiff(data[1], new ProfilesFavMeRefreshedAction(), 'favMe');
    });
  }

  protected observeFavMy(): void {
    const favMyTTL: Observable<any> = this.store.select('storeFavMyTTL');
    const source: Observable<any> = interval(this.intervalCheck);

    this.favMy$ = source.pipe(withLatestFrom(favMyTTL)).subscribe((data: any[]): void => {
      this.ttlDiff(data[1], new ProfilesFavMyRefreshedAction(), 'favMy');
    });
  }

  protected observeViewed(): void {
    const viewedTTL: Observable<any> = this.store.select('storeViewedTTL');
    const source: Observable<any> = interval(this.intervalCheck);

    this.viewed$ = source.pipe(withLatestFrom(viewedTTL)).subscribe((data: any[]): void => {
      this.ttlDiff(data[1], new ProfilesViewedRefreshedAction(), 'viewedMe');
    });
  }

  protected ttlDiff(ttlTime: number, action: Action, listName: ListName): void {
    const diff = Date.now() - ttlTime;

    if (diff > Config.listsTTL) {
      this.subscriptions.push(
        this.downloadManager.resetAndUpdate(listName).subscribe(undefined, (error: any): void => {
          this.errorCallback(error);
        }),
      );
      this.store.dispatch(action);
    }
  }

  protected errorCallback = (error: any): Observable<never> => {
    return throwError(error);
  };
}
