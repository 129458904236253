import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { UserButtonsPhotoVerifyComponentCommon } from '@libs/modules/main/shared/user-buttons-photo-verify/user-buttons-photo-verify.component.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

@Component({
  selector: 'mp-user-buttons-photo-verify',
  templateUrl: './user-buttons-photo-verify.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserButtonsPhotoVerifyComponent extends UserButtonsPhotoVerifyComponentCommon {
  constructor(
    protected router: Router,
    protected store: Store<IApplicationState>,
  ) {
    super(store);
  }

  getPhotoVerifyButtonIcon(): string {
    return UserCommon.resolveImageName('verify-inverted-icon', '.svg');
  }

  navigateToPhotoVerify(): void {
    this.closeSlidableMenu();
    this.router.navigate(['main', 'photo-verify']);
  }
}
