import { AfterViewInit, Directive, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[lib-mp-swipe]',
})
export class MpSwipeDirective implements AfterViewInit {
  public touchstartX = 0;
  public touchstartY = 0;
  public timestamp = 0;
  protected readonly MIN_SWIPE_DISTANCE: number = 60;
  protected readonly MAX_SWIPE_TIME_IN_MS: number = 5000;

  @Output() swipeLeft: EventEmitter<TouchEvent> = new EventEmitter<TouchEvent>();
  @Output() swipeRight: EventEmitter<TouchEvent> = new EventEmitter<TouchEvent>();

  constructor(
    protected readonly renderer2: Renderer2,
    protected readonly elementRef: ElementRef,
  ) {
    //
  }

  public ngAfterViewInit(): void {
    this.renderer2.listen(this.elementRef.nativeElement, 'touchstart', (event: TouchEvent): void => {
      this.handleTouchStart(event);
    });

    this.renderer2.listen(this.elementRef.nativeElement, 'touchend', (event: TouchEvent): void => {
      this.handleTouchMove(event);
    });
  }

  public handleTouchStart(event: TouchEvent): void {
    this.touchstartX = event.touches[0].pageX;
    this.touchstartY = event.touches[0].pageY;
    this.timestamp = event.timeStamp;
  }

  public handleTouchMove(event: TouchEvent): void {
    if (!this.touchstartX || !this.touchstartY) {
      return;
    }

    const endTouch: Touch = event.touches[0] || event.changedTouches[0];
    const touchEndX: number = endTouch.pageX;
    const swipeDistanceX: number = touchEndX - this.touchstartX;
    const touchTimeDifference: number = event.timeStamp - this.timestamp;

    if (this.canDoSwipe(touchTimeDifference, swipeDistanceX)) {
      this.handleSwipeEvents(swipeDistanceX, event);
    }

    this.resetValues();
  }

  public handleSwipeEvents(swipeDistanceX: number, event: TouchEvent): void {
    if (swipeDistanceX > 0) {
      this.emitSwipeRight(event);
      return;
    }

    this.emitSwipeLeft(event);
  }

  public canDoSwipe(touchTimeDifference: number, swipeDistanceX: number): boolean {
    return touchTimeDifference < this.MAX_SWIPE_TIME_IN_MS && Math.abs(swipeDistanceX) > this.MIN_SWIPE_DISTANCE;
  }

  public resetValues(): void {
    this.touchstartX = 0;
    this.touchstartY = 0;
  }

  public emitSwipeRight(event: TouchEvent): void {
    this.swipeRight.emit(event);
  }

  public emitSwipeLeft(event: TouchEvent): void {
    this.swipeLeft.emit(event);
  }
}
