import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { IProfileContextReceivedActionPayload } from '@libs/store/profile-context/actions';

export interface IProfileContext {
  id: string;
  profileContext: IProfileContextReceivedActionPayload;
}

export interface IProfileContextState extends EntityState<IProfileContext> {
  //
}

export const profileContextEntityAdapter = createEntityAdapter<IProfileContext>({
  selectId: (url: IProfileContext) => url.id,
  sortComparer: false,
});

export const profileContextEntitiesInitialState: IProfileContextState = profileContextEntityAdapter.getInitialState({
  //
});
