import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CloseMenuButtonComponentCommon } from '@libs/components/close-menu-button/close-menu-button.component.common';

import { SlidableMenuService } from '@meupatrocinio/services/slidable-menu/slidable-menu.service';

@Component({
  selector: 'mp-close-menu-button',
  templateUrl: './close-menu-button.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CloseMenuButtonComponent extends CloseMenuButtonComponentCommon {
  constructor(protected slidableMenuService: SlidableMenuService) {
    super(slidableMenuService);
  }
}
