import { IProfile } from '@libs/shared/profile/profile';
import {
  ProfilesFavMeReceivedAction,
  PREPEND_FAV_ME_PROFILE_ACTION,
  PROFILES_FAV_ME_RECEIVED_ACTION,
  PROFILES_FAV_ME_REFRESHED_ACTION,
  PROFILES_FAV_ME_REMOVED_ACTION,
} from '@libs/store/profiles/actions/fav-me.action';

export function storeFavMeProfiles(state: number[] = [], action: ProfilesFavMeReceivedAction): number[] {
  switch (action.type) {
    case PREPEND_FAV_ME_PROFILE_ACTION:
      return onProfilesFavMeReceived(state, action.payload.profiles, true);
    case PROFILES_FAV_ME_RECEIVED_ACTION:
      return onProfilesFavMeReceived(state, action.payload.profiles);
    case PROFILES_FAV_ME_REMOVED_ACTION:
      return onProfilesFavMeRemoved(state, action.payload.profiles);
    case PROFILES_FAV_ME_REFRESHED_ACTION:
      return [];
    default:
      return state;
  }
}

function onProfilesFavMeReceived(state: number[], profiles: IProfile[], reverse?: boolean): number[] {
  const profileIds = profiles.map((profile: IProfile): number => profile.profile_id);
  state = state.filter((profileId: number): boolean => profileIds.indexOf(profileId) === -1);

  if (reverse) {
    return [...profileIds.reverse(), ...state];
  }

  return [...state, ...profileIds];
}

function onProfilesFavMeRemoved(state: number[], profiles: IProfile[]): number[] {
  for (const profile of profiles) {
    state = onProfileFavMeRemoved(state, profile);
  }

  return state;
}

function onProfileFavMeRemoved(state: number[], newProfile: IProfile): number[] {
  const index: number = state.indexOf(newProfile.profile_id);
  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
