export const MARKETING_COOKIES = ['gclid', 'vcid', 'utm_campaign', 'utm_source'];

export const COOKIES_TO_BE_UPDATED: string[] = ['vcid', 'utm_source', 'utm_campaign'];

export interface IUpdateCookiesResponse {
  gclid?: string;
  vcid?: string;
  utm_campaign?: string;
  utm_source?: string;
}
