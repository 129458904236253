import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ModalRef } from '@libs/services/modal/modal-ref';

@Component({
  templateUrl: './modal-conversation-already.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConversationAlreadyDeleted {
  constructor(protected modalRef: ModalRef) {
    //
  }

  public closeModal() {
    this.modalRef.close();
  }
}
