import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { IValidations } from '@libs/services/payment/validators';

import { MoipValidator } from 'moip-sdk-js';

const validation: ValidationErrors[] = [Validators.required, Validators.pattern(/^\d{3,4}$/), Validators.maxLength(4)];

function messages(errors: ValidationErrors): string {
  if (errors === null || errors.invalidCvv) {
    return 'modules.main.pages.payment.cc_cvv.error.invalid';
  }

  if (errors.required) {
    return 'modules.main.pages.payment.cc_cvv.error.missing';
  }

  return 'modules.main.pages.payment.cc_cvv.error.invalid';
}

export function validateCvv(group: UntypedFormGroup): ValidationErrors | null {
  const cardNumber = group.get('cardNumber').value.toString();
  const cvv = group.get('cvv').value.toString();

  if (MoipValidator.isSecurityCodeValid(cardNumber, cvv)) {
    return null;
  }

  return {
    invalidCvv: true,
  };
}

export const validations: IValidations = {
  validation,
  messages,
};
