import {
  NotificationTokenAction,
  NOTIFICATION_TOKEN_ACTION,
} from '@libs/store/notifications/actions/notification.action';

export function notificationToken(state = '', action: NotificationTokenAction): string {
  switch (action.type) {
    case NOTIFICATION_TOKEN_ACTION:
      return action.payload;

    default:
      return state;
  }
}
