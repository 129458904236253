export class NagBarList<T> {
  protected items: T[] = [];

  public add(item: T): void {
    this.items.push(item);
  }

  public size(): number {
    return this.items.length;
  }

  public get(index: number): T {
    return this.items[index];
  }
}
