import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';

import { PaymentModalPixFormService } from '@meupatrocinio/services/payment-modal-pix-form/payment-modal-pix-form.service';
@Component({
  selector: 'mp-payment-modal-pix-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './payment-modal-pix-form.html',
})
export class PaymentModalPixFormComponent implements OnInit, OnDestroy {
  @Output() public readonly cpfData: EventEmitter<string> = new EventEmitter<string>();
  @Input() public submitButtonTranslation = 'modules.main.pages.payment.pay';
  @Input() public showLoading = false;

  public isValidCPF = false;
  public paymentModalPixForm: FormGroup = this.paymentModalPixFormService.getInitialValue();

  constructor(
    private readonly paymentModalPixFormService: PaymentModalPixFormService,
    private readonly paymentHelpersService: PaymentHelpersService,
  ) {}

  public ngOnInit() {
    this.initializeForm();
  }

  public ngOnDestroy() {
    this.resetForm();
    this.paymentModalPixFormService.clearAllData();
  }

  public initializeForm() {
    this.paymentModalPixForm = this.paymentModalPixFormService.getFormGroup();
  }

  public makePayment() {
    if (!this.isFormGroupValid()) {
      return;
    }

    this.handlePaymentData();
  }

  public handleCPF() {
    this.formatCPFValue();
    this.checkCPFValidation();
  }

  public formatCPFValue() {
    this.paymentModalPixFormService.setCPF(
      this.paymentHelpersService.formatNumber(this.paymentModalPixFormService.getCPF()),
    );
  }

  public checkCPFValidation() {
    this.isValidCPF = this.paymentModalPixFormService.isCPFValid();
  }

  public getErrorMessage(controlName: string) {
    return this.paymentModalPixFormService.getFormValidationErrosMessage(controlName);
  }

  public isFormGroupValid() {
    return this.paymentModalPixFormService.isFormValid();
  }

  public resetForm() {
    this.paymentModalPixForm.reset();
  }

  public handlePaymentData() {
    this.cpfData.emit(this.paymentModalPixForm.get('cpf')?.value);
  }
}
