import { Action, createReducer, on } from '@ngrx/store';

import { refreshRecommendedProfileList, storeRecommendedProfileIdList } from '@libs/store/recommended-list/actions';

const SearchV2Reducer = createReducer(
  [],
  on(storeRecommendedProfileIdList, (state: number[], { profiles }) => {
    const profileIds = profiles.map(({ profile_id }) => profile_id);

    return [...state, ...profileIds];
  }),
  on(refreshRecommendedProfileList, () => []),
);

export function reducer(state: number[], action: Action) {
  return SearchV2Reducer(state, action);
}
