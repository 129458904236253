import { Action } from '@ngrx/store';

export const PROFILES_ID_PRIVATE_PHOTO_GAVE_ACCESS_RECEIVED_ACTION: string =
  'PROFILES_ID_PRIVATE_PHOTO_GAVE_ACCESS_RECEIVED_ACTION';
export const PROFILES_ID_PRIVATE_PHOTO_REMOVE_ACCESS_RECEIVED_ACTION: string =
  'PROFILES_ID_PRIVATE_PHOTO_REMOVE_ACCESS_RECEIVED_ACTION';

export interface IProfileIdPrivatePhotoGaveAcessReceivedActionPayload {
  profileIds: number[];
}

export class ProfilesIdPrivatePhotoIGaveAccessReceivedAction implements Action {
  readonly type: string = PROFILES_ID_PRIVATE_PHOTO_GAVE_ACCESS_RECEIVED_ACTION;

  constructor(public payload: IProfileIdPrivatePhotoGaveAcessReceivedActionPayload) {
    //
  }
}

export class ProfilesIdPrivatePhotoIRemoveAccessReceivedAction implements Action {
  readonly type: string = PROFILES_ID_PRIVATE_PHOTO_REMOVE_ACCESS_RECEIVED_ACTION;

  constructor(public payload: IProfileIdPrivatePhotoGaveAcessReceivedActionPayload) {
    //
  }
}
