import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ConversationServiceCommon } from '@libs/modules/main/services/conversation/conversation.service.common';
import { AnalyticsServiceCommon } from '@libs/services/analytics/analytics.service.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { MessageReceivedInputedAction } from '@libs/store/authentication/actions/message.received.action';
import { IConversation } from '@libs/store/conversations';
import { IMessage, MessageActions } from '@libs/store/messages';

@Injectable({
  providedIn: 'root',
})
export abstract class MessageServiceCommon {
  protected messagesToSend: Observable<any>[] = [];
  protected messageSendTries = 0;

  constructor(
    protected auth: AuthenticationServiceCommon,
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpServiceCommon,
    protected conversationService: ConversationServiceCommon,
    protected analytics: AnalyticsServiceCommon,
  ) {
    //
  }

  public abstract downloadMessages(peerId: number, page: number): Observable<IAuthResponse>;

  public abstract downloadBulkMessages(messageIds: number[]): Observable<IAuthResponse>;

  public abstract downloadMessagesRecent(lastMessageId: number, page: number): Observable<IAuthResponse>;

  public updateMessages(messages: IMessage[]): void {
    if (!messages.every((message): boolean => !!message)) {
      throw new Error('Invalid response from the API. Null messages were sent.');
    }

    this.store.dispatch(MessageActions.loadMessages({ messages }));
  }

  public updateMessagesRecent(messages: IMessage[]): void {
    if (messages.length === 0) {
      return;
    }

    if (!messages.every((message): boolean => !!message)) {
      throw new Error('Invalid response from the API. Null messages were sent.');
    }

    this.store.dispatch(MessageActions.upsertMessages({ messages }));
    this.store.dispatch(new MessageReceivedInputedAction());
  }

  public abstract sendMessage(
    profileId: number,
    messageText: string,
    uuid: string,
    conversation?: IConversation,
  ): Observable<IMessage>;
}
