import { Injectable } from '@angular/core';

import { TrialRuleServiceCommon } from '@libs/modules/main/services/trial/rules/trial.rule.service.common';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class TrialRuleService extends TrialRuleServiceCommon {
  constructor(protected auth: AuthenticationService) {
    super(auth);
  }
}
