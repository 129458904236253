import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NetcoreActions = createActionGroup({
  source: 'Netcore',
  events: {
    'Handle All Plans View Event': emptyProps(),
    'Handle Plan View': props<{ price: number }>(),
    'Handle Subscription Purchase': props<{ price: number; paymentMethod: 'credit_card' | 'boleto' }>(),
  },
});
