import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RANDOM_PROFILE_PLACEHOLDERS } from '@meupatrocinio/utils/components/blurhash-image/constants';
import { decode } from 'blurhash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-blurhash-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './blurhash-image.html',
  styleUrls: ['./blurhash-image.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurhashImageComponent implements OnInit {
  @Input() width!: number | string;
  @Input() height!: number | string;
  @Input() imageUrl!: string;
  @Input() hash?: string;

  @ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;

  imageLoaded = new BehaviorSubject(false);

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.renderBlurhash();
  }

  private hashToDecode!: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.hashToDecode = this.hash || this.getRandomBlurhashPlaceholder();
  }

  ngAfterViewInit() {
    this.renderBlurhash();
  }

  renderBlurhash(): void {
    if (!this.canvasRef?.nativeElement) {
      return;
    }

    const container = this.elementRef.nativeElement;
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');

    const rect = container.getBoundingClientRect();
    this.updateCanvas(canvas, ctx, Math.ceil(rect.width), Math.ceil(rect.height));
  }

  private updateCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, width: number, height: number): void {
    const pixels = decode(this.hashToDecode, width, height);
    const imageData = new ImageData(new Uint8ClampedArray(pixels), width, height);

    canvas.width = width;
    canvas.height = height;

    ctx.putImageData(imageData, 0, 0);
    this.cdr.markForCheck();
  }

  private getRandomBlurhashPlaceholder(): string {
    const randomIndex = Math.floor(Math.random() * RANDOM_PROFILE_PLACEHOLDERS.length);
    return RANDOM_PROFILE_PLACEHOLDERS[randomIndex];
  }

  onImageLoad() {
    this.imageLoaded.next(true);
  }
}
