import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { CustomerSupportComponentCommon } from '@libs/modules/main/modules/settings/customer-support/customer-support.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { CustomerSupportService } from '@meupatrocinio/services/customer-support.service';
import { ModalService } from '@meupatrocinio/services/modal.service';

@Component({
  selector: 'mp-customer-support',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customer-support.html',
  styleUrls: ['./customer-support.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerSupportComponent extends CustomerSupportComponentCommon implements AfterViewInit, OnDestroy {
  @ViewChild('reputationElement', { static: true })
  reputationElement: ElementRef<HTMLDivElement>;

  public title = '';
  public issue = '';
  public loading = false;
  public messageSent = false;
  protected reputationElementScript: HTMLScriptElement = {} as HTMLScriptElement;

  constructor(
    protected customerSupportService: CustomerSupportService,
    protected router: Router,
    protected modalService: ModalService,
    protected store: Store<IApplicationState>,
    protected changeDetectorReference: ChangeDetectorRef,
    protected renderer2: Renderer2,
    @Inject(DOCUMENT) protected document: Document,
  ) {
    super(customerSupportService, store);
  }

  ngAfterViewInit(): void {
    this.initializeReputationElement();
  }

  ngOnDestroy(): void {
    this.destroyReputationElement();
  }

  public initializeReputationElement(): void {
    this.reputationElementScript = this.renderer2.createElement('script');
    this.renderer2.setAttribute(this.reputationElementScript, 'type', 'text/javascript');
    this.renderer2.setAttribute(this.reputationElementScript, 'id', 'ra-embed-reputation');
    this.renderer2.setAttribute(
      this.reputationElementScript,
      'data-id',
      'UnBJQ19MVFFPdVF6MGpqVTptZXUtcGF0cm9jaW5pbw==',
    );
    this.renderer2.setAttribute(this.reputationElementScript, 'data-target', 'reputation-ra');
    this.renderer2.setAttribute(this.reputationElementScript, 'data-model', '2');
    this.renderer2.setAttribute(
      this.reputationElementScript,
      'src',
      'https://s3.amazonaws.com/raichu-beta/selos/bundle.js',
    );

    this.renderer2.appendChild(this.reputationElement.nativeElement, this.reputationElementScript);
  }

  public destroyReputationElement(): void {
    this.renderer2.removeChild(this.reputationElement.nativeElement, this.reputationElementScript);
    this.reputationElementScript = {} as HTMLScriptElement;
  }

  goBack(): void {
    this.router.navigate(['main', 'settings']);
  }

  sendIssue(): void {
    const title: string = this.title;
    const message: string = this.issue;
    if (!this.verifyFields(title, message)) {
      this.modalService.open('common.all_fields_required');
      return;
    }

    this.loading = true;
    this.send(
      title,
      message,
      (): void => {
        this.loading = false;
        this.messageSent = true;
        this.markForChangeDetection();
      },
      (): void => {
        this.loading = false;
        this.modalService.open('common.unknown_error');
        this.markForChangeDetection();
      },
    );
  }

  protected markForChangeDetection(): void {
    this.changeDetectorReference.markForCheck();
  }
}
