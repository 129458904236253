import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IProfileContextState, profileContextEntityAdapter } from './state';

const selectProfileContextFeature = createFeatureSelector<IProfileContextState>('profileContext');

const { selectEntities, selectAll } = profileContextEntityAdapter.getSelectors(selectProfileContextFeature);

export const selectProfileContextEntities = selectEntities;
export const selectAllProfileContexts = selectAll;

export const selectCurrentProfileContext = createSelector(
  selectProfileContextEntities,
  (entities) => entities['current']?.profileContext,
);
