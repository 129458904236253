import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { Config } from '@meupatrocinio/config';
import { ITestimonials } from '@meupatrocinio/modules/main/services/testimonials/interfaces/testimonials.interface';
import { TestimonialsService } from '@meupatrocinio/modules/main/services/testimonials/testimonials.service';

@Component({
  selector: 'mp-testimonials',
  templateUrl: './testimonials.html',
})
export class TestimonialsComponent implements OnInit {
  @Input() public limit = -1;
  @Input() public mode = '';
  @Input() public membershipType = 0;
  @Input() isFullScreen = false;

  public testimonials$: Observable<ITestimonials[]> = of([]);

  constructor(
    protected testimonialsService: TestimonialsService,
    protected translate: TranslateService,
  ) {
    //
  }

  ngOnInit(): void {
    this.testimonials$ = this.testimonialsService.getTestimonials$(this.limit);
  }

  public get quotationMarks(): string {
    return `${Config.imagesFolderBaseUrl}quotation-marks.svg`;
  }

  public getTitleTranslation(): string {
    if (this.membershipType > 0) {
      return this.testimonialsService.getTitleTranslation(this.membershipType);
    }

    return this.translate.instant('modules.main.pages.payment.testimonials_label');
  }

  public getFullWidthClass(): string {
    if (!this.isFullScreen) {
      return '';
    }

    return 'testimonial--full-width';
  }

  public trackByUser(_: number, testimonial: ITestimonials): string {
    return testimonial.user;
  }
}
