import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';

@Directive()
export abstract class NewPasswordComponentCommon implements OnDestroy, OnInit {
  public success = false;
  public password: string;
  public passwordConfirmation: string;
  protected subscriptions: Subscription[] = [];
  protected tmpToken = '';
  protected loading = false;

  constructor(
    public translate: TranslateService,
    protected userService: UserServiceCommon,
    protected store: Store<IApplicationState>,
    protected profileService: ProfileServiceCommon,
    protected auth: AuthenticationServiceCommon,
  ) {
    //
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('uiState').subscribe((uiState: any): void => {
        this.tmpToken = uiState.tmpToken;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  abstract alert(title: string, message: string, closeButtonTitle: string, callback?: () => void): void;

  proceed(): void {
    if (!this.validatePasswords(this.password, this.passwordConfirmation)) {
      return;
    }

    this.loading = true;

    this.updatePasswordAuth(
      this.password,
      this.passwordConfirmation,
      (_token: string): void => {
        this.success = true;

        this.alert(
          this.translate.instant('common.error'),
          this.translate.instant('modules.main.pages.change_password.password_updated'),
          this.translate.instant('common.ok'),
          (): void => {
            this.redirectSuccess();
          },
        );
      },
      (response): void => {
        if (response.status === 422) {
          this.alert(
            this.translate.instant('common.error'),
            this.translate.instant('modules.main.pages.new_password.current_equals_new_password'),
            this.translate.instant('common.ok'),
          );
        }

        this.loading = false;
      },
    );
  }

  isLoading(): boolean {
    return this.loading;
  }

  redirectSuccess(): void {
    this.auth.redirectUserToMain();
  }

  validatePasswords(newPassword: string, passwordConfirm: string): boolean {
    if (newPassword !== passwordConfirm) {
      this.alert(
        this.translate.instant('common.error'),
        this.translate.instant('modules.main.pages.change_password.mismatched_password'),
        this.translate.instant('common.ok'),
      );
      return false;
    }

    if (newPassword.length < 4) {
      this.alert(
        this.translate.instant('common.error'),
        this.translate.instant('modules.main.pages.new_password.password_minlength'),
        this.translate.instant('common.ok'),
      );
      return false;
    }

    return true;
  }

  updatePasswordAuth(
    password: string,
    passwordConfirmation: string,
    successCallback: (token: string, status: string) => void,
    errorCallback: (response: any) => void,
  ): void {
    this.profileService.updatePasswordAuth(
      password,
      passwordConfirmation,
      this.tmpToken,
      successCallback,
      errorCallback,
    );
  }
}
