<mp-advanced-modal-ui>
    <p header>
        {{ 'modules.main.pages.conversation.conversation_already_deleted.title' | translate }}
    </p>
    <p description>
        {{ 'modules.main.pages.conversation_already_deleted.description' | translate }}
    </p>
    <button
        footer
        (click)="closeModal()"
        class="btn btn-danger"
    >
        {{ 'common.close' | translate }}
    </button>
</mp-advanced-modal-ui>
