import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { VersionCheckingEffectsCommon } from '@libs/modules/main/shared/version-checking/version-checking.effects.common';

import { Config } from '@meupatrocinio/config';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class VersionCheckingEffects extends VersionCheckingEffectsCommon {
  constructor(
    protected actions$: Actions,
    protected httpClient: HttpClient,
    protected store: Store,
    protected router: Router,
    protected authenticationService: AuthenticationService,
  ) {
    super(actions$, httpClient, store, router, authenticationService);
  }

  protected requestNewestVersion(): Observable<HttpResponse<string>> {
    return this.httpClient.get(Config.v2URL + 'version.txt', {
      responseType: 'text',
      observe: 'response',
    });
  }

  protected isVersionEmpty(): boolean {
    return Config.version === '';
  }

  protected getVersion(): string {
    return Config.version;
  }
}
