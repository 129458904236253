import { createAction, props } from '@ngrx/store';

enum Actions {
  WATCH_VIEW_PROFILE = '[Profile / Notification] - Watch ViewProfile',
  WATCH_BOOKMARK = '[Profile / Notification] - Watch Bookmark',
  HANDLE_PROFILE_PUSH_NOTIFICATION = '[Profile / Notification] - Handle Profile Push Notification',
}

export const watchViewProfile = createAction(Actions.WATCH_VIEW_PROFILE);

export const watchBookmark = createAction(Actions.WATCH_BOOKMARK);

export const handleProfilePushNotification = createAction(
  Actions.HANDLE_PROFILE_PUSH_NOTIFICATION,
  props<{ profileId: number }>(),
);
