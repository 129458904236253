import { Action } from '@ngrx/store';

export const PULLREFRESH_ACTION: string = 'PULLREFRESH_ACTION';

export interface IPullRefreshActionPayload {
  refreshing: boolean;
}

export class PullRefreshAction implements Action {
  readonly type: string = PULLREFRESH_ACTION;

  constructor(public payload: IPullRefreshActionPayload) {
    //
  }
}
