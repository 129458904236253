import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileViewersBaselineServiceCommon } from '@libs/modules/boost/services/profile-viewers-baseline/profile-viewers-baseline.service.common';
import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IViewsData } from '@libs/shared/interfaces/views-data.interface';
import { IBaseProfile, IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';

@Injectable({
  providedIn: 'root',
})
export abstract class BoostLastViewersServiceCommon {
  constructor(
    protected authHttp: AuthHttpServiceCommon,
    protected authenticationService: AuthenticationServiceCommon,
    protected profileService: ProfileServiceCommon,
    protected profileViewersBaselineService: ProfileViewersBaselineServiceCommon,
  ) {
    //
  }

  protected get user(): UserCommon {
    return this.authenticationService.get();
  }

  public abstract getLastViewersProfilesFromBff$(quantityOfProfiles?: number): Observable<IProfile[]>;

  public calculateMultiplier(totalViews = 0): number {
    if (totalViews === 0) {
      return totalViews;
    }

    const profileViewersBaseline: number = this.profileViewersBaselineService.getProfileViewersBaseline(this.user);

    return this.formatMultiplier(totalViews, profileViewersBaseline) * 100;
  }

  public getViewsProfile(dataViews: IViewsData[]): IProfile[] {
    return dataViews.map((dataView: IViewsData): IProfile => dataView.profileInfo);
  }

  public formatMultiplier(totalViews: number, membershipBaseline: number): number {
    return Math.round((totalViews / membershipBaseline) * 10) / 10;
  }

  getProfilesForBubbles(profiles: IProfile[]): IProfile[] {
    return profiles
      .reverse()
      .filter((profile: IProfile): boolean => !this.profileService.isProfileBlocked(profile))
      .slice(0, 5);
  }

  public sawMeInLastBoost(lastViewersInStore: IProfile[], userProfile: IBaseProfile | undefined): boolean {
    if (typeof userProfile === 'undefined' || typeof userProfile.profile_id === 'undefined') {
      return false;
    }

    return lastViewersInStore.some((recentViewer: IProfile): boolean => {
      return recentViewer.profile_id === userProfile.profile_id;
    });
  }
}
