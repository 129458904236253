import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';

export interface IViewToastState extends EntityState<IProfile> {
  //
}

function selectProfileId(profile: IProfile): number | undefined {
  return profile.profile_id;
}

export const viewToastAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>({
  selectId: selectProfileId,
  sortComparer: false,
});

export const viewToastInitialState: IViewToastState = viewToastAdapter.getInitialState({});
