import { Injectable, Type } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IModalViewOptions } from '@libs/components/modal-view/interface/modal-view-options';
import { ModalViewEffectsCommon } from '@libs/effects/modal-view/modal-view.effects.common';
import { IApplicationState } from '@libs/store/application-state';

import { ModalViewService } from '@meupatrocinio/services/modal-view/modal-view.service';

@Injectable()
export class ModalViewEffects extends ModalViewEffectsCommon {
  constructor(
    protected actions$: Actions,
    protected store: Store<IApplicationState>,
    protected router: Router,
    protected modalViewService: ModalViewService,
  ) {
    super(actions$, store, router);
  }

  openModalView(component: Type<unknown>, options: IModalViewOptions): void {
    this.modalViewService.open(component, options);
  }

  closeModalView(): void {
    this.modalViewService.close();
  }
}
