import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { FrictionlessStep0ComponentCommon } from '@libs/modules/frictionless-registration/step0/step0.component.common';
import { IApplicationState } from '@libs/store/application-state';

@Component({
  selector: 'mp-frictionless-step0',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './step0.html',
})
export class FrictionlessStep0Component extends FrictionlessStep0ComponentCommon {
  constructor(protected store: Store<IApplicationState>) {
    super(store);
  }
}
