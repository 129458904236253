import { RouteReuseStrategy, Route, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles: Map<Route, DetachedRouteHandle> = new Map();

  public shouldDetach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (!route.routeConfig) {
      return;
    }

    this.storedRouteHandles.set(route.routeConfig, handle);
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.storedRouteHandles.get(route.routeConfig);
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || !this.storedRouteHandles.get(route.routeConfig)) {
      return null;
    }

    return this.storedRouteHandles.get(route.routeConfig);
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    if (
      current.data?.reuseRoute !== undefined &&
      future.data?.reuseRoute !== undefined &&
      current.component === future.component
    ) {
      return current.data.reuseRoute;
    }

    return future.routeConfig === current.routeConfig;
  }
}
