import { Injectable } from '@angular/core';

import * as CPF from 'gerador-validador-cpf';
import { MoipValidator } from 'moip-sdk-js';

@Injectable({
  providedIn: 'root',
})
export class PaymentHelpersService {
  public formatNumber(value: string): string {
    return value.replace(/\D+/g, '');
  }

  public formatDate(date: string): string {
    date = date.trim();

    if (this.isMMSlashYYFormat(date)) {
      return date;
    }

    if (this.isMYYYYFormat(date)) {
      return this.formatToMMSlashYY(date);
    }

    if (this.isSingleDigitMYYFormat(date)) {
      return this.formatToMMSlashYY(date, true);
    }

    return '';
  }

  protected isMMSlashYYFormat(date: string): boolean {
    return /^\d{2}\/\d{2}$/.test(date);
  }

  protected isMYYYYFormat(date: string): boolean {
    return /^\d{2}\d{2,}$/.test(date) || /^\d{2}\s*\d{2,}$/.test(date);
  }

  protected isSingleDigitMYYFormat(date: string): boolean {
    return /^\d{1}\d{2,}$/.test(date) || /^\d{1}\s*\d{2,}$/.test(date);
  }

  protected formatToMMSlashYY(date: string, prependZero = false): string {
    let month = date.substring(0, 2);

    if (prependZero) {
      month = '0' + date.substring(0, 1);
    }

    return month + '/' + date.slice(-2);
  }

  public formatCurrency(value: string): string {
    return value.replace(/\./, ',');
  }

  public getCardBrand(cardNumber: string): string {
    const brand = MoipValidator.cardType(cardNumber);

    if (brand === null) {
      return '';
    }

    return brand['brand'];
  }

  public isValidCardInput(cardNumber: string): boolean {
    return this.validInput(cardNumber);
  }

  public validInput(field: string): boolean {
    return this.exist(field) && !this.containWhiteSpace(field);
  }

  public exist(atribute: any): boolean {
    return atribute !== null && atribute !== undefined && atribute !== '';
  }

  public containWhiteSpace(data: string): boolean {
    return /\s/.test(data);
  }

  public isValidCardNumber(cardNumber: string): boolean {
    return MoipValidator.isValidNumber(cardNumber);
  }

  public isValidCVV(cvv: string, cardNumber: string): boolean {
    return MoipValidator.isSecurityCodeValid(cardNumber, cvv);
  }

  public isValidHolder(holder: string): boolean {
    return this.exist(holder);
  }

  public isValidCPFInput(cpf: string): boolean {
    return /^\d{11}$/.test(cpf);
  }

  public isValidCPFField(cpf: string): boolean {
    return CPF.validate(cpf);
  }
}
