export interface IModalComponent<T = never> {
  data: T;
}

export interface Modal {
  message: string;
  type: string;
  okCallback: () => void;
  closeCallback: () => void;
  cancelCallback: () => void;
  okText: string;
  closeText: string;
}
