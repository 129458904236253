<div [@MeltModal] class="melt-modal no-padding">
    <div class="melt-modal__box">
        <div class="melt-modal__header">
            <img class="caramel-image" src="/assets/img/caramel-dark.svg" alt="caramel" />
            <div class="melt-logo">
                <img src="/assets/img/melt-logo.svg" alt="melt logo" />
            </div>
            <button class="modal-close" type="button" (click)="closeModal()">
                <img src="/assets/img/close-icon-black.svg" />
            </button>
        </div>
        <div class="melt-modal__content">
            <div class="users-melt">
                <div class="users-melt__image">
                    <div class="user user--left">
                        <img [src]="getUserPicture(user)" />
                    </div>
                    <div class="user user--right">
                        <img [src]="getProfilePicture(dataMelt.profile)" />
                    </div>
                </div>

                <p class="users-melt__name">
                    <mp-advanced-translate key="modules.main.shared.modal_melt.users">
                        <span mp-translate-param="you">{{ 'modules.main.shared.modal_melt.you' | translate }}</span>
                        <span mp-translate-param="userMelt">{{ dataMelt.profile.username }}</span>
                    </mp-advanced-translate>
                </p>
                <p class="users-melt__description">
                    {{ 'modules.main.shared.modal_melt.description' | translate }}</p>
            </div>
        </div>
        <div class="melt-modal__footer">
            <button (click)="initConversation(dataMelt.profile)">
                {{ 'modules.main.shared.modal_melt.actions.message_now' | translate }}
            </button>
            <span (click)="closeModal()">
                {{ 'modules.main.shared.modal_melt.actions.message_later' | translate }} </span>
        </div>
    </div>
</div>
