import { HttpErrorResponse } from '@angular/common/http';
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ModalServiceCommon } from '@libs/services/modal/modal.service.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';

@Directive()
export class StepConfirmationComponentCommon implements OnInit, OnDestroy {
  @Input() confirmEmail: boolean;
  @Input() email: string;

  public emailError: string;
  public tmpToken: string;
  protected subscriptions: Subscription[] = [];

  constructor(
    protected userService: UserServiceCommon,
    protected store: Store<IApplicationState>,
    protected modalService: ModalServiceCommon,
  ) {
    //
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('uiState').subscribe((data): void => {
        this.tmpToken = data.tmpToken;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public isValidEmail(email: string): boolean {
    this.emailError = '';

    if (!UserCommon.isValidEmail(email)) {
      this.emailError = 'modules.registration.pages.registration2.email.invalid';
      return false;
    }

    this.emailError = '';
    return true;
  }

  public sendEmailAgain(event: Event): void {
    if (!this.isValidEmail(this.email)) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    this.userService.sendVerificationEmailAgain(this.email, this.tmpToken).subscribe(
      (data: any): void => {
        this.modalService.open('modules.registration.pages.confirmation.resent');
        this.tmpToken = data.token;
      },
      (responseError: HttpErrorResponse): void => {
        if (responseError.status === 400 && responseError.error.error === 'email.exists') {
          this.modalService.open('modules.registration.pages.registration2.email.taken');
          return;
        }
        this.modalService.open('common.unknown_error');
      },
    );
  }
}
