import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IConversation, ListNameConversation } from '@libs/store/conversations';

enum Actions {
  UPSERT_CONVERSATIONS = '[Conversation] - Upsert Conversations Action',
  FETCH_CONVERSATION_PEERS = '[Conversation / DownloadManager] - Fetch Conversation Peers',
  LOAD_CONVERSATIONS_FAILED = '[Conversation / API] - Error Downloading Conversations',
  SELECT_CONVERSATION = '[Conversation] - Select Conversation Action',
  REMOVE_CONVERSATION = '[Conversation / API] - Remove Conversation Action',
  DELETE_CONVERSATION = '[Conversation / API] - Delete Conversation Action',
  CONVERSATION_DELETION_SUCCESS = '[Conversation / API] - Conversation Deletion Success',
  CONVERSATION_DELETION_ERROR = '[Conversation / API] - Error Deleting Conversation',
  MARK_AS_READ = '[Conversation / API] - Mark as Read Conversation Action',
  MARK_AS_READ_BY_CONVERSATION_ID = '[Conversation / Socket.io] - Mark as Read By ConversationId Action',
  MARK_AS_READ_ERROR = '[Conversation / API] - Mark as Read Request Error',
  FETCH_UNREAD_CONVERSATIONS = '[Conversation / API] - Fetch Unread Conversations Action',
  LOAD_CONVERSATIONS = '[Conversation / API] - Load Conversations Action',
  LOAD_UNREAD_CONVERSATIONS = '[Conversation / API] - Load Unread Conversations Action',
  LOAD_UNREAD_CONVERSATIONS_ERROR = '[Conversation] - Load Unread Conversations Action Error',
  CLEAN_CONVERSATIONS = '[Conversation] - Clean Conversations Action',
  UPDATE_LAST_MESSAGE = '[Conversation] - Check Current Last Message Conversations Action',
  LOAD_ALREADY_STORED_LAST_MESSAGES = '[Conversation] - Load Already Stored Last Messages Conversations Action',
  LOAD_BULK_LAST_MESSAGES_TO_CONVERSATION = '[Conversation / API] - Load Bulk Last Messages Conversations Action',
  CHECK_EXISTS_BY_PEER_ID = '[Conversation / API] - Load conversation from API by Peer ID Action',
  RESET_CONVERSATION_LIST_PAGINATION = '[Conversation / DownloadManager] - Reset Conversation Pagination Action',
  FETCH_CONVERSATION_NEXT_PAGE = '[Conversation / DownloadManager] - Fetch Conversation Next Page Action',
  FETCHED_CONVERSATION_NEXT_PAGE = '[Conversation / DownloadManager] - Fetched Conversation Next Page',
  FETCHED_NEXT_PAGE_ERROR = '[Conversation / DownloadManager] - Error Next Page Action',
  WATCH_CONVERSATION = '[Conversation / Notification] - Watch Conversation',
  WATCH_READ_CONVERSATION = '[Conversation / Notification] - Watch Read Conversation',
  CONVERSATION_ALREADY_DELETED = '[Conversation] - Conversation Already Deleted',
}

export const upsertConversations = createAction(
  Actions.UPSERT_CONVERSATIONS,
  props<{ conversations: IConversation[] }>(),
);

export const fetchConversationPeers = createAction(
  Actions.FETCH_CONVERSATION_PEERS,
  props<{ conversations: IConversation[] }>(),
);

export const loadConversationsFailed = createAction(
  Actions.LOAD_CONVERSATIONS_FAILED,
  props<{ error: HttpErrorResponse }>(),
);

export const selectConversation = createAction(Actions.SELECT_CONVERSATION, props<{ peerId: number }>());

export const removeConversation = createAction(Actions.REMOVE_CONVERSATION, props<{ conversation: IConversation }>());

export const deleteConversation = createAction(Actions.DELETE_CONVERSATION, props<{ conversation: IConversation }>());

export const conversationDeletionSuccess = createAction(Actions.CONVERSATION_DELETION_SUCCESS);

export const conversationDeletionError = createAction(Actions.CONVERSATION_DELETION_ERROR);

export const markAsReadConversation = createAction(Actions.MARK_AS_READ);

export const markAsReadByConversationId = createAction(
  Actions.MARK_AS_READ_BY_CONVERSATION_ID,
  props<{ conversationId: number }>(),
);

export const markAsReadConversationError = createAction(
  Actions.MARK_AS_READ_ERROR,
  props<{ error: HttpErrorResponse }>(),
);

export const fetchUnreadConversations = createAction(Actions.FETCH_UNREAD_CONVERSATIONS);

export const loadConversations = createAction(Actions.LOAD_CONVERSATIONS, props<{ conversations: IConversation[] }>());

export const loadUnreadConversations = createAction(
  Actions.LOAD_UNREAD_CONVERSATIONS,
  props<{ unreadConversations: number }>(),
);

export const loadUnreadConversationsError = createAction(
  Actions.LOAD_UNREAD_CONVERSATIONS_ERROR,
  props<{ error: HttpErrorResponse }>(),
);

export const cleanConversations = createAction(Actions.CLEAN_CONVERSATIONS);

export const updateLastMessage = createAction(Actions.UPDATE_LAST_MESSAGE, props<{ conversation: IConversation }>());

export const loadStoredLastMessagesToConversation = createAction(
  Actions.LOAD_ALREADY_STORED_LAST_MESSAGES,
  props<{ conversations: IConversation[] }>(),
);

export const loadBulkLastMessagesToConversation = createAction(
  Actions.LOAD_BULK_LAST_MESSAGES_TO_CONVERSATION,
  props<{ conversations: IConversation[] }>(),
);

export const checkExistsConversationByPeerId = createAction(
  Actions.CHECK_EXISTS_BY_PEER_ID,
  props<{ peerId: number }>(),
);

export const resetConversationListPagination = createAction(
  Actions.RESET_CONVERSATION_LIST_PAGINATION,
  props<{ listName: ListNameConversation }>(),
);

export const fetchConversationNextPage = createAction(Actions.FETCH_CONVERSATION_NEXT_PAGE);

export const conversationFetched = createAction(Actions.FETCHED_CONVERSATION_NEXT_PAGE);

export const fetchConversationNextPageError = createAction(
  Actions.FETCHED_NEXT_PAGE_ERROR,
  props<{ error: HttpErrorResponse }>(),
);

export const watchConversation = createAction(Actions.WATCH_CONVERSATION);

export const watchReadConversation = createAction(Actions.WATCH_READ_CONVERSATION);

export const conversationAlreadyDeleted = createAction(Actions.CONVERSATION_ALREADY_DELETED);
