import { createAction } from '@ngrx/store';

enum Actions {
  START_VERSION_CHECKING_CYCLE = '[Main component] - Start version checking cycle',
  CHECK_VERSION = '[Main component] - Check if version is outdated',
  RELOAD_APPLICATION = '[Main component] - Reload application',
}

export const startVersionCheckingCycle = createAction(Actions.START_VERSION_CHECKING_CYCLE);

export const checkVersion = createAction(Actions.CHECK_VERSION);

export const reloadApplication = createAction(Actions.RELOAD_APPLICATION);
