import { Injectable, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { SlidableMenuServiceCommon } from '@libs/modules/main/services/slidable-menu/slidable-menu.service.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { BoostSelectors } from '@libs/store/boost';
import { closeSlidableMenu, openSlidableMenu } from '@libs/store/slidable-menu/action';

import { BoostEligibilityHelpers } from '@meupatrocinio/modules/boost/helpers/boost-eligibility-helpers';
import { UserButtonsBoostComponent } from '@meupatrocinio/modules/main/shared/user-buttons-boost/user-buttons-boost.component';
import { UserButtonsPhotoVerifyComponent } from '@meupatrocinio/modules/main/shared/user-buttons-photo-verify/user-buttons-photo-verify.component';
import { UserButtonsReferralComponent } from '@meupatrocinio/modules/main/shared/user-buttons-referral/user-buttons-referral.component';
import { SubscribedStatusComponent } from '@meupatrocinio/modules/shared/subscribed-status/subscribed-status.component';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { InjectComponentService } from '@meupatrocinio/services/inject-component.service';
import { ISlidableMenuButtons } from '@meupatrocinio/services/slidable-menu/interface/slidable-menu-buttons.interface';
import { SlidableMenuButtonComponent } from '@meupatrocinio/services/slidable-menu/interface/slidable-menu-component.type';

@Injectable({
  providedIn: 'root',
})
export class SlidableMenuService extends SlidableMenuServiceCommon {
  protected hasBoostRunningOrCredits = false;

  protected subscriptions: Subscription[] = [];

  constructor(
    protected auth: AuthenticationService,
    protected injectComponentService: InjectComponentService,
    protected store: Store,
  ) {
    super();

    this.subscribeToHasBoostRunningOrCredits();
  }

  protected subscribeToHasBoostRunningOrCredits(): void {
    const hasBoostRunningOrCredits$: Observable<boolean> = this.store.pipe(
      select(BoostSelectors.selectHasBoostRunningOrBoostCredits),
    );

    this.subscriptions.push(
      hasBoostRunningOrCredits$.subscribe({
        next: (hasBoostRunningOrCredits: boolean): void => {
          this.hasBoostRunningOrCredits = hasBoostRunningOrCredits;
        },
      }),
    );
  }

  public openSlidableMenu() {
    this.store.dispatch(openSlidableMenu());
  }

  public closeSlidableMenu() {
    this.store.dispatch(closeSlidableMenu());
  }

  public injectSubscribedStatus(viewContainerReference: ViewContainerRef): void {
    this.injectComponentService.inject(SubscribedStatusComponent, viewContainerReference, undefined);
  }

  protected get user(): UserCommon {
    return this.auth.get();
  }

  protected isAuthenticated(): boolean {
    return this.auth.isAuthenticated();
  }

  protected slidableMenuButtons(): ISlidableMenuButtons[] {
    return [
      {
        check: (): boolean =>
          this.isActive() &&
          (this.isDaddyElegibleForBoostButton() || this.isBabyElegibleForBoostButton()) &&
          BoostEligibilityHelpers.canUseBoost(this.hasBoostRunningOrCredits),
        component: UserButtonsBoostComponent,
      },
      {
        check: (): boolean => UserCommon.isBaby(this.user) && !this.hasPhotoVerified(),
        component: UserButtonsPhotoVerifyComponent,
      },
      {
        check: (): boolean => this.isAuthenticated(),
        component: UserButtonsReferralComponent,
      },
    ];
  }

  protected injectButtons(validButtonsOnMenu: SlidableMenuButtonComponent[], viewContainerReference: ViewContainerRef) {
    this.injectComponentService.injectMultiple(validButtonsOnMenu, viewContainerReference);
  }

  public getValidSlidableMenuButtons(): SlidableMenuButtonComponent[] {
    const buttons = [];

    for (const item of this.slidableMenuButtons()) {
      if (item.check()) {
        buttons.push(item.component);
      }
    }

    return buttons;
  }

  protected hasPhotoVerified(): boolean {
    return UserCommon.isPhotoVerified(this.user);
  }

  protected isActive(): boolean {
    return this.auth.isActive();
  }

  protected isDaddyElegibleForBoostButton(): boolean {
    return BoostEligibilityHelpers.isDaddyEligibleToBoost(this.user);
  }

  protected isBabyElegibleForBoostButton(): boolean {
    return BoostEligibilityHelpers.isBabyEligibleToBoost(this.user);
  }

  public injectUserButtons(
    validButtonsOnMenu: SlidableMenuButtonComponent[],
    viewContainerReference: ViewContainerRef,
  ): void {
    if (!this.user) {
      return;
    }

    this.injectButtons(validButtonsOnMenu, viewContainerReference);
  }
}
