import { TranslateService } from '@ngx-translate/core';

import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { MembershipType } from '@libs/shared/membership/membership.common';

import { ModalService } from '@meupatrocinio/services/modal.service';

export class CPFValidationHandler {
  constructor(
    private readonly paymentHelpersService: PaymentHelpersService,
    private readonly modalService: ModalService,
    private readonly translateService: TranslateService,
    private readonly type: MembershipType,
    private readonly period: number,
  ) {}

  public isExistingCPF(cpf: string): boolean {
    if (!this.paymentHelpersService.exist(cpf)) {
      this.whenCPFNotExist();
      return false;
    }

    return true;
  }

  public isValidInput(cpf: string): boolean {
    if (!this.paymentHelpersService.isValidCPFInput(cpf)) {
      this.whenCPFInvalidInput();
      return false;
    }

    return true;
  }

  public isValidField(cpf: string): boolean {
    if (!this.paymentHelpersService.isValidCPFField(cpf)) {
      this.whenCPFInvalidNumber();
      return false;
    }

    return true;
  }

  public isNotTrialPayment(): boolean {
    if (PaymentCommon.isTrialPayment(this.type, this.period)) {
      this.whenIsTrialPayment();
      return false;
    }

    return true;
  }

  private whenCPFNotExist() {
    this.modalService.open(this.translateService.instant('modules.main.pages.payment.cpf.error.missing'));
  }

  private whenCPFInvalidInput() {
    this.modalService.open(this.translateService.instant('modules.main.pages.payment.cpf.error.invalid_input'));
  }

  private whenCPFInvalidNumber() {
    this.modalService.open(this.translateService.instant('modules.main.pages.payment.cpf.error.invalid'));
  }

  private whenIsTrialPayment() {
    this.modalService.open(this.translateService.instant('common.load_error'));
  }
}
