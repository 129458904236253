import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { IProfilePhotos } from '@libs/shared/profile/photo';
import { IProfile } from '@libs/shared/profile/profile';

import { Config } from '@meupatrocinio/config';
import { PhotoVisualizationActions } from '@meupatrocinio/effects/photo-visualization/actions/actions';
import { IPhotoVisualizationType } from '@meupatrocinio/effects/photo-visualization/interface/photo-visualization.interface';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable()
export class PhotoVisualizationService {
  public REGEX_PHOTO_ID = /\/\d+\/(\d+)\//;
  protected photosViewed: Set<number> = new Set();
  protected profilePhotoId = 0;

  constructor(
    protected store: Store,
    protected authHttp: AuthHttpService,
  ) {
    //
  }

  public setProfilePhotoId(profile: IProfile) {
    if (!profile || !profile.photo) {
      return;
    }

    this.profilePhotoId = profile.photo.photo_id;
  }

  protected hasPhotoViewAlreadyBeenCounted(photoId: number) {
    return this.photosViewed.has(photoId);
  }

  public clearPhotoViewsCounter() {
    this.photosViewed.clear();
  }

  protected countPhotoView(photoId: number) {
    this.photosViewed.add(photoId);
  }

  protected getPhotoId(imageUrl: string) {
    const id = imageUrl.match(this.REGEX_PHOTO_ID)[1];

    return Number(id);
  }

  public handleEvent(photo: IProfilePhotos, visualizationType: IPhotoVisualizationType, profileId: number) {
    if (!photo.imageUrl) {
      return;
    }

    const photoId = this.getPhotoId(photo.imageUrl);

    if (this.hasPhotoViewAlreadyBeenCounted(photoId)) {
      return;
    }

    this.countPhotoView(photoId);
    this.store.dispatch(
      PhotoVisualizationActions.handlePhotoVisualization({
        photoId: photoId,
        visualizationType,
        profileId,
      }),
    );
  }

  public photoVisualization({
    photoId,
    type,
    profileId,
  }: {
    photoId: number;
    type: IPhotoVisualizationType;
    profileId: number;
  }) {
    const endpoint = `${Config.serverIp}v1/metrics/photo-visualization`;

    return this.authHttp.post(endpoint, {
      photoId,
      type,
      profileId,
    });
  }
}
