import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PaymentLoadingComponentCommon } from '@libs/modules/main/shared/payment-loading/payment-loading.component.common';

@Component({
  selector: 'mp-payment-loading',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: 'payment-loading.html',
})
export class PaymentLoadingComponent extends PaymentLoadingComponentCommon {
  @Input() loadingText = 'common.payment.processing';
}
