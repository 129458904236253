import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EmailUnsubscribeErrorComponentCommon } from '@libs/modules/initial/email-unsubscribe-error/email-unsubscribe-error.component.common';

import { EmailUnsubscribeAnimations } from '@meupatrocinio/modules/initial/pages/email-unsubscribe/email-unsubscribe.animations';

@Component({
  selector: 'mp-email-unsubscribe-error',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './email-unsubscribe-error.html',
  animations: [EmailUnsubscribeAnimations.slideToggle],
})
export class EmailUnsubscribeErrorComponent extends EmailUnsubscribeErrorComponentCommon {
  //
}
