import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';

import { AlertService } from '@meupatrocinio/services/alert.service';
import { UserService } from '@meupatrocinio/services/user.service';

interface IModalData {
  closeCallback?(): void;
}

@Component({
  selector: 'mp-forgot-password-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './forgot-password-modal.html',
})
export class ForgotPasswordModalComponent implements IModalComponent<IModalData> {
  public forgotPasswordOpen: boolean;
  public forgotPasswordEmail: string;
  public isLoading: boolean;

  constructor(
    protected userService: UserService,
    public translate: TranslateService,
    protected alertService: AlertService,
    protected modalRef: ModalRef,
    @Inject(MODAL_DATA) public data: IModalData,
  ) {
    //
  }

  forgotPasswordClose(): void {
    this.modalRef.close();

    if (this.data && this.data.closeCallback) {
      this.data.closeCallback();
    }
  }

  forgotPasswordSubmit(): void {
    this.alertService.clear();
    this.isLoading = true;
    this.userService.forgotPassword(this.forgotPasswordEmail).subscribe(
      (): void => {
        this.isLoading = false;
        this.forgotPasswordClose();
        setTimeout((): void => {
          this.alertService.success(
            this.translate.instant('modules.initial.pages.login.forgot_password.recovery_sent', {
              email: this.forgotPasswordEmail,
            }),
          );
        }, 10);
      },
      (err: string): void => {
        this.isLoading = false;
        this.alertService.error(err);
      },
    );
  }
}
