import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { concatMap, tap, withLatestFrom } from 'rxjs/operators';

import { MembershipType } from '@libs/shared/membership/membership.common';
import { IApplicationState } from '@libs/store/application-state';
import { MembershipActions, MembershipSelectors } from '@libs/store/membership';

export abstract class MembershipEffectsCommon {
  paidMembershipChange$: Observable<[Action, MembershipType]> = createEffect(
    (): Observable<[Action, MembershipType]> =>
      this.actions$.pipe(
        ofType(MembershipActions.latestPaidMembershipChange),
        concatMap((action: Action): Observable<[Action, MembershipType]> => {
          return of(action).pipe(
            withLatestFrom(this.store.pipe(select(MembershipSelectors.selectLatestPaidMembership))),
          );
        }),
        tap(([_, latestPaidMembership]: [Action, MembershipType]): void => {
          this.handlePaidMembershipChange(latestPaidMembership);
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected store: Store<IApplicationState>,
  ) {
    //
  }

  protected abstract handlePaidMembershipChange(latestPaidMembership: MembershipType | null): void;
}
