import { Store } from '@ngrx/store';

import { IApplicationState } from '@libs/store/application-state';
import { closeSlidableMenu } from '@libs/store/slidable-menu/action';

export class UserButtonsBoostComponentCommon {
  constructor(protected store: Store<IApplicationState>) {
    //
  }

  closeSlidableMenu(): void {
    this.store.dispatch(closeSlidableMenu());
  }
}
