import { IUnsafeAction } from '@libs/store/application-state';
import { AUTH_TOKEN_ACTION } from '@libs/store/ui/actions/auth-token.action';
import { SHOW_HIDE_FEEDBACK_NAGBAR_ACTION } from '@libs/store/ui/actions/feedback-nagbar.action';
import { IMAGE_TAPPED_ACTION } from '@libs/store/ui/actions/image-tapped.action';
import { LIST_VIEW_MODE_ACTION } from '@libs/store/ui/actions/list-view-mode.action';
import { MAINTENANCE_ACTION } from '@libs/store/ui/actions/maintenance.action';
import { SHOW_HIDE_MP_SAFE_TIP_NAGBAR_ACTION } from '@libs/store/ui/actions/mp-safe-tip-nagbar.action';
import { SHOW_HIDE_PHOTO_VERIFY_NAGBAR_ACTION } from '@libs/store/ui/actions/photo-verify-nagbar.action';
import { PING_ACTION } from '@libs/store/ui/actions/ping.action';
import { PREVIOUS_PROFILE_ID_ACTION } from '@libs/store/ui/actions/previous-profile-id.action';
import { PROFILE_ALBUM_ACCESS_DELETE_ADD } from '@libs/store/ui/actions/profile-album-access-delete-add.action';
import { PROFILE_ALBUM_ACCESS_DELETE_REMOVE } from '@libs/store/ui/actions/profile-album-access-delete-remove.action';
import { PULLREFRESH_ACTION } from '@libs/store/ui/actions/pullrefresh.action';
import {
  DISPLAYING_SEARCH_RESULT_ACTION,
  REMOVE_SEARCH_ACTION,
  SAVED_SEARCH_RECEIVED_ACTION,
  SEARCH_ACTION,
  SET_CURRENT_SEARCH_NAME_ACTION,
} from '@libs/store/ui/actions/search.action';
import { ALREADY_SENT_FREE_MSG_ACTION } from '@libs/store/ui/actions/sent-free-msg.action';
import { SUPPORT_PARAMS_ACTION } from '@libs/store/ui/actions/support-params.action';
import { INITIAL_UI_STATE, IUiState } from '@libs/store/ui/ui-state';
import { arrayUnique } from '@libs/utils/array-functions';

export function uiState(state: IUiState = INITIAL_UI_STATE, action: IUnsafeAction): IUiState {
  switch (action.type) {
    case AUTH_TOKEN_ACTION:
      return Object.assign({}, state, {
        tmpToken: action.payload.tmpToken,
      });
    case PROFILE_ALBUM_ACCESS_DELETE_ADD:
      return onProfileIdsAlbumAccessDeleteAddReceived(state, action.payload.profileAlbumAccessToDelete);
    case PROFILE_ALBUM_ACCESS_DELETE_REMOVE:
      return onProfileIdsAlbumAccessDeleteRemoveReceived(state, action.payload.profileAlbumAccessToDelete);
    case PREVIOUS_PROFILE_ID_ACTION:
      return Object.assign({}, state, {
        previousProfileId: action.payload.previousProfileId,
      });
    case IMAGE_TAPPED_ACTION:
      return Object.assign({}, state, {
        imageTapped: action.payload.image,
      });
    case PULLREFRESH_ACTION:
      return Object.assign({}, state, {
        refreshingStatus: action.payload.refreshing,
      });
    case SEARCH_ACTION:
      return Object.assign({}, state, {
        profileSearch: Object.assign({}, action.payload, {
          basic_filter: action.payload.basic_filter,
        }),
        displayingSearchResult: true,
      });
    case REMOVE_SEARCH_ACTION:
      return onRemoveSearchReceived(state, action.payload);
    case SET_CURRENT_SEARCH_NAME_ACTION:
      return Object.assign({}, state, {
        currentSearchName: action.payload,
      });
    case DISPLAYING_SEARCH_RESULT_ACTION:
      return Object.assign({}, state, {
        displayingSearchResult: action.payload,
      });
    case SAVED_SEARCH_RECEIVED_ACTION:
      return onSavedSearchReceived(state, action.payload.savedSearches);
    case MAINTENANCE_ACTION:
      return Object.assign({}, state, {
        maintenance: action.payload,
      });
    case ALREADY_SENT_FREE_MSG_ACTION:
      return Object.assign({}, state, {
        alreadySentFreeMsg: action.payload,
      });
    case SHOW_HIDE_PHOTO_VERIFY_NAGBAR_ACTION:
      return Object.assign({}, state, {
        photoVerifyHidden: action.payload,
      });
    case SHOW_HIDE_MP_SAFE_TIP_NAGBAR_ACTION:
      return Object.assign({}, state, {
        mpSafeTipNagbarHidden: action.payload,
      });
    case SHOW_HIDE_FEEDBACK_NAGBAR_ACTION:
      return Object.assign({}, state, {
        feedbackHidden: action.payload,
      });
    case SUPPORT_PARAMS_ACTION:
      return Object.assign({}, state, {
        supportParams: action.payload,
      });
    case LIST_VIEW_MODE_ACTION:
      return Object.assign({}, state, {
        listViewMode: action.payload,
      });
    case PING_ACTION:
      console.log('ping');
      break;
    default:
      return state;
  }

  return state;
}

function onRemoveSearchReceived(state: IUiState, savedSearchId: number): IUiState {
  const searchToRemove = state.savedSearches.filter((search): boolean => search.id === savedSearchId)[0];
  if (searchToRemove.name === state.currentSearchName) {
    state.currentSearchName = '';
  }

  return Object.assign({}, state, {
    savedSearches: state.savedSearches.filter((search): boolean => search.id !== savedSearchId),
    currentSearchName: state.currentSearchName,
  });
}

function onSavedSearchReceived(state: IUiState, savedSearches: any[]): IUiState {
  return Object.assign({}, state, {
    savedSearches: arrayUnique(
      [...savedSearches, ...state.savedSearches],
      (itemA, itemB): boolean => itemA.id === itemB.id,
    ),
  });
}

function onProfileIdsAlbumAccessDeleteAddReceived(state: IUiState, profileIds: number[]): IUiState {
  for (const profileId of profileIds) {
    state = Object.assign({}, state, {
      profileAlbumAccessToDelete: onProfileIdAlbumAccessDeleteAddReceived(state.profileAlbumAccessToDelete, profileId),
    });
  }

  return state;
}

function onProfileIdAlbumAccessDeleteAddReceived(state: number[], profileId: number): number[] {
  const index: number = state.indexOf(profileId);
  if (index !== -1) {
    return state;
  }

  return [...state, profileId];
}

function onProfileIdsAlbumAccessDeleteRemoveReceived(state: IUiState, profileIds: number[]): IUiState {
  for (const profileId of profileIds) {
    state = Object.assign({}, state, {
      profileAlbumAccessToDelete: onProfileIdAlbumAccessDeleteRemoveReceived(
        state.profileAlbumAccessToDelete,
        profileId,
      ),
    });
  }

  return state;
}

function onProfileIdAlbumAccessDeleteRemoveReceived(state: number[], profileId: number): number[] {
  const index: number = state.indexOf(profileId);
  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
