import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import { IPushParameters } from '@libs/store/push-notification/interface';

enum Actions {
  REQUEST_PERMISSION = '[Push Notification] - Request Browser Permission',
  PERSIST_PERMISSION = '[Push Notification / Cookie] - Persist Browser Permission',
  TOGGLE_PERMISSION = '[Push Notification] - Toggle Permission',
  DOWNLOAD_PROFILE_BEFORE_PUSH_NOTIFICATION = '[Push Notification] - Download Profile Before Push Notification',
  PREPARE_PROFILE_TO_PUSH_NOTIFICATION = '[Push Notification] - Prepare Profile to Push Notification',
  CREATE_PROFILE_PUSH = '[Push Notification] - Create Profile Push',
  CREATE_MOBILE_PUSH_NOTIFICATION = '[Push Notification] - Create Mobile Push Notification',
  CREATE_PUSH_NOTIFICATION = '[Push Notification] - Create Push Notification',
  PUSH_NOTIFICATION_CREATED = '[Push Notification] - Push Notification Created',
}

export const requestPermission = createAction(Actions.REQUEST_PERMISSION);

export const persistPermission = createAction(
  Actions.PERSIST_PERMISSION,
  props<{ permission: NotificationPermission }>(),
);

export const togglePermission = createAction(Actions.TOGGLE_PERMISSION);

export const createProfilePush = createAction(
  Actions.CREATE_PROFILE_PUSH,
  props<{ profileId: number; parameters: IPushParameters }>(),
);

export const downloadProfileBeforePushNotification = createAction(
  Actions.DOWNLOAD_PROFILE_BEFORE_PUSH_NOTIFICATION,
  props<{ profileId: number; parameters: IPushParameters }>(),
);

export const prepareProfileToPush = createAction(
  Actions.PREPARE_PROFILE_TO_PUSH_NOTIFICATION,
  props<{ profile: IProfile; parameters: IPushParameters }>(),
);

export const createPushNotification = createAction(
  Actions.CREATE_PUSH_NOTIFICATION,
  props<{ parameters: IPushParameters }>(),
);

export const createMobilePushNotification = createAction(
  Actions.CREATE_MOBILE_PUSH_NOTIFICATION,
  props<{ parameters: IPushParameters }>(),
);

export const pushNotificationCreated = createAction(Actions.PUSH_NOTIFICATION_CREATED);
