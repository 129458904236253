import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Config } from '@meupatrocinio/config';

@Injectable({
  providedIn: 'root',
})
export class RegV5Guard {
  constructor(protected router: Router) {
    //
  }

  public canLoad() {
    if (!Config.isRegV5Enabled) {
      return this.router.createUrlTree(['/cadastro']);
    }

    return true;
  }
}
