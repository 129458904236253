import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';

import { UserCommon } from '@libs/shared/user/user.common';

import { CustomerIOActions } from '@meupatrocinio/infra/customer-io/actions';
import { CustomerIO } from '@meupatrocinio/infra/customer-io/customer-io';
import { ICustomerIOUserData } from '@meupatrocinio/infra/customer-io/interfaces/customer-io-user-data.interface';
import { CustomerIOEligibilityService } from '@meupatrocinio/infra/customer-io/services/customer-io-eligibility.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerIOService {
  public customerIO: CustomerIO = new CustomerIO();

  constructor(
    protected authenticationService: AuthenticationService,
    protected customerIOEligibilityService: CustomerIOEligibilityService,
    protected store: Store,
    @Inject(DOCUMENT) protected document: Document,
  ) {
    //
  }

  protected get user() {
    return this.authenticationService.get();
  }

  public identify(customerData: ICustomerIOUserData) {
    const user = this.authenticationService.get();

    if (!this.isCustomerIOAvailable() || !UserCommon.isConnected(user)) {
      return;
    }

    this.customerIO.client.identify({
      ...customerData,
    });
  }

  public sendPageView(page: string, customerData: ICustomerIOUserData) {
    this.customerIO.client.page(page, {
      ...customerData,
    });
  }

  public loadScript(renderer: Renderer2) {
    if (!this.isEligible()) {
      return;
    }

    if (this.isCustomerIOTrackerAlreadyInstantiated()) {
      this.store.dispatch(CustomerIOActions.handleDataInitializationAfterRelogin());

      return;
    }

    this.appendCustomerIOSnippet(renderer);
  }

  protected appendCustomerIOSnippet(renderer: Renderer2) {
    const scriptElement: HTMLScriptElement = renderer.createElement('script');
    const scriptSrc = './customer-io.js';

    scriptElement.type = 'text/javascript';
    scriptElement.src = scriptSrc;

    renderer.appendChild(this.document.body, scriptElement);

    scriptElement.onload = (): void => {
      this.store.dispatch(CustomerIOActions.handleDataInitialization());
    };
  }

  protected isCustomerIOTrackerAlreadyInstantiated() {
    const customerIOTracker: HTMLScriptElement | null = this.document.head.querySelector('#cio-tracker');

    return customerIOTracker !== null;
  }

  public canLoadCustomerIO() {
    return this.isCustomerIOAvailable() && this.isEligible();
  }

  public isCustomerIOAvailable() {
    return this.customerIO.client !== undefined;
  }

  protected isEligible() {
    return this.customerIOEligibilityService.isProfileEligibleForCustomerIO(this.user);
  }
}
