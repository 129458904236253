import { MembershipType } from '@libs/shared/membership/membership.common';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';

export const DEFAULT_SELECTED_PLANS: {
  [key: string]: ProductVariantIdentifier;
} = {
  [MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM]: ProductVariantIdentifier.DADDY_PREMIUM_3_MONTHS,
  [MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE]: ProductVariantIdentifier.DADDY_ELITE_3_MONTHS,
  [MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM]: ProductVariantIdentifier.BABY_PREMIUM_3_MONTHS,
};

export const DEFAULT_SELECTED_NEW_PLANS: {
  [key: string]: ProductVariantIdentifier;
} = {
  [MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM]:
    ProductVariantIdentifier.MEMBERSHIP_DADDY_PREMIUM_THREE_MONTHS_AB_TEST,
  [MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE]: ProductVariantIdentifier.DADDY_ELITE_3_MONTHS,
  [MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM]: ProductVariantIdentifier.BABY_PREMIUM_3_MONTHS,
};

export const NEW_PLANS = {
  '116': [
    {
      membership_type_id: 116,
      price: 1495,
      period: 6,
      units: 'months',
      uuid: 'cf5e6372-ac48-4269-9cb7-b79557c9ca2e',
      prize_uuid: null,
      preferred_plan: false,
    },
    {
      membership_type_id: 116,
      price: 749,
      period: 3,
      units: 'months',
      uuid: '0fe7fed8-0f79-43f9-9b28-a13a5251b5d7',
      prize_uuid: null,
      preferred_plan: true,
    },
    {
      membership_type_id: 116,
      price: 299,
      period: 1,
      units: 'months',
      uuid: '78d1f94d-e5cb-4894-bd4b-3322ed10fac9',
      prize_uuid: null,
      preferred_plan: false,
    },
  ],
  '120': [
    {
      membership_type_id: 120,
      price: 4794,
      period: 6,
      units: 'months',
      uuid: '11c56322-6e5b-4c07-b6de-ee378bef0fe9',
      prize_uuid: null,
      preferred_plan: false,
    },
    {
      membership_type_id: 120,
      price: 2697,
      period: 3,
      units: 'months',
      uuid: '44ddf0e9-ca53-4adb-8cd0-e3a24b7858d5',
      prize_uuid: null,
      preferred_plan: true,
    },
    {
      membership_type_id: 120,
      price: 999,
      period: 1,
      units: 'months',
      uuid: '35c95102-8630-4f5f-aee1-bed41e801036',
      prize_uuid: null,
      preferred_plan: false,
    },
  ],
};
