import { ILoginTokenData } from '@libs/shared/interfaces/login-token-data.interface';
import {
  ITokenReceivedActionPayload,
  TokenReceivedAction,
  TOKEN_RECEIVED_ACTION,
} from '@libs/store/authentication/actions/token.action';

export const INITIAL_TOKEN_STATUS: ILoginTokenData = {
  token: '',
};

export function token(state: ILoginTokenData = INITIAL_TOKEN_STATUS, action: TokenReceivedAction): ILoginTokenData {
  switch (action.type) {
    case TOKEN_RECEIVED_ACTION:
      return onTokenReceived(action.payload);
    default:
      return state;
  }
}

function onTokenReceived(payload: ITokenReceivedActionPayload): ILoginTokenData {
  return Object.assign(
    {},
    {
      token: payload.token,
    },
  );
}
