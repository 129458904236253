<div
    class="slidable-menu-bar inertial"
    [@slideToggle]="animationState"
    (@slideToggle.done)="onToggleFinished($event)"
>
    <div class="slidable-menu-bar__user-header">
        <mp-user-avatar></mp-user-avatar>
        <mp-close-menu-button></mp-close-menu-button>
    </div>
    <div class="slidable-menu-bar__subscribed-status">
        <ng-template #subscribedStatusContainer></ng-template>
    </div>
    <div class="slidable-menu-bar__user-buttons">
        <ng-template #userButtonsContainer></ng-template>
    </div>
    <div class="slidable-menu-bar__actions">
        <div class="menu-separator"></div>
        <mp-menu-actions></mp-menu-actions>
    </div>
    <div class="slidable-menu-bar__bottom">
        <mp-social-media-list></mp-social-media-list>
    </div>
</div>
