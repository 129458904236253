import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { concatMap, delay, filter, map, retryWhen, take, tap } from 'rxjs/operators';

import { ABTestsActions } from '@libs/store/ab-tests/';

import { IABTest } from '@meupatrocinio/modules/ab-tests/interfaces/ab-test.interface';
import { IRegisterSavedABTestAction } from '@meupatrocinio/modules/ab-tests/interfaces/register-saved-ab-test-action.interface';
import { ISaveABTestTreatmentAction } from '@meupatrocinio/modules/ab-tests/interfaces/save-ab-test-treatment-action.interface';
import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';
import { ABTestsService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-service/ab-tests.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class ABTestsEffects {
  protected readonly RETRY_DELAY_IN_MILLISECONDS: number = 5000;
  protected readonly RETRY_ATTEMPTS_LIMIT: number = 5;

  protected testsBeingSaved: string[] = [];
  protected savedTests: string[] = [];
  protected testTreatments: { [key: string]: string } = {};

  saveTestTreatment$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ABTestsActions.saveTestTreatment),
        filter(({ testName }: ISaveABTestTreatmentAction): boolean => {
          const isSaving: boolean = this.testsBeingSaved.includes(testName);
          const isSaved: boolean = this.savedTests.includes(testName);

          if (isSaving) {
            return false;
          }

          if (!isSaved) {
            this.testsBeingSaved.push(testName);
          }

          return !isSaved;
        }),
        concatMap((action: ISaveABTestTreatmentAction): Observable<Action> => {
          return this.abTestsService.saveTestTreatmentOnDatabase$(action.testName, action.treatment).pipe(
            map((): Action => {
              return ABTestsActions.registerSavedTest({
                testName: action.testName,
              });
            }),
            retryWhen((error: Observable<string>): Observable<string> => {
              return error.pipe(delay(this.RETRY_DELAY_IN_MILLISECONDS), take(this.RETRY_ATTEMPTS_LIMIT));
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  registerSavedTest$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ABTestsActions.registerSavedTest),
        tap({
          next: ({ testName }: IRegisterSavedABTestAction): void => {
            this.handleFlowControlsOnSuccessfulSave(testName);
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  resetFeatureOnLogout$: Observable<void> = createEffect(
    (): Observable<void> =>
      this.authenticationService.onLogout$.pipe(
        tap({
          next: (): void => {
            this.clearEffectFlowControls();
            this.abTestsService.deactivate();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  activateFeatureOnLogin$: Observable<void> = createEffect(
    (): Observable<void> =>
      this.authenticationService.onLogin$.pipe(
        tap({
          next: (): void => {
            if (this.abTestsService.isServiceInitialized()) {
              return;
            }

            this.abTestsService.activate();
            this.abTestsLoaderService.loadActiveTests();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected abTestsService: ABTestsService,
    protected abTestsLoaderService: ABTestsLoaderService,
    protected authenticationService: AuthenticationService,
  ) {
    //
  }

  protected clearEffectFlowControls(): void {
    this.testsBeingSaved = [];
    this.savedTests = [];
  }

  protected handleFlowControlsOnSuccessfulSave(testName: string): void {
    this.testsBeingSaved = this.testsBeingSaved.filter((savedTestName: string): boolean => savedTestName !== testName);
    this.savedTests.push(testName);
  }

  protected updateABTestTreatments(testTreatment: IABTest) {
    if (!testTreatment) {
      return null;
    }

    return {
      ...this.testTreatments,
      [testTreatment.name]: testTreatment.treatment,
    };
  }
}
