import { Action, createReducer, on } from '@ngrx/store';

import {
  setHasDoneProfileSwipe,
  setIsSwipingOnNearby,
  setIsSwipingProfiles,
  setLatestSwipeListName,
  setListScrollPosition,
  setProfileSwipeTest,
} from '@libs/store/profile-swipe/actions';
import { IProfileSwipeState } from '@libs/store/profile-swipe/interfaces/profile-swipe-state.interface';
import { profileSwipeInitialState } from '@libs/store/profile-swipe/state';

const profileSwipeReducer = createReducer(
  profileSwipeInitialState,
  on(
    setIsSwipingProfiles,
    (state: IProfileSwipeState, { isSwipingProfiles }: { isSwipingProfiles: boolean }): IProfileSwipeState => {
      return {
        ...state,
        isSwipingProfiles,
      };
    },
  ),
  on(
    setLatestSwipeListName,
    (state: IProfileSwipeState, { latestSwipeListName }: { latestSwipeListName: string }): IProfileSwipeState => {
      return {
        ...state,
        latestSwipeListName,
      };
    },
  ),
  on(
    setIsSwipingOnNearby,
    (state: IProfileSwipeState, { isSwipingOnNearby }: { isSwipingOnNearby: boolean }): IProfileSwipeState => {
      return {
        ...state,
        isSwipingOnNearby,
      };
    },
  ),
  on(
    setHasDoneProfileSwipe,
    (state: IProfileSwipeState, { hasDoneProfileSwipe }: { hasDoneProfileSwipe: boolean }): IProfileSwipeState => {
      return {
        ...state,
        hasDoneProfileSwipe,
      };
    },
  ),
  on(
    setProfileSwipeTest,
    (state: IProfileSwipeState, { hasProfileSwipeTest }: { hasProfileSwipeTest: boolean }): IProfileSwipeState => {
      return {
        ...state,
        hasProfileSwipeTest,
      };
    },
  ),
  on(
    setListScrollPosition,
    (state: IProfileSwipeState, { listScrollPosition }: { listScrollPosition: number }): IProfileSwipeState => {
      return {
        ...state,
        listScrollPosition,
      };
    },
  ),
);

export function reducer(state: IProfileSwipeState, action: Action): IProfileSwipeState {
  return profileSwipeReducer(state, action);
}
