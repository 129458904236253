import { Widths } from '@libs/utils/widths';

export class ScreenSizeHelpers {
  public static isMediumScreenOrLarger(): boolean {
    return window.innerWidth >= Widths.MD;
  }

  public static isMobile(): boolean {
    return window.innerWidth < Widths.MD;
  }

  public static isMediumOrSmallScreen(): boolean {
    return window.innerWidth <= Widths.SM;
  }

  public static isSmallScreen(): boolean {
    return window.innerWidth < Widths.SM;
  }
}
