import { Injectable } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectorFacadeService {
  constructor(private readonly deviceService: DeviceDetectorService) {
    //
  }

  public getDeviceInfo() {
    return this.deviceService.getDeviceInfo();
  }
}
