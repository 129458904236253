<div
    class="step"
    id="step05"
>
    <div class="form-field feedback">
        <label for="presentation">
            {{ 'modules.main.pages.edit_profile.headline' | translate }}
        </label>
        <textarea
            id="presentation"
            #headlineElement
            [placeholder]="'modules.frictionless.presentation.placeholder' | translate"
            maxlength="128"
            name="headline"
            [(ngModel)]="headline"
            (input)="resizeTextarea($event.target); checkField('headline')"
            (blur)="checkField('headline', true)"
            required
        ></textarea>
        <small [ngClass]="{ ok: headlineOk }">{{ headlineError | translate }}</small>
    </div>
    <div class="form-field feedback">
        <label for="description">
            {{ 'modules.main.pages.edit_profile.general_description.hint' | translate }}
        </label>
        <textarea
            class="min-height"
            id="description"
            #descriptionElement
            [placeholder]="'modules.main.pages.edit_profile.general_description.placeholder' | translate"
            name="description"
            [(ngModel)]="description"
            (input)="resizeTextarea($event.target); checkField('description')"
            (blur)="checkField('description', true)"
            required
        ></textarea>
        <small [ngClass]="{ ok: descriptionOk }">{{ descriptionError | translate }}</small>
    </div>
    <div class="form-field feedback">
        <label for="happyToTravel">
            {{ 'modules.frictionless.happy_to_travel' | translate }}
        </label>
        <select
            id="happyToTravel"
            name="happyToTravel"
            [(ngModel)]="happyToTravel"
            [ngClass]="{ 'placeholder-selected': happyToTravel === '' && !isDropdownOpen }"
            (focus)="toggleDropdown()"
            (blur)="toggleDropdown()"
        >
            <option value="" disabled selected hidden>
                {{ 'modules.frictionless.select_option' | translate }}
            </option>
            <option
                *ngFor="let opt of happyToTravelOptions()"
                [value]="opt"
            >
                {{ opt | translate }}
            </option>
        </select>
        <small [ngClass]="{ ok: happyToTravelOk }">{{ happyToTravelError | translate }}</small>
    </div>
    <div class="form-field feedback">
        <label for="lookingfor">
            {{ 'modules.main.pages.edit_profile.description' | translate }}
        </label>
        <textarea
            class="min-height"
            id="lookingfor"
            #interestDescriptionElement
            [placeholder]="'modules.frictionless.interest_description.placeholder' | translate"
            name="interestDescription"
            [(ngModel)]="interestDescription"
            (input)="resizeTextarea($event.target); checkField('interestDescription')"
            (blur)="checkField('interestDescription', true)"
            required
        ></textarea>
        <small class="warning">
            {{ 'modules.frictionless.interest_description.help' | translate }}
        </small>
        <small [ngClass]="{ ok: interestDescriptionOk }">
            {{ interestDescriptionError | translate }}
        </small>
    </div>
    <div class="buttons-container">
        <button
            id="registration-step5-button-save-continue"
            class="mp-main-button bt-shadow bt-lg"
            [disabled]="!canContinue()"
            (click)="skippingWithoutPhotoConfirmation()"
        >
            {{ 'modules.frictionless.finish_registration' | translate }}
        </button>
        <button
            id="registration-step5-button-finish-later"
            class="text-link"
            *ngIf="canFinishRegistrationLater()"
            (click)="submitRegistration()"
        >
            {{ 'modules.frictionless.finish_later' | translate }}
        </button>
    </div>
</div>
