import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@meupatrocinio/config';

export class NagbarCommon {
  static readonly PAYMENT_NAGBAR_COMPONENT_NAME: string = 'PaymentNagbarComponent';
  static readonly FEEDBACK_NAGBAR_COMPONENT_NAME: string = 'FeedbackNagbarComponent';
  static readonly MOBILE_NAGBAR_COMPONENT_NAME: string = 'MobileNagbarComponent';
  static readonly REFERRAL_FRIENDSHIP_NAGBAR_COMPONENT_NAME: string = 'ReferralFriendshipDayNagbarComponent';
  static readonly PHOTO_VERIFY_NAGBAR_COMPONENT_NAME: string = 'PhotoVerifyNagbarComponent';
  static readonly BOOST_COMPONENT_NAME: string = 'BoostNagbarComponent';

  static isMinimumVersion(): boolean {
    const minVersion = Config.feedbackNagbarPatchMinVersion;
    const currentVersion = Config.version.replace('v', '').split('.');

    return minVersion >= +currentVersion[2];
  }

  static isPaymentNagbarValid(user: UserCommon, paymentStatus?: PaymentStatus): boolean {
    return UserCommon.isPendingElite(user) || paymentStatus !== undefined;
  }

  static isMobileNagbarValid(user: UserCommon): boolean {
    return !FrictionlessCommon.isComplete(user) || UserCommon.daddyHasPendingEmailVerification(user);
  }

  static isReferralFriendshipNagbarValid(
    user: UserCommon,
    canShowFriendshipRefferalAd: () => boolean,
    paymentStatus: PaymentStatus,
  ): boolean {
    return canShowFriendshipRefferalAd() && !NagbarCommon.isPaymentNagbarValid(user, paymentStatus);
  }

  static isPhotoVerifyNagbarValid(
    isPhotoVerifyNagbarVisible: boolean,
    user: UserCommon,
    canShowFriendshipRefferalAd: () => boolean,
  ): boolean {
    return (
      UserCommon.isBaby(user) &&
      !UserCommon.isPhotoVerified(user) &&
      isPhotoVerifyNagbarVisible &&
      !canShowFriendshipRefferalAd()
    );
  }

  static isFeedbackNagbarValid(
    user: UserCommon,
    userJoindaysTimestamp: number,
    feedbackNagbarHidden: boolean,
  ): boolean {
    return (
      user !== undefined &&
      UserCommon.getUserJoinDays(userJoindaysTimestamp) > 10 &&
      !feedbackNagbarHidden &&
      NagbarCommon.isMinimumVersion()
    );
  }
}
