<div [style.width]="width"
     [style.height]="height"
     class="image-container">
  @if (!(imageLoaded | async)) {
    <canvas #canvas></canvas>
  }
  @defer (on viewport) {
    <img
      [src]="imageUrl"
      [style.width]="width"
      [style.height]="height"
      (load)="onImageLoad()"
      [ngClass]="{'image-loaded': imageLoaded | async}"
    />
  } @placeholder {
    <canvas></canvas>
  }
</div>
