import { UserCommon } from '@libs/shared/user/user.common';
import { IUnsafeAction } from '@libs/store/application-state';
import { MESSAGE_COUNT_INPUTED_ACTION } from '@libs/store/authentication/actions/message.count.action';
import { USER_RECEIVED_ACTION } from '@libs/store/authentication/actions/user.action';

export function user(state: UserCommon, action: IUnsafeAction): any {
  switch (action.type) {
    case USER_RECEIVED_ACTION:
      return Object.assign({}, action.payload.user, {
        conversations_qty: action.payload.user.conversations_qty || 0,
      });
    case MESSAGE_COUNT_INPUTED_ACTION:
      return onMessageCountInputedAction(state);
    default:
      return state;
  }
}

function onMessageCountInputedAction(state: UserCommon): UserCommon {
  return Object.assign({}, state, {
    conversations_qty: state.conversations_qty + 1,
  });
}
