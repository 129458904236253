import { ViewedTTLUpdatedAction, VIEWED_TTL_UPDATED_ACTION } from '@libs/store/profiles/actions/viewed.action';

export function storeViewedTTL(state: number = Date.now(), action: ViewedTTLUpdatedAction): number {
  switch (action.type) {
    case VIEWED_TTL_UPDATED_ACTION:
      return updateTTL(state, action.payload.ttl);
    default:
      return state;
  }
}

function updateTTL(_state: number, newTTL: number): number {
  return newTTL;
}
