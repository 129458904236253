import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Gender } from '@libs/shared/profile/profile';
import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { RegistrationInputedAction } from '@libs/store/registration/actions/registration.action';

@Directive()
export abstract class FrictionlessRegistrationComponentCommon implements OnInit, OnDestroy {
  public get stepTitle(): string {
    if (this.isAtTerms()) {
      return 'modules.main.pages.terms_conditions.title';
    }

    if (this.isAtBasic()) {
      return 'modules.frictionless.step_1';
    }

    if (this.isAtPicture()) {
      return 'modules.main.pages.my_profile.photos.profile';
    }

    if (this.isAtBody()) {
      return 'modules.frictionless.step_3';
    }

    if (this.isAtSocial()) {
      return 'modules.frictionless.step_4';
    }

    if (this.isAtFinal()) {
      return 'modules.frictionless.step_5';
    }

    if (this.isAtConfirmation()) {
      return 'modules.main.shared.mobile_nagbar.confirm_email_header';
    }

    return '';
  }

  public readonly TYPE_SUGAR_PARENT: number = UserCommon.WHAT_SUGGAR_DADDY_MOMMY;
  public readonly TYPE_SUGAR_BABY: number = UserCommon.WHAT_SUGGAR_BABY;

  public readonly STEP_TERMS: number = FrictionlessCommon.STEP_TERMS;
  public step: number = FrictionlessCommon.STEP_INITIAL;
  public lastStep: number = FrictionlessCommon.STEP_INITIAL;

  public myGender: Gender;
  public myMatch: number;
  public myType: number;

  public typeExplanation: number;
  public lookingForMen: boolean;
  public lookingForWomen: boolean;
  public confirmEmail: boolean;
  public email: string;
  public pictureData: string;

  protected registrationPayload: IRegistrationPayload = {};

  protected subscriptions: Subscription[] = [];

  constructor(
    protected userService: UserServiceCommon,
    protected store: Store<IApplicationState>,
  ) {
    //
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('registration').subscribe((value): void => {
        this.lastStep = value.step || FrictionlessCommon.STEP_INITIAL;
        const skipCommitStep = value.skipCommitStep;
        delete value.skipCommitStep;

        if (this.step > FrictionlessCommon.STEP_INITIAL) {
          if (skipCommitStep) {
            this.registrationPayload = {
              ...this.registrationPayload,
              ...value,
            };
            return;
          }
          this.validateStep(value);
        }

        this.myGender = value.sex;
        this.email = value.email;

        if (value.match_sex) {
          this.myMatch = value.match_sex.reduce((previous, current): number => previous + current, 0);
        }
        this.myType = value['extended[what]'];
        this.setLookingFor();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public abstract submitRegistration(): void;

  public abstract validateStep(value: IRegistrationPayload): void;

  public commitStep(value?: IRegistrationPayload): void {
    this.registrationPayload = { ...this.registrationPayload, ...value };

    if (this.isAtFinal()) {
      this.submitRegistration();
    }

    this.step++;

    const match_sex: number[] = [];
    if (this.lookingForMen) {
      match_sex.push(UserCommon.GENDER_MAN);
    }

    if (this.lookingForWomen) {
      match_sex.push(UserCommon.GENDER_WOMAN);
    }

    this.store.dispatch(
      new RegistrationInputedAction({
        data: {
          step: this.step,
          sex: this.myGender,
          match_sex,
          'extended[what]': this.myType,
          skipCommitStep: true,
        },
      }),
    );
  }

  public resetStep(step: number): void {
    this.step = step;
    this.store.dispatch(
      new RegistrationInputedAction({
        data: {
          step: this.step,
          skipCommitStep: true,
        },
      }),
    );
    location.hash = 's' + this.step.toString();
  }

  public genderToString(gender?: number): string {
    gender = gender || this.myGender;
    return UserCommon.getGenderStatus(gender);
  }

  public genderOptions(): number[] {
    return UserCommon.getGenderIds();
  }

  public typeToString(accType?: number): string {
    accType = accType || this.myType;
    return UserCommon.getTypeName(this.myGender, accType);
  }

  public typeOptions(): number[] {
    return UserCommon.getAccTypeIds();
  }

  public matchSexToString(matchSex: number): string {
    return UserCommon.getMatchSexStatus(matchSex);
  }

  public matchSexOptions(): number[] {
    return UserCommon.getLookingForIds();
  }

  public canContinue(): boolean {
    return (
      this.step >= FrictionlessCommon.STEP_TERMS ||
      (this.myGender && this.myType && (this.lookingForMen || this.lookingForWomen))
    );
  }

  public setLookingFor(): void {
    if (this.myMatch === UserCommon.GENDER_MAN + UserCommon.GENDER_WOMAN) {
      this.lookingForMen = this.lookingForWomen = true;
      return;
    }

    this.lookingForMen = this.myMatch === UserCommon.GENDER_MAN;
    this.lookingForWomen = this.myMatch === UserCommon.GENDER_WOMAN;
  }

  isAtTerms(): boolean {
    return this.step === FrictionlessCommon.STEP_TERMS;
  }

  isAtBasic(): boolean {
    return this.step === FrictionlessCommon.STEP_BASIC;
  }

  isAtPicture(): boolean {
    return this.step === FrictionlessCommon.STEP_PICTURE;
  }

  isAtBody(): boolean {
    return this.step === FrictionlessCommon.STEP_BODY;
  }

  isAtSocial(): boolean {
    return this.step === FrictionlessCommon.STEP_SOCIAL;
  }

  isAtFinal(): boolean {
    return this.step === FrictionlessCommon.STEP_FINAL;
  }

  isAtConfirmation(): boolean {
    return this.step === FrictionlessCommon.STEP_CONFIRMATION;
  }
}
