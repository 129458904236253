import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { defer, Observable, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { ConversationCommon } from '@libs/shared/conversation/conversation.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ConversationActions, IConversation, ListNameConversation } from '@libs/store/conversations';
import { ConversationWritingActions } from '@libs/store/conversations-writing';
import { MessageActions } from '@libs/store/messages';
import { Tick } from '@libs/utils/timeout-typings';

interface IWriter {
  [profileId: number]: Tick;
}

@Injectable({
  providedIn: 'root',
})
export abstract class ConversationServiceCommon implements OnDestroy {
  protected user: UserCommon = new UserCommon(0);
  protected subscriptions: Subscription[] = [];
  protected writingTimeouts: IWriter = [];

  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpServiceCommon,
    protected profileService: ProfileServiceCommon,
  ) {
    this.subscriptions.push(
      this.store
        .select('user')
        .pipe(filter((user) => UserCommon.isConnected(user)))
        .subscribe((user) => {
          this.onUserMembershipTypeIdChange(user);
          this.user = user;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public abstract tellThemImWriting(receiverId: number): Observable<IAuthResponse>;

  public abstract checkConversationExists(peerId: number): Observable<IAuthResponse>;

  public abstract downloadConversations(page: number): Observable<IAuthResponse>;

  public abstract downloadFavoritedConversations(page: number): Observable<IAuthResponse>;

  public abstract setConversationRead(conversation: IConversation): Observable<IAuthResponse>;

  public abstract loadUnreadConversations(): Observable<IAuthResponse>;

  public abstract delete(conversation: IConversation): Observable<IAuthResponse>;

  maybeRedirectBeforeDeletion(profileId: number, conversation: IConversation, router: Router): Observable<boolean> {
    if (!router.url.endsWith(`/${ConversationCommon.getTheirProfileId(profileId, conversation)}`)) {
      return of(true);
    }

    return defer(async (): Promise<boolean> => router.navigate(['main', 'conversation'], { replaceUrl: true }));
  }

  markConversationAsRead(conversation: IConversation): IConversation {
    if (ConversationCommon.isUserInterlocutor(conversation, this.user)) {
      return this.markLastMessageReadToInterlocutor(conversation);
    }

    return this.markLastMessageReadToInitiator(conversation);
  }

  marksForOnlyOtherHaveRead(conversation: IConversation): IConversation {
    if (ConversationCommon.isUserInterlocutor(conversation, this.user)) {
      return this.markLastMessageReadToInitiator(conversation);
    }

    return this.markLastMessageReadToInterlocutor(conversation);
  }

  addWriter(conversationPeerId: number): void {
    clearTimeout(this.writingTimeouts[conversationPeerId]);

    this.store.dispatch(
      ConversationWritingActions.setConversationWriting({
        conversation_peer_id: conversationPeerId,
      }),
    );
    this.writingTimeouts[conversationPeerId] = setTimeout((): void => this.removeWriter(conversationPeerId), 2000);
  }

  removeWriter(conversationPeerId: number): void {
    if (this.writingTimeouts[conversationPeerId]) {
      clearTimeout(this.writingTimeouts[conversationPeerId]);
      delete this.writingTimeouts[conversationPeerId];
    }

    this.store.dispatch(
      ConversationWritingActions.unsetConversationWriting({
        conversation_peer_id: conversationPeerId,
      }),
    );
  }

  protected onUserMembershipTypeIdChange(updatedProfile: UserCommon): void {
    if (
      this.user.membership_type_id !== undefined &&
      this.user.membership_type_id !== updatedProfile.membership_type_id
    ) {
      this.store.dispatch(ConversationActions.cleanConversations());
      this.store.dispatch(MessageActions.cleanMessages());
    }
  }

  protected markLastMessageReadToInterlocutor(conversation: IConversation): IConversation {
    return {
      ...conversation,
      last_message_read_id_interlocutor: conversation.last_message_id,
    };
  }

  protected markLastMessageReadToInitiator(conversation: IConversation): IConversation {
    return {
      ...conversation,
      last_message_read_id_initiator: conversation.last_message_id,
    };
  }

  public resetAndUpdateConversationsList(listName: ListNameConversation): void {
    this.store.dispatch(ConversationActions.resetConversationListPagination({ listName }));
  }
}
