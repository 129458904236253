import { Injectable } from '@angular/core';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';

declare let progressier: {
  add: (data: { id: number }) => void;
};

@Injectable({
  providedIn: 'root',
})
export class ProgressierService {
  private initialized = false;

  constructor(
    protected readonly authenticationService: AuthenticationService,
    protected readonly globalObjectService: GlobalObjectService,
  ) {
    //
  }

  private get window() {
    return this.globalObjectService.window;
  }

  public injectScript() {
    if (this.window.location.hostname.includes('localhost')) {
      return;
    }

    this.appendManifestLink();
    this.appendScript();
    this.initialized = true;
  }

  private appendManifestLink() {
    const link = this.window.document.createElement('link');

    link.rel = 'manifest';
    link.href = 'https://progressier.app/KoHgrrBcXM5V25TWYMKO/progressier.json';
    this.window.document.head.appendChild(link);
  }

  private appendScript() {
    const script = this.window.document.createElement('script');

    script.defer = true;
    script.src = 'https://progressier.app/KoHgrrBcXM5V25TWYMKO/script.js';
    this.window.document.head.appendChild(script);
  }

  public identifyUser() {
    if (!this.initialized) {
      return;
    }

    progressier.add({
      id: this.authenticationService.get().profile_id,
    });
  }
}
