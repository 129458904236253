import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { DateCommon } from '@libs/shared/date/date.common';
import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { RegistrationInputedAction } from '@libs/store/registration/actions/registration.action';
import { range } from '@libs/utils/array-functions';

import { TimeToRegisterActions } from '@meupatrocinio/modules/time-to-register/state/actions';

@Directive()
export class FrictionlessStep3ComponentCommon implements OnDestroy, OnInit {
  public height: string;
  public bodyType: string;
  public skinTone: string;
  public hairType: string;
  public eyeColor: string;
  public myType: number;
  public birthdate: string;

  @Output() readonly skipped: EventEmitter<void> = new EventEmitter<void>();

  protected subscriptions: Subscription[] = [];

  constructor(
    protected translate: TranslateService,
    protected store: Store<IApplicationState>,
  ) {
    //
  }

  public ngOnInit(): void {
    this.store
      .select('registration')
      .pipe(take(1))
      .subscribe((value): void => {
        this.myType = value['extended[what]'];
        this.birthdate = value.birthdate;

        if (value['extended[height1]']) {
          this.height = this.translate.instant('common.height', {
            height: Math.log2(value['extended[height1]']) + 150,
          });
        }
        this.bodyType = UserCommon.getBodyTypeStatus(value['extended[body_type]']);
        this.skinTone = UserCommon.getEthnicityStatus(value['extended[ethnicity]']);
        this.hairType = UserCommon.getHairColorStatus(value['extended[hair_color]']);
        this.eyeColor = UserCommon.getEyeColorStatus(value['extended[eye_color]']);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public canContinue(): boolean {
    return !!this.height && !!this.bodyType && !!this.skinTone && !!this.hairType && !!this.eyeColor;
  }

  public commitStep(): void {
    this.store.dispatch(
      new RegistrationInputedAction({
        data: {
          'extended[height1]': this.height && Math.pow(2, parseInt(this.height, 10) - 150),
          'extended[body_type]': this.bodyType && UserCommon.getBodyTypeKey(this.bodyType),
          'extended[ethnicity]': this.skinTone && UserCommon.getEthnicityKey(this.skinTone),
          'extended[hair_color]': this.hairType && UserCommon.getHairColorKey(this.hairType),
          'extended[eye_color]': this.eyeColor && UserCommon.getEyeColorKey(this.eyeColor),
        },
      }),
    );
  }

  public submitRegistration(): void {
    this.store.dispatch(TimeToRegisterActions.updateRequest({ endTime: Date.now() }));
    this.skipped.emit();
  }

  public heightOptions(): string[] {
    return range(150, 211).map((height): any => this.translate.instant('common.height', { height }));
  }

  public bodyTypeOptions(): string[] {
    return UserCommon.getBodyTypeOptions();
  }

  public skinToneOptions(): string[] {
    return UserCommon.getEthnicityStatusOptions();
  }

  public hairTypeOptions(): string[] {
    return UserCommon.getHairTypeOptions();
  }

  public eyeColorOptions(): string[] {
    return UserCommon.getEyeColorOptions();
  }

  public canFinishRegistrationLater(): boolean {
    return FrictionlessCommon.canFinishRegistrationLater(this.myType, DateCommon.yearDiff(new Date(this.birthdate)));
  }
}
