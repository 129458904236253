import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { MembershipBadgeComponentCommon } from '@libs/components/membership-badge/membership-badge.component.common';
import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';

@Component({
  selector: 'mp-membership-badge',
  templateUrl: 'membership-badge.html',
})
export class MembershipBadgeComponent extends MembershipBadgeComponentCommon {
  public isWhiteThemeEnabled$ = this.abTestsLoaderService.getWhiteThemeTreatment$();

  constructor(
    protected store: Store,
    protected abTestsLoaderService: ABTestsLoaderService,
  ) {
    super(store);
  }
}
