import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { empty, Observable, of } from 'rxjs';

import { DownloadManagerServiceCommon, ListName } from '@libs/modules/main/services/download-manager.service.common';
import { IListMapper } from '@libs/modules/main/services/download-manager/download-manager';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@meupatrocinio/config';
import { ConversationService } from '@meupatrocinio/services/conversation.service';
import { ListService } from '@meupatrocinio/services/list.service';
import { MessageService } from '@meupatrocinio/services/message.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { SearchService } from '@meupatrocinio/services/search.service';

@Injectable()
export class DownloadManagerService extends DownloadManagerServiceCommon {
  constructor(
    protected listService: ListService,
    protected profileService: ProfileService,
    protected searchService: SearchService,
    protected conversationService: ConversationService,
    protected messageService: MessageService,
    protected store: Store<IApplicationState>,
  ) {
    super(listService, profileService, searchService, conversationService, messageService, store);
  }

  public updatePage(listName: ListName, subParam: any, page: number): Observable<IAuthResponse> {
    if (this.isDownloading(listName, subParam)) {
      if (Config.showLogs.downloads) {
        console.error(`Trying to update ${listName} when it is currently downloading`);
      }

      return of();
    }

    const pageCallableMapper: IListMapper = this.getListMapper(subParam, page);

    return pageCallableMapper[listName]();
  }

  public updateNextPage(listName: ListName, subParam?: any): Observable<IAuthResponse> {
    if (this.isAtLastPage(listName, subParam)) {
      if (Config.showLogs.downloads) {
        console.error('Trying to update ' + listName + ' when it has already reached the last page');
      }

      return of();
    }

    if (this.isDownloading(listName, subParam)) {
      if (Config.showLogs.downloads) {
        console.error('Trying to update ' + listName + ' when it is currently downloading');
      }

      return of();
    }

    const pageCallableMapper: IListMapper = this.getListMapper(subParam);

    if (!pageCallableMapper.hasOwnProperty(listName)) {
      return empty();
    }

    return pageCallableMapper[listName]();
  }

  protected log(started: boolean, listName: ListName, subParam?: number): void {
    if (!Config.showLogs.downloads) {
      return;
    }

    const time = new Date();
    const timeString =
      time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds() + '.' + time.getMilliseconds();

    let description = listName;
    if (this.listIsMap(listName)) {
      description += '/' + subParam;
    }

    let status = 'started';
    if (!started) {
      status = 'finished';
    }

    console.log('[' + timeString + '] DOWNLOAD MANAGER --> Download of ' + description + ' ' + status + '.');
  }
}
