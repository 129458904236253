import { Action } from '@ngrx/store';

import { ILists } from '@libs/modules/main/services/download-manager.service.common';

export const DOWNLOAD_MANAGER_UPDATED_ACTION: string = 'DOWNLOAD_MANAGER_UPDATED_ACTION';

export class DownloadManagerUpdatedAction implements Action {
  readonly type: string = DOWNLOAD_MANAGER_UPDATED_ACTION;

  constructor(public payload: ILists) {
    //
  }
}
