import { ICustomWindow } from '@libs/services/global-object/global-object.service.common';
import { NativeEventsNetcore } from '@meupatrocinio/infra/netcore/types/native-events-netcore';

export abstract class Netcore {
  public static smartech(
    window: ICustomWindow,
    eventName: NativeEventsNetcore,
    value: string | number,
    object?: unknown,
  ) {
    window?.smartech(eventName, value, object);
  }
}
