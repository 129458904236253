import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { UserButtonsReferralComponentCommon } from '@libs/modules/main/shared/user-buttons-referral/user-buttons-referral.component.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

@Component({
  selector: 'mp-user-buttons-referral',
  templateUrl: './user-buttons-referral.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserButtonsReferralComponent extends UserButtonsReferralComponentCommon {
  constructor(
    protected router: Router,
    protected store: Store<IApplicationState>,
  ) {
    super(store);
  }

  getReferralButtonIcon(): string {
    return UserCommon.resolveImageName('refer-icon', '.svg');
  }

  navigateToReferral(): void {
    this.closeSlidableMenu();
    this.router.navigate(['main', 'settings', 'referral']);
  }
}
