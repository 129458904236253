import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { empty, Observable, of, Subscription } from 'rxjs';

import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';
import { MaintenanceAction } from '@libs/store/ui/actions/maintenance.action';

@Injectable({
  providedIn: 'root',
})
export abstract class AuthHttpServiceCommon implements OnDestroy {
  token: string;

  protected subscriptions: Subscription[] = [];

  protected readonly INPUT_TYPE_POST: string = 'post';
  protected readonly INPUT_TYPE_GET: string = 'get';
  protected readonly INPUT_TYPE_PUT: string = 'put';
  protected readonly INPUT_TYPE_DELETE: string = 'delete';
  protected readonly INPUT_TYPE_PATCH: string = 'patch';

  constructor(
    protected http: HttpClient,
    protected store: Store<IApplicationState>,
  ) {
    this.subscriptions.push(
      this.store.select('token').subscribe((data): void => {
        this.token = data.token;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  abstract getOrigin(): string;

  public get(endpoint: string, data?: any): Observable<IAuthResponse> {
    return this.action(this.INPUT_TYPE_GET, endpoint, data);
  }

  public post(
    endpoint: string,
    data: any,
    urlEncoded?: boolean,
    customHeaders?: { [key: string]: string },
  ): Observable<IAuthResponse> {
    return this.action(this.INPUT_TYPE_POST, endpoint, data, urlEncoded, customHeaders);
  }

  public delete(endpoint: string): Observable<IAuthResponse> {
    return this.action(this.INPUT_TYPE_DELETE, endpoint);
  }

  public patch(endpoint: string, data: any, urlEncoded?: boolean): Observable<IAuthResponse> {
    return this.action(this.INPUT_TYPE_PATCH, endpoint, data, urlEncoded);
  }

  public put(
    endpoint: string,
    data: any,
    urlEncoded?: boolean,
    customHeaders?: { [key: string]: string },
  ): Observable<IAuthResponse> {
    return this.action(this.INPUT_TYPE_PUT, endpoint, data, urlEncoded, customHeaders);
  }

  public uploadPhoto(
    uri: string,
    type: any,
    uploadCallback: (uri: string, photoType: string, token: string) => void,
  ): void {
    uploadCallback(uri, type, this.token);
  }

  protected abstract action(
    verb: string,
    endpoint: string,
    data?: any,
    urlEncoded?: boolean,
    customHeaders?: { [key: string]: string },
  ): Observable<IAuthResponse>;

  protected extractData(res: any, currentToken: string): IAuthResponse {
    if (!this.hasToken()) {
      throw new HttpErrorResponse({
        error: res,
        statusText: 'Got a response but the token is no longer available.',
      });
    }

    this.store.dispatch(new MaintenanceAction(false));

    if (this.token !== currentToken) {
      throw new Error('Token has changed since start of request');
    }

    let data = res.body || {};
    let last_page = 0;
    const status = res.status;
    let next_page_url = '';
    const total = data.total;

    if (res.status === 206) {
      data = res.body.data;
      last_page = res.body.last_page;
      next_page_url = res.body.next_page_url;
    }

    return {
      data,
      status,
      next_page_url,
      last_page,
      total,
    };
  }

  protected handleError(error: HttpErrorResponse): Observable<never | HttpErrorResponse | IAuthResponse> {
    if (error.status === 503) {
      this.store.dispatch(new MaintenanceAction(true));
      return empty();
    }

    if (error.status < 200 || error.status >= 300) {
      throw new HttpErrorResponse(error);
    }

    this.store.dispatch(new MaintenanceAction(false));

    return of({
      data: error.error || {},
      status: error.status,
      last_page: 0,
      next_page_url: '',
      total: 0,
    });
  }

  protected abstract debugRequest(res: any): any;

  protected hasToken(): boolean {
    return this.token && this.token.length > 0;
  }
}
