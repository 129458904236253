import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { IBoostTimeRemaining } from '@libs/modules/boost/interface/boost-time-remaining';
import { BoostProgressServiceCommon } from '@libs/modules/boost/services/boost-progress/boost-progress.service.common';

@Directive()
export class BoostProgressComponentCommon implements OnInit, OnDestroy {
  protected canUnfillProgress = false;
  public boostTimeRemaining$: Observable<IBoostTimeRemaining> = of(
    this.boostProgressService.getDefaultTimeRemainingValues(),
  );
  public progressInPercentage$: Observable<string> = of(this.boostProgressService.getDefaultProgress());

  constructor(protected boostProgressService: BoostProgressServiceCommon) {
    this.boostProgressService.initilizeCountdownSubjects();
  }

  ngOnInit(): void {
    this.boostProgressService.startCountdownSequence();
    this.boostTimeRemaining$ = this.boostProgressService.getCountdown$();
    this.progressInPercentage$ = this.boostProgressService.getProgressInPercentage$();
  }

  ngOnDestroy(): void {
    this.boostProgressService.destroyCountdownSubjects();
  }

  getFormattedTimeRemaining({ hours, minutes, seconds }: IBoostTimeRemaining): string {
    return `${hours}:${minutes}:${seconds}`;
  }
}
