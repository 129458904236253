import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ConversationServiceCommon } from '@libs/modules/main/services/conversation/conversation.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';
import { IConversation } from '@libs/store/conversations';

import { Config } from '@meupatrocinio/config';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { SocketService } from '@meupatrocinio/services/socket/socket.service';

@Injectable()
export class ConversationService extends ConversationServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpService,
    protected profileService: ProfileService,
    protected socketService: SocketService,
  ) {
    super(store, authHttp, profileService);
  }

  public tellThemImWriting(receiverId: number): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'conversations/' + receiverId + '/writing';

    return this.authHttp.post(endpoint, null);
  }

  public checkConversationExists(peerId: number): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'chat/conversation/peer/' + peerId;

    return this.authHttp.get(endpoint);
  }

  public downloadConversations(page = 1): Observable<IAuthResponse> {
    const endpoint = Config.serverIp + 'chat/' + this.user.profile_id + '?page=' + page;

    return this.authHttp.get(endpoint);
  }

  public downloadFavoritedConversations(page = 1): Observable<IAuthResponse> {
    const endpoint = Config.serverIp + 'chat/favorited/' + this.user.profile_id + '?page=' + page;
    return this.authHttp.get(endpoint);
  }

  public setConversationRead(conversation: IConversation): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'conversations/' + conversation.peer_id + '/read';

    return this.authHttp.put(endpoint, {
      last_message_id: conversation.last_message_id,
    });
  }

  public loadUnreadConversations(): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'conversations/count-unread-messages';

    return this.authHttp.get(endpoint);
  }

  public delete(conversation: IConversation): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'conversations/' + conversation.peer_id;

    return this.authHttp.delete(endpoint);
  }
}
