import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';

export interface IModalOptions<T> {
  userDismissable?: boolean;
  closesOnNavigation?: boolean;
  data?: T;
}

export abstract class AdvancedModalServiceCommon {
  public abstract open<T extends IModalComponent<T['data']>>(
    component: any,
    options: IModalOptions<T['data']>,
  ): ModalRef;
}
