<mp-advanced-modal-ui>
    <p header>
        {{ 'modules.main.pages.conversation.delete.modal.header' | translate }}
    </p>
    <p description>
        {{ 'modules.main.pages.conversation.confirm_delete.title' | translate }}
    </p>
    <ng-container footer>
        <button
            class="btn btn-default"
            (click)="closeModal()"
        >
            {{ 'common.cancel' | translate }}
        </button>
        <button
            class="btn btn-danger"
            (click)="deleteConversation()"
        >
            {{ 'modules.main.pages.conversation.delete' | translate }}
        </button>
    </ng-container>
</mp-advanced-modal-ui>
