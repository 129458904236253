import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';
import { IBoostLastViewersState } from '@libs/store/boost-last-viewers';

function selectProfileId(profile: IProfile): number {
  return profile.profile_id;
}

export const boostLastViewersAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>({
  selectId: selectProfileId,
});

export const boostLastViewserInitialState: IBoostLastViewersState = boostLastViewersAdapter.getInitialState({});
