import { createAction, props } from '@ngrx/store';

import { IBoostPackageProduct } from '@libs/modules/boost/interface/boost-package-product';
import { IBoostGtmData } from '@libs/shared/boost/interfaces/boost-gtm-data.interface';
import { IPaymentInfo } from '@libs/store/payment-info';

export enum Actions {
  HANDLE_PAYMENT_ERROR = '[Boost Product] - Handle Payment Error',
  BOOST_PAYMENT_SUCCESS = '[Boost Product] - Boost Package Payment Success',
  CHECK_IF_IS_BOOST_PAYMENT = '[Boost Product] - Check if is Boost Package Payment',
  HANDLE_BOOST_STATE_AFTER_PAYMENT = '[Boost Product] - Handle Boost State After Payment',
  HANDLE_BOOST_STATE_WITH_DELAY = '[Boost Product] - Handle Boost State With Delay',
  HANDLE_BALANCE_AFTER_PAYMENT = '[Boost Product] - Handle Balance After Payment',
  GET_PAYMENT_INFO_TO_PUSH_ON_GTM = '[Boost Product] - Get Payment Info To Push On GTM',
  GET_BOOST_BALANCE_TO_PUSH_ON_GTM = '[Boost Product] - Get Boost Balance To Push on GTM',
  GET_LATEST_BOOST_DATA_TO_PUSH_ON_GTM = '[Boost Product] - Get Latest Boost Data To Push On GTM',
}

export const boostPackagePaymentSuccess = createAction(Actions.BOOST_PAYMENT_SUCCESS);

export const checkIfIsBoostPayment = createAction(
  Actions.CHECK_IF_IS_BOOST_PAYMENT,
  props<{ updatedPaymentInfo: IPaymentInfo }>(),
);

export const handlePaymentError = createAction(
  Actions.HANDLE_PAYMENT_ERROR,
  props<{ product: IBoostPackageProduct }>(),
);

export const handleBoostStateAfterPayment = createAction(Actions.HANDLE_BOOST_STATE_AFTER_PAYMENT);

export const handleBoostStateAfterDelay = createAction(
  Actions.HANDLE_BOOST_STATE_WITH_DELAY,
  props<{ hasBoostEnded: boolean }>(),
);

export const getPaymentInfoToPushOnGtm = createAction(Actions.GET_PAYMENT_INFO_TO_PUSH_ON_GTM);

export const getBoostBalanceToPushOnGtm = createAction(
  Actions.GET_BOOST_BALANCE_TO_PUSH_ON_GTM,
  props<{ boostData: IBoostGtmData }>(),
);

export const getLatestBoostDataToPushOnGtm = createAction(
  Actions.GET_LATEST_BOOST_DATA_TO_PUSH_ON_GTM,
  props<{ boostData: IBoostGtmData }>(),
);

export const handleBalanceAfterPayment = createAction(Actions.HANDLE_BALANCE_AFTER_PAYMENT);
