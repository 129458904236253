<div class="badges-container">
    <ng-container *ngFor="let badge of getAttributes">
        <div
            class="profile-badge"
            [class]="badge.extendedClassName"
            *ngIf="badge.isVerified()"
        >
            <img [src]="badge.extendedImagePath" [alt]="getTranslate(badge)">
            <p *ngIf="!badge.isMelt">
                {{ getTranslate(badge) }}
            </p>
        </div>
    </ng-container>
</div>
