import { Injectable } from '@angular/core';
import { Observable, Subscriber, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { IGeolocationPosition } from '@libs/services/geolocation/interfaces/geolocation.interface';
import { IProfile, IProfileGeoLocation } from '@libs/shared/profile/profile';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  constructor() {
    //
  }

  public getDistanceFromProfile(profile: IProfile): Observable<number | null> {
    return this.getCurrentPosition().pipe(
      distinctUntilChanged(this.arePositionsEqual),
      switchMap((position: IGeolocationPosition): Observable<number | null> => {
        if (position) {
          return this.getDistanceValue(position, profile.geolocation);
        }

        return of(null);
      }),
    );
  }

  protected getCurrentPosition(): Observable<IGeolocationPosition> {
    return new Observable<IGeolocationPosition>((observer: Subscriber<IGeolocationPosition>): void => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position: IGeolocationPosition): void => {
          observer.next(position);
          observer.complete();
        });
      }
    });
  }

  protected getDistanceValue(
    currentCoords: IGeolocationPosition,
    targetCoords: IProfileGeoLocation,
  ): Observable<number | null> {
    if (!currentCoords || !targetCoords) {
      return of(null);
    }

    if (this.areCoordinatesEqual(currentCoords, targetCoords)) {
      return of(0);
    }

    const distanceKilometers = this.calculateDistanceKilometers(currentCoords, targetCoords);

    return of(Math.round(distanceKilometers));
  }

  protected areCoordinatesEqual(currentCoords: IGeolocationPosition, targetCoords: IProfileGeoLocation): boolean {
    return currentCoords.coords.latitude === targetCoords.lat && currentCoords.coords.longitude === targetCoords.lon;
  }

  protected arePositionsEqual(previousPosition: IGeolocationPosition, currentPosition: IGeolocationPosition): boolean {
    return (
      previousPosition.coords.latitude === currentPosition.coords.latitude &&
      previousPosition.coords.longitude === currentPosition.coords.longitude
    );
  }

  protected calculateDistanceKilometers(
    currentCoords: IGeolocationPosition,
    targetCoords: IProfileGeoLocation,
  ): number {
    const currentLatitudeRadians = this.degreesToRadians(currentCoords.coords.latitude);
    const targetLatitudeRadians = this.degreesToRadians(targetCoords.lat);
    const longitudeDifferenceRadians = this.degreesToRadians(currentCoords.coords.longitude - targetCoords.lon);

    const distanceRadians = this.calculateDistanceRadians(
      currentLatitudeRadians,
      targetLatitudeRadians,
      longitudeDifferenceRadians,
    );

    return this.radiansToKilometers(distanceRadians);
  }

  protected degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  protected calculateDistanceRadians(
    currentLatitudeRadians: number,
    targetLatitudeRadians: number,
    longitudeDifferenceRadians: number,
  ): number {
    const sinProduct = Math.sin(currentLatitudeRadians) * Math.sin(targetLatitudeRadians);
    const cosProduct =
      Math.cos(currentLatitudeRadians) * Math.cos(targetLatitudeRadians) * Math.cos(longitudeDifferenceRadians);

    return (Math.acos(Math.min(1, sinProduct + cosProduct)) * 180) / Math.PI;
  }

  protected radiansToKilometers(radians: number): number {
    return radians * 60 * 1.1515 * 1.609344;
  }
}
