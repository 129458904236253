import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserMenuService } from '@meupatrocinio/services/user-menu/user-menu.service';

@Component({
  selector: 'mp-user-menu',
  templateUrl: './user-menu.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserMenuComponent implements OnDestroy {
  protected subscription: Subscription;

  @Input() isSelectedForSearchV2 = false;

  constructor(
    protected router: Router,
    protected userMenuService: UserMenuService,
  ) {
    this.router.events.subscribe((event: Event): void => {
      if (event instanceof NavigationEnd) {
        this.userMenuService.checkRoute(event.url);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showMenuItems(): boolean {
    return !this.userMenuService.responsiveMode();
  }
}
