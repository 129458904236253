import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import { ISearchState } from '@libs/store/search/interface/search-state.interface';

export const searchStateAdapter: EntityAdapter<ISearchState> = createEntityAdapter<ISearchState>();

export const searchStateInitialState: ISearchState = searchStateAdapter.getInitialState({
  scrollYPosition: 0,
  isSavingSearch: false,
  searchModalActiveTab: 'filters',
  hasUpdateFailed: false,
  hasErrorOccurred: false,
  isDeletingSearch: false,
  savedSearches: [],
});
