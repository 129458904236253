import { createAction, props } from '@ngrx/store';

enum Actions {
  UPDATE_MARKETING_COOKIES = '[Cookies] - Update Marketing Cookies',
}

export const updateMarketingCookies = createAction(
  Actions.UPDATE_MARKETING_COOKIES,
  props<{
    utm_source?: string;
    utm_campaign?: string;
    gclid?: string;
    vcid?: string;
  }>(),
);
