import { MembershipTypeName } from '@libs/shared/membership/membership-type-name';

export enum MembershipType {
  MEMBERSHIP_TYPE_EXPRESS_APPROVAL = -1,
  MEMBERSHIP_TYPE_NONE = 0,
  MEMBERSHIP_TYPE_DADDY_PREMIUM = 116,
  MEMBERSHIP_TYPE_BABY_FREE = 118,
  MEMBERSHIP_TYPE_DADDY_FREE = 119,
  MEMBERSHIP_TYPE_DADDY_ELITE = 120,
  MEMBERSHIP_TYPE_BABY_PREMIUM = 121,
}

export enum SubscriptionType {
  SUBSCRIPTION_ELITE = 'elite',
  SUBSCRIPTION_DADDY_PREMIUM = 'premium',
  SUBSCRIPTION_BABY_PREMIUM = 'sbp',
  SUBSCRIPTION_FREE = 'free',
}

export abstract class MembershipCommon {
  static isFree(membershipTypeId: MembershipType) {
    return MembershipCommon.isBabyFree(membershipTypeId) || MembershipCommon.isDaddyMommyFreeType(membershipTypeId);
  }

  static isBabyFree(membershipTypeId: MembershipType): boolean {
    return membershipTypeId === MembershipType.MEMBERSHIP_TYPE_BABY_FREE;
  }

  static isBabyPremium(membershipTypeId: MembershipType): boolean {
    return membershipTypeId === MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM;
  }

  static isBaby(membershipTypeId: MembershipType): boolean {
    return MembershipCommon.isBabyFree(membershipTypeId) || MembershipCommon.isBabyPremium(membershipTypeId);
  }

  static isDaddyMommyFreeType(membershipTypeId: MembershipType): boolean {
    return membershipTypeId === MembershipType.MEMBERSHIP_TYPE_DADDY_FREE;
  }

  static isDaddyMommyPremiumType(membershipTypeId: MembershipType): boolean {
    return membershipTypeId === MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM;
  }

  static isEliteType(membershipTypeId: MembershipType): boolean {
    return membershipTypeId === MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE;
  }

  static isPremium(membershipTypeId: MembershipType): boolean {
    return (
      (membershipTypeId !== undefined && MembershipCommon.isDaddyMommyPremiumType(membershipTypeId)) ||
      MembershipCommon.isBabyPremium(membershipTypeId)
    );
  }

  static isDaddyMommyType(membershipTypeId: MembershipType): boolean {
    return (
      MembershipCommon.isDaddyMommyFreeType(membershipTypeId) ||
      MembershipCommon.isDaddyMommyPremiumType(membershipTypeId) ||
      MembershipCommon.isEliteType(membershipTypeId)
    );
  }

  static getMembership(membership_type_id: MembershipType): string {
    const TYPE_DADDY_MOMMY_FREE_STR: string = 'shared.user.memberships.free';
    const TYPE_DADDY_MOMMY_PREMIUM_STR: string = 'shared.user.memberships.premium';
    const TYPE_DADDY_MOMMY_ELITE_STR: string = 'shared.user.memberships.elite';
    const TYPE_BABY_PREMIUM_STR: string = 'shared.user.memberships.sbp';

    if (MembershipCommon.isEliteType(membership_type_id)) {
      return TYPE_DADDY_MOMMY_ELITE_STR;
    }

    if (MembershipCommon.isDaddyMommyPremiumType(membership_type_id)) {
      return TYPE_DADDY_MOMMY_PREMIUM_STR;
    }

    if (MembershipCommon.isBabyPremium(membership_type_id)) {
      return TYPE_BABY_PREMIUM_STR;
    }

    return TYPE_DADDY_MOMMY_FREE_STR;
  }

  static getMachineMembership(membership_type_id: MembershipType): SubscriptionType {
    if (membership_type_id === MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE) {
      return SubscriptionType.SUBSCRIPTION_ELITE;
    }

    if (membership_type_id === MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM) {
      return SubscriptionType.SUBSCRIPTION_DADDY_PREMIUM;
    }

    if (membership_type_id === MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM) {
      return SubscriptionType.SUBSCRIPTION_BABY_PREMIUM;
    }

    return SubscriptionType.SUBSCRIPTION_FREE;
  }

  static getMembershipWithMember(membership_type_id: MembershipType): string {
    const TYPE_DADDY_MOMMY_FREE_MEMBER_STR: string = 'modules.main.pages.my_profile.basic.member_free';
    const TYPE_DADDY_MOMMY_PREMIUM_MEMBER_STR: string = 'modules.main.pages.my_profile.basic.member_premium';
    const TYPE_DADDY_MOMMY_ELITE_MEMBER_STR: string = 'modules.main.pages.profile.member_elite';
    const TYPE_BABY_PREMIUM_MEMBER_STR: string = 'shared.user.memberships.sbp';

    if (MembershipCommon.isEliteType(membership_type_id)) {
      return TYPE_DADDY_MOMMY_ELITE_MEMBER_STR;
    }

    if (MembershipCommon.isDaddyMommyPremiumType(membership_type_id)) {
      return TYPE_DADDY_MOMMY_PREMIUM_MEMBER_STR;
    }

    if (MembershipCommon.isBabyPremium(membership_type_id)) {
      return TYPE_BABY_PREMIUM_MEMBER_STR;
    }

    return TYPE_DADDY_MOMMY_FREE_MEMBER_STR;
  }

  static getSuccessfullyPaymentTranslationMembership(payedMembership: MembershipType): string {
    if (!MembershipCommon.isEliteType(payedMembership)) {
      return 'modules.main.pages.payment.processing.success.nonelite';
    }

    return 'modules.main.pages.payment.processing.success.elite';
  }

  static getAccountTypeNameByMembership(
    membershipTypeId: MembershipType,
    numberOfPayments: number,
  ): MembershipTypeName {
    if (!MembershipCommon.isDaddyMommyType(membershipTypeId)) {
      return MembershipCommon.handleBabyAccountType(membershipTypeId, numberOfPayments);
    }

    return MembershipCommon.handleDaddyAccountType(membershipTypeId, numberOfPayments);
  }

  static handleDaddyAccountType(membershipTypeId: MembershipType, numberOfPayments: number): MembershipTypeName {
    if (MembershipCommon.isEliteType(membershipTypeId)) {
      return MembershipTypeName.DaddyElite;
    }

    if (MembershipCommon.isDaddyMommyPremiumType(membershipTypeId)) {
      return MembershipTypeName.DaddyPremium;
    }

    if (numberOfPayments > 0 && MembershipCommon.isDaddyMommyFreeType(membershipTypeId)) {
      return MembershipTypeName.DaddyExpired;
    }

    return MembershipTypeName.DaddyFree;
  }

  static handleBabyAccountType(membershipTypeId: MembershipType, numberOfPayments: number): MembershipTypeName {
    if (MembershipCommon.isBabyPremium(membershipTypeId)) {
      return MembershipTypeName.BabyPremium;
    }

    if (numberOfPayments > 0 && MembershipCommon.isBaby(membershipTypeId)) {
      return MembershipTypeName.ExpiredBabyPremium;
    }

    return MembershipTypeName.BabyFree;
  }
}
