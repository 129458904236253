import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { BoostBubblesComponentCommon } from '@libs/modules/boost/components/boost-bubbles/boost-bubbles.component.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { BoostLastViewersService } from '@meupatrocinio/modules/shared/boost-shared/services/boost-last-viewers/boost-last-viewers.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

@Component({
  selector: 'mp-boost-bubbles',
  templateUrl: './boost-bubbles.html',
})
export class BoostBubblesComponent extends BoostBubblesComponentCommon {
  constructor(
    protected authenticationService: AuthenticationService,
    protected boostLastViewersService: BoostLastViewersService,
    protected store: Store<IApplicationState>,
  ) {
    super(authenticationService, boostLastViewersService, store);
  }

  getProfilePicture(profile: IProfile): string {
    return ImageHelper.getProfilePicture(profile, this.authenticationService.get(), UserCommon.PICTURE_SMALL);
  }
}
