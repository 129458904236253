import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionbarComponentCommon } from '@libs/modules/shared/actionbar/actionbar.component.common';

import { ActionbarService } from '@meupatrocinio/modules/main/services/actionbar/actionbar.service';

@Component({
  selector: 'mp-actionbar',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar.html',
})
export class ActionbarComponent extends ActionbarComponentCommon {
  constructor(protected actionBarService: ActionbarService) {
    super(actionBarService);
  }
}
