import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';

export class ProfileCommon {
  static findProfileByProfileId(profileId: number, profiles: IProfile[]): IProfile | undefined {
    return profiles.find((profile: IProfile): boolean => profile.profile_id === profileId);
  }

  static getEmailVerifiedAsBoolean(emailVerified: string): boolean {
    return emailVerified === 'yes' ? true : false;
  }

  static isDaddyExpired(profile: IProfile): boolean {
    return (
      profile !== undefined &&
      profile.membership_type_id !== undefined &&
      profile.had_previous_membership_purchase !== undefined &&
      MembershipCommon.isDaddyMommyFreeType(profile.membership_type_id) &&
      UserCommon.isMale(profile) &&
      profile.had_previous_membership_purchase === 'yes'
    );
  }
}
