<mp-actionbar>
    <mp-actionbar-back-title title="{{ 'modules.main.pages.customer_support.title' | translate }}"></mp-actionbar-back-title>
</mp-actionbar>
<mp-loading *ngIf="loading"></mp-loading>
<div
    class="container-fluid"
    *ngIf="!loading && !messageSent"
>
    <h4 class="bold visible-md visible-lg">
        {{ 'modules.main.pages.customer_support.title' | translate }}
    </h4>
    <form
        class="page-form"
        (submit)="sendIssue()"
    >
        <div class="form-group">
            <label for="title">
                {{ 'modules.main.pages.customer_support.message_subject' | translate }}
            </label>
            <input
                class="form-control"
                type="text"
                id="title"
                placeholder="{{ 'common.fill_out_field' | translate }}"
                [(ngModel)]="title"
                [ngModelOptions]="{standalone: true}"
            >
        </div>
        <div class="form-group">
            <label for="newpass">
                {{ 'modules.main.pages.customer_support.message_body' | translate }}
            </label>
            <textarea
                class="form-control"
                rows="7"
                type="text"
                id="newpass"
                placeholder="{{ 'common.fill_out_field' | translate }}"
                [(ngModel)]="issue"
                [ngModelOptions]="{standalone: true}"
            ></textarea>
        </div>
        <button
            class="btn btn-lg"
            type="submit"
        >
            {{ 'common.submit' | translate }}
        </button>
    </form>
</div>
<div class="avaliation-snippet">
    <div
        id="reputation-ra"
        #reputationElement
    >
    </div>
</div>
<div
    class="container-fluid"
    *ngIf="!loading && messageSent"
>
    <h4 class="bold">
        {{ 'modules.main.pages.customer_support.thank_you' | translate }}
    </h4>
    <p>
        {{ 'modules.main.pages.customer_support.message_sent' | translate }}
    </p>
</div>
