import { Injectable } from '@angular/core';
import { IScripts } from '@libs/shared/interfaces/script-loader.interface';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private scripts: IScripts = {};

  public loadScript(name: string, src: string): Observable<void> {
    if (!this.scripts[name]) {
      this.scripts[name] = { loaded: false, src };
    }

    return new Observable((observer: Observer<void>) => {
      if (this.scripts[name].loaded) {
        observer.next();
        observer.complete();

        return;
      }

      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'script';
      link.href = this.scripts[name].src;

      link.onload = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.async = true;

        script.onload = () => {
          this.scripts[name].loaded = true;
          observer.next();
          observer.complete();
        };
        script.onerror = () => {
          observer.error('Error loading script: ' + src);
        };

        document.head.appendChild(script);
      };

      link.onerror = () => {
        observer.error('Error preloading script: ' + src);
      };

      document.head.appendChild(link);
    });
  }
}
