import { BoostCommon } from '@libs/shared/boost/boost.common';
import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@meupatrocinio/config';

export class BoostEligibilityHelpers {
  public static isUserEligibleToBoost(user: UserCommon): boolean {
    return this.isBabyEligibleToBoost(user) || this.isDaddyEligibleToBoost(user);
  }

  public static isBabyEligibleToBoost(user: UserCommon): boolean {
    if (
      !Config.isBoostForBabiesEnabled ||
      UserCommon.isUserCategory4(user) ||
      UserCommon.isUserCategory3(user) ||
      !BoostEligibilityHelpers.isBoostEnabled()
    ) {
      return false;
    }

    return BoostCommon.isBabyElegible(user);
  }

  public static isDaddyEligibleToBoost(user: UserCommon): boolean {
    if (!BoostEligibilityHelpers.isBoostEnabled()) {
      return false;
    }

    return BoostCommon.isDaddyElegible(user) && (UserCommon.isDaddyMommyPremium(user) || UserCommon.isElite(user));
  }

  public static isBoostEnabled(): boolean {
    return Config.isBoostEnabled;
  }

  public static isBoostPaymentEnabled(): boolean {
    return Config.isBoostPaymentEnabled;
  }

  public static canUseBoost(hasBoostRunningOrCredits: boolean): boolean {
    return hasBoostRunningOrCredits || BoostEligibilityHelpers.isBoostPaymentEnabled();
  }
}
