import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import { concatMap, repeatWhen, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { ILatestBoost } from '@libs/modules/boost/interface/boost-bff-endpoints';
import { IBoostTimeRemaining } from '@libs/modules/boost/interface/boost-time-remaining';
import { IApplicationState } from '@libs/store/application-state';
import { BoostActions, BoostSelectors } from '@libs/store/boost';
import { IBoostRunningInfo } from '@libs/store/boost/boost-running-info';
import { padStart } from '@libs/utils/pad-string';

@Injectable({
  providedIn: 'root',
})
export class BoostProgressServiceCommon implements OnDestroy {
  protected readonly MILLISECONDS_PER_DAY: number = 1000 * 60 * 60 * 24;
  protected readonly MILLISECONDS_PER_HOUR: number = 1000 * 60 * 60;
  protected readonly MILLISECONDS_PER_MINUTE: number = 1000 * 60;
  public readonly MILLISECONDS_PER_SECOND: number = 1000;
  public startCountdown$: Subject<void> = new Subject<void>();
  public stopCountdown$: Subject<void> = new Subject<void>();
  protected subscriptions: Subscription[] = [];

  constructor(protected store: Store<IApplicationState>) {
    //
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];
  }

  setBoostRunningInfo(boostInfo: ILatestBoost): void {
    const createdDateInMs: number = new Date(boostInfo.createdAt).getTime();

    this.store.dispatch(
      BoostActions.setBoostRunningInfo({
        boostRunning: {
          startTimestamp: createdDateInMs,
          endTimestamp: createdDateInMs + boostInfo.creditDurationInMs,
        },
      }),
    );
  }

  initilizeCountdownSubjects(): void {
    this.startCountdown$ = new Subject<void>();
    this.stopCountdown$ = new Subject<void>();
  }

  destroyCountdownSubjects(): void {
    this.startCountdown$.complete();
    this.stopCountdown$.complete();
  }

  startCountdownSequence(): void {
    this.startCountdown$.next();
  }

  stopCountdownSequence(): void {
    this.stopCountdown$.next();
  }

  getCountdown$(): Observable<IBoostTimeRemaining> {
    return interval(this.MILLISECONDS_PER_SECOND).pipe(
      withLatestFrom(this.store.pipe(select(BoostSelectors.selectBoostRunningInfo))),
      tap(([_, boostRunningInfo]: [number, IBoostRunningInfo]): void =>
        this.handleCountdownEndProgress(boostRunningInfo),
      ),
      concatMap(([_, boostRunningInfo]: [number, IBoostRunningInfo]): Observable<IBoostTimeRemaining> => {
        return of(this.getTimeRemainingAsString(boostRunningInfo.endTimestamp));
      }),
      takeUntil(this.stopCountdown$),
      repeatWhen((): Subject<void> => this.startCountdown$),
    );
  }

  getProgressInPercentage$(): Observable<string> {
    return interval(this.MILLISECONDS_PER_SECOND).pipe(
      withLatestFrom(this.store.pipe(select(BoostSelectors.selectBoostRunningInfo))),
      concatMap(([_, boostRunningInfo]: [number, IBoostRunningInfo]): Observable<string> => {
        return of(this.getRemainingPercentage(boostRunningInfo));
      }),
      takeUntil(this.stopCountdown$),
      repeatWhen((): Subject<void> => this.startCountdown$),
    );
  }

  getDefaultTimeRemainingValues(): IBoostTimeRemaining {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }

  getDefaultProgress(): string {
    return `${0}%`;
  }

  getTimeRemainingAsString(endBoostTimestamp: number): IBoostTimeRemaining {
    const timeDifference: number = this.getTimeDifference(endBoostTimestamp);
    const days: number = Math.floor(timeDifference / this.MILLISECONDS_PER_DAY);
    const hours: number = Math.floor((timeDifference % this.MILLISECONDS_PER_DAY) / this.MILLISECONDS_PER_HOUR);
    const minutes: number = Math.floor((timeDifference % this.MILLISECONDS_PER_HOUR) / this.MILLISECONDS_PER_MINUTE);
    const seconds: number = Math.floor((timeDifference % this.MILLISECONDS_PER_MINUTE) / this.MILLISECONDS_PER_SECOND);

    if (!this.isValidTimeRemaining(days, hours, minutes)) {
      return this.getDefaultTimeRemainingValues();
    }

    return {
      days: padStart(days.toString(), 2, '0'),
      hours: padStart(hours.toString(), 2, '0'),
      minutes: padStart(minutes.toString(), 2, '0'),
      seconds: padStart(seconds.toString(), 2, '0'),
    };
  }

  hasBoostEnded(endBoostTimestamp: number): boolean {
    return this.getTimeDifference(endBoostTimestamp) < 0;
  }

  protected handleCountdownEndProgress(boostRunningInfo: IBoostRunningInfo): void {
    if (!this.hasBoostEnded(boostRunningInfo.endTimestamp)) {
      return;
    }

    this.stopCountdownSequence();
  }

  protected getTimeDifference(endBoostTimestamp: number): number {
    return endBoostTimestamp - Date.now();
  }

  protected isValidPercentage(percentage: number): boolean {
    return percentage >= 0;
  }

  protected isValidTimeRemaining(days: number, hours: number, minutes: number): boolean {
    return days >= 0 && hours >= 0 && minutes >= 0;
  }

  protected getRemainingPercentage(boostRunningInfo: IBoostRunningInfo): string {
    const now: number = Date.now();
    const totalTime: number = boostRunningInfo.endTimestamp - boostRunningInfo.startTimestamp;
    const remainingTime: number = boostRunningInfo.endTimestamp - now;
    const percentage: number = Math.round((remainingTime / totalTime) * 100);

    if (!this.isValidPercentage(percentage)) {
      return this.getDefaultProgress();
    }

    return `${percentage}%`;
  }
}
