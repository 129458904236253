import { FocusTrapFactory } from '@angular/cdk/a11y';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { empty, merge, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AdvancedModalServiceCommon, IModalOptions } from '@libs/services/modal/advanced-modal.service.common';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IApplicationState } from '@libs/store/application-state';

@Injectable()
export class AdvancedModalService extends AdvancedModalServiceCommon implements OnDestroy {
  protected modals: ModalRef[] = [];
  protected subscriptions: Subscription[] = [];
  protected navigationEndEvent: Observable<Event>;

  protected readonly DEFAULT_MODAL_OPTIONS: IModalOptions<never> = {
    userDismissable: true,
    closesOnNavigation: false,
  };

  constructor(
    protected router: Router,
    protected injector: Injector,
    protected overlay: Overlay,
    protected focusTrap: FocusTrapFactory,
    protected store: Store<IApplicationState>,
  ) {
    super();

    this.navigationEndEvent = this.router.events.pipe(filter((event): boolean => event instanceof NavigationEnd));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];

    this.modals.forEach((modal): void => modal.close());
  }

  public open<T extends IModalComponent<T['data']>>(
    component: ComponentType<T['data']>,
    options: IModalOptions<T['data']> = this.DEFAULT_MODAL_OPTIONS,
  ): ModalRef {
    this.closeAll();

    const overlayRef = this.overlay.create(this.getOverlayConfig());
    const focusTrap = this.focusTrap.create(overlayRef.overlayElement, true);
    const modalRef = new ModalRef(focusTrap, overlayRef, <HTMLElement>document.activeElement);

    this.handleModalOptions(options, modalRef, overlayRef);
    this.attachModalContainer(component, overlayRef, options.data, modalRef);

    focusTrap.attachAnchors();
    focusTrap.focusInitialElementWhenReady();

    this.modals.push(modalRef);

    return modalRef;
  }

  protected getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      disposeOnNavigation: true,
      backdropClass: 'mp-modal-backdrop',
      panelClass: 'mp-modal-content',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
  }

  protected attachModalContainer<T extends IModalComponent<T['data']>>(
    component: ComponentType<T['data']>,
    overlayRef: OverlayRef,
    data: T['data'],
    modalRef: ModalRef,
  ): void {
    overlayRef.attach(new ComponentPortal(component, null, this.createInjector(data, modalRef)));
  }

  protected createInjector<T extends IModalComponent<T['data']>>(data: T['data'], modalRef: ModalRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(ModalRef, modalRef);
    injectionTokens.set(MODAL_DATA, data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  protected closeAll(): void {
    this.modals.forEach((modal): void => modal.close());
  }

  protected handleModalOptions<T extends IModalComponent<T['data']>>(
    options: IModalOptions<T['data']>,
    modalRef: ModalRef,
    overlayRef: OverlayRef,
  ): void {
    let observable$: Observable<UIEvent | Event> = empty();

    if (options.userDismissable) {
      observable$ = merge(
        observable$,
        overlayRef.backdropClick(),
        overlayRef.keydownEvents().pipe(filter((event): boolean => event.key === 'Escape')),
      );
    }

    if (options.closesOnNavigation) {
      observable$ = merge(observable$, this.navigationEndEvent);
    }

    this.subscriptions.push(
      observable$.subscribe((): void => {
        modalRef.close();
      }),
    );
  }
}
