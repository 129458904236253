import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { NotificationEffectsCommon } from '@libs/effects/notification/notification.effects.common';
import { IApplicationState } from '@libs/store/application-state';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { SocketService } from '@meupatrocinio/services/socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationEffects extends NotificationEffectsCommon {
  constructor(
    protected actions$: Actions,
    protected store: Store<IApplicationState>,
    protected socketService: SocketService,
    protected authenticationService: AuthenticationService,
    protected downloadManagerService: DownloadManagerService,
  ) {
    super(actions$, store, socketService, authenticationService, downloadManagerService);
  }
}
