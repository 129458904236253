import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { IPaymentData } from '@libs/modules/main/shared/payment-modal-form/payment-data';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { PaymentModalFormServiceCommon } from '@libs/services/payment-modal-form/payment-modal-form.service.common';

@Directive()
export class PaymentModalFormComponentCommon implements OnInit {
  @Input() submitButtonTranslation = 'modules.main.pages.payment.pay';
  @Input() showLoading = false;
  @Output() readonly paymentData: EventEmitter<IPaymentData> = new EventEmitter<IPaymentData>();
  public paymentModalForm: UntypedFormGroup = this.paymentModalFormService.getInitialValue();
  public isValidCardNumber = true;
  public isValidDate = true;
  public isValidCVV = true;
  public isValidHolder = true;
  public isValidCPF = true;

  constructor(
    protected paymentModalFormService: PaymentModalFormServiceCommon,
    protected paymentHelpersService: PaymentHelpersService,
  ) {
    //
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.paymentModalForm = this.paymentModalFormService.getFormGroup();
  }

  makePayment(): void {
    if (!this.isFormGroupValid()) {
      return;
    }

    this.handlePaymentData();
  }

  isFormGroupValid(): boolean {
    return this.paymentModalFormService.isFormValid();
  }

  isIssuedAbroad(): boolean {
    return this.paymentModalFormService.getIsIssuedAbroad();
  }

  getErrorMessage(controlName: string): string {
    return this.paymentModalFormService.getFormValidationErrosMessage(controlName);
  }

  handleCardNumber(): void {
    this.formatCardNumberValue();
    this.checkCardNumberValidation();
    this.checkCVVValidation();
  }

  formatCardNumberValue(): void {
    this.paymentModalFormService.setCardNumber(
      this.paymentHelpersService.formatNumber(this.paymentModalFormService.getCardNumber()),
    );
  }

  checkCardNumberValidation(): void {
    this.isValidCardNumber =
      this.paymentHelpersService.isValidCardNumber(this.paymentModalFormService.getCardNumber()) ||
      this.paymentModalFormService.isCardNumberPristine();
  }

  checkCVVValidation(): void {
    this.isValidCVV =
      (this.paymentModalFormService.isValidCVV() && !this.paymentModalFormService.groupHasInvalidCVVError()) ||
      this.paymentModalFormService.isPristineCVV();
  }

  checkHolderValidation(): void {
    this.isValidHolder = this.paymentModalFormService.isValidHolder();
  }

  handleDate(): void {
    this.formatDateValue();
    this.checkDateValidation();
  }

  formatDateValue(): void {
    this.paymentModalFormService.setDate(this.paymentHelpersService.formatDate(this.paymentModalFormService.getDate()));
  }

  checkDateValidation(): void {
    this.isValidDate = this.paymentModalFormService.isValidDate();
  }

  handleIsIssuedAbroad(checked: boolean): void {
    this.paymentModalFormService.setIsIssuedAbroad(checked);
    this.paymentModalFormService.handleDefaultCPF(checked);
  }

  handleCPF(): void {
    this.formatCPFValue();
    this.checkCPFValidation();
  }

  formatCPFValue(): void {
    this.paymentModalFormService.setCPF(this.paymentHelpersService.formatNumber(this.paymentModalFormService.getCPF()));
  }

  checkCPFValidation(): void {
    this.isValidCPF = this.paymentModalFormService.isCPFValid();
  }

  handlePaymentData(): void {
    this.paymentData.emit({
      cardNumber: this.paymentModalFormService.getCardNumber(),
      date: this.paymentModalFormService.getDate(),
      cvv: this.paymentModalFormService.getCvv(),
      holder: this.paymentModalFormService.getHolder(),
      cpf: this.paymentModalFormService.getCPF(),
    });
  }
}
