export enum ProductVariantIdentifier {
  EXPRESS_APPROVAL = '4c1cdbbb-1df5-45e0-bb13-db8d10e533f9',

  TRIAL_DADDY_PREMIUM_7_DAYS = '08c34900-7226-4cbb-a03b-6f5920088f80',

  DADDY_PREMIUM_15_DAYS = 'ad0ff771-9a47-4b79-bc71-d328811d34bc',
  DADDY_PREMIUM_1_MONTH = '78d1f94d-e5cb-4894-bd4b-3322ed10fac9',
  DADDY_PREMIUM_3_MONTHS = 'dc26c188-e2ac-4283-a085-43b98a06cf3a',
  DADDY_PREMIUM_6_MONTHS = '4db6917f-4703-4637-aceb-545be1739726',

  MEMBERSHIP_DADDY_PREMIUM_SIX_MONTHS_AB_TEST = 'cf5e6372-ac48-4269-9cb7-b79557c9ca2e',
  MEMBERSHIP_DADDY_PREMIUM_THREE_MONTHS_AB_TEST = '0fe7fed8-0f79-43f9-9b28-a13a5251b5d7',

  DADDY_PREMIUM_PLUS_1_MONTH = 'fc748487-3d7f-4b2d-8e55-f13e453b0057',

  DADDY_ELITE_1_MONTH = '35c95102-8630-4f5f-aee1-bed41e801036',
  DADDY_ELITE_3_MONTHS = '44ddf0e9-ca53-4adb-8cd0-e3a24b7858d5',
  DADDY_ELITE_6_MONTHS = '11c56322-6e5b-4c07-b6de-ee378bef0fe9',

  BABY_PREMIUM_1_MONTH = '4bfd5c53-d604-4e90-8308-668936ca0879',
  BABY_PREMIUM_3_MONTHS = '052f9989-4ce5-4226-bd47-459087d6ea95',
  BABY_PREMIUM_6_MONTHS = 'b25e3021-700c-4aaa-a400-b19264c1d9ed',
}
