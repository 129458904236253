import { Action } from '@ngrx/store';

export const AUTH_TOKEN_ACTION: string = 'AUTH_TOKEN';

export interface ITmpTokenPayload {
  tmpToken: string;
}

export class SetTmpTokenAction implements Action {
  readonly type: string = AUTH_TOKEN_ACTION;

  constructor(public payload: ITmpTokenPayload) {
    //
  }
}
