import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IProfile } from '@libs/shared/profile/profile';
import { ProfilesSelectors } from '@libs/store/profiles-v2';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';

import { UserCommon } from '@libs/shared/user/user.common';
import { UserProfileSelectors } from '@libs/store/authentication';
import { ReportFormControlName } from '@meupatrocinio/modules/main/modals/report-modal/enum/report-form-control-names';
import { IModalData } from '@meupatrocinio/modules/main/modals/report-modal/interface/modal-data.interface';
import { ReportService } from '@meupatrocinio/modules/main/modals/report-modal/service/report.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { TypeFormService } from '@meupatrocinio/services/type-form/type-form.service';
import { UserService } from '@meupatrocinio/services/user.service';

@Component({
  selector: 'mp-report-modal',
  templateUrl: './report-modal.html',
  styleUrls: ['./report-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportModalComponent implements IModalComponent<IModalData>, OnDestroy {
  public showInvalidFormMessage = false;
  protected profileReported: IProfile;
  public reason: string = ReportFormControlName.REASON;
  public subscriptions: Subscription[] = [];
  public user: UserCommon;

  constructor(
    protected store: Store,
    protected modalService: ModalService,
    protected reportService: ReportService,
    protected modalRef: ModalRef,
    protected translateService: TranslateService,
    protected userService: UserService,
    protected typeFormService: TypeFormService,
    @Inject(MODAL_DATA) public data: IModalData,
  ) {
    this.subscriptions.push(this.getProfileData(data).subscribe());
    this.subscriptions.push(
      this.store.select(UserProfileSelectors.selectUser).subscribe((user: UserCommon): void => {
        this.user = user;
      }),
    );
    this.reportService.setupReportForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  protected getProfileData(data: IModalData): Observable<IProfile> {
    return this.store.pipe(
      select(ProfilesSelectors.selectProfileById(data.profileId)),
      tap({
        next: (profile: IProfile): void => {
          if (!profile || !profile.profile_id) {
            return;
          }
          this.profileReported = profile;
        },
      }),
    );
  }

  public get reportModalForm(): UntypedFormGroup {
    return this.reportService.getReportFormInstance();
  }

  public get reasonMessage(): string {
    return FormHelpers.getFormControlValue(this.reportModalForm, ReportFormControlName.REASON);
  }

  public close(): void {
    this.modalRef.close();
  }

  public submit() {
    if (!this.isFormValid()) {
      this.showInvalidFormMessage = true;
      return;
    }

    this.typeFormService.callTypeForm(this.user, this.profileReported);

    this.showInvalidFormMessage = false;

    this.close();
    this.subscriptions.push(this.send$(this.reasonMessage).subscribe());
  }

  public send$(reason: string): Observable<IAuthResponse> {
    return this.reportService.report$(
      this.profileReported.profile_id,
      reason,
      this.successCallback,
      this.errorCallback,
    );
  }

  protected isFormValid(): boolean {
    return FormHelpers.isFormValid(this.reportModalForm);
  }

  protected successCallback = (): void => {
    this.modalService.open('modules.main.pages.report.success');
  };

  protected errorCallback = (): void => {
    this.modalService.open('common.unknown_error');
  };

  public fieldFillingInstruction(): string {
    return this.translateService.instant('modules.main.pages.report.intro1');
  }

  public noticeAboutProfileToBeReported(): string {
    return this.translateService.instant('modules.main.pages.report.intro2', {
      username: this.profileReported.username,
    });
  }

  public title(): string {
    return this.translateService.instant('common.report');
  }

  public placeholderTextarea(): string {
    return this.translateService.instant('modules.main.pages.report.body');
  }

  public invalidFormMessage(): string {
    return this.translateService.instant('common.all_fields_required');
  }

  public closeModalButtonText(): string {
    return this.translateService.instant('common.close');
  }

  public submitModalButtonText(): string {
    return this.translateService.instant('common.submit');
  }
}
