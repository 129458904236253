<div class="new-password outside-body">
    <div class="outside-banner new-pass visible-md visible-lg"></div>
    <div class="outside-page">
        <div class="container header">
            <div class="row">
                <div class="col-xs-12 col-md-6 col-lg-5">
                    <div class="fluid-container">
                        <div class="row">
                            <div class="col-xs-12">
                                <img src="/assets/img/meu-patrocinio-logo-v02.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div
                class="row new-password-xs-sm-center"
                *ngIf="!isLoading()"
            >
                <div class="col-xs-12 col-md-6 col-lg-5">
                    <h1>
                        {{'modules.main.pages.new_password.title' | translate}}.
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-6 col-lg-5">
                    <mp-loading *ngIf="isLoading()"></mp-loading>
                    <div
                        class="page-form new-password-form"
                        *ngIf="!isLoading()"
                    >
                        <div class="row">
                            <div class="form-group col-xs-12">
                                <label for="newpass">
                                    {{'modules.main.pages.change_password.new_password' | translate}}
                                </label>
                                <input
                                    class="form-control"
                                    type="password"
                                    id="newpass"
                                    [(ngModel)]="password"
                                    placeholder="{{ 'modules.main.pages.change_password.new_password.placeholder' | translate }}"
                                >
                                <i
                                    class="material-icons"
                                    aria-hidden="true"
                                >
                                    {{ passwordValidateIcon }}
                                </i>
                            </div>
                            <div class="form-group col-xs-12">
                                <label for="newpassconfirm">
                                    {{'modules.main.pages.change_password.confirm_new_password' | translate}}
                                </label>
                                <input
                                    class="form-control"
                                    type="password"
                                    [(ngModel)]="passwordConfirmation"
                                    id="newpassconfirm"
                                    placeholder="{{ 'modules.main.pages.change_password.confirm_new_password.placeholder' | translate }}"
                                >
                                <i
                                    class="material-icons"
                                    aria-hidden="true"
                                >
                                    {{ passwordConfirmationValidateIcon }}
                                </i>
                            </div>
                            <div class="form-group col-xs-12">
                                <button
                                    class="main-button btn btn-lg"
                                    type="submit"
                                    (click)="proceed()"
                                >
                                    {{ 'modules.main.pages.change_password.save_new_password' | translate }}
                                </button>
                            </div>
                        </div>
                        <p class="small">
                            {{'modules.main.pages.new_password.advise' | translate}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
