import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ValidationGroupErrorComponentCommon } from '@libs/utils/components/validation-group/validation-group-error.component.common';

@Component({
  selector: 'mp-validation-group-error',
  templateUrl: './validation-group-error.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./validation-group-error.scss'],
})
export class ValidationGroupErrorComponent extends ValidationGroupErrorComponentCommon {
  //
}
