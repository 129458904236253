import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IReferralStatus } from '@libs/shared/referral/referral-status';

export interface IReferralStatusState extends EntityState<IReferralStatus> {
  //
}

function selectReferralStatusId(referralStatus: IReferralStatus): string | null {
  return referralStatus.code;
}

export const referralStatusAdapter: EntityAdapter<IReferralStatus> = createEntityAdapter<IReferralStatus>({
  selectId: selectReferralStatusId,
});

export const referralStatusInitialState: IReferralStatusState = referralStatusAdapter.getInitialState({});
