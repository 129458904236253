<ng-container *ngIf="profiles$ | async as profiles">
    <div class="melt-toast">
        <button
            class="melt-toast__close"
            (click)="close()"
            [attr.aria-label]="'common.close' | translate"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <img
            class="melt-toast__caramel"
            src="/assets/img/melted-caramel-toast.svg"
        />
        <div class="melt-toast__images">
            <mp-img-srcset
                *ngFor="let image of getImages(profiles); trackBy: trackByFn"
                [url]="image"
                imgClass="melt-toast__images__avatar"
                place="toast"
            ></mp-img-srcset>
        </div>

        <div class="melt-toast__infos">
            <div class="melt-toast__infos__logo">
                <img src="/assets/img/melt-logo-fill.svg" alt="melt logo" />
            </div>
            <span class="melt-toast__infos__title">{{ profiles | meltToastTitle }}</span>
        </div>
    </div>
</ng-container>

