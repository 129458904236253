<div [formGroup]="form">
    <div
        class="registration-form-field"
        [ngClass]="getFeedbackModifierClass()"
    >
        <label>{{ label | translate }}</label>
        <input
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
            [formControlName]="controlName"
            [placeholder]="placeholder | translate"
            [type]="type"
            autocomplete="new-password"
            autocapitalize="{{ autoCapitalize }}"
        >
        <div
            *ngIf="checkField"
            class="validating-field"
        ></div>
        <mp-validation-control-error
            *ngIf="canShowValidationError()"
            [requiredMessage]="requiredMessage"
            [form]="form"
            [controlName]="controlName"
        ></mp-validation-control-error>
        <ng-container *ngIf="shouldShowSuccessMessage">
            <mp-validation-success
                *ngIf="canShowSuccessMessage()"
                [successMessage]="successMessage"
                [form]="form"
            ></mp-validation-success>
        </ng-container>
    </div>
</div>
