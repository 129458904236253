import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';

import { IApplicationState } from '@libs/store/application-state';
import { RegistrationInputedAction } from '@libs/store/registration/actions/registration.action';

@Directive()
export class FrictionlessStep0ComponentCommon {
  public acceptTerms: boolean;

  constructor(protected store: Store<IApplicationState>) {
    //
  }

  public commitStep(): void {
    this.store.dispatch(new RegistrationInputedAction({ data: {} }));
  }
}
