import { Action } from '@ngrx/store';

export const NOTIFICATION_TOKEN_ACTION: string = 'NOTIFICATION_TOKEN_ACTION';

export class NotificationTokenAction implements Action {
  readonly type: string = NOTIFICATION_TOKEN_ACTION;

  constructor(public payload: string) {
    //
  }
}
