import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';

export interface IMeltProfilesState extends EntityState<IProfile> {
  lastUpdateTimestamp: number;
}

function selectProfileId(profile: IProfile): number {
  return profile.profile_id;
}

export const meltProfilesAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>({
  selectId: selectProfileId,
});

export const meltProfilesInitialState: IMeltProfilesState = meltProfilesAdapter.getInitialState({
  lastUpdateTimestamp: 0,
});
