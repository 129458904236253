import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';

import { TooltipComponent } from '@libs/components/tooltip/tooltip.component';
import { AdvancedModalUiComponent } from '@libs/really-shared/advanced-modal-ui/advanced-modal-ui.component';
import { AdvancedTranslateComponent } from '@libs/really-shared/advanced-translate/advanced-translate.component';
import { TranslateParamDirective } from '@libs/really-shared/advanced-translate/translate-param.directive';
import { DivideByOneHundred } from '@libs/really-shared/divide-by-one-hundred/divide-by-one-hundred.pipe';
import { GenerateRangePipe } from '@libs/really-shared/generate-range/generate-range.pipe';
import { LineBreaker } from '@libs/really-shared/line-breaker/line-breaker.pipe';
import { PaymentSecurityWarningsComponent } from '@libs/really-shared/payment-security-warnings/payment-security-warnings.component';
import { MPWebMissingTranslationHandler } from '@libs/utils/missing-translation-handler.web';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MPWebMissingTranslationHandler,
      },
    }),
  ],
  declarations: [
    AdvancedTranslateComponent,
    TranslateParamDirective,
    PaymentSecurityWarningsComponent,
    LineBreaker,
    DivideByOneHundred,
    GenerateRangePipe,
    AdvancedModalUiComponent,
    TooltipComponent,
  ],
  exports: [
    AdvancedTranslateComponent,
    TranslateParamDirective,
    PaymentSecurityWarningsComponent,
    LineBreaker,
    DivideByOneHundred,
    GenerateRangePipe,
    AdvancedModalUiComponent,
    TooltipComponent,
  ],
})
export class ReallySharedModule {
  //
}
