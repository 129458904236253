import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import {
  CPFValidations,
  CVVValidations,
  CardNumberValidatons,
  DateValidations,
  HolderValidations,
  InstallmentValidations,
  getNestableControl,
} from '@libs/services/payment/validators';
import { Interest } from '@libs/shared/money/interest/interest';
import { Money } from '@libs/shared/money/money';
import { PaymentInfoActions, PaymentInfoSelectors } from '@libs/store/payment-info';

import { UuidGenerator } from '@libs/utils/uuid-generator/uuid-generator';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions';

@Component({
  selector: 'mp-card-payment-form',
  templateUrl: './card-payment-form.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPaymentFormComponent {
  @Input() isExpressApproval = false;
  @Input() type = 0;
  @Input() period = 0;
  @Input() price;
  @Input() productDescription = '';
  @Input() productUuid = '';
  @Input() variantUuid = '';

  @Output() public selectInstallments: EventEmitter<number> = new EventEmitter<number>();

  public paymentGroup: UntypedFormGroup = this.formBuilder.group({});
  public installmentsWithInterest: Money = new Money(0);

  public readonly HOLDER = 'holder';
  public readonly CARD_NUMBER = 'cardNumber';
  public readonly DATE = 'date';
  public readonly CPF = 'cpf';
  public readonly CVV = 'cvv';
  public readonly INSTALLMENTS = 'installments';
  public readonly INVALID_CVV = 'invalidCv';

  public validCardNumber = true;
  public validDate = true;
  public validCVV = true;
  public validHolder = true;
  public validCPF = true;
  public validInstallment = true;
  public isPaying$ = this.store.pipe(select(PaymentInfoSelectors.selectIsPaying));

  protected interest = 0;
  protected previousCPF = '';
  protected previousInstallmentsOption = 0;

  protected readonly IS_ISSUED_ABROAD: string = 'isIssuedAbroad';
  protected readonly ONE_INSTALLMENT: number = 1;
  protected readonly PLACEHOLDER_OPTION: number = 0;
  protected readonly FIRST_INSTALLMENT_OPTION: number = 1;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected paymentHelpersService: PaymentHelpersService,
    protected router: Router,
    protected store: Store,
    protected translateService: TranslateService,
  ) {
    //
  }

  ngOnInit() {
    this.setPayingStatusAsFalse();
    this.buildPaymentFormGroup();
  }

  protected setPayingStatusAsFalse() {
    this.store.dispatch(PaymentInfoActions.setIsPaying({ isPaying: false }));
  }

  protected buildPaymentFormGroup() {
    this.paymentGroup = this.formBuilder.group(
      {
        [this.HOLDER]: ['', HolderValidations.validations.validation],
        [this.CARD_NUMBER]: ['', CardNumberValidatons.validations.validation],
        [this.DATE]: ['', DateValidations.validations.validation],
        [this.CVV]: ['', CVVValidations.validations.validation],
        [this.CPF]: ['', CPFValidations.validations.validation],
        [this.IS_ISSUED_ABROAD]: [false],
        [this.INSTALLMENTS]: [this.FIRST_INSTALLMENT_OPTION, InstallmentValidations.validations.validation],
      },
      {
        validators: CVVValidations.validateCvv,
      },
    );
  }

  public makePayment() {
    const idempotencyKey = UuidGenerator.generate();
    const paymentData = {
      idempotencyKey,
      paymentData: {
        holderInfo: {
          name: this.getHolder(),
          cpf: this.getCPF(),
          membershipId: this.type,
        },
        cardInfo: {
          date: this.getDate(),
          cardNumber: this.getCardNumber(),
          cvcNumber: this.getCvv(),
          installments: this.getInstallments(),
          interest: this.getInterest(),
        },
        purchaseInfo: {
          subtotalAmount: this.getSubtotalAmount(),
          totalAmount: this.getTotalAmount(),
          product: {
            product_uuid: this.productUuid,
            variant_uuid: this.variantUuid,
          },
        },
      },
      productDescription: this.productDescription,
    };

    this.store.dispatch(ProviderSwitchActions.prepareCreditCardPayment(paymentData));
    this.scrollToTop();
  }

  protected scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public handleCardNumber() {
    this.formatCardNumber();
    this.checkCardNumber();
  }

  protected formatCardNumber() {
    this.paymentGroup
      .get(this.CARD_NUMBER)
      .setValue(this.paymentHelpersService.formatNumber(this.paymentGroup.get(this.CARD_NUMBER).value));
  }

  protected checkCardNumber() {
    this.validCardNumber = this.isValidCardNumber() || this.paymentGroup.get(this.CARD_NUMBER).pristine;
    this.checkCVV();
  }

  protected isValidCardNumber() {
    return this.paymentHelpersService.isValidCardNumber(this.getCardNumber());
  }

  public checkCVV() {
    const controlCVV: AbstractControl = this.paymentGroup.get(this.CVV);

    this.validCVV = (controlCVV.valid && !this.paymentGroup.hasError(this.INVALID_CVV)) || controlCVV.pristine;
  }

  protected getHolder() {
    return this.paymentGroup.get(this.HOLDER).value.toString();
  }

  protected getCardNumber() {
    return this.paymentGroup.get(this.CARD_NUMBER).value.toString();
  }

  protected getCvv() {
    return this.paymentGroup.get(this.CVV).value.toString();
  }

  protected getCPF() {
    return this.paymentGroup.get(this.CPF).value.toString();
  }

  protected getDate() {
    return this.paymentGroup.get(this.DATE).value.toString();
  }

  protected getInstallments() {
    return this.paymentGroup.get(this.INSTALLMENTS).value;
  }

  protected getInterest() {
    return this.interest;
  }

  protected getTotalAmount() {
    return new Interest(new Money(this.getSubtotalAmount()), this.getInterest()).getTotal().toNumber();
  }

  protected getSubtotalAmount() {
    return this.price;
  }

  public handleDate() {
    this.formatDate();
    this.checkDate();
  }

  protected formatDate() {
    this.paymentGroup
      .get(this.DATE)
      .setValue(this.paymentHelpersService.formatDate(this.paymentGroup.get(this.DATE).value));
  }

  protected checkDate() {
    const controlDate: AbstractControl = this.paymentGroup.get(this.DATE);

    this.validDate = controlDate.valid || controlDate.pristine;
  }

  public checkHolder() {
    const controlHolder: AbstractControl = this.paymentGroup.get(this.HOLDER);

    this.validHolder = controlHolder.valid || controlHolder.pristine;
  }

  public checkIsIssuedAbroad() {
    if (this.isIssuedAbroad()) {
      this.previousCPF = this.getCPF();
      this.paymentGroup.get(this.CPF).setValue(PaymentCommon.DEFAULT_CPF);
      this.paymentGroup.get(this.CPF).setValidators(null);
      this.paymentGroup.get(this.CPF).updateValueAndValidity();
      this.checkCPF();
      this.saveSelectedInstallmentsOption();
      this.updateInstallments(this.ONE_INSTALLMENT);

      return;
    }

    this.uncheckIsIssuedAbroad();
  }

  public isIssuedAbroad() {
    return this.paymentGroup.get(this.IS_ISSUED_ABROAD).value;
  }

  protected saveSelectedInstallmentsOption() {
    if (this.getInstallments() === this.PLACEHOLDER_OPTION) {
      this.previousInstallmentsOption = this.ONE_INSTALLMENT;
    }

    if (this.getInstallments() >= this.ONE_INSTALLMENT) {
      this.previousInstallmentsOption = this.getInstallments();
    }
  }

  protected uncheckIsIssuedAbroad() {
    this.paymentGroup.get(this.CPF).setValue(this.previousCPF);
    this.paymentGroup.get(this.CPF).setValidators(<ValidatorFn[]>CPFValidations.validations.validation);
    this.paymentGroup.get(this.CPF).updateValueAndValidity();
    this.checkCPF();
    this.updateInstallments(this.previousInstallmentsOption);
  }

  public handleCPF() {
    this.formatCPF();
    this.checkCPF();
  }

  protected formatCPF() {
    this.paymentGroup
      .get(this.CPF)
      .setValue(this.paymentHelpersService.formatNumber(this.paymentGroup.get(this.CPF).value));
  }

  protected checkCPF() {
    const cpfControl: AbstractControl = this.paymentGroup.get(this.CPF);

    this.validCPF = cpfControl.valid || cpfControl.pristine;
  }

  protected updateInstallments(value: number) {
    this.paymentGroup.get(this.INSTALLMENTS).setValue(value);
    this.paymentGroup.get(this.INSTALLMENTS).updateValueAndValidity();
    this.checkInstallments();
  }

  public checkInstallments() {
    const controlInstallment: AbstractControl = this.paymentGroup.get(this.INSTALLMENTS);

    this.validInstallment = controlInstallment.valid || controlInstallment.pristine;

    if (this.validInstallment) {
      this.handleInterest();
    }
  }

  protected handleInterest() {
    this.interest = this.getInterestFromInstallment(this.paymentGroup.get(this.INSTALLMENTS).value);
    this.installmentsWithInterest = this.getTotalAmountWithInterest(this.interest + 1);
    this.triggerEmitInstallments();
  }

  protected getInterestFromInstallment(installment: number) {
    return Interest.InstalmentToInterest[installment];
  }

  protected triggerEmitInstallments() {
    this.selectInstallments.emit(this.installmentsWithInterest.toNumber());
  }

  protected getTotalAmountWithInterest(installment: number) {
    const interest: number = Interest.InstalmentToInterest[installment];

    if (installment === this.ONE_INSTALLMENT) {
      return new Money(this.price);
    }

    return new Interest(new Money(this.price), interest).getTotal();
  }

  public getInstallmentsOptions() {
    const installments: string[] = [];
    const numberOfInstallments: number = this.getNumberOfInstallments();

    installments.push(this.translateService.instant('modules.main.pages.payment.installmentOption'));

    for (let i = 1; i <= numberOfInstallments; i++) {
      const totalAmountWithInterest: Money = this.getTotalAmountWithInterest(i);
      const installmentAmount: Money = new Money(totalAmountWithInterest.toNumber() / i);

      installments.push(
        this.installmentSelectLabel(i, installmentAmount.toString(), totalAmountWithInterest.toString()),
      );
    }

    return installments;
  }

  protected getNumberOfInstallments() {
    if (!this.isInstallmentsAllowed()) {
      return 1;
    }

    return this.period;
  }

  protected isInstallmentsAllowed() {
    if (this.period === 1 || this.isExpressApproval || PaymentCommon.isTrialPayment(this.type, this.period)) {
      return false;
    }

    return true;
  }

  protected installmentSelectLabel(installment: number, value: string, totalAmountWithInterest: string) {
    return this.translateService.instant('modules.main.pages.payment.installment', {
      installment,
      value: this.paymentHelpersService.formatCurrency(value),
      total: totalAmountWithInterest,
    });
  }

  public canSubmit() {
    if (!this.isIssuedAbroad()) {
      this.previousCPF = this.getCPF();
    }

    return this.paymentGroup.valid;
  }

  public handleDisabledInstallments(index: number) {
    if (this.isIssuedAbroad()) {
      return index !== this.ONE_INSTALLMENT;
    }

    return this.onlyFirstOptionDisabled(index);
  }

  protected onlyFirstOptionDisabled(index: number) {
    return index === this.PLACEHOLDER_OPTION;
  }

  public getErrorMessage(controlName: string) {
    return this.translateService.instant(this.getValidationMessage(controlName));
  }

  protected getValidationMessage(controlName: string) {
    const errors = getNestableControl(controlName, this.paymentGroup).errors;

    const fieldsTovalidate = {
      [this.HOLDER]: HolderValidations.validations.messages,
      [this.CARD_NUMBER]: CardNumberValidatons.validations.messages,
      [this.DATE]: DateValidations.validations.messages,
      [this.CPF]: CPFValidations.validations.messages,
      [this.CVV]: CVVValidations.validations.messages,
      [this.INSTALLMENTS]: InstallmentValidations.validations.messages,
    };

    if (fieldsTovalidate[controlName] !== undefined) {
      return fieldsTovalidate[controlName](errors);
    }

    return '';
  }
}
