import { createAction, props } from '@ngrx/store';

import { IAvailableTrial, ITrialModalData, ITrialModalOnce, ITrialModalTimer } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';

enum Actions {
  SET_GLOBAL_TRIAL_ACTION = '[Trial] - Set Global Trial Action',
  UPDATE_TRIALS = '[Trial] - Update All Trials Options',
  SET_GAME_TRIAL_ACTION = '[Trial] - Set Game Trial Action',
  SET_LINK_TRIAL_ACTION = '[Trial] - Set Link Trial Action',
  OPEN_TRIAL_MODAL = '[Trial] - Open Trial Modal',
  INITIALIZE_TRIAL_TIMER_MODAL = '[Trial] - Initialize Trial Timer Modal',
  STOP_TRIAL_TIMER = '[Trial] - Stop Trial Timer',
  INITIALIZE_TRIAL_ONCE_MODAL = '[Trial] - Initialize Trial Once Modal',
  LOAD_AVAILABLE_TRIAL = '[Trial] - Loading global trial from api endpoint',
  LOAD_TRIAL_OPTIONS = '[Trial] - Loading all trials from api endpoint',
}

export const loadAvailableTrial = createAction(Actions.LOAD_AVAILABLE_TRIAL);

export const loadTrialOptions = createAction(Actions.LOAD_TRIAL_OPTIONS);

export const setGlobalTrial = createAction(Actions.SET_GLOBAL_TRIAL_ACTION, props<{ trial: IAvailableTrial }>());

export const setLinkTrial = createAction(Actions.SET_LINK_TRIAL_ACTION, props<{ period: number }>());

export const updateTrials = createAction(Actions.UPDATE_TRIALS, props<{ trials: IAvailableTrial[] }>());

export const setGameTrial = createAction(Actions.SET_GAME_TRIAL_ACTION, props<{ period: number }>());

export const initializeTrialTimerModal = createAction(
  Actions.INITIALIZE_TRIAL_TIMER_MODAL,
  props<{ trialProps: ITrialModalTimer }>(),
);

export const stopTrialTimer = createAction(Actions.STOP_TRIAL_TIMER);

export const initializeTrialOnceModal = createAction(
  Actions.INITIALIZE_TRIAL_ONCE_MODAL,
  props<{ trialProps: ITrialModalOnce }>(),
);

export const openTrialModal = createAction(
  Actions.OPEN_TRIAL_MODAL,
  props<{ trial: Trial; modalData: ITrialModalData }>(),
);
