import { Action } from '@ngrx/store';

import { IRegion } from '@libs/services/location/region/region';

export const REGIONS_RECEIVED_ACTION: string = 'REGIONS_RECEIVED_ACTION';

export interface IRegionsReceivedActionPayload {
  regions: IRegion[];
}

export class RegionsReceivedAction implements Action {
  readonly type: string = REGIONS_RECEIVED_ACTION;

  constructor(public payload: IRegionsReceivedActionPayload) {
    //
  }
}
