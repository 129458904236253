import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PixStateFacadeService } from '@libs/modules/payment-v2/services/pix-state-facade.service';
import { truncateWithoutParentheses } from '@libs/utils/truncate-string';

@Component({
  selector: 'mp-payment-modal-qr-code-result',
  templateUrl: './payment-modal-qr-code-result.html',
  styleUrls: ['./payment-modal-qr-code-result.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentModalQrCodeResultComponent implements OnInit, OnDestroy {
  public time$: Observable<string> = of('15:00');

  @Input() public qrCode = '';
  @Input() public copyCode = '';
  @Input() public shouldHideQrCode = false;
  @Input() public qrCodeGenerationTimestamp = 0;

  @Output() onClose = new EventEmitter<void>();
  @Output() onNavigateAwayFromResult = new EventEmitter<void>();

  constructor(
    private readonly clipboard: Clipboard,
    private readonly pixStateFacade: PixStateFacadeService,
  ) {
    //
  }

  public ngOnInit() {
    this.pixStateFacade.selectExpirationTime(this.qrCodeGenerationTimestamp);
    this.time$ = this.pixStateFacade.expirationTime$;
    //
  }

  public ngOnDestroy() {
    this.pixStateFacade.finishExpirationTime();
    this.finish();
  }

  public finish() {
    this.onNavigateAwayFromResult.emit();
  }

  public handleClose() {
    this.onClose.emit();
  }

  public canShowPaymentContent() {
    return this.qrCode !== '' && this.copyCode !== '';
  }

  public truncateCopyCode(copyCode: string) {
    return truncateWithoutParentheses(copyCode, 28);
  }

  public copyToClipboard(url: string) {
    this.clipboard.copy(url);
  }

  public goToQrCodeGeneration() {
    this.finish();
  }
}
