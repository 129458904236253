<div
    class="step"
    id="step02"
>
    <mp-upload-image-box
        id="registration-step2-upload-image"
        [myType]="myType"
        [pictureData]="pictureData"
        [isRegistration]="true"
        (pictureBoxData)="setUserHasPictures($event)"
    ></mp-upload-image-box>
    <p class="text-center">
        {{ 'modules.frictionless.picture_warning' | translate }}
    </p>
    <div class="buttons-container">
        <button
            id="registration-step2-button-save-continue"
            class="mp-main-button bt-shadow bt-lg"
            [disabled]="!canContinue()"
            (click)="commitStep()"
        >
            {{ 'modules.frictionless.save_and_continue' | translate }}
        </button>
        <button
            id="registration-step2-button-advance-without-photo"
            class="text-link"
            *ngIf="canCommitStep()"
            (click)="skipWithoutPhoto()"
        >
            {{ 'modules.frictionless.skip_picture' | translate }}
        </button>
        <button
            id="registration-step2-button-finish-later"
            class="text-link"
            *ngIf="canFinishRegistrationLater()"
            (click)="submitRegistration()"
        >
            {{ 'modules.frictionless.finish_later' | translate }}
        </button>
    </div>
</div>
