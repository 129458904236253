<div
    class="step"
    id="step03"
>
    <mp-fancy-dropdown
        id="registration-step3-select-body-type"
        #bodyTypeElement
        label="modules.main.pages.profile.details_page.body_type"
        [(value)]="bodyType"
        [options]="bodyTypeOptions()"
        (valueChange)="activateDropdownIfEmpty(skinToneElement)"
        [active]="!bodyType"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step3-select-skin-tone"
        #skinToneElement
        label="modules.main.pages.profile.details_page.ethnicity"
        [(value)]="skinTone"
        [options]="skinToneOptions()"
        (valueChange)="activateDropdownIfEmpty(hairTypeElement)"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step3-select-hair-color"
        #hairTypeElement
        label="modules.main.pages.profile.details_page.hair_color"
        [(value)]="hairType"
        [options]="hairTypeOptions()"
        (valueChange)="activateDropdownIfEmpty(eyeColorElement)"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step3-select-eye-color"
        #eyeColorElement
        label="modules.main.pages.profile.details_page.eye_color"
        [(value)]="eyeColor"
        [options]="eyeColorOptions()"
        (valueChange)="activateDropdownIfEmpty(heightElement)"
    ></mp-fancy-dropdown>
    <mp-fancy-dropdown
        id="registration-step3-select-height"
        #heightElement
        label="modules.frictionless.height"
        [(value)]="height"
        [options]="heightOptions()"
        [notSoFancy]="true"
    ></mp-fancy-dropdown>
    <div class="buttons-container">
        <button
            id="registration-step3-button-save-continue"
            class="mp-main-button bt-shadow bt-lg"
            [disabled]="!canContinue()"
            (click)="commitStep()"
        >
            {{ 'modules.frictionless.save_and_continue' | translate }}
        </button>
        <button
            id="registration-step3-button-finish-later"
            class="text-link"
            *ngIf="canFinishRegistrationLater()"
            (click)="submitRegistration()"
        >
            {{ 'modules.frictionless.finish_later' | translate }}
        </button>
    </div>
</div>
