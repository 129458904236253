import { Injectable } from '@angular/core';

import { PrizeIdentifier } from '@libs/shared/product/prize.enum';

import { IPrize } from '@meupatrocinio/modules/main/services/prize/interfaces/prize.interface';

@Injectable({
  providedIn: 'root',
})
export class PrizeService {
  protected readonly prizes: IPrize[] = [
    {
      title: '2 Boosts',
      identifier: PrizeIdentifier.TWO_BOOST_CREDITS,
      iconClass: 'two-boost-credits',
      iconText: '2x',
    },
  ];

  public getPrize(identifier?: string) {
    if (!identifier) {
      return null;
    }

    return this.prizes.find((prize: IPrize): boolean => prize.identifier === identifier) ?? null;
  }
}
