import { ValidationErrors, Validators } from '@angular/forms';

import { IValidations } from '@libs/services/payment/validators';

const validation: ValidationErrors[] = [Validators.required, Validators.pattern(/^[1-9]\d*$/)];

function messages(errors: ValidationErrors): string {
  if (errors === null) {
    return 'modules.main.pages.payment.installment.error.invalid';
  }

  if (errors.required) {
    return 'modules.main.pages.payment.installment.error.required';
  }

  if (errors.pattern) {
    return 'modules.main.pages.payment.installment.error.number';
  }

  return 'modules.main.pages.payment.installment.error.invalid';
}

export const validations: IValidations = {
  validation,
  messages,
};
