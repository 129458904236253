import { IProfile } from '@libs/shared/profile/profile';
import {
  ProfilesFavMyReceivedAction,
  PROFILES_FAV_MY_RECEIVED_ACTION,
  PROFILES_FAV_MY_REFRESHED_ACTION,
  PROFILES_FAV_MY_REMOVED_ACTION,
} from '@libs/store/profiles/actions/fav-my.action';

export function storeMyFavProfiles(state: number[] = [], action: ProfilesFavMyReceivedAction): number[] {
  switch (action.type) {
    case PROFILES_FAV_MY_RECEIVED_ACTION:
      return onProfilesFavMyReceived(state, action.payload.profiles);
    case PROFILES_FAV_MY_REMOVED_ACTION:
      return onProfilesFavRemoved(state, action.payload.profiles);
    case PROFILES_FAV_MY_REFRESHED_ACTION:
      return [];
    default:
      return state;
  }
}

function onProfilesFavMyReceived(state: number[], profiles: IProfile[]): number[] {
  for (const profile of profiles) {
    state = onProfileFavMyReceived(state, profile);
  }

  return state;
}

function onProfileFavMyReceived(state: number[], newProfile: IProfile): number[] {
  if (state.indexOf(newProfile.profile_id) !== -1) {
    return state;
  }

  return [...state, newProfile.profile_id];
}

function onProfilesFavRemoved(state: number[], profiles: IProfile[]): number[] {
  for (const profile of profiles) {
    state = onProfileFavMyRemoved(state, profile);
  }

  return state;
}

function onProfileFavMyRemoved(state: number[], newProfile: IProfile): number[] {
  const index: number = state.indexOf(newProfile.profile_id);
  if (index === -1) {
    return state;
  }

  return [...state.slice(0, index), ...state.slice(index + 1)];
}
