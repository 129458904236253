<a
    class="clearfix"
    [routerLink]="['/main', 'profile', this.profileId]"
>
    <div class="info">
        <mp-img-srcset
            url="{{ getProfilePicture() }}"
            imgClass="avatar"
            place="settings-photo"
        ></mp-img-srcset>
        <p class="username notranslate">{{ getProfileUsername() }}</p>
    </div>
</a>
