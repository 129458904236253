import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Registration } from '@libs/interfaces/registration/registration';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';

@Directive()
export class ValidationControlErrorComponentCommon implements OnInit, OnDestroy {
  @Input() public requiredMessage = '';
  @Input() public controlName = '';
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() public invalidLength = 'modules.registration.pages.location.username.invalid';

  protected subscriptions: Subscription[] = [];

  constructor(protected changeDetectorReference: ChangeDetectorRef) {
    //
  }

  ngOnInit(): void {
    this.handleValueChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }

  protected handleValueChanges(): void {
    this.subscriptions.push(
      FormHelpers.getControlValueChanges$<string>(this.form, this.controlName).subscribe({
        next: (): void => {
          this.changeDetectorReference.markForCheck();
        },
      }),
    );
  }

  public get isControlWithRequiredError(): boolean {
    return FormHelpers.hasErrorWithSpecificName(this.form, this.controlName, 'required');
  }

  public isControlWithMinLengthError(): boolean {
    return FormHelpers.hasErrorWithSpecificName(this.form, this.controlName, 'invalidLength');
  }

  public canShowMinLengthError() {
    return (
      this.isControlWithMinLengthError() && !this.isControlWithRequiredError && this.form.get(this.controlName).touched
    );
  }

  public get controlCustomErrorMessage(): string {
    return FormHelpers.getFormControlSpecificError(this.form, this.controlName, Registration.VALIDATION_ERROR_KEY_NAME);
  }
}
