<div
    class="nagbar"
    *ngIf="paymentFailed((paymentStatus$ | async))"
>
    <div class="nagbar__header">
        <p class="nagbar__header__title">
            {{ errorTitle }}
        </p>
        <button
            class="nagbar__header__close"
            type="button"
            (click)="close()"
        >
            <img
                [src]="closeSvgPath"
                [alt]="alternativoInfoForButtonImage"
            >
        </button>
    </div>
    <p class="nagbar__description">
        <mp-advanced-translate key="modules.main.pages.home.error_subtext">
            <a
                href="mailto:suporte@meupatrocinio.com"
                mp-translate-param="email"
            >
                suporte&#64;meupatrocinio.com
            </a>
        </mp-advanced-translate>
    </p>
</div>
