import { Action, createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as ViewToastActions from '@libs/store/view-toast/actions';
import { viewToastAdapter, viewToastInitialState, IViewToastState } from '@libs/store/view-toast/state';

const viewToastReducer = createReducer(
  viewToastInitialState,
  on(ViewToastActions.upsertProfile, (state: IViewToastState, { profile }: { profile: IProfile }): IViewToastState => {
    return viewToastAdapter.upsertOne(profile, state);
  }),
  on(ViewToastActions.removeAllProfiles, (state: IViewToastState): IViewToastState => {
    return viewToastAdapter.removeAll(state);
  }),
);

export function reducer(state: IViewToastState, action: Action): IViewToastState {
  return viewToastReducer(state, action);
}
