<div class="upsell-card">
    <div
        (click)="navigateToUpgradeAccount()"
        class="upsell-card__content"
    >
        <img
            class="upsell-card__content__badge"
            [src]="getUpsellPlanBadge()"
        >
        <p
            [innerHTML]="getUpsellText()"
            [ngClass]="getTextStyle()"
        ></p>
    </div>
    <button
        class="upsell-card__button"
        (click)="hideUpsell()"
    >
        <span class="material-icons">
            &#xe5cd;
        </span>
    </button>
</div>
