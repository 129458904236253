export interface ICookieFakeData {
  name: string;
  value: string;
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: CookieType;
}

export enum CookieType {
  TYPE_LAX = 'Lax',
  TYPE_NONE = 'None',
  TYPE_STRICT = 'Strict',
}
