import { createAction, props } from '@ngrx/store';

enum Actions {
  SEND_REQUEST = '[Waiting List] - Send new confirmation email http request',
  SET_IS_SENDING_REQUEST = '[Waiting List] - Set is sending request',
  SET_IS_REQUESTING_NEW_CONFIRMATION_EMAIL = '[Waiting List] - Set is requesting new confirmation email',
  SET_REQUEST_TIMESTAMP = '[Waiting List] - Set request timestamp',
  START_REQUEST_COUNTDOWN = '[Waiting List] - Start request countdown',
  STOP_REQUEST_COUNTDOWN = '[Waiting List] - Stop request countdown',
  SET_USER_APPROVED = '[Waiting List] - Set User Approved',
  SET_EMAIL_VERIFIED = '[Waiting List] - Set Email Verified',
  ACCEPT_TERMS_AND_NAVIGATE = '[Waiting List] - Accept Terms and Navigate',
}

export const sendRequest = createAction(Actions.SEND_REQUEST);

export const setIsRequestingNewConfirmationEmail = createAction(
  Actions.SET_IS_REQUESTING_NEW_CONFIRMATION_EMAIL,
  props<{ isRequesting: boolean }>(),
);

export const setRequestTimestamp = createAction(Actions.SET_REQUEST_TIMESTAMP, props<{ timestamp: number }>());

export const startRequestCountdown = createAction(Actions.START_REQUEST_COUNTDOWN);

export const stopRequestCountdown = createAction(Actions.STOP_REQUEST_COUNTDOWN);

export const setUserApproved = createAction(Actions.SET_USER_APPROVED);

export const setEmailVerified = createAction(Actions.SET_EMAIL_VERIFIED);

export const acceptTermsAndNavigate = createAction(Actions.ACCEPT_TERMS_AND_NAVIGATE);

export const setIsSendingRequest = createAction(Actions.SET_IS_SENDING_REQUEST, props<{ isSendingRequest: boolean }>());
