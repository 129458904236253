import { Action } from '@ngrx/store';

export const NOTIFICATION_SETTING_CHANGED_ACTION: string = 'NOTIFICATION_SETTING_CHANGED_ACTION';

interface INotificationSetting {
  push?: boolean;
  email?: boolean;
}

export interface INotificationSettings {
  email_message?: INotificationSetting;
  email_favorite_me?: INotificationSetting;
  email_view_my_profile?: INotificationSetting;
  email_marketing?: INotificationSetting;
  email_account?: INotificationSetting;
  email_payment?: INotificationSetting;
}

export const INITIAL_NOTIFICATION_SETTINGS = {
  email_message: { push: true, email: false },
  email_favorite_me: { push: true, email: false },
  email_view_my_profile: { push: true, email: false },
  email_marketing: { push: true, email: false },
  email_account: { push: true, email: false },
  email_payment: { push: true, email: false },
};

export interface INotificationSettingChangedActionPayload {
  setting: string;
  type: string;
  value: boolean;
}

export class NotificationSettingChangedAction implements Action {
  readonly type: string = NOTIFICATION_SETTING_CHANGED_ACTION;

  constructor(public payload: INotificationSettingChangedActionPayload[]) {
    //
  }
}
