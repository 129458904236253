import { Directive } from '@angular/core';

import { SlidableMenuServiceCommon } from '@libs/modules/main/services/slidable-menu/slidable-menu.service.common';

@Directive()
export class CloseMenuButtonComponentCommon {
  constructor(protected slidableMenuService: SlidableMenuServiceCommon) {
    //
  }

  closeMenu(): void {
    this.slidableMenuService.closeSlidableMenu();
  }
}
