import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionbarThreeDotsComponentCommon } from '@libs/modules/shared/actionbar/actionbar-three-dots/actionbar-three-dots.component.common';
import { IThreeDotsMenuItem } from '@libs/shared/interfaces/three-dots-menu-item.interface';
import { Tick } from '@libs/utils/timeout-typings';

@Component({
  selector: 'mp-actionbar-three-dots',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar-three-dots.html',
})
export class ActionbarThreeDotsComponent extends ActionbarThreeDotsComponentCommon {
  protected menuTimeout: Tick | undefined;

  menuEnter(): void {
    clearTimeout(this.menuTimeout);
  }

  menuLeave(): void {
    this.menuTimeout = setTimeout((): void => {
      this.menuHidden = true;
    }, 400);
  }

  toggleMenu(): void {
    this.menuHidden = !this.menuHidden;
  }

  handleOptionClicked(option: IThreeDotsMenuItem): void {
    if (option.closeMenuOnClick) {
      this.toggleMenu();
    }

    option.action();
  }
}
