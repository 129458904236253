import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FrictionlessStep5ComponentCommon } from '@libs/modules/frictionless-registration/step5/step5.component.common';
import { FrictionlessCommon } from '@libs/shared/user/frictionless.common';
import { IApplicationState } from '@libs/store/application-state';

import { ModalService } from '@meupatrocinio/services/modal.service';

@Component({
  selector: 'mp-frictionless-step5',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './step5.html',
})
export class FrictionlessStep5Component extends FrictionlessStep5ComponentCommon implements AfterViewInit {
  @ViewChild('headlineElement', { static: true }) headlineElement: ElementRef;
  @ViewChild('descriptionElement', { static: true })
  descriptionElement: ElementRef;
  @ViewChild('interestDescriptionElement', { static: true })
  interestDescriptionElement: ElementRef;

  public isDropdownOpen = false;

  constructor(
    protected translate: TranslateService,
    protected store: Store<IApplicationState>,
    protected modalService: ModalService,
  ) {
    super(translate, store);
  }

  public ngAfterViewInit(): void {
    this.resizeTextarea(this.headlineElement.nativeElement);
    this.resizeTextarea(this.descriptionElement.nativeElement);
    this.resizeTextarea(this.interestDescriptionElement.nativeElement);
  }

  public resizeTextarea(textarea: EventTarget): void {
    (<HTMLTextAreaElement>textarea).style.height = '0';
    (<HTMLTextAreaElement>textarea).style.height = `${(<HTMLTextAreaElement>textarea).scrollHeight}px`;
  }

  skippingWithoutPhotoConfirmation(): void {
    if (FrictionlessCommon.isDaddyMommyWithoutPhoto(this.myType, this.hasPictures, this.skippedPhotoStep)) {
      this.modalService.confirm(
        'modules.frictionless.modal.daddy_mommy_no_photo',
        (): void => {
          this.commitStep();
        },
        'modules.frictionless.finish_registration',
        'modules.frictionless.modal.daddy_mommy_no_photo_send_photo',
        (): void => {
          this.resetStep.emit(FrictionlessCommon.STEP_PICTURE);
        },
      );

      return;
    }

    this.commitStep();
  }

  public toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
