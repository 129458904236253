import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@meupatrocinio/config';

import { SplitService } from '@splitsoftware/splitio-angular';

@Injectable({
  providedIn: 'root',
})
export class SplitIOFacade {
  public splitService!: SplitService;

  constructor() {
    this.instantiateService();
  }

  public instantiateService(): void {
    this.splitService = new SplitService();
  }

  public initialize$(user: UserCommon): Observable<string> {
    const sdkConfig: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey: Config.splitIOAuthorizationKey,
        key: user.profile_id.toString(),
      },
    };

    return this.splitService.init(sdkConfig);
  }

  public getTestTreatment$(testName: string, attributes: SplitIO.Attributes = {}): Observable<string> {
    return of(this.splitService.getTreatment(testName, attributes));
  }

  public destroy(): void {
    this.splitService.destroy();
  }
}
