import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export enum Actions {
  LOAD_LAST_VIEWERS = '[Boost] - Load Boost Last Viewers',
  SET_BOOST_LAST_VIEWERS = '[Boost] - Set Boost Last Viewers',
  UPDATE_LAST_VIEWERS_DURING_BOOST = '[Boost] - Update Last Viewers During Boost',
  CANCEL_UPDATE_LAST_VIEWERS_DURING_BOOST = '[Boost] Cancel Update Last Viewers During Boost',
  CLEAR_BOOST_LAST_VIEWERS = '[Boost] - Clear Boost Last Viewers',
}

export const loadLastViewers = createAction(Actions.LOAD_LAST_VIEWERS);

export const updateLastViewersDuringBoost = createAction(Actions.UPDATE_LAST_VIEWERS_DURING_BOOST);

export const cancelUpdateLastViewersDuringBoost = createAction(Actions.CANCEL_UPDATE_LAST_VIEWERS_DURING_BOOST);

export const setBoostLastViewers = createAction(Actions.SET_BOOST_LAST_VIEWERS, props<{ profiles: IProfile[] }>());

export const clearBoostLastViewers = createAction(Actions.CLEAR_BOOST_LAST_VIEWERS);
