import { Injectable } from '@angular/core';

import { IBoostGtmData } from '@libs/shared/boost/interfaces/boost-gtm-data.interface';
import { IPaymentInfo, PaymentInfoStatus } from '@libs/store/payment-info';
import { divideBy } from '@libs/utils/generic-checkers/generic-checkers';

@Injectable({
  providedIn: 'root',
})
export class BoostProductServiceCommon {
  public isBoostPackagePayment(updatedPaymentInfo: IPaymentInfo | null, boostProductUuid: string | null): boolean {
    if (updatedPaymentInfo === null || boostProductUuid === null) {
      return false;
    }

    return updatedPaymentInfo.product_uuid === boostProductUuid;
  }

  public getLastBoostPayment(paymentInfo: IPaymentInfo[], boostProductUuid: string): IPaymentInfo | undefined {
    const filteredPaymentInfo: IPaymentInfo[] = paymentInfo.filter((info: IPaymentInfo): boolean =>
      this.isValidBoostPayment(info, boostProductUuid),
    );

    return filteredPaymentInfo.reduce(
      (previousInfo: IPaymentInfo | undefined, currentInfo: IPaymentInfo): IPaymentInfo | undefined => {
        if (
          previousInfo !== undefined &&
          currentInfo !== undefined &&
          previousInfo.fin_sale_id > currentInfo.fin_sale_id
        ) {
          return previousInfo;
        }

        return currentInfo;
      },
      undefined,
    );
  }

  public isValidBoostPayment(paymentInfo: IPaymentInfo, boostProductUuid: string): boolean {
    return (
      paymentInfo.product_uuid !== undefined &&
      paymentInfo.product_uuid === boostProductUuid &&
      paymentInfo.status === PaymentInfoStatus.StatusApproval
    );
  }

  public getOrderStampFromBoostPayment(boostPayment: IPaymentInfo | undefined): number {
    if (boostPayment === undefined) {
      return 0;
    }

    return boostPayment.order_stamp;
  }

  public getSubtotalAmountFromBoostPayment(boostPayment: IPaymentInfo | undefined): number {
    if (boostPayment === undefined) {
      return 0;
    }

    return boostPayment.subtotal_amount;
  }

  public handleBoostPaymentDataToGtm(boostPaymentInfo: IPaymentInfo): IBoostGtmData {
    return {
      lastPurchaseDate: this.getOrderStampFromBoostPayment(boostPaymentInfo),
      lastPurchasePackage: this.getSubtotalAmountFromBoostPayment(boostPaymentInfo),
      balance: 0,
      lastActivation: 0,
    };
  }

  handleLastActivation(startTimestamp: number): number {
    return Math.floor(divideBy(1000, startTimestamp));
  }
}
