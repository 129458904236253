export enum Medias {
  MIN_WIDTH_420 = '(min-width: 420px)',
  MIN_WIDTH_550 = '(min-width: 550px)',
  MIN_WIDTH_992 = '(min-width: 992px)',
  MIN_WIDTH_1200 = '(min-width: 1200px)',
  MAX_WIDTH_450 = '(max-width: 450px)',
  MAX_WIDTH_550 = '(max-width: 550px)',
  MAX_WIDTH_768 = '(max-width: 768px)',
  MAX_WIDTH_991 = '(max-width: 991px)',
  BETWEEN_WIDTH_992_1199 = '(min-width: 992px) and (max-width: 1199px)',
}
