import { Action } from '@ngrx/store';

export const SUPPORT_PARAMS_ACTION: string = 'SUPPORT_PARAMS_ACTION';

export class SupportParamsAction implements Action {
  readonly type: string = SUPPORT_PARAMS_ACTION;

  constructor(public payload: any) {
    //
  }
}
