import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { IEncryptionService } from '@libs/modules/payment-v2/interfaces/encryption-service.interface';
import { IPaymentAttemptData } from '@libs/modules/payment-v2/interfaces/payment-data-attempt.interface';

import { Config } from '@meupatrocinio/config';
import { IEncryptDataForPagseguro } from '@meupatrocinio/modules/payment-v2/providers/pagseguro-v2/interfaces/encrypt-data-for-pagseguro.interface';

declare let PagSeguro: {
  encryptCard: (params: {
    publicKey: string;
    holder: string;
    number: string;
    securityCode: string;
    expMonth: string;
    expYear: string;
  }) => { encryptedCard: string };
};

@Injectable({
  providedIn: 'root',
})
export class PagseguroEncryptionService implements IEncryptionService {
  public encrypt(data: IPaymentAttemptData) {
    const publicKey = Config.pagseguro.publicKey;
    const { cardInfo, holderInfo } = data;
    const preparedData: IEncryptDataForPagseguro = {
      cardInfo,
      holderInfo,
    };

    return from(this.encryptData(preparedData, publicKey));
  }

  protected async encryptData(data: IEncryptDataForPagseguro, publicKey: string): Promise<string> {
    try {
      const cardInfo = data.cardInfo;
      const holderInfo = data.holderInfo;
      const dates: string[] = cardInfo.date.split('/');
      const expirationMonth: string = dates[0];
      const expirationYear: string = `20${dates[1]}`;
      const card = PagSeguro.encryptCard({
        publicKey,
        holder: holderInfo.name,
        number: cardInfo.cardNumber,
        securityCode: cardInfo.cvcNumber,
        expMonth: expirationMonth,
        expYear: expirationYear,
      });
      const encrypted = card.encryptedCard;

      return encrypted;
    } catch (_error) {
      throw new Error('Failed to encrypt data.');
    }
  }
}
