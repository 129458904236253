import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { IReferral } from '@libs/shared/referral/referral';
import { ReferralCommon } from '@libs/shared/referral/referral.common';

import { Config } from '@meupatrocinio/config';

@Component({
  selector: 'mp-referral-friend-counter',
  templateUrl: './referral-friend-counter.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['referral-friend-counter.scss'],
})
export class ReferralFriendCounterComponent {
  @Input() referrals$: Observable<IReferral[]> = of([]);
  protected referralsFriendSubscribed: IReferral[] = [];
  protected badgeState = 'premiumDark';

  constructor(
    protected translateService: TranslateService,
    protected store: Store,
  ) {
    //
  }

  protected isFirstBadgeWithOddReferrals(subscribedFriends: number, badgeIndex: number): boolean {
    return badgeIndex === 0 && subscribedFriends % 2 === 1;
  }

  public shouldBadgesBeCentered(referral: IReferral[]): boolean {
    return Math.ceil(this.getNumberOfSubscribedFriends(referral) / 2) <= 4;
  }

  public getBadgePremiumState(referral: IReferral[], item: number): string {
    const subscribedFriends = this.getNumberOfSubscribedFriends(referral);
    const badgeImage =
      subscribedFriends < 2 || this.isFirstBadgeWithOddReferrals(subscribedFriends, item)
        ? 'premium-dark.svg'
        : 'premium-rounded.svg';

    return `${Config.imagesFolderBaseUrl}${badgeImage}`;
  }

  public getAwardMessage(referral: IReferral[]): string {
    const subscribedFriends = this.getNumberOfSubscribedFriends(referral);
    const premiumDays = Math.floor(subscribedFriends / 2) * 30;

    return this.translateService.instant('modules.main.pages.referral.award_message', { premiumDays });
  }

  public getScoreSubscribedText(referral: IReferral[]): string {
    return this.translateService.instant('modules.main.pages.referral.counter', {
      score: this.getNumberOfSubscribedFriends(referral),
    });
  }

  protected getNumberOfSubscribedFriends(referral: IReferral[]): number {
    if (referral.length === 0) {
      return 0;
    }

    this.referralsFriendSubscribed = referral.filter((friend: IReferral): boolean => {
      return friend.status === ReferralCommon.REFERRAL_STATUS_PAID;
    });

    return this.referralsFriendSubscribed.length;
  }

  public getProgressBarModifierClass(referral: IReferral[], item: number): string {
    const className = 'referral-friend-counter__progress-bar__progress__circle';
    const subscribedFriends = this.getNumberOfSubscribedFriends(referral);

    if (subscribedFriends === 0) {
      return '';
    }

    if (subscribedFriends === 1 || this.isFirstBadgeWithOddReferrals(subscribedFriends, item)) {
      return `${className}--one`;
    }

    return `${className}--two`;
  }

  public canShowAwardMessage(referral: IReferral[]): boolean {
    return this.getNumberOfSubscribedFriends(referral) >= 2;
  }

  public badgePremiumCounter(referral: IReferral[]): Number[] {
    const subscribedFriends = this.getNumberOfSubscribedFriends(referral);

    if (subscribedFriends <= 2) {
      return [1];
    }

    const numberOfBadges = Math.ceil(subscribedFriends / 2);

    return Array.from(Array(numberOfBadges).keys());
  }
}
