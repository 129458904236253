<ul class="menu-actions">
    <li class="menu-actions--item">
        <a
            id="menu-bar-button-edit-profile"
            class="menu-actions--link"
            href="javascript:void(0);"
            (click)="navigateToEditProfile()"
        >
            {{ 'modules.main.pages.edit_profile.title' | translate }}
        </a>
    </li>
    <li class="menu-actions--item">
        <a
            id="menu-bar-button-settings"
            class="menu-actions--link"
            href="javascript:void(0);"
            (click)="navigateToSettings()"
        >
            {{ 'modules.main.pages.settings.title' | translate }}
        </a>
    </li>
    <li class="menu-actions--item">
        <a
            id="menu-bar-button-central-help"
            class="menu-actions--link"
            [href]="getUrlToCustomerSupport()"
            (click)="closeSlidableMenu()"
        >
            {{ 'modules.main.shared.menu-actions.customer-support' | translate }}
        </a>
    </li>
    <li class="menu-actions--item">
        <a
            id="menu-bar-button-logout"
            class="menu-actions--link"
            href="javascript:void(0);"
            (click)="logout()"
        >
            {{ 'common.logout' | translate }}
        </a>
    </li>
</ul>
