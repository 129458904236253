import { Action } from '@ngrx/store';

export const MAINTENANCE_ACTION: string = 'MAINTENANCE_ACTION';

export class MaintenanceAction implements Action {
  readonly type: string = MAINTENANCE_ACTION;

  constructor(public payload: boolean) {
    //
  }
}
