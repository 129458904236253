import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { MembershipBadgeComponentCommon } from '@libs/components/membership-badge/membership-badge.component.common';

@Component({
  selector: 'mp-membership-badge-extended',
  templateUrl: 'membership-badge-extended.html',
})
export class MembershipBadgeExtendedComponent extends MembershipBadgeComponentCommon {
  constructor(protected store: Store) {
    super(store);
  }
}
