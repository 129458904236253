import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { MembershipType } from '@libs/shared/membership/membership.common';

import { ITestimonials } from '@meupatrocinio/modules/main/services/testimonials/interfaces/testimonials.interface';

@Injectable({
  providedIn: 'root',
})
export class TestimonialsService {
  constructor(protected translate: TranslateService) {
    //
  }

  protected readonly testimonials: ITestimonials[] = [
    {
      quote:
        'Impressionante. Entendi o valor de ter uma Sugar Baby ao meu lado, alguém que tenha uma boa conversa, que seja leve, muito bonita, transparente e sem cobranças.',
      user: 'GustaSp78 - São Paulo, SP',
    },
    {
      quote:
        'Me identifico muito com o estilo de vida sugar. Porque no fim do dia eu só quero fugir dos estresses e confusões do dia a dia, sem drama com uma mulher incrível.',
      user: 'Newdady - Rio de Janeiro, RJ',
    },
    {
      quote:
        'Estou no MP desde 2018 e já conheci vários Sugar Daddies. O mais marcante me levou para um resort em Porto de Galinhas, de frente para o mar, por uma semana. Além de romântico, ele é muito generoso.',
      user: 'KitKat19 - Cuiabá, MT',
    },
    {
      quote:
        'Tenho experiências únicas e inesquecíveis aqui no MeuPatrocínio. Sou do interior da Paraíba e meu Daddy é do Rio de Janeiro. Ele sempre compra passagens para que possamos ficar juntos. Apaixonada.',
      user: 'Marianasou1 - João Pessoa, Paraíba',
    },
    {
      quote:
        'Tive um relacionamento de 2 anos com meu Daddy, ele construiu a casa onde eu moro. Viajamos muito e ele sempre me ajudou com tudo. Terminamos porque ele foi morar na China a trabalho.',
      user: 'AndressaSant814 - Itabuna, Bahia',
    },
    {
      quote:
        'Assim que me cadastrei já encontrei um Sugar Daddy na minha cidade que me deu muitos presentes como procedimentos estéticos, viagens, roupas, jantares e pagou minhas contas.',
      user: 'AnaCarol888  - Serra, Espírito Santo',
    },
    {
      quote:
        'Estou no MP há 1 ano e gosto muito da privacidade e discrição. Eu amo isso na plataforma. Os Sugar Daddies aqui são muito respeitosos. Me trataram feito uma rainha!',
      user: 'Doceana21 - Rio Bonito, Rio de Janeiro',
    },
  ];

  protected readonly titles: Dictionary<string> = {
    [MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM]: 'modules.main.pages.payment.testimonials_premium_label',
  };

  public getTestimonials$(limit = -1): Observable<ITestimonials[]> {
    if (limit === -1) {
      limit = this.testimonials.length;
    }

    return of(this.shuffleTestimonials(this.testimonials).slice(0, limit));
  }

  protected getTitleKey(membershipType: number): string {
    return this.titles[membershipType] ?? '';
  }

  public getTitleTranslation(membershipType: number): string {
    const titleKey = this.getTitleKey(membershipType);

    if (titleKey === '') {
      return '';
    }

    return this.translate.instant(titleKey);
  }

  protected shuffleTestimonials(testimonials: ITestimonials[]): ITestimonials[] {
    return [...testimonials].sort((): number => Math.random() - 0.5);
  }
}
