import { Injectable } from '@angular/core';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class LoginGuard {
  constructor(protected authenticationService: AuthenticationService) {
    //
  }

  public canActivate(): boolean {
    return !this.authenticationService.isAuthenticated();
  }
}
