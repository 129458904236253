import { createAction, props } from '@ngrx/store';

enum Actions {
  SAVE_TEST_TREATMENT = '[ABTests] - Save Test Treatment',
  REGISTER_SAVED_TEST = '[ABTests] - Register Saved Test',
  SEND_TEST_TREATMENT_FOR_GTM = '[ABTests] - Send Test Treatment For GTM',
}

export const saveTestTreatment = createAction(
  Actions.SAVE_TEST_TREATMENT,
  props<{ testName: string; treatment: string }>(),
);

export const registerSavedTest = createAction(Actions.REGISTER_SAVED_TEST, props<{ testName: string }>());

export const sendTestTreatmentToAnalytics = createAction(
  Actions.SEND_TEST_TREATMENT_FOR_GTM,
  props<{ name: string; treatment: string }>(),
);
