import { ILanguage } from '@libs/shared/language/interfaces/language.interface';

export abstract class LanguageServiceCommon {
  public abstract currentLanguage(): ILanguage;

  public abstract changeLanguage(value: ILanguage): void;

  public isCurrentLanguage(value: ILanguage): boolean {
    return value.lang === this.currentLanguage().lang;
  }
}
