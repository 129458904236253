import { Directive, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ITrialModalData } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';
import { IApplicationState } from '@libs/store/application-state';
import { TrialActions } from '@libs/store/trial';

@Directive()
export abstract class TrialModalComponentCommon implements OnDestroy {
  protected subscriptions: Subscription[] = [];

  protected readonly TRIAL_THREE_DAYS_LEFT: number = 3;
  protected readonly TRIAL_NO_DAYS_LEFT: number = 0;

  constructor(
    protected store: Store<IApplicationState>,
    protected translate: TranslateService,
  ) {
    //
  }

  abstract trial(): Trial;

  abstract modalData(): ITrialModalData | undefined;

  abstract close(): void;

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public canShowCountdown(): boolean {
    return (
      this.trial().getEndTrialDaysCount() <= this.TRIAL_THREE_DAYS_LEFT &&
      this.trial().getEndTrialDaysCount() >= this.TRIAL_NO_DAYS_LEFT
    );
  }

  public getTrialPlanDescription(): string {
    return this.translate.instant('modules.main.shared.trial_modal.price', {
      trialDays: this.trial().getTrialPlanDaysActive(),
      trialPrice: this.trial().getTrialPrice().toString(),
    });
  }

  public getTrialPlansFinePrint(): string {
    if (this.trial().isEmpty()) {
      return '';
    }

    if (!this.trial().isFifteenDaysTrialPlan()) {
      return this.translate.instant('modules.main.shared.trial_modal.trial_plans.fine_print.months', {
        trialMonths: this.trial().getTrialNextRecurringPeriod(),
        trialPrice: this.trial().getTrialNextRecurringPrice().toString(),
      });
    }

    return this.translate.instant('modules.main.shared.trial_modal.trial_plans.fine_print.fifteenDays', {
      trialPrice: this.trial().getTrialNextRecurringPrice().toString(),
    });
  }

  public getModalTitle(): string {
    if (this.modalData().title === undefined) {
      return 'modules.main.shared.trial_modal.title';
    }

    return this.modalData().title;
  }

  public getDeclineText(): string {
    if (this.modalData().declineText === undefined) {
      return 'common.not_now';
    }

    return this.modalData().declineText;
  }

  public getAcceptButtonText(): string {
    if (this.modalData().buttonText === undefined) {
      return 'modules.main.shared.trial_modal.accept';
    }

    return this.modalData().buttonText;
  }

  public isOriginalTrialPlan(): boolean {
    return this.trial().isOriginalTrialPlan();
  }

  public declineModal(): void {
    this.close();
    this.userHasDeclined();

    if (this.modalData().declineCallback !== undefined) {
      this.modalData().declineCallback();
    }
  }

  public acceptOffer(): void {
    this.close();

    if (this.modalData().sucessCallback !== undefined) {
      this.modalData().sucessCallback();
    }
  }

  public userHasDeclined(): void {
    this.store.dispatch(
      TrialActions.setGameTrial({
        period: null,
      }),
    );
  }
}
