import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MembershipType } from '@libs/shared/membership/membership.common';

export interface Membership {
  //
}

export interface IMembershipState extends EntityState<Membership> {
  latestPaidMembership: MembershipType | null;
}

export const membershipAdapter: EntityAdapter<Membership> = createEntityAdapter<Membership>({});

export const membershipInitialState: IMembershipState = membershipAdapter.getInitialState({
  latestPaidMembership: null,
});
