import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TimeToRegisterActions = createActionGroup({
  source: 'TimeToRegister',
  events: {
    'Create Request': emptyProps(),
    'Update Request': props<{
      profileId?: number;
      endTime?: number;
    }>(),
    'Update Profile Id Request Frictionless': props<{
      profileId: number;
    }>(),
  },
});
