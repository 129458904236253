import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { IBirthdateFields } from '@libs/interfaces/registration/interfaces/birthdate-fields.interface';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';
import { RegistrationUtils } from '@libs/utils/registration-utils/registration-utils';

@Component({
  selector: 'mp-birthdate-field',
  templateUrl: './birthdate-field.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./birthdate-field.scss'],
})
export class BirthdateFieldComponent {
  public readonly dayRange: number[] = [1, 31];
  public readonly monthRange: number[] = [1, 12];
  public readonly yearRange: number[] = RegistrationUtils.getBirthYearRangeTuple();

  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() groupName = '';
  @Input() dayControlName = '';
  @Input() monthControlName = '';
  @Input() yearControlName = '';
  @Input() shouldShowSuccessFeedback = true;

  public getMonthName(monthNumber: number): string {
    const options = [
      'common.months.january',
      'common.months.february',
      'common.months.march',
      'common.months.april',
      'common.months.may',
      'common.months.june',
      'common.months.july',
      'common.months.august',
      'common.months.september',
      'common.months.october',
      'common.months.november',
      'common.months.december',
    ];

    return options[monthNumber - 1];
  }

  public canShowValidationErrorFeedback(): boolean {
    return !this.isParentFormValid() && !this.isBirthdateValid() && this.areAllFieldsFilled();
  }

  public canShowValidationSuccessFeedback(): boolean {
    return this.isBirthdateValid() && this.hasAnyDirtyControl();
  }

  protected hasAnyDirtyControl(): boolean {
    return FormHelpers.isAnyNestedControlDirty(this.form, this.groupName);
  }

  public get fieldNames(): string[] {
    return Object.keys(this.birthdate);
  }

  protected isParentFormValid(): boolean {
    return FormHelpers.isFormValid(this.form);
  }

  protected isBirthdateValid(): boolean {
    return FormHelpers.isControlValid(this.form, this.groupName);
  }

  protected areAllFieldsFilled(): boolean {
    return FormHelpers.areAllNestedControlsFilled(this.form, this.groupName);
  }

  public get birthdate(): IBirthdateFields {
    return FormHelpers.getFormControlValue<IBirthdateFields>(this.form, this.groupName);
  }

  public trackByDate(_: number, date: number) {
    return date;
  }
}
