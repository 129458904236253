import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { TrialServiceCommon } from '@libs/modules/main/services/trial/trial.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { DeactivateReasonActions, DeactivateReasonSelectors, IReason } from '@libs/store/deactivate-reason';

@Injectable({
  providedIn: 'root',
})
export abstract class DeactivateAccountServiceCommon implements OnDestroy {
  protected subscriptions: Subscription[] = [];

  constructor(
    protected store: Store<IApplicationState>,
    protected trialService: TrialServiceCommon,
    protected translate: TranslateService,
    protected profileService: ProfileServiceCommon,
    protected authenticationService: AuthenticationServiceCommon,
  ) {
    //
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  protected abstract handleLastModalAnswer(reason: string): Observable<unknown>;

  abstract showDeactivatedSuccessModal(): void;

  abstract showDeactivateErrorModal(): void;

  handleDisableAccount(disableReasonValue: number, disableDescription: string): void {
    this.subscriptions.push(
      this.store
        .pipe(
          select(DeactivateReasonSelectors.selectReasonByValue, {
            value: disableReasonValue,
          }),
          map((reasonValue: IReason | undefined): string => {
            let reason: string = disableDescription;

            if (reasonValue !== undefined) {
              reason = this.translate.instant(`modules.main.pages.visibility.disable_reasons.${reasonValue.label}`);
            }

            return reason;
          }),
          tap((reason: string): void => {
            this.handleDeactivateAccountReason(reason);
          }),
          take(1),
        )
        .subscribe(),
    );
  }

  protected handleDeactivateAccountReason(reason: string): void {
    if (!this.trialService.isElectiveToDeactivateModal(this.authenticationService.get())) {
      this.deactivateAccountRequest(reason);
      return;
    }

    this.store.dispatch(
      DeactivateReasonActions.setCurrentDeactivateReason({
        currentReasonTextSelected: reason,
      }),
    );

    this.startModalGame();
  }

  startModalGame(): void {
    this.trialService.setGameTrialAccordingToGlobal();
    this.trialService.startGamefiedTrial({
      firstTimer: 0,
      satisfied: (): boolean => !this.trialService.getGameTrial().isEmpty(),
      retryStrategy: {
        retryWhen: (): boolean => false,
        millisecondsRetry: 0,
      },
      trial: this.trialService.getGameTrial(),
      modalData: {
        declineCallback: this.userDeclinedOffer,
        sucessCallback: this.userAcceptedOffer,
        title: 'modules.main.pages.visibility.disable.modal.trial_title',
        buttonText: 'modules.main.pages.visibility.disable.modal.trial_button',
      },
    });
  }

  deactivateAccountRequest = (reason: string): void => {
    this.subscriptions.push(
      this.profileService.deactivateSelf(reason).subscribe(this.deactivateAccountSuccess, this.deactivateAccountError),
    );
  };

  userAcceptedOffer = (): void => {
    this.store.dispatch(
      DeactivateReasonActions.setCurrentDeactivateReason({
        currentReasonTextSelected: null,
      }),
    );
  };

  userDeclinedOffer = (): void => {
    this.subscriptions.push(
      this.store
        .pipe(
          select(DeactivateReasonSelectors.selectCurrentReason),
          switchMap((reason: string | null): Observable<unknown> => {
            if (reason === null) {
              return of({});
            }

            return this.handleLastModalAnswer(reason);
          }),
          take(1),
        )
        .subscribe(),
    );
  };

  deactivateAccountSuccess = (): void => {
    this.store.dispatch(
      DeactivateReasonActions.setCurrentDeactivateReason({
        currentReasonTextSelected: null,
      }),
    );

    this.showDeactivatedSuccessModal();
  };

  deactivateAccountError = (): void => {
    this.store.dispatch(
      DeactivateReasonActions.setCurrentDeactivateReason({
        currentReasonTextSelected: null,
      }),
    );

    this.showDeactivateErrorModal();
  };

  logout = (): void => {
    this.authenticationService.logout();
  };
}
