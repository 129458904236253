import { createAction } from '@ngrx/store';

enum Actions {
  MAIN_MODULE_LOADED = '[Main] - Module Loaded',
  USER_INITIALIZED = '[Main / User] - Initialized',
  LOAD_PREMIUM_SETTINGS = '[Main / User] - Load Premium Settings',
}

export const moduleLoaded = createAction(Actions.MAIN_MODULE_LOADED);

export const userInitialized = createAction(Actions.USER_INITIALIZED);

export const loadPremiumSettings = createAction(Actions.LOAD_PREMIUM_SETTINGS);
