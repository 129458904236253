import { MembershipType } from '@libs/shared/membership/membership.common';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';
import { IUpgradeAccountState } from '@libs/store/upgrade-account/interfaces/upgrade-account-state.interface';
import { UPGRADE_ACCOUNT_VERSIONS } from '@libs/store/upgrade-account/types/upgrade-account-version.type';

export const upgradeAccountInitialState: IUpgradeAccountState = {
  paymentOptions: {},
  activeVersion: UPGRADE_ACCOUNT_VERSIONS.standardPlans,
  isResolvingVisiblePlans: false,
  selectedPlans: {
    [MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM]: ProductVariantIdentifier.DADDY_PREMIUM_3_MONTHS,
    [MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE]: ProductVariantIdentifier.DADDY_ELITE_3_MONTHS,
    [MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM]: ProductVariantIdentifier.BABY_PREMIUM_3_MONTHS,
  },
  dataTTL: 0,
};
