import { createAction, props } from '@ngrx/store';

import { ICustomerIOUserData } from '@meupatrocinio/infra/customer-io/interfaces/customer-io-user-data.interface';

enum Actions {
  HANDLE_DATA_INITIALIZATION = '[Customer.IO] - Handle Data Initialization',
  HANDLE_DATA_INITIALIZATION_AFTER_RELOGIN = '[Customer.IO] - Handle Data Initialization After Relogin',
  INITIALIZE_CUSTOMER_IO = '[Customer.IO] - Initialize Service',
}

export const handleDataInitialization = createAction(Actions.HANDLE_DATA_INITIALIZATION);

export const handleDataInitializationAfterRelogin = createAction(Actions.HANDLE_DATA_INITIALIZATION_AFTER_RELOGIN);

export const initialize = createAction(
  Actions.INITIALIZE_CUSTOMER_IO,
  props<{ customerIOData: ICustomerIOUserData }>(),
);
