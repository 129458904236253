<div class="user-menu-items" *ngIf="!platformService.isMobile">
    <nav *ngIf="isConnected()">
        <a
            class="regular-link"
            [routerLink]="['/main', 'home']"
            [ngClass]="{'active': isInActivedPage(PAGE_ID_HOME)}"
        >
            <i
                class="material-icons"
                aria-hidden="true"
            >
                &#xE88A;
            </i>
            <p>
                {{ 'modules.main.shared.home_menu.home' | translate }}
            </p>
        </a>
        <a
            class="regular-link"
            [routerLink]="['/main', 'conversation']"
            [ngClass]="{'active': isInActivedPage(PAGE_ID_CONVERSATION)}"
        >
            <i
                class="material-icons"
                aria-hidden="true"
            >
                &#xE8AF;
            </i>
            <p>
                {{ 'modules.main.pages.conversation.title' | translate }}
            </p>
            <div
                class="notification-tip"
                *ngIf="showUnreadConversations$() | async"
            >
                {{ getUnreadConversationsCount$() | async }}
            </div>
        </a>
        <ng-container *ngIf="!isSelectedForSearchV2">
            <a
                class="regular-link"
                [routerLink]="['/main', 'search']"
                [ngClass]="{'active': isInActivedPage(PAGE_ID_SEARCH)}"
            >
                <i
                    class="material-icons"
                    aria-hidden="true"
                >
                    &#xE8B6;
                </i>
                <p>
                    {{ 'modules.main.pages.search_filter.title' | translate }}
                </p>
            </a>
        </ng-container>
        <a
            class="regular-link"
            [routerLink]="['/main', 'favorites']"
            [ngClass]="{'active': isInActivedPage(PAGE_ID_INTERESTS)}"
        >
            <i
                class="material-icons"
                aria-hidden="true"
            >
                &#xE87E;
            </i>
            <p>
                {{ 'modules.main.shared.home_menu.interests' | translate }}
            </p>
        </a>
    </nav>
</div>
