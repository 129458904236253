import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { ActionbarBackButtonComponentCommon } from '@libs/modules/shared/actionbar/actionbar-back-button/actionbar-back-button.component.common';

import { HAS_ACCESSED_FORBIDDEN_CHECKOUT_QUERY_PARAM_NAME } from '@meupatrocinio/effects/payment/constants/constants';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';
import { Subject } from 'rxjs';

interface INavigationState {
  navigationId: number;
}

@Component({
  selector: 'mp-actionbar-back-button',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar-back-button.html',
})
export class ActionbarBackButtonComponent extends ActionbarBackButtonComponentCommon implements OnDestroy {
  protected cameFromNewTab = false;
  protected hasRedirectedAfterForbiddenCheckout = false;

  protected readonly NO_BROWSER_HISTORY: number = 1;
  protected destroy$ = new Subject<void>();

  constructor(
    protected location: Location,
    protected router: Router,
    protected route: ActivatedRoute,
    protected globalObjectService: GlobalObjectService,
  ) {
    super();

    this.handleQueryParams();
    this.addOpenedTabToQueryParam();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected handleQueryParams(): void {
    this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((params: ParamMap): void => {
      this.cameFromNewTab = params.get('openedTab') === 'true';
      this.checkIfRedirectedAfterForbiddenCheckout(params);
    });
  }

  protected checkIfRedirectedAfterForbiddenCheckout(params: ParamMap): void {
    this.hasRedirectedAfterForbiddenCheckout = params.get(HAS_ACCESSED_FORBIDDEN_CHECKOUT_QUERY_PARAM_NAME) !== null;

    if (!this.hasRedirectedAfterForbiddenCheckout) {
      return;
    }

    this.clearRedirectedQueryParam(params);
  }

  protected clearRedirectedQueryParam(params: ParamMap): void {
    const queryParamKeys: string[] = params.keys;
    const queryParamMap = Object.assign(
      {},
      ...queryParamKeys.map((key: string): { [key: string]: string } => {
        return {
          [key]: params.get(key),
        };
      }),
    );

    this.router.navigate([], {
      queryParams: Object.assign({}, queryParamMap, {
        [HAS_ACCESSED_FORBIDDEN_CHECKOUT_QUERY_PARAM_NAME]: null,
      }),
    });
  }

  public goBack(): void {
    if (this.definedBackAction === undefined) {
      this.handleBackNavigationHistory();

      return;
    }

    this.definedBackAction();
  }

  handleBackNavigationHistory(): void {
    if (
      !this.globalObjectService.window.history.length ||
      this.cameFromNewTab ||
      this.hasRedirectedAfterForbiddenCheckout
    ) {
      this.router.navigate(['main', 'home']);
      this.hasRedirectedAfterForbiddenCheckout = false;

      return;
    }

    this.location.back();
  }

  addOpenedTabToQueryParam(): void {
    if (
      this.location.getState() &&
      (<INavigationState>this.location.getState()).navigationId === this.NO_BROWSER_HISTORY
    ) {
      this.router.navigate([], {
        queryParams: { openedTab: true },
        queryParamsHandling: 'merge',
        replaceUrl: true,
        preserveFragment: true,
      });
    }
  }
}
