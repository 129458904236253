import { IBirthdateFields } from '@libs/interfaces/registration/interfaces/birthdate-fields.interface';
import { Registration } from '@libs/interfaces/registration/registration';
import { SomeGender } from '@libs/interfaces/registration/types/genders.type';
import { SomeSexualPreference } from '@libs/interfaces/registration/types/sexual-preferences.type';
import { SomeSugarRole } from '@libs/interfaces/registration/types/sugar-roles.type';
import { generateRange } from '@libs/utils/array-functions';

export class RegistrationUtils {
  public static shouldOpenCollapse(recoveredAnswer: string): boolean {
    if (recoveredAnswer !== '') {
      return false;
    }

    return true;
  }

  public static getIndexRangeForFileInputs(sugarRole: SomeSugarRole, multiUploadAllowed = true): number[] {
    if ((sugarRole !== undefined && RegistrationUtils.isDaddyOrMommySugarRole(sugarRole)) || !multiUploadAllowed) {
      return [0];
    }

    return generateRange([0, 2]);
  }

  public static isDaddyOrMommySugarRole(sugarRole: SomeSugarRole): boolean {
    return sugarRole === Registration.SUGAR_ROLE_DADDY_OR_MOMMY;
  }

  public static isAgeAboveExpressApprovalThreshold(daddyOrMommyAge: number): boolean {
    return daddyOrMommyAge > Registration.DADDY_AND_MOMMY_EXPRESS_APPROVAL_AGE_THRESHOLD;
  }

  public static isEligibleToFinishLater(sugarRole: SomeSugarRole, birthdateObject: IBirthdateFields): boolean {
    const age: number = RegistrationUtils.getRegistreeAge(birthdateObject);

    return (
      RegistrationUtils.isDaddyOrMommySugarRole(sugarRole) && RegistrationUtils.isAgeAboveExpressApprovalThreshold(age)
    );
  }

  public static getRegistreeAge(birthdateObject: IBirthdateFields): number {
    const birthdate: Date = new Date(`${birthdateObject.year}-${birthdateObject.month}-${birthdateObject.day}`);
    const ageDifferenceInMilliseconds: number = Date.now() - birthdate.getTime();

    return new Date(ageDifferenceInMilliseconds).getUTCFullYear() - 1970;
  }

  public static getBirthYearRangeTuple(): number[] {
    const laterYear: number = new Date().getFullYear() - Registration.MINIMUM_AGE;
    const earlierYear: number = laterYear - Registration.DIFFERENCE_TO_100_YEARS;

    return [earlierYear, laterYear];
  }

  public static removeUndueSpacesFromUsername(username: string): string {
    const hasUndueSpaces: boolean = Registration.USERNAME_UNDUE_SPACES_PATTERN.test(username);

    if (!hasUndueSpaces) {
      return username;
    }

    return username.replace(Registration.USERNAME_UNDUE_SPACES_PATTERN, '');
  }

  public static padDateWithZero(dayOrMonth: number): string {
    return String(dayOrMonth).padStart(2, '0');
  }

  public static getCurrentStep(routerUrl: string): string {
    const outletName: string = Registration.MODAL_OUTLET_NAME;
    const outletNameIndicatorLength: number = outletName.length + 1;
    const startTrimIndex: number = routerUrl.indexOf(outletName) + outletNameIndicatorLength;
    const unwantedCharacters: RegExp = /\)|\s/g;
    const currentStep: string = routerUrl.substring(startTrimIndex).replace(unwantedCharacters, '');

    return currentStep;
  }

  public static getGenderOptions(): SomeGender[] {
    return [Registration.GENDER_MALE, Registration.GENDER_FEMALE];
  }

  public static getGenderTranslationKey(gender: SomeGender): string {
    return {
      [Registration.GENDER_FEMALE]: Registration.GENDER_FEMALE_TEXT,
      [Registration.GENDER_MALE]: Registration.GENDER_MALE_TEXT,
    }[gender];
  }

  public static getSugarRoleOptions(): SomeSugarRole[] {
    return [Registration.SUGAR_ROLE_DADDY_OR_MOMMY, Registration.SUGAR_ROLE_BABY];
  }

  public static getSugarRoleTranslationKey(sugarRole: SomeSugarRole, gender: SomeGender): string {
    if (!RegistrationUtils.isDaddyOrMommySugarRole(sugarRole)) {
      return Registration.SUGAR_BABY_TEXT;
    }

    return {
      [Registration.GENDER_MALE]: Registration.SUGAR_DADDY_TEXT,
      [Registration.GENDER_FEMALE]: Registration.SUGAR_MOMMY_TEXT,
    }[gender];
  }

  public static isMale(gender: SomeGender): boolean {
    return gender === Registration.GENDER_MALE;
  }

  public static getSexualPreferenceOptions(): SomeSexualPreference[] {
    return [Registration.GENDER_MALE, Registration.GENDER_FEMALE, Registration.BISEXUAL];
  }

  public static getSexualPreferenceTranslationKey(sexualPreference: SomeSexualPreference): string {
    return {
      [Registration.GENDER_FEMALE]: Registration.USER_LOOKING_FOR_WOMEN_TEXT,
      [Registration.GENDER_MALE]: Registration.USER_LOOKING_FOR_MEN_TEXT,
      [Registration.BISEXUAL]: Registration.USER_LOOKING_FOR_MEN_AND_WOMEN_TEXT,
    }[sexualPreference];
  }

  public static getSugarRoleShortName(gender: SomeGender, sugarRole: SomeSugarRole): string {
    if (sugarRole === Registration.SUGAR_ROLE_DADDY_OR_MOMMY && gender !== Registration.GENDER_FEMALE) {
      return Registration.JUST_DADDY_TEXT;
    }

    if (sugarRole === Registration.SUGAR_ROLE_DADDY_OR_MOMMY && gender === Registration.GENDER_FEMALE) {
      return Registration.JUST_MOMMY_TEXT;
    }

    return Registration.JUST_BABY_TEXT;
  }
}
