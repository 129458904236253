import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CouponServiceCommon } from '@libs/modules/main/services/coupon.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

import { Config } from '@meupatrocinio/config';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable()
export class CouponService extends CouponServiceCommon {
  constructor(protected authHttp: AuthHttpService) {
    super(authHttp);
  }

  public redeem(couponCode: string): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + this.ENDPOINT_COUPON_REDEEM;
    return this.authHttp.post(endpoint, { coupon_code: couponCode });
  }
}
