import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';

import { Medias } from '@libs/utils/medias';

import {
  ISrcsetConfigItem,
  ISrcsetDimension,
  srcsetConfig,
} from '@meupatrocinio/modules/main/shared/img-srcset/srcset-dimensions';

@Component({
  selector: 'mp-img-srcset',
  templateUrl: './img-srcset.component.html',
})
export class ImgSrcsetComponent implements OnInit {
  @Input() url: string;
  @Input() imgClass: string;
  @Input() place: string;

  public srcsets: number[][];

  protected placeKey: string;
  protected configItem: ISrcsetConfigItem;

  constructor(protected breakpointObserver: BreakpointObserver) {
    //
  }

  ngOnInit(): void {
    this.configItem = srcsetConfig[this.getPlaceKey()];
    this.srcsets = this.getSrcsets();
  }

  protected getPlaceKey(): string {
    const srcsetConfigKeys: string[] = Object.keys(srcsetConfig);

    if (this.placeKey !== undefined) {
      return this.placeKey;
    }

    return srcsetConfigKeys.find((key: string): boolean => this.place === srcsetConfig[key].place);
  }

  protected isSmallScreen(): boolean {
    return this.breakpointObserver.isMatched(Medias.MAX_WIDTH_991);
  }

  protected getSrcsets(): number[][] {
    const first: number = 0;

    if (this.isSmallScreen()) {
      return [this.configItem.dimensions[first]];
    }

    return this.configItem.dimensions;
  }

  protected getDimensionUrls(dimensions: number[]): string[] {
    let size = 0;

    return dimensions.map(
      (dimension: number): string => this.url.replace(/(w|h)(\_)(\d{1,3})/g, `$1$2${dimension}`) + ` ${++size}x`,
    );
  }

  getMedia(index: number): string {
    if (this.configItem.medias !== undefined) {
      return this.configItem.medias[index];
    }

    return '';
  }

  getSrcsetString(index: number): string {
    const dimension: ISrcsetDimension = this.configItem.dimensions[index];

    return this.getDimensionUrls(dimension).join(',');
  }
}
