import { Injectable } from '@angular/core';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { Config } from '@meupatrocinio/config';
import { GlobalObjectService } from '../global-object-service';

@Injectable()
export class TypeFormService {
  constructor(protected globalObjectService: GlobalObjectService) {
    //
  }

  public callTypeForm(user: UserCommon, profileReported: IProfile) {
    const baseUrl = Config.typeFormUrl;

    const dataReport = {
      complainant_id: user.profile_id,
      complainant_username: user.username,
      complainant_user_type: user.profiletype,
      complained_user_id: profileReported.profile_id,
      complained_user_username: profileReported.username,
    };

    const typeFormUrl = `${baseUrl}${Object.keys(dataReport)
      .map((key) => `${key}=${dataReport[key]}`)
      .join('&')}`;

    this.globalObjectService.window.open(typeFormUrl);
  }
}
