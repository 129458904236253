import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ModalComponentCommon } from '@libs/modules/shared/modals/modal/modal.component.common';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';

@Component({
  selector: 'mp-pending-approval-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './pending-approval-modal.html',
})
export class PendingApprovalModalComponent extends ModalComponentCommon implements IModalComponent {
  public data: never;

  constructor(protected modalRef: ModalRef) {
    super();
  }

  closeModal(): void {
    this.modalRef.close();
  }
}
