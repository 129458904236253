import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { ValidationControlErrorComponentCommon } from '@libs/utils/components/validation-error/validation-error.component.common';

@Component({
  selector: 'mp-validation-control-error',
  templateUrl: './validation-control-error.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationControlErrorComponent extends ValidationControlErrorComponentCommon {
  constructor(protected changeDetectorReference: ChangeDetectorRef) {
    super(changeDetectorReference);
  }
}
