import { Overlay, OverlayConfig } from '@angular/cdk/overlay';

import { IModalViewOptions } from '@libs/components/modal-view/interface/modal-view-options';

export class ModalViewOverlayConfig {
  protected readonly BACKDROP_CLASS: string = 'modal-view__backdrop';

  constructor(protected overlay: Overlay) {
    //
  }

  public getOverlayConfig(options: IModalViewOptions): OverlayConfig {
    return new OverlayConfig({
      height: '100%',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      hasBackdrop: true,
      disposeOnNavigation: options.disposeOnNavigation ?? true,
      backdropClass: this.BACKDROP_CLASS,
      panelClass: this.getPanelClasses(options),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
  }

  protected getPanelClasses(options: IModalViewOptions): string[] {
    const classes: string[] = [];

    classes.push('modal-view');

    if (options.closeOnBackdropClick) {
      classes.push('modal-view__pointer-events');
    }

    return classes;
  }
}
