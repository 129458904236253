import { Action } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const PROFILE_VIEWED_ME_RECEIVED_ACTION: string = 'PROFILE_VIEWED_ME_RECEIVED_ACTION';
export const PROFILE_VIEWED_ME_REFRESHED_ACTION: string = 'PROFILE_VIEWED_ME_REFRESHED_ACTION';
export const PROFILE_VIEWED_ME_PREPEND_ACTION: string = 'PROFILE_VIEWED_ME_PREPEND_ACTION';
export const PROFILE_VIEWED_ME_REMOVED_ACTION: string = 'PROFILE_VIEWED_ME_REMOVED_ACTION';
export const VIEWED_TTL_UPDATED_ACTION: string = 'VIEWED_TTL_UPDATED_ACTION';

export interface IProfileViewedMeReceivedActionPayload {
  profiles: IProfile[];
}

export class ProfileViewedMeReceivedAction implements Action {
  readonly type: string = PROFILE_VIEWED_ME_RECEIVED_ACTION;

  constructor(public payload: IProfileViewedMeReceivedActionPayload) {
    //
  }
}

export class ProfilesViewedRefreshedAction implements Action {
  readonly type: string = PROFILE_VIEWED_ME_REFRESHED_ACTION;
}

export class ProfileViewedMePrependAction implements Action {
  readonly type: string = PROFILE_VIEWED_ME_PREPEND_ACTION;

  constructor(public payload: IProfileViewedMeReceivedActionPayload) {
    //
  }
}

export interface IViewedTTLActionPayload {
  ttl: number;
}

export class ViewedTTLUpdatedAction implements Action {
  readonly type: string = VIEWED_TTL_UPDATED_ACTION;

  constructor(public payload: IViewedTTLActionPayload) {
    //
  }
}

export class ProfilesViwedMeRemovedAction implements Action {
  readonly type: string = PROFILE_VIEWED_ME_REMOVED_ACTION;

  constructor(public payload: IProfileViewedMeReceivedActionPayload) {
    //
  }
}
