import { Directive } from '@angular/core';

import { ICity } from '@libs/services/location/city/city';
import { ICountry } from '@libs/services/location/country/country';
import { IRegion } from '@libs/services/location/region/region';

@Directive()
export abstract class LocationServiceCommon {
  public abstract loadCountries(): void;

  public abstract loadRegions(country: string): void;

  public abstract loadCities(region: string): void;

  public abstract getCountryCode(countries: ICountry[], countryName: string): string;

  public abstract getRegionCode(regions: IRegion[], regionName: string): string;

  public abstract getCityCode(cities: ICity[], cityName: string): number;

  public abstract getCountryName(countries: ICountry[], countryCode: string): string;

  public abstract getRegionName(regions: IRegion[], regionCode: string): string;

  public abstract getCityName(cities: ICity[], cityCode: number): string;

  public abstract getCountriesNames(countries: ICountry[]): string[];

  public abstract getRegionsNames(regions: IRegion[]): string[];

  public abstract getCitiesNames(cities: ICity[]): string[];

  public abstract filterCountries: (country: ICountry) => boolean;
}
