import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListServiceCommon } from '@libs/modules/main/services/list/list.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';

import { Config } from '@meupatrocinio/config';
import { AuthRequestFacade } from '@meupatrocinio/modules/main/services/auth-request-facade/auth-request-facade';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable()
export class ListService extends ListServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpService,
    protected authRequestFacade: AuthRequestFacade,
  ) {
    super(store, authHttp, authRequestFacade);
  }

  public downloadHomeFeatured(page = 1): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}search/featured?page=${page}`;

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  public downloadHomeNearby(page = 1): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}search/nearby?page=${page}`;

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  public downloadHomeNewUsers(page = 1): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}search/newUsers?page=${page}`;

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  public downloadBlockedUsers(page = 1): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}search/blocked-list?page=${page}`;

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  public downloadFavMeProfiles(page = 1): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}search/bookmarked?page=${page}`;

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  public downloadMyFavProfiles(page = 1): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}search/bookmark?page=${page}`;

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  public downloadFavMelt(page = 1): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}search/melt?page=${page}`;

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  public downloadViewedMeProfiles(page = 1): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}search/viewed?page=${page}`;

    return this.authRequestFacade.get({
      endpoint,
    });
  }

  public downloadRecommendedProfiles(page: number) {
    const endpoint = `${Config.serverIp}v2/search/profiles?page=${page}`;

    return this.authRequestFacade
      .get({
        endpoint,
      })
      .pipe(
        exponentialBackoff(),
        map(
          (
            response: IAuthResponse<{
              totalPages: number;
              totalItems: number;
            }>,
          ) => {
            return {
              ...response,
              last_page: response.data?.totalPages ?? 1,
              total: response.data?.totalItems ?? 0,
            };
          },
        ),
      );
  }
}
