export const isEqual = (string1: string, string2: string): boolean => {
  return string1 === string2;
};

export const isNotNull = (content: string): boolean => {
  return content !== null && content !== undefined && content !== '';
};

export const divideBy = (divisor: number, dividend: number): number => {
  return dividend / divisor;
};

export const multiplyBy = (multiplier: number, multiplicand: number): number => {
  return multiplicand * multiplier;
};

export const remainderOfDivision = (value: number, module: number): number => {
  return value % module;
};
