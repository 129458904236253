<ng-container *ngIf="submenuItems"></ng-container>
<div
    id="home-submenu"
    class="submenu-list"
    [ngClass]="{'white-theme-submenu': (isWhiteThemeEnabled$ | async)}"
>
    <ul class="submenu-list__navigation-list container" role="tablist">
        <li
            class="submenu-list__item items-center gap-4"
            *ngFor="let listItem of submenuItems; trackBy: trackByRoute"
            role="presentation"
            [ngClass]="getActiveClass(listItem.index)"
            (click)="selectItem(listItem.index)"
        >
            <a class="submenu-list--action" aria-controls="home" role="tab" data-toggle="tab">
                {{ listItem.displayName | translate }}
            </a>
            <a
                class="refresh-icon hidden-sm hidden-xs"
                [ngClass]="{'animate-spin': isLoading(listItem.routeName, listItem.index)}"
                data-toggle="tooltip"
                data-placement="bottom"
                [title]="'common.refresh' | translate"
            >
                <i class="material-icons" aria-hidden="true"> &#xE863; </i>
            </a>
        </li>
    </ul>
</div>
