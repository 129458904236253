import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { PaymentInfoActions, PaymentInfoSelectors } from '@libs/store/payment-info';

import { UuidGenerator } from '@libs/utils/uuid-generator/uuid-generator';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions';
import { CPFValidator } from '@meupatrocinio/modules/payment-v2/validators/cpf-validator';
import { IResolvedProductRouteInfo } from '@meupatrocinio/resolvers/payment/interfaces/resolved-product-route-info.interface';
import { ModalService } from '@meupatrocinio/services/modal.service';

@Component({
  selector: 'mp-boleto-payment-form',
  templateUrl: 'boleto-payment-form.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoletoPaymentFormComponent {
  @Input() isExpressApproval = false;
  @Input() type: MembershipType = 0;
  @Input() period = 0;
  @Input() price = 0;
  @Input() product!: IResolvedProductRouteInfo;
  @Input() productDescription = '';

  public cpf: string;

  public isLoading: Observable<boolean> = of(false);
  public boletoUrlToCopy: Observable<string> = of('');
  public isProcessingPayment: Observable<boolean> = of(false);
  public cpfValidator: CPFValidator;

  constructor(
    protected clipboard: Clipboard,
    protected modalService: ModalService,
    protected paymentHelpersService: PaymentHelpersService,
    protected store: Store,
    protected translateService: TranslateService,
  ) {
    this.cpfValidator = new CPFValidator(
      this.paymentHelpersService,
      this.modalService,
      this.translateService,
      this.type,
      this.period,
    );
  }

  ngOnInit() {
    this.store.dispatch(PaymentInfoActions.resetBoletoUrl());
    this.boletoUrlToCopy = this.store.pipe(select(PaymentInfoSelectors.selectBoletoUrl));
    this.isLoading = this.store.pipe(select(PaymentInfoSelectors.selectIsPaying));
  }

  public makePayment() {
    if (!this.isFormValid()) {
      return;
    }

    const { productUuid, variantUuid } = this.product;
    const idempotencyKey = UuidGenerator.generate();

    this.store.dispatch(
      ProviderSwitchActions.handleBoletoPayment({
        paymentData: {
          productUuid,
          variantUuid,
          taxId: this.cpf,
          idempotencyKey,
          totalPrice: this.price,
        },
      }),
    );
  }

  protected isFormValid() {
    return this.cpfValidator.isFormValid(this.cpf);
  }

  public copyToClipboard(url: string) {
    this.clipboard.copy(url);
  }
}
