import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { EmailSuggestionFacade } from '@libs/really-shared/email-suggestion/email-suggestion.facade';

@Injectable()
export class EmailSuggestionService {
  constructor(
    protected emailSuggestionFacade: EmailSuggestionFacade,
    protected translateService: TranslateService,
  ) {
    //
  }

  public isEmailSuggestionEmpty(emailSuggestion: string) {
    if (emailSuggestion !== '') {
      return false;
    }

    return true;
  }

  public getEmailSuggestion(email: string) {
    const emailChecker = this.emailSuggestionFacade.getEmailSuggestionMachine();
    const emailChecked = emailChecker(email);

    if (emailChecked.length === 0) {
      return {
        suggestionString: '',
        suggestedEmail: '',
      };
    }

    const suggestedEmail = emailChecked[0].corrected;

    return {
      suggestionString: this.translateService.instant('modules.registration.pages.registration2.email.suggestion', {
        warningEmoji: '&#9888;&#65039;',
        email: suggestedEmail,
      }),
      suggestedEmail,
    };
  }
}
