import { createAction } from '@ngrx/store';

enum Actions {
  INITIALIZE = '[Datadog] - Initialize',
  SET_USER = '[Datadog] - Set User',
}

export const initialize = createAction(Actions.INITIALIZE);

export const setUser = createAction(Actions.SET_USER);
