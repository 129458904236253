import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ComponentNames } from '@libs/effects/toast/common/component-names';
import { IToastOptions } from '@libs/modules/shared/toast-common/toast-options.interface';
import { TOAST_OPTIONS } from '@libs/modules/shared/toast-common/toast.tokens';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { MeltToastActions, MeltToastSelectors } from '@libs/store/melt-toast';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

import { ToastrService } from 'ngx-toastr';
import { Memoize } from 'typescript-memoize';

@Component({
  selector: 'mp-melt-toast',
  templateUrl: './melt-toast.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeltToastComponent implements OnDestroy, OnInit {
  public static componentName: string = ComponentNames.MELT_TOAST_COMPONENT;
  public profiles$: Observable<IProfile[]> = of([]);

  protected subscriptions: Subscription[] = [];

  constructor(
    @Inject(TOAST_OPTIONS) public toastOptions: IToastOptions,
    protected authenticationService: AuthenticationService,
    protected translate: TranslateService,
    protected store: Store,
    protected toastrService: ToastrService,
  ) {
    //
  }

  ngOnInit(): void {
    this.profiles$ = this.store.pipe(select(MeltToastSelectors.selectAll));
    this.handleOnTap();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  getImages(profiles: IProfile[]): string[] {
    return profiles.slice(-2).map((profile: IProfile): string => this.getProfilePicture(profile));
  }

  @Memoize()
  getProfilePicture(profile: IProfile): string {
    return ImageHelper.getProfilePicture(profile, this.authenticationService.get(), UserCommon.PICTURE_SMALL);
  }

  handleOnTap(): void {
    if (this.toastOptions.toast === undefined) {
      return;
    }

    this.subscriptions.push(
      this.toastOptions.toast.onTap.pipe(take(1)).subscribe({
        next: (): void => {
          this.onTap();
          this.handleProfilesDeletion();
        },
      }),
    );
  }

  onTap(): void {
    this.store.dispatch(MeltToastActions.handleTapOnMeltToast());
  }

  trackByFn(index: number): number {
    return index;
  }

  close(): void {
    this.handleProfilesDeletion();

    if (this.toastOptions.toast === undefined) {
      return;
    }

    this.toastrService.remove(this.toastOptions.toast.toastId);
  }

  handleProfilesDeletion(): void {
    this.store.dispatch(MeltToastActions.handleProfilesDeletion());
  }
}
