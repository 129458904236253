import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { UserCommon } from '@libs/shared/user/user.common';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { UpsellService } from '@meupatrocinio/services/upsell/upsell.service';

@Injectable()
export class UpsellEffects {
  showUpsellOnLogin$: Observable<void> = createEffect(
    (): Observable<void> =>
      this.authenticationService.onLogin$.pipe(
        filter((): boolean => this.upsellService.isEligibleToSeeAd(this.user)),
        tap({
          next: (): void => {
            this.upsellService.showUpsell();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected authenticationService: AuthenticationService,
    protected upsellService: UpsellService,
  ) {
    //
  }

  protected get user(): UserCommon {
    return this.authenticationService.get();
  }
}
