import { IBlogPost } from '@libs/shared/interfaces/blog-post.interface';
import { BlogPostsReceivedAction, BLOG_POSTS_RECEIVED_ACTION } from '@libs/store/blog/actions/blog-posts.action';

export function blog(state: IBlogPost[] = [], action: BlogPostsReceivedAction): IBlogPost[] {
  switch (action.type) {
    case BLOG_POSTS_RECEIVED_ACTION:
      return (action.payload.posts || []).slice();

    default:
      return state;
  }
}
