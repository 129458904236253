<div class="dial-box rules-modal">
    <button
        class="close-button"
        (click)="decline()"
    >
        <i
            class="material-icons"
            aria-hidden="true"
        >
            &#xE5CD;
        </i>
    </button>
    <h2 class="title">
        {{ 'modules.main.shared.pending_review_modal.title' | translate }}
    </h2>
    <p class="subtext">
        {{ 'modules.main.shared.pending_review_modal.subtext' | translate }}
    </p>
    <div class="buttons-container">
        <button
            class="mp-main-button bt-shadow bt-md"
            (click)="agree()"
        >
            {{ 'modules.main.shared.pending_review_modal.i_agree' | translate }}
        </button>
    </div>
</div>
