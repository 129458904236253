import { Directive, Input } from '@angular/core';

@Directive()
export abstract class ActionbarCallToActionComponentCommon {
  @Input() action: (() => void) | undefined;
  @Input() label: string | undefined;
  @Input() outline = false;
  @Input() borderLess = false;
  @Input() borderLessWithNormalWeight = false;
  @Input() disabled = false;
  @Input() noMargin = false;

  get isDisabled(): boolean {
    return this.disabled;
  }

  showCssClasses(): string[] {
    const classes: string[] = [];

    if (this.outline) {
      classes.push('outline-white');
    }

    if (this.borderLess) {
      classes.push('border-less');
    }

    if (this.noMargin) {
      classes.push('no-margin');
    }

    if (this.borderLessWithNormalWeight) {
      classes.push('white-borderless-normal');
    }

    return classes;
  }
}
